import React from 'react'
import Login_header from './Login_header'
import Menu from './Menu'

function Home() {
    return (
        <>
            <Login_header />
            <Menu />
        </>
    )
}

export default Home
