import React, { useEffect, useState } from 'react'
import { Row, Col, Table, Form, Button } from 'react-bootstrap'
import Select from "react-select";
import * as Common from "../../Common";

function Master_ledger_location(props) {
    const sid = sessionStorage.getItem("sessionId");
    const [lcLocOptions, setLcLocOptions] = useState([]);
    const [lcLocation, setLcLocation] = useState({ value: 0, label: 'Select' });
    const [lcStatus, setLcStatus] = useState("1");

    const [ledgerLink, setLedgerLink] = useState([]);

    const [onceRun, setOnceRun] = useState(false);

    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.callApi(Common.apiEntity, [sid, "listjson"], result => {
                setLcLocOptions(JSON.parse(result));
            });
            setLedgerLink(props.locationLink);
            setOnceRun(true);
        }
    }, [onceRun]);

    useEffect(() => {
        setLedgerLink(props.locationLink);
    }, [props.locationLink])

    const saveIsdChanges = () => {
        const obj = {
            location: lcLocation.value,
            status: lcStatus,
            ldgsrno: props.ldgsrno,
            ldggroupsrno: props.ldggrpsrno
        }
        if (lcLocation.value == 0) {
            return;
        } else {
            Common.callApi(Common.apiLedger, [sid, "isdstatus", JSON.stringify(obj)], result => {
                setLedgerLink(JSON.parse(result));
            });
        }
    }

    return (
        <>
            {
                props.visiblePg === "Q" ? null :
                    <Row>
                        <Col className='col-md-3 col-6'>
                            <Form.Group>
                                <Form.Label>Location</Form.Label>
                                <Select isDisabled={props.visiblePg === "Q"} options={lcLocOptions} value={lcLocation} onChange={v => setLcLocation(v)} />
                            </Form.Group>
                        </Col>
                        <Col className='col-md-3 col-6'>
                            <Form.Group>
                                <Form.Label>Status</Form.Label>
                                <Form.Select disabled={props.visiblePg === "Q"} value={lcStatus} onChange={e => setLcStatus(e.target.value)}>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>

                        <Col style={{ marginTop: "33px" }}>
                            <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => saveIsdChanges()}>Save</Button>
                        </Col>
                    </Row>
            }
            <Row>&nbsp;</Row>
            <Table responsive striped bordered>
                <thead>
                    <tr>
                        <th>Srno</th>
                        <th>Location</th>
                        <th>Active</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        ledgerLink.map((item, index) => (
                            <tr key={index}>
                                <td>{item.entity_id}</td>
                                <td>{item.entity_name}</td>
                                <td>{item.active}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </>
    )
}

export default Master_ledger_location
