import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Form, Button, Table, Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Menu from '../Menu';
import Login_header from '../Login_header';
import * as Common from '../Common';
import * as Picklist from '../Picklist';
import DatePicker from 'react-datepicker';
import AsyncSelect from 'react-select/async';
import Dialog from '../Dialog';
import $ from "jquery";

const Master_IBR = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');

    const [userRight, setUserRight] = useState([]);
    const [onceRun, setOnceRun] = useState(false);
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");
    const [isClickedUpload, setIsClickedUpload] = useState(false);

    const [ibrCurrency, setIbrCurrency] = useState(null);
    const [currencyCode, setCurrencyCode] = useState('');
    const [headerData, setHeaderData] = useState([]);
    const [ibrRate, setIbrRate] = useState("");
    const [filterIbrLimit, setFilterIbrLimit] = useState('1');
    const [filterIbrFromDate, setFilterIbrFromDate] = useState(new Date());
    const [filterIbrToDate, setFilterIbrToDate] = useState(new Date());
    const [ibrFile, setIbrFile] = useState('');

    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.callApi(Common.apiRight, [sid, 'all', 'IBR'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);


    const handleCurrencyChange = (value) => {
        setIbrCurrency(value);
        const currency = value.id.split('^');
        setCurrencyCode(currency[1]);
    }

    //-------------------------------------Fetching Data From Database--------------------------------------
    const list = () => {
        const obj = {
            filterLimit: filterIbrLimit,
            filterFromDate: Common.dateYMD(filterIbrFromDate),
            filterToDate: Common.dateYMD(filterIbrToDate),
        }
        Common.callApi(Common.apiMasterIBR, [sid, 'list', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);
            setHeaderData(resp);
        });
    }

    //-------------------------------------Insert Data in Database-----------------------------------------
    const addData = () => {
        var msg = [], i = 0;
        if (ibrCurrency === null || ibrRate === "") {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (ibrCurrency === null ? " Currency." : '');
            msg[i++] = (ibrRate === "" ? "Rate." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const obj = {
                filterLimit: filterIbrLimit,
                filterFromDate: Common.dateYMD(filterIbrFromDate),
                filterToDate: Common.dateYMD(filterIbrToDate),

                isd: currencyCode,
                rate: ibrRate,
            }
            Common.callApi(Common.apiMasterIBR, [sid, 'add', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                setHeaderData(resp);
                setIbrCurrency(null);
                setIbrRate("");
            });
        }
    }

    const uploadBtn = () => {
        setIsClickedUpload(true);
    }


    const uploadFile = () => {
        console.log(document.getElementById('fileExcel').files[0]);
        $('.loader').show();
        if (document.getElementById('fileExcel').files[0] === undefined) {
            $('.loader').hide();
            setMsgText("Please select a file to upload.");
            setMsgBox(true);
        } else {
            var tempArray = document.getElementById('fileExcel').files[0].name.split('.');
            if (tempArray.length > 2) {
                $('.loader').hide();
                setMsgText("(.) not allowed in file name.");
                setMsgBox(true);
            } else if (document.getElementById('fileExcel').files[0].size > 10 * 1024 * 1024) {  // 10 MB
                $('.loader').hide();
                setMsgText("Please select a file with in 10 MB size.");
                setMsgBox(true);
            } else if (tempArray[1] != 'xlsx') {
                $('.loader').hide();
                setMsgText("File extension should be in xlsx format");
                setMsgBox(true);
            } else {
                const obj = {
                    sid: sid,
                    srno: 6666,
                    isd: currencyCode,
                    ibrFile: ibrFile,
                    uploadType: "uploadIbr",
                    pageright: "UPLOADIBR",

                    filterLimit: filterIbrLimit,
                    filterFromDate: Common.dateYMD(filterIbrFromDate),
                    filterToDate: Common.dateYMD(filterIbrToDate),
                }
                Common.uploadApi(JSON.stringify(obj), 'fileExcel', (result) => {
                    let resp = JSON.parse(result);
                    // console.log(resp);
                    $('.loader').hide();
                    // setHeaderData(resp.docdetail);
                    setMsgText(resp.msg)
                    setMsgBox(true);
                    setIsClickedUpload(false);
                    setIbrFile("");
                });
            }
        }
    }

    const genRateCard = () => {
        Common.callApi(Common.apiMasterIBR, [sid, "ratecard"], result => {
            console.log(result);
        });
    }

    const handleDownloadBadge = () => {
        var obj = {
            right: 'IBR'
        }
        Common.callDownloadApiPost(Common.apiReport, "post", [sid, JSON.stringify(obj)]);
    }


    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h3>Master IBR</h3>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                <Row>
                    {
                        userRight.query === "1" ?
                            <>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                                        <DatePicker className="form-control"
                                            selected={filterIbrFromDate}
                                            onChange={(date) => setFilterIbrFromDate(date)}
                                            dateFormat="dd/MM/yyyy"
                                            showYearDropdown
                                            showMonthDropdown
                                            useShortMonthInDropdown
                                            dropdownMode="select"
                                            peekNextMonth
                                            customInput={
                                                <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>To<span className="colorThemeRed">*</span></Form.Label>
                                        <DatePicker className="form-control"
                                            selected={filterIbrToDate}
                                            onChange={(date) => setFilterIbrToDate(date)}
                                            dateFormat="dd/MM/yyyy"
                                            showYearDropdown
                                            showMonthDropdown
                                            useShortMonthInDropdown
                                            dropdownMode="select"
                                            peekNextMonth
                                            customInput={
                                                <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Limit<span className="text-danger">*</span></Form.Label>
                                        <Form.Select value={filterIbrLimit} onChange={e => setFilterIbrLimit(e.target.value)}
                                            required>
                                            <option value="1">All</option>
                                            <option value="10">50</option>
                                            <option value="50">100</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col style={{ marginTop: "34px" }}>
                                    <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => list()}>List</Button>
                                </Col>
                            </> : null
                    }
                </Row>
                <Row>
                    {
                        userRight.add === "1" ?
                            <>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Currency<span className="text-danger">*</span>
                                        </Form.Label>
                                        <AsyncSelect
                                            cacheOptions={false}
                                            defaultOptions={false}
                                            value={ibrCurrency}
                                            getOptionLabel={e => e.label + ' '}
                                            getOptionValue={e => e.id}
                                            loadOptions={(search) => Picklist.fetchCurrencyAllowedPicklist(search, 'CN')} // location = 1 , 2
                                            onInputChange={Picklist.handleInputChange}
                                            onChange={handleCurrencyChange}
                                        >
                                        </AsyncSelect>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Rate<span className="text-danger">*</span></Form.Label>
                                        <Form.Control
                                            value={ibrRate}
                                            onChange={(e) => Common.validateDecValue(e.target.value, setIbrRate)}
                                            maxLength={20}
                                            placeholder="Rate" />
                                    </Form.Group>
                                </Col>
                                <Col style={{ marginTop: "34px" }}>
                                    <Button variant='outline-primary' size='sm' className='buttonStyle' onClick={() => addData()}>Add</Button>
                                </Col>
                            </> : null
                    }
                </Row>
                <Row>&nbsp;</Row>
                <Row>
                    <Col>
                        {
                            (userRight.query === "1" || userRight.add === "1") ?
                                <>
                                    <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => uploadBtn()}>Upload</Button>&nbsp;
                                    <Button variant='outline-danger' className='buttonStyle' size='sm' onClick={() => genRateCard()}>Generate Rate Card</Button><br />
                                    <span className="mx-2"><Badge bg="primary" onClick={() => handleDownloadBadge()}>Download</Badge></span>
                                </> : null
                        }
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                {
                    isClickedUpload &&
                    <>
                        <Row>
                            <Col className='col-md-3 col-6'>
                                <Form.Group controlId="fileExcel">
                                    <Form.Label>Choose File<span className="text-danger">*</span></Form.Label>
                                    <Form.Control type="file" value={ibrFile} onChange={(e) => setIbrFile(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>&nbsp;</Row>
                        <Row>
                            <Col><Button variant="outline-success" className='buttonStyle' size='sm' onClick={() => uploadFile()}>Submit</Button>{' '}</Col>
                        </Row>
                    </>
                }
                <Row>&nbsp;</Row>
                <Row>
                    <Col>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>ISD</th>
                                    <th>Rate</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    headerData?.map((res) => (
                                        <tr key={res.ibr_srno}>
                                            <td>{res.ibr_isd}</td>
                                            <td>{res.ibr_rate}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
            </div>
        </>
    )
}

export default Master_IBR