import React, { useEffect, useState } from 'react';
import * as Common from "../../Common";
import { Button, Col, Container, Row, Form, Table } from 'react-bootstrap';

const Tran_fa_book_print_advance = (props) => {

    const sid = sessionStorage.getItem('sessionId');
    const [printDtlData, setPrintDtlData] = useState([]);
    const [printHdrData, setPrintHdrData] = useState([]);
    const [creditValue, setCreditValue] = useState(0);

    useEffect(() => {
        const obj = {
            srno: props.srno,
            location: props.location,
            type: props.type,
        }
        Common.callApi(Common.apiBookEntry, [sid, "download", JSON.stringify(obj)], result => {
            let resp = JSON.parse(result);
            console.log(resp.header);
            console.log(resp.detail);
            setPrintDtlData(resp.detail);
            setPrintHdrData(resp.header[0]);
            setCreditValue(resp.credit);
            console.log(resp.header[0].tfh_total_credit);
        });
    }, []);

    const tableStyle = {
        borderCollapse: 'collapse',
        border: '1px solid black',
        fontFamily: 'Bookman Old Style',
    };

    const cellStyle = {
        border: '1px solid black',
        textAlign: 'left',
        fontFamily: 'Bookman Old Style',
    };

    const handleBack = () => {
        props.hidePrint('');
    }

    return (
        <>
            <div className='Button'>
                <Button variant='outline-success' size='sm' onClick={() => handleBack()}>Back</Button>
            </div>


            <div className='container-fluid'>
                <p style={{ marginLeft: '120px', fontFamily: 'Bookman Old Style' }}> ZENITH LEISURE HOLIDAYS LTD <br />
                    68, BALLYGUNGE, CIRCULAR ROAD, <br /> ANNAPURNA APARTMENT, 1ST FLOOR, <br />
                    KOLKOTA-700019 <br /> RBI LICENCE NO.-FE.KOL/ADCAT-II/01-2017</p>

                <p style={{ marginLeft: '220px', fontFamily: 'Bookman Old Style' }}>RECEIPT</p>

                <p style={{ marginLeft: '6px' }}>RECEIPT No: <span>Date:</span><br />
                    AMOUNT RECEIVED FROM : <br />
                    201002059946 <br />
                    ON ACCOUNT OF      : <br />
                    REFERENCE NO.      : 0 <br />
                    Cheque/Cash Detail for Payments received</p>

                <table className='col-md-5 col-12' style={tableStyle}>
                    <thead>
                        <tr>
                            <th style={{ ...cellStyle, textAlign: 'center' }}>Drawee Bank & Branch</th>
                            <th style={{ ...cellStyle, textAlign: 'center' }}>Cheque No.</th>
                            <th style={{ ...cellStyle, textAlign: 'center' }}>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {printDtlData.map((res) => (
                            <tr key={res.tfd_line_no}>
                                <td style={{ ...cellStyle }}>{res.mvli_ledger_name}</td>
                                <td style={{ ...cellStyle }}>{res.tfd_cheque_no}</td>
                                <td style={{ ...cellStyle }}>{res.tfd_amount}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <p style={{ marginLeft: '400px' }}>____________________________________________<br />
                    Stamp & Signature of Authorized Official</p>
                <p style={{ marginLeft: '6px' }}>Conditions :</p>
                <ol>
                    <li>Forex to be released, subject to be realization of cheque.</li>
                    <li>Forex rate shall be aplicable on the day on which FOREX is released <br /> and all relevant documents are duly signed by passanger.</li>
                    <li>Subject to KOLKOTA Jurisdiction.</li>
                    <li>Please retail Receipt & produce while availing forex.</li>
                </ol>
            </div>
        </>
    )
}

export default Tran_fa_book_print_advance
