import React, { useEffect, useState } from 'react'
import Login_header from '../Login_header'
import Menu from '../Menu'
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap'
import * as Common from "../Common";
import Dialog from '../Dialog';
import $ from "jquery";
import { json, useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';




const Master_dsr = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const [userRight, setUserRight] = useState([]);
    const [onceRun, setOnceRun] = useState(false);

    const [file, setFile] = useState("");
    const [dsrData, setDSRData] = useState([]);
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [radioType, setRadioType] = useState('N');
    var msg = [], i = 0;


    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.callApi(Common.apiRight, [sid, 'all', 'MASTER_DSR'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/'); // no query right
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);


    const uploadFile = () => {
        $('.loader').show();
        if (document.getElementById('importExcel').files[0] === undefined) {
            $('.loader').hide();
            setMsgText("Please select a file to upload.");
            setMsgBox(true);
        } else {
            var tempArray = document.getElementById('importExcel').files[0].name.split('.');
            if (tempArray.length > 2) {
                $('.loader').hide();
                setMsgText("(.) not allowed in file name.");
                setMsgBox(true);
            } else if (document.getElementById('importExcel').files[0].size > 10 * 1024 * 1024) {  // 10 MB
                $('.loader').hide();
                setMsgText("Please select a file with in 10 MB size.");
                setMsgBox(true);
            } else if (tempArray[1] != 'xlsx') {
                $('.loader').hide();
                setMsgText("File extension should be in xlsx format");
                setMsgBox(true);
            }
            else {

                const obj = {
                    // loc: locationCode,
                    srno: 8888,
                    uploadType: "uploadDSR",
                    pageright: "MASTER_DSR",
                    sid: sid,
                    file: file,
                    fromDate : Common.dateYMD(fromDate),
                    toDate:Common.dateYMD(toDate),
                    radioType:radioType
                    // loc: locationCode,
                    // allowedLocation:allowedLocation
                }
                Common.uploadApi(JSON.stringify(obj), 'importExcel', (result) => {
                    let resp = JSON.parse(result);
                    console.log(resp);
                    $('.loader').hide();
                    // setStockList(resp.docdetail.resarray);
                    // setTotalAmount(resp.docdetail.sumamount);
                    setMsgBox(true);
                    setMsgText(resp.msg);
                    setDSRData(resp.docdetail);
                    setFile('');
                });
            }
        }
    }

    const list = () => {

        if(!fromDate || !toDate){
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = ( !fromDate  ? 'Select from date' : '');
            msg[i++] = ( !toDate  ? 'Select to date' : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        }else{
            const obj = {
                fromDate :  Common.dateYMD(fromDate),
                toDate: Common.dateYMD(toDate),
                radioType:radioType
            }

            Common.callApi(Common.apiMasterDSR,[sid,'showDSR',JSON.stringify(obj)],(result)=>{
                let resp = JSON.parse(result);
                setDSRData(resp);
            })
        }

    }

    console.log(dsrData);



    return (
        <div>
            <Login_header />
            <Menu />

            <Container fluid>
                <Row>
                    <Col>
                        <h2>Master DSR</h2>
                    </Col>
                </Row>&nbsp;

                <Row>
                    <Col className='col-md-4 col-6'>
                        <Form.Group>
                            <Row style={{ marginTop: "30px" }}>
                                <Col>
                                    <Form.Check checked={radioType === "N"} onChange={() => setRadioType("N")} type='radio' label="New" />
                                </Col><Col>
                                    <Form.Check checked={radioType === "D"} onChange={() => setRadioType("D")} type='radio' label="Date" />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>&nbsp;

                <Row>
                    {
                        radioType === 'N' ?
                            <>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group controlId="importExcel">
                                        <Form.Label>Upload File<span className="text-danger">*</span></Form.Label>
                                        <Form.Control type="file" value={file} onChange={e => setFile(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </>
                            : radioType === 'D' ?
                                <>
                                    <Col className='col-md-3 col-6'>
                                        <Form.Group>
                                            <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                                            <DatePicker className="form-control"
                                                selected={fromDate}
                                                onChange={(date) => setFromDate(date)}
                                                dateFormat="dd/MM/yyyy"
                                                showYearDropdown
                                                showMonthDropdown
                                                useShortMonthInDropdown
                                                dropdownMode="select"
                                                peekNextMonth
                                                customInput={
                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col className='col-md-3 col-6'>
                                        <Form.Group>
                                            <Form.Label>To<span className="colorThemeRed">*</span></Form.Label>
                                            <DatePicker className="form-control"
                                                selected={toDate}
                                                onChange={(date) => setToDate(date)}
                                                dateFormat="dd/MM/yyyy"
                                                showYearDropdown
                                                showMonthDropdown
                                                useShortMonthInDropdown
                                                dropdownMode="select"
                                                peekNextMonth
                                                customInput={
                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </>

                                :
                                null
                    }
                </Row>
                <Row>&nbsp;</Row>

                <Row>
                    <Col >
                        {userRight.add === "1" && radioType === 'N' ?
                            <Button variant="outline-success" className='buttonStyle' size='sm' onClick={() => uploadFile()}>Submit</Button>
                            : null
                        }
                        &nbsp;
                        {
                            userRight.query === "1" && radioType === 'D' ?
                                <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => list()}>List</Button>
                                : null
                        }
                    </Col>
                </Row>

                <Row>&nbsp;</Row>

                <Row>
                    <Col>
                        <Table responsive bordered striped>
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>ISD</th>
                                    <th>Rate</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dsrData.map((item, index) => (
                                        <tr >
                                            <td>{item.md_product}</td>
                                            <td>{item.md_isd}</td>
                                            <td>{item.md_rate}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>

            <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                {msgText}
            </Dialog>
        </div>
    )
}

export default Master_dsr
