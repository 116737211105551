import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Table, Button, Form, Modal } from 'react-bootstrap'
import * as Common from "../Common";
import Dialog from '../Dialog';
import DialogYesNo from '../Dialog_Yes_No';
import { useNavigate } from 'react-router-dom';
import * as Picklist from "../Picklist";
import AsyncSelect from 'react-select/async';
import DatePicker from 'react-datepicker';
import Menu from '../Menu'
import Login_header from '../Login_header'
import Select from "react-select";
import $ from "jquery";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faL, faTrash } from '@fortawesome/free-solid-svg-icons';


const Bank_cheque_book_cancellation = () => {

    const sid = sessionStorage.getItem("sessionId");
    const navigate = useNavigate();
    const startDate = sessionStorage.getItem('startDate');
    const endDate = sessionStorage.getItem('endDate');

    const [cbOutLocationValue, setCbOutLocationValue] = useState(0);
    const [radioType, setRadioType] = useState("N");
    const [cbOutlocation, setCbOutLocation] = useState(null);

    const [cbOutdate, setCbOutDate] = useState(new Date());
    const [cbOutBank, setCbOutBank] = useState(null);
    const [cbOutFromSrNo, setCbOutFromSrno] = useState("");
    const [cbOutToSrno, setCbOutToSrno] = useState("");
    const [cbOutFromDate, setCbOutFromDate] = useState(new Date());
    const [cbOutToDate, setCbOutToDate] = useState(new Date());

    const [location, setLocation] = useState("");
    const [bankName, setBankName] = useState("");

    const [locationCode, setLocationCode] = useState("");

    const [onceRun, setOnceRun] = useState(false);
    const [userRight, setUserRight] = useState([]);
    const [scrMode, setScrMode] = useState("");

    const [cbOutQuantity, setCbOutQuantity] = useState("");
    const [cbOutFrom, setCbOutFrom] = useState("");
    const [cbOutTo, setCbOutTo] = useState("");

    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    const [headerData, setHeaderData] = useState([]);

    var msg = [], i = 0;

    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.callApi(Common.apiRight, [sid, 'all', 'CHEQUE_CANCELLATION'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/'); // no query right
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);

    const handleChangeQuantity = (e) => {
        Common.validateNumValue(e.target.value, setCbOutQuantity);
        if (e.target.value === '' || cbOutFrom === '') {
            setCbOutTo('');
        } else {
            setCbOutTo(1 * e.target.value + (1 * cbOutFrom - 1));
        }
    }


    const handleChangeFrom = (e) => {
        Common.validateNumValue(e.target.value, setCbOutFrom);
        if (cbOutQuantity === '' || e.target.value === '') {
            setCbOutTo('');
        } else {
            setCbOutTo((1 * cbOutQuantity) + (1 * e.target.value - 1));
        }
    }


    const handleChangeLocation = (value) => {
        setCbOutLocation(value);
        var id = value.id;
        var locationVal = id.split('^');
        setCbOutLocationValue(locationVal[1]);
        setLocationCode(locationVal[1]);
        setLocation(locationVal[2]);
        // setCbInBank(null);
    }

    const handleBankValue = (value) => {
        console.log(value);
        setCbOutBank(value);
        const bnk = value.id.split('^');
        console.log(bnk);
        setBankName(bnk[5]);
    }

    const addNew = () => {

        if (!cbOutlocation || (radioType == 'N' && !cbOutBank) || (radioType == 'N' && !cbOutdate)) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (!cbOutlocation ? 'Please select location' : '');
            msg[i++] = ((radioType == 'N' && !cbOutBank) ? 'Please select bank' : '');
            msg[i++] = ((radioType == 'N' && !cbOutdate) ? 'Please select date' : '');

            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            setScrMode('A');
        }

    }

    const chequeBooklist = () => {

        if (!cbOutlocation || (radioType == 'S' && cbOutFromSrNo == '') || (radioType == 'S' && !cbOutToSrno)
            || (radioType == 'D' && !cbOutFromDate) || (radioType == 'D' && !cbOutToDate)) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (!cbOutlocation ? 'Please select location' : '');
            msg[i++] = ((radioType == 'S' && cbOutFromSrNo == '') ? 'Please enter from srno' : '');
            msg[i++] = ((radioType == 'S' && !cbOutToSrno) ? 'Please enter to srno' : '');
            msg[i++] = ((radioType == 'D' && !cbOutFromDate) ? 'Please select from date' : '');
            msg[i++] = ((radioType == 'D' && !cbOutToDate) ? 'Please select to date' : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const obj = {
                location: cbOutLocationValue,
                fromSrno: cbOutFromSrNo,
                toSrno: cbOutToSrno,
                fromDate: cbOutFromDate,
                toDate: cbOutToDate,
                radioType: radioType
            }
            Common.callApi(Common.apiCancelChequeBook, [sid, 'getCancelledCheque', JSON.stringify(obj)], (result) => {
                console.log(result);
                let resp = JSON.parse(result);
                setHeaderData(resp)
            })
        }
    }

    const cancelCheque = () => {

        if (!cbOutQuantity || !cbOutFrom || !cbOutTo) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (!cbOutQuantity ? 'Please enter quantity' : '');
            msg[i++] = (!cbOutFrom ? 'Please enter from' : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const obj = {
                quantity: cbOutQuantity,
                from: cbOutFrom,
                to: cbOutTo,
                date: Common.dateYMD(cbOutdate),
                location: cbOutLocationValue,
                radioType: radioType
            }

            Common.callApi(Common.apiCancelChequeBook, [sid, 'cancelChequeBook', JSON.stringify(obj)], (result) => {
                console.log(result);
                let resp = JSON.parse(result);
                setHeaderData(resp)

            })
            setScrMode('');
            setCbOutLocation('');
            setCbOutBank('');
            setCbOutDate('');
        }
    }

    const cancelConfirm = () => {
        if (scrMode === "A" || scrMode === "E") {
            setMsgTextYN('Do you really want to exit ?');
            setMsgBoxYN(true);
        } else {
            cancelData();
        }
    }

    const cancelData = () => {
        setMsgBoxYN(false);
        setScrMode("");
    }

    return (
        <div>
            <Login_header />
            <Menu />

            <Container fluid>
                <Row>
                    <Col>
                        <h2>Cheque Book Cancellation</h2>
                    </Col>
                </Row>&nbsp;

                {
                    scrMode == '' ?
                        <>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Location<span className="text-danger">*</span></Form.Label>
                                        <AsyncSelect
                                            cacheOptions={false}
                                            defaultOptions={false}
                                            value={cbOutlocation}
                                            getOptionLabel={e => e.label + ' '}
                                            getOptionValue={e => e.id}
                                            loadOptions={Picklist.fetchLocationAllowedPicklist}
                                            onInputChange={Picklist.handleInputChange}
                                            onChange={handleChangeLocation}
                                        >
                                        </AsyncSelect>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-4 col-6'>
                                    <Form.Group>
                                        <Row style={{ marginTop: "30px" }}>
                                            <Col>
                                                <Form.Check checked={radioType === "N"} onChange={() => setRadioType("N")} type='radio' label="New" />
                                            </Col><Col>
                                                <Form.Check checked={radioType === "S"} onChange={() => setRadioType("S")} type='radio' label="SrNo" />
                                            </Col><Col>
                                                <Form.Check checked={radioType === "D"} onChange={() => setRadioType("D")} type='radio' label="Date" />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                {
                                    radioType === 'N' ?
                                        <>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Date<span className="text-danger">*</span></Form.Label>
                                                    <DatePicker className="form-control"
                                                        selected={cbOutdate}
                                                        minDate={new Date(startDate)}
                                                        maxDate={new Date(endDate)}
                                                        onChange={(date) => setCbOutDate(date)}
                                                        dateFormat="dd/MM/yyyy"
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        useShortMonthInDropdown
                                                        dropdownMode="select"
                                                        peekNextMonth
                                                        customInput={
                                                            <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Bank<span className="text-danger">*</span></Form.Label>
                                                    <AsyncSelect
                                                        cacheOptions={false}
                                                        defaultOptions={false}
                                                        value={cbOutBank}
                                                        getOptionLabel={e => e.label + ' '}
                                                        getOptionValue={e => e.id}
                                                        loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 'BANK', cbOutLocationValue)}
                                                        onInputChange={Picklist.handleInputChange}
                                                        onChange={handleBankValue}
                                                    >
                                                    </AsyncSelect>
                                                </Form.Group>
                                            </Col>
                                        </>
                                        :
                                        radioType === 'S' ?
                                            <>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>From<span className="text-danger">*</span></Form.Label>
                                                        <Form.Control value={cbOutFromSrNo}
                                                            maxLength={20}
                                                            onChange={(e) => setCbOutFromSrno(e.target.value)}
                                                            placeholder="From" />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>To<span className="text-danger">*</span></Form.Label>
                                                        <Form.Control value={cbOutToSrno}
                                                            maxLength={20}
                                                            onChange={(e) => setCbOutToSrno(e.target.value)}
                                                            placeholder="To" />
                                                    </Form.Group>
                                                </Col>
                                            </>
                                            :
                                            radioType === 'D' ?
                                                <>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                                                            <DatePicker className="form-control"
                                                                selected={cbOutFromDate}
                                                                onChange={(date) => setCbOutFromDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>To<span className="colorThemeRed">*</span></Form.Label>
                                                            <DatePicker className="form-control"
                                                                selected={cbOutToDate}
                                                                onChange={(date) => setCbOutToDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </>
                                                : null
                                }
                            </Row>&nbsp;
                            <Row>
                                <Col>

                                    <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => navigate('/cancellation')}>Back</Button>&nbsp;

                                    {
                                        userRight.add === "1" && radioType == 'N' ?
                                            <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addNew()}>Add New</Button> : null
                                    }
                                    &nbsp;
                                    {
                                        userRight.query === "1" && radioType !== 'N' ?
                                            <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => chequeBooklist()}>List</Button>
                                            : null
                                    }
                                </Col>
                            </Row>&nbsp;

                            {
                                headerData.length > 0 &&
                                <Row>
                                    <Table responsive striped bordered hover>
                                        <thead>
                                            <tr>
                                                {userRight.edit === "1" ? <th></th> : null}
                                                <th>SrNo</th>
                                                <th>Date</th>
                                                <th>Cheque No.</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {headerData.map((res) =>
                                                <tr key={res.hdr_srno}>
                                                    {userRight.edit === "1" ?
                                                        <td className="textCenter">
                                                            <span className="handCursor colorThemeBlue" 
                                                            // onClick={() => viewList(res.tcbb_srno, res.tcbb_location, res.tcbb_in_type)}
                                                            >
                                                                <FontAwesomeIcon icon={faEye} />
                                                            </span>
                                                        </td>
                                                        : null
                                                    }
                                                    <td>{res.tcbb_srno}</td>
                                                    <td>{res.formatted_date}</td>
                                                    <td>{res.tcbb_from}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </Row>
                            }

                        </> : <>
                            <Row>
                                <Col>
                                    <Button variant='outline-danger' className='buttonStyle' size='sm' onClick={() => cancelConfirm()}>Back</Button>{' '}
                                </Col>
                            </Row>&nbsp;

                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Label>Location : {location}</Form.Label>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Label>Bank : {bankName}</Form.Label>
                                </Col>
                            </Row>&nbsp;

                            {
                                scrMode === 'E' || scrMode === 'A' ?
                                    <>
                                        <Row>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Quantity<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control value={cbOutQuantity}
                                                        onChange={(e) => handleChangeQuantity(e)}
                                                        placeholder="Quantity" />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>From<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control value={cbOutFrom}
                                                        maxLength={20}
                                                        onChange={(e) => handleChangeFrom(e)}
                                                        placeholder="From" />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>To</Form.Label>
                                                    <Form.Control value={cbOutTo}
                                                        maxLength={20}
                                                        onChange={(e) => setCbOutTo(e.target.value)}
                                                        placeholder="To"
                                                        disabled />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: "30px" }}>
                                            <Col className='col-md-3 col-6'>
                                                {
                                                    userRight.add === "1" ?
                                                        <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => cancelCheque()}>Cancel Cheque Book</Button> : null
                                                }
                                            </Col>
                                        </Row>
                                    </> :
                                    null
                            }
                        </>

                }


            </Container>
            <>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
                <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
                    {msgTextYN}
                </DialogYesNo>
            </>
        </div>
    )
}

export default Bank_cheque_book_cancellation
