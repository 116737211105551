import { faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from "jquery";
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row, Tab, Table, Tabs } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import * as Common from "../Common";
import Dialog from '../Dialog';
import DialogYesNo from '../Dialog_Yes_No';
import LocationSelector from '../LocationSelector';
import Login_header from '../Login_header';
import Menu from '../Menu';
import * as Picklist from "../Picklist";

const displayNone = {
    display: "none"
}

function Tran_glvtm_settlement() {

    const finStartDate = sessionStorage.getItem("startDate");
    const finEndDate = sessionStorage.getItem("endDate");
    const sid = sessionStorage.getItem("sessionId");
    const branchDisable = sessionStorage.getItem('branchDisable');
    const navigate = useNavigate();

    const [radioType, setRadioType] = useState("N");
    const [userRight, setUserRight] = useState([]);

    const [showGvtModal, setShowGvtModal] = useState(false);

    const [counter, setCounter] = useState(0);
    const [key, setKey] = useState("INFO");
    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");
    const [hdrDate, setHdrDate] = useState("");
    const [scrMode, setScrMode] = useState("");
    const [vtLocationValue, setVTLocationValue] = useState("");
    const [hdrLocation, setHdrLocation] = useState("");
    const [gtvList, setGvtList] = useState([]);

    const [stLocation, setSTLocation] = useState(null);
    const [stNewDate, setSTNewDate] = useState(new Date());
    const [stFromDate, setSTFromDate] = useState(new Date());
    const [stSrnoFrom, setSTSrnoFrom] = useState("");
    const [stSrnoTo, setSTSrnoTo] = useState("");
    const [stToDate, setSTToDate] = useState(new Date());
    const [stIssuer, setSTIssuer] = useState(null);
    const [stBank, setSTBank] = useState(null);
    const [stSaleType, setSTSaleType] = useState("N")
    const [hdrSrno, setHdrSrno] = useState(0);
    const [stLocationValue, setSTLocationValue] = useState("");

    const [nTTDate, setNTTDate] = useState(null);
    const [nTTValue, setNTTValue] = useState("");
    const [nManualNo, setNManualno] = useState(null);
    const [nRemark1, setNRemark1] = useState("");
    const [nRemark2, setNRemark2] = useState("");

    const [branchRadio, setBranchRadio] = useState("A");
    const [pLocation, setPLocation] = useState(null);
    const [pRefNo, setPRefNo] = useState(null);
    const [pRate, setPRate] = useState("");
    const [pValue, setPValue] = useState("");
    const [pOptions, setPOptions] = useState("SEARCH");
    const [pVtm, setPVtm] = useState(null);
    const [pIsdId, setPIsdId] = useState("");
    const [pIssuerAccount, setPIssuerAccount] = useState("");
    const [pIssuerGroup, setPIssuerGroup] = useState("");
    const [pIsd, setPIsd] = useState(null);
    const [pIssuer, setPIssuer] = useState(null);
    const [pSelectedLocations, setPSelectedLocations] = useState([]);
    const [pLocRadio, setPLocRadio] = useState("");
    const [pIssuerName, setPIssuerName] = useState("");
    const [settlementList, setSettlementList] = useState([]);
    const [selectiveVTList, setSelectiveVTList] = useState([]);
    const [maxSettleLineNo, setMaxSettleLineNo] = useState(1);

    const [pTotalAmount, setPTotalAmount] = useState(0);
    const [pOtherCharges, setPOtherCharges] = useState(0);
    const [pCgst, setPCgst] = useState(0);
    const [pSgst, setPSgst] = useState(0);
    const [pIgst, setPIgst] = useState(0);
    const [pUgst, setPUgst] = useState(0);
    const [pForexTotal, setPForexTotal] = useState(0);
    const [pRoundOff, setPRoundOff] = useState(0);
    const [pTaxableVal, setPTaxableVal] = useState(0);

    const [evtLocationValue, setEVTLocationValue] = useState("");
    const [surSelectedLocations, setSurSelectedLocations] = useState([]);
    const [surRefNo, setSurRefNo] = useState(null);
    const [surLocRadio, setSurLocRadio] = useState("");

    const [surIsd, setSurIsd] = useState(null);
    const [surIsdId, setSurIsdId] = useState("");
    const [surOptions, setSurOptions] = useState("SEARCH");

    const [surRate, setSurRate] = useState("");
    const [surValue, setSurValue] = useState("");
    const [surSelectiveEVTList, setSurSelectiveEVTList] = useState([]);

    const [surrenderList, setSurrenderList] = useState([]);
    const [maxSurrenderLineNo, setMaxSurrenderLineNo] = useState(1);

    const [totalSettlement, setTotalSettlement] = useState(0);
    const [totalSurrender, setTotalSurrender] = useState(0);


    useEffect(() => {
        Common.getActiveBranch().then(response => {
            setSTLocation(response);
            response != null && handleLocationChange(response);
        });
        Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_GL_SETTLEMENT_VTM'], function (result) {
            let resp = JSON.parse(result);
            if (resp.query === "1") {
                setUserRight(resp);
            } else {
                navigate('/'); // no query right
            }
        });
    }, []);

    const handleManualNo = (value) => setNManualno(value);

    const handleLocationChange = (value) => {
        setSTLocation(value);
        var id = value.id;
        var location = id.split("^");
        setSTLocationValue(location[1]);
        setHdrLocation(location[2]);
        sessionStorage.activeBranch = location[1];
    }

    const handleChangeIsd = (value) => {
        let a;
        if (key == 'SETTLEMENT') {
            setPIsd(value);
            a = value.id.split("^");
            setPIsdId(a[1]);
        } else if (key == 'SURRENDER') {
            setSurIsd(value);
            a = value.id.split("^");
            setSurIsdId(a[1]);
        }
    }

    const handleTabChange = (k) => {
        setKey(k);
    }

    const switchToPaymentTab = (e) => {
        if (e.key === 'Enter') setKey("PAYMENT");
        else if (e.key === "Tab") {
            e.preventDefault();
            //vtmR.current.focus();
        }
    }

    const handleOptions = (v) => {
        if (key == 'SETTLEMENT') {
            setPOptions(v);
            setPRefNo(null);
            setPRate("");
            setPValue("");
            setSelectiveVTList([]);
        } else if (key == 'SURRENDER') {
            setSurOptions(v);
            setSurRefNo(null);
            setSurRate("");
            setSurValue("");
            setSurSelectiveEVTList([]);
        }
    }

    const handleRefChange = (value) => {
        var a;
        if (key == 'SETTLEMENT') {
            setPRefNo(value);
            a = value.id.split("^");
            setPValue(a[4]);
        } else if (key == 'SURRENDER') {
            setSurRefNo(value);
            a = value.id.split("^");
            setSurValue(a[4]);
        }

    }

    const handleIssuerChange = value => {
        setSTIssuer(value);
        setPIssuer(value);
        var a = value.id.split("^");
        setPIssuerGroup(a[1]);
        setPIssuerAccount(a[2]);
        setPIssuerName(a[5]);
    }

    const addNewForm = () => {
        var d = Common.dateYMD(stNewDate);
        var ddmy = Common.dateDMY(stNewDate);
        setHdrDate(ddmy);
        var today = Common.dateYMD(new Date());
        var msg = []; var i = 0;
        if (stLocation == null || pIssuer === null || stBank === null) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (stLocation == null ? "Location." : '');
            msg[i++] = (pIssuer === null ? "Issuer." : '');
            msg[i++] = (stBank === null ? "Bank." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (d > finEndDate || d < finStartDate) {
            setMsgBox(true);
            setMsgText(Common.getMessage("MSG0010"));
        } else {
            setScrMode("A");
            setHdrSrno(0);
            setSelectiveVTList([]);
            setSettlementList([]);
            setNManualno(null);
            setNTTDate(null);
            setNTTValue("");
            setNRemark1("");
            setNRemark2("");
            setPIsd(null);
            setPRate("");
            setPValue("");
            setPRefNo(null);
            var a = pIssuer.id.split("^");
            setPIssuerGroup(a[1]);
            setPIssuerAccount(a[2]);
            setPIssuerName(a[5]);
        }
    };

    const cancelConfirm = () => {
        if (scrMode === "A" || scrMode === "E") {
            setMsgTextYN(Common.getMessage("WRN0000"));
            setMsgBoxYN(true);
        } else {
            cancelData();
        }
    }

    const cancelData = () => {
        setMsgBoxYN(false);
        setScrMode("");
    }

    const addProduct = (type) => {
        var selectivevtList = [];
        var i = 0, msg = [];
        if (pIsd === null || stIssuer === null || pRate === "" || pRate === 0
            || (pOptions === "SEARCH" && pRefNo === null)
            || (pOptions === "FIFO" && pValue === "")
        ) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (pIsd === null ? "Currency" : '');
            msg[i++] = (stIssuer === null ? "Issuer" : '');
            msg[i++] = (pRate === "" || pRate === 0 ? "Rate can't be zero or null" : '');
            msg[i++] = (pOptions === "SEARCH" && pRefNo === null ? "Reference Number" : '');
            msg[i++] = (pOptions === "FIFO" && pValue === "" ? "Value" : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            var issuer = pIssuer.id.split("^");
            const obj = {
                isd: pIsdId,
                group: pIssuerGroup,
                account: pIssuerAccount,
                location: pLocRadio === "S" ? pSelectedLocations : vtLocationValue,
                type: 'VT'
            }
            if (pOptions === "FIFO" || pOptions === "SELECTIVE") {
                var totalvalue = pValue;
                Common.callApi(Common.apiGVTSettlement, [sid, "fifovtlist", JSON.stringify(obj)], result => {
                    let resp = JSON.parse(result);
                    let gvtsettled = false;
                    for (var i = 0; i < resp.length; i++) {
                        resp[i].prd_rate = pRate;
                        resp[i].prd_amount = resp[i].prd_deno * pRate;
                        resp[i].prd_account = pIssuerAccount;
                        resp[i].prd_group = pIssuerGroup;
                        //resp[i].prd_qty = 1;
                        resp[i].mvli_ledger_name = issuer[5];
                        resp[i].uniquekey = Common.getRandomString();
                        if (pOptions === "FIFO") {
                            if (totalvalue < 0) break;
                            if (totalvalue >= resp[i].prd_deno * 1 && !isProductAlreadyAdded(resp[i])) {
                                gvtsettled = true;
                                setSettlementList(Common.arrayAddItem(settlementList, resp[i]));
                                totalvalue = totalvalue - resp[i].prd_deno * 1;
                            }
                        } else {
                            gvtsettled = true;
                            if (!isProductAlreadyAdded(resp[i]))
                                selectivevtList = Common.arrayAddItem(selectivevtList, resp[i]);
                            setShowGvtModal(true);
                        }
                    }
                    setSelectiveVTList(selectivevtList);
                    if (!gvtsettled) {
                        setMsgBox(true);
                        setMsgText("VTM not available for settlement");
                    }
                });
            } else {
                var ref = pRefNo.id.split("^");
                console.log(ref);

                const obj1 = {
                    uniquekey: Common.getRandomString(),
                    prd_type: 'VT',
                    prd_isd: pIsdId,
                    prd_line_no: ref[1],
                    prd_location: ref[2],
                    prd_srno: ref[3],
                    prd_deno: ref[4],
                    prd_amount: pRate * ref[4],
                    prd_from: ref[5],
                    entity_name: ref[6],
                    mvli_ledger_name: issuer[5],
                    prd_account: pIssuerAccount,
                    prd_group: pIssuerGroup,
                    prd_rate: pRate,
                    prd_qty: 1,
                    delny: "Y"
                }
                if (!isProductAlreadyAdded(obj1))
                    setSettlementList(Common.arrayAddItem(settlementList, obj1));
                else {
                    setMsgBox(true);
                    setMsgText("VTM already added.");
                }
            }
            calculateTotalSettlement(settlementList);
            setCounter(counter + 1);
            setPRefNo(null);
            setPRate("");
            setPValue("");
        }
    };

    const calculateTotalSettlement = (list) => {

        let totalAmt = 0;
        let i;
        for (i = 0; i < list.length; i++) {
            totalAmt = totalAmt + list[i].prd_amount * 1;
        }
        setTotalSettlement(totalAmt);
    }

    const calculateTotalSurrender = (list) => {

        let totalAmt = 0;
        let i;
        for (i = 0; i < list.length; i++) {
            totalAmt = totalAmt + list[i].prd_amount * 1;
        }
        setTotalSurrender(totalAmt);
    }

    const addSurProduct = (type) => {
        var selectivevtList = [];
        var i = 0, msg = [];
        if (surIsd === null || stIssuer === null || surRate === "" || surRate === 0
            || (surOptions === "SEARCH" && surRefNo === null)
            || (surOptions === "FIFO" && surValue === "")
        ) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (surIsd === null ? "Currency" : '');
            msg[i++] = (stIssuer === null ? "Issuer" : '');
            msg[i++] = (surRate === "" || surRate === 0 ? "Rate can't be zero or null" : '');
            msg[i++] = (surOptions === "SEARCH" && surRefNo === null ? "Reference Number" : '');
            msg[i++] = (surOptions === "FIFO" && surRefNo === "" ? "Value" : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            var issuer = pIssuer.id.split("^");
            const obj = {
                isd: surIsdId,
                group: pIssuerGroup,
                account: pIssuerAccount,
                location: surLocRadio === "S" ? surSelectedLocations : evtLocationValue,
                type: 'EVT'
            }
            if (surOptions === "FIFO" || surOptions === "SELECTIVE") {
                var totalvalue = surValue;
                Common.callApi(Common.apiGVTSettlement, [sid, "fifovtlist", JSON.stringify(obj)], result => {
                    let resp = JSON.parse(result);
                    let gvtsettled = false;
                    for (var i = 0; i < resp.length; i++) {
                        resp[i].prd_rate = surRate;
                        resp[i].prd_amount = resp[i].prd_deno * surRate;
                        resp[i].prd_account = pIssuerAccount;
                        resp[i].prd_group = pIssuerGroup;
                        //resp[i].prd_qty = 1;
                        resp[i].mvli_ledger_name = issuer[5];
                        resp[i].uniquekey = Common.getRandomString();
                        if (surOptions === "FIFO") {
                            if (totalvalue < 0) break;
                            if (totalvalue >= resp[i].prd_deno * 1 && !isProductAlreadyAdded(resp[i])) {
                                gvtsettled = true;
                                setSurrenderList(Common.arrayAddItem(surrenderList, resp[i]));
                                totalvalue = totalvalue - resp[i].prd_deno * 1;
                            }
                        } else {
                            gvtsettled = true;
                            if (!isProductAlreadyAdded(resp[i]))
                                selectivevtList = Common.arrayAddItem(selectivevtList, resp[i]);
                            setShowGvtModal(true);
                        }
                    }
                    setSelectiveVTList(selectivevtList);
                    if (!gvtsettled) {
                        setMsgBox(true);
                        setMsgText("EVTM not available for settlement");
                    }
                });
            } else {
                var ref = surRefNo.id.split("^");
                console.log(ref);
                const obj1 = {
                    uniquekey: Common.getRandomString(),
                    prd_type: 'EVT',
                    prd_isd: surIsdId,
                    prd_line_no: ref[1],
                    prd_location: ref[2],
                    prd_srno: ref[3],
                    prd_deno: ref[4],
                    prd_amount: surRate * ref[4],
                    prd_from: ref[5],
                    entity_name: ref[6],
                    mvli_ledger_name: issuer[5],
                    prd_account: pIssuerAccount,
                    prd_group: pIssuerGroup,
                    prd_rate: surRate,
                    prd_qty: 1,
                    delny: "Y"
                }
                console.log(obj1);
                if (!isProductAlreadyAdded(obj1))
                    setSurrenderList(Common.arrayAddItem(surrenderList, obj1));
                else {
                    setMsgBox(true);
                    setMsgText("EVTM already added.");
                }
            }
            calculateTotalSurrender(surrenderList);

            setCounter(counter + 1);
            setSurRefNo(null);
            setSurRate("");
            setSurValue("");
            setSurIsd("");
        }
    };


    function isProductAlreadyAdded(obj) {
        return settlementList.some(row =>
            row.prd_srno === obj.prd_srno &&
            row.prd_line_no === obj.prd_line_no &&
            row.prd_location === obj.prd_location
        );
    }

    const deleteProduct = (value) => {
        let gvtlist;
        if (key == 'SETTLEMENT') {
            gvtlist = Common.arrayRemoveItem(settlementList, "uniquekey", value);
            setSettlementList(gvtlist);
            setCounter(counter - 1);
        } else if (key == 'SURRENDER') {
            gvtlist = Common.arrayRemoveItem(surrenderList, "uniquekey", value);
            setSurrenderList(gvtlist);
            setCounter(counter - 1);
        }
    }

    const editData = () => {
        setScrMode("E");
    };

    const listGVTdata = () => {
        var msg = [], i = 0;
        $(".loader").show();
        if (stLocation === null || (radioType === 'S' && !stSrnoFrom) || (radioType === 'S' && !stSrnoTo) || (radioType === 'D' && !stFromDate) ||
            (radioType === 'D' && !stToDate)) {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (stLocation === null ? " Location." : '');
            msg[i++] = (radioType === 'S' && !stSrnoFrom ? " From Srno." : '');
            msg[i++] = (radioType === 'S' && !stSrnoTo ? " To Srno." : '');
            msg[i++] = (radioType === 'D' && !stFromDate ? " From Date." : '');
            msg[i++] = (radioType === 'D' && !stToDate ? " To Date." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const obj = {
                location: stLocationValue,
                type: "GVT",
                radioType: radioType,
                stSrnoTo: stSrnoTo,
                stSrnoFrom: stSrnoFrom,
                stToDate: Common.dateYMD(stToDate),
                stFromDate: Common.dateYMD(stFromDate)
            }
            Common.callApi(Common.apiGVTSettlement, [sid, "listdata", JSON.stringify(obj)], result => {
                $(".loader").hide();
                setGvtList(JSON.parse(result));
            });
        }
    };

    const fillViewForm = (hdrsrno, location, type, date) => {
        $(".loader").show();
        setHdrDate(date);
        setHdrSrno(hdrsrno);
        setKey("INFO");
        setScrMode('Q');
        const obj = {
            srno: hdrsrno,
            location: location,
            type: type
        }
        Common.callApi(Common.apiGVTSettlement, [sid, "viewdata", JSON.stringify(obj)], result => {
            $(".loader").hide();
            let resp = JSON.parse(result);
            console.log(resp);
            setHdrLocation(resp.locname);
            setScrMode("Q");
            setSTIssuer({ id: resp.issid, label: resp.isslabel });
            setSettlementList(resp.productlist);
            setMaxSettleLineNo(resp.maxline);
            setSurrenderList(resp.prdlistevtm);
            setMaxSurrenderLineNo(resp.maxlineevtm);
            setPIssuerGroup(resp.grp);
            setPIssuerAccount(resp.acct);
            setPIssuerName(resp.accname);
        });
    }


    const saveData = () => {
        if (surrenderList <= 0 || settlementList <= 0) {
            setMsgText("Please enter record in settlemenmt or surrender.");
            setMsgBox(true);
        } else {
            $(".loader").show();
            const obj1 = {
                type: "GVT",
                srno: hdrSrno,
                location: stLocationValue,
                stIssuer: stIssuer,
                stBank: stBank,
                stNewDate: Common.dateYMD(stNewDate),
                stSaleType: stSaleType,
                nTTValue: nTTValue,
                nTTDate: Common.dateYMD(nTTDate),
                nManualNo: nManualNo,
                nRemark1: nRemark1,
                nRemark2: nRemark2,
                settlementList: settlementList,
                surrenderList: surrenderList,
                totalSettlement: totalSettlement,
                totalSurrender: totalSurrender,

                radioType: radioType,
                stToDate: Common.dateYMD(stToDate),
                stFromDate: Common.dateYMD(stFromDate)
            }
            Common.callApi(Common.apiGVTSettlement, [sid, "savedata", JSON.stringify(obj1)], result => {
                let resp = JSON.parse(result);
                $(".loader").hide();
                setMsgBox(true);
                setMsgText(Common.getMessage(resp.msg));
                setScrMode("");
                setGvtList(resp.gvtdata);
            });
        }
    };


    const handleDataFromChild = (newLocationCode, newSelectedItems, newRadioType) => {
        if (key == 'SETTLEMENT') {
            setVTLocationValue(newLocationCode);
            setPSelectedLocations(newSelectedItems);
            setPRefNo(null);
            setPLocRadio(newRadioType);
        } else if (key == 'SURRENDER') {
            setEVTLocationValue(newLocationCode);
            setSurSelectedLocations(newSelectedItems);
            setSurRefNo(null);
            setSurLocRadio(newRadioType);
        }

    };

    const handlePIssuer = (value) => {
        setPIssuer(value);
        var a = value.id.split("^");
        setPIssuerGroup(a[1]);
        setPIssuerAccount(a[2]);
    }

    const updateSelectiveCheck = (e, index, name) => {
        const tempArr = selectiveVTList.map((item, i) => {
            if (index === i) {
                if (name === "ischecked") {
                    if (e.target.checked) return { ...item, [name]: "1" };
                    else return { ...item, [name]: "0" };
                }
            } else {
                return item;
            }
        });
        setSelectiveVTList(tempArr);
    }

    const addSelectiveGvt = () => {
        selectiveVTList.map(item => {
            if (item.ischecked === "1") {
                setSettlementList(Common.arrayAddItem(settlementList, item));
                setShowGvtModal(false);
            }
        });
    }

    return (
        <div>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h2>Global VTM Settlement</h2>
                    </Col>
                </Row>
                {
                    scrMode === "" ?
                        <>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Location<span className="colorThemeRed">*</span></Form.Label>
                                        <AsyncSelect
                                            cacheOptions={false}
                                            defaultOptions={false}
                                            value={stLocation}
                                            getOptionLabel={e => e.label + ' '}
                                            getOptionValue={e => e.id}
                                            loadOptions={Picklist.fetchLocationAllowedPicklist}
                                            onInputChange={Picklist.handleInputChange}
                                            onChange={handleLocationChange}
                                            isDisabled={branchDisable === "true"}
                                        >
                                        </AsyncSelect>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-4 col-6'>
                                    <Form.Group>
                                        <Row style={{ marginTop: "30px" }}>
                                            <Col style={{ display: "flex" }}>
                                                <Form.Check checked={radioType === "N"} onChange={() => setRadioType("N")} type='radio' label="New" />
                                                <Form.Check checked={radioType === "S"} onChange={() => setRadioType("S")} type='radio' label="SrNo" />
                                                <Form.Check checked={radioType === "D"} onChange={() => setRadioType("D")} type='radio' label="Date" />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                {
                                    radioType === "N" ?
                                        <>
                                            <Row>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Date</Form.Label>
                                                        <DatePicker className="form-control"
                                                            selected={stNewDate}
                                                            onChange={(date) => setSTNewDate(date)}
                                                            dateFormat="dd/MM/yyyy"
                                                            showYearDropdown
                                                            showMonthDropdown
                                                            useShortMonthInDropdown
                                                            dropdownMode="select"
                                                            peekNextMonth
                                                            customInput={
                                                                <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Sale Type</Form.Label>
                                                        <Form.Select value={stSaleType} onChange={e => setSTSaleType}>
                                                            <option value="N">Normal</option>
                                                            <option value="EEFCB">EEFC</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Issuer<span className='colorThemeRed'>*</span></Form.Label>
                                                        <AsyncSelect
                                                            cacheOptions={false}
                                                            defaultOptions={false}
                                                            value={stIssuer}
                                                            getOptionLabel={e => e.label + ' '}
                                                            getOptionValue={e => e.id}
                                                            loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 'ISSUER', "0")} // location = 1 , 2
                                                            onInputChange={Picklist.handleInputChange}
                                                            onChange={handleIssuerChange}
                                                        >
                                                        </AsyncSelect>
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Bank<span className='colorThemeRed'>*</span></Form.Label>
                                                        <AsyncSelect
                                                            cacheOptions={false}
                                                            defaultOptions={false}
                                                            value={stBank}
                                                            getOptionLabel={e => e.label + ' '}
                                                            getOptionValue={e => e.id}
                                                            loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 'BANK', "0")} // location = 1 , 2
                                                            onInputChange={Picklist.handleInputChange}
                                                            onChange={(value) => setSTBank(value)}
                                                        >
                                                        </AsyncSelect>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </>
                                        : radioType === "S" ?
                                            <>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                                                        <Form.Control type='text' maxLength={5} value={stSrnoFrom} onChange={e => Common.validateNumValue(e.target.value, setSTSrnoFrom)} />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>To<span className="colorThemeRed">*</span></Form.Label>
                                                        <Form.Control type='text' maxLength={5} value={stSrnoTo} onChange={e => Common.validateNumValue(e.target.value, setSTSrnoTo)} />
                                                    </Form.Group>
                                                </Col>
                                            </>
                                            : radioType === "D" || radioType === "I" || radioType === "C" ?
                                                <>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                                                            <DatePicker className="form-control"
                                                                selected={stFromDate}
                                                                onChange={(date) => setSTFromDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>To<span className="colorThemeRed">*</span></Form.Label>
                                                            <DatePicker className="form-control"
                                                                selected={stToDate}
                                                                onChange={(date) => setSTToDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </>
                                                : null
                                }
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    {
                                        userRight.query === "1" && radioType !== "N" ?
                                            <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => listGVTdata()}>List</Button> : null
                                    }
                                    &nbsp;
                                    {
                                        userRight.add === "1" && radioType === "N" ?
                                            <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addNewForm()}>Add New</Button> : null
                                    }
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Table responsive bordered striped>
                                    <thead>
                                        <tr>
                                            <th>&nbsp;</th>
                                            <th>Type</th>
                                            <th>Account</th>
                                            <th>Group</th>
                                            <th>Account Name</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            gtvList.map((item, index) => (
                                                <tr key={item.hdr_srno + '^' + item.hdr_location}>
                                                    <td>
                                                        <span onClick={() => fillViewForm(item.hdr_srno, item.hdr_location, item.hdr_type, item.hdr_date)} className='pointer colorThemeBlue'>
                                                            <FontAwesomeIcon icon={faEye} />
                                                        </span>
                                                    </td>
                                                    <td>{item.hdr_type}</td>
                                                    <td>{item.hdr_ledger}</td>
                                                    <td>{item.hdr_group}</td>
                                                    <td>{item.mvli_ledger_name}</td>
                                                    <td>{item.hdr_date}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </Row>
                        </>
                        :
                        <>
                            <Row>
                                <Col>
                                    <Button variant='outline-danger' size='sm' className='buttonStyle' onClick={() => cancelConfirm()}>Back</Button>
                                    &nbsp;
                                    {
                                        (userRight.edit === "1" && scrMode === 'Q') ? <>
                                            <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                editData()}>Edit</Button>&nbsp;
                                        </> : null
                                    }
                                    {
                                        (userRight.edit === "1" && scrMode === 'E') || (userRight.add === "1" && scrMode === 'A') ? <>
                                            <Button variant='outline-primary' size='sm' className='buttonStyle' onClick={() => saveData()}>Save</Button>&nbsp;
                                        </> : null
                                    }
                                    {/*
                                        (userRight.query === "1" && scrMode === "Q") ? <>
                                            <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() => print("D")}>With Deno</Button>&nbsp;
                                            <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={() => print("")}>Without Deno</Button>&nbsp;
                                            <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={() => handleFaView()}>Fa View</Button>
                                        </> : null
                                    */}
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col className="col-md-2 col-4">
                                    <span>Location : <b>{hdrLocation}</b></span>
                                </Col>
                                <Col className="col-md-2 col-4">
                                    <span>Srno. : <b>{hdrSrno}</b></span>
                                </Col>
                                <Col className="col-md-2 col-4">
                                    <span>Date : <b>{hdrDate}</b></span>
                                </Col>
                                <Col className="col-md-4 col-4">
                                    <span>Issuer : <b>{pIssuerGroup + " " + pIssuerAccount + " " + pIssuerName}</b></span>
                                </Col>
                                {/* <Col className='ppCharges'>
                                    <span><b>Forex Total (INR): {pForexTotal}</b></span>
                                    &nbsp;
                                    <span><b>Total Amount (INR): {pTotalAmount}</b></span>
                                </Col> */}
                            </Row>
                            <Row>&nbsp;</Row>
                            <Tabs activeKey={key} defaultActiveKey="INFO" onSelect={k => handleTabChange(k)}>
                                <Tab eventKey="INFO" title="Info">
                                    <Row>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Type</Form.Label>
                                                <Form.Select value={stSaleType} onChange={e => setSTSaleType(e.target.value)} disabled>
                                                    <option value="N">Normal</option>
                                                    <option value="EEFBC">EEFBC</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col className="col-md-3 col-6">
                                            <Form.Group>
                                                <Form.Label>Issuer<span className='colorThemeRed'>*</span></Form.Label>
                                                <AsyncSelect
                                                    cacheOptions={false}
                                                    defaultOptions={false}
                                                    value={stIssuer}
                                                    getOptionLabel={e => e.label + ' '}
                                                    getOptionValue={e => e.id}
                                                    loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 'ISSUER', vtLocationValue)} // location = 1 , 2
                                                    onInputChange={Picklist.handleInputChange}
                                                    isDisabled
                                                    onChange={(value) => setSTIssuer(value)}
                                                >
                                                </AsyncSelect>
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Bank</Form.Label>
                                                <AsyncSelect
                                                    cacheOptions={false}
                                                    defaultOptions={false}
                                                    value={stBank}
                                                    getOptionLabel={e => e.label + ' '}
                                                    getOptionValue={e => e.id}
                                                    loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 'BANK', "0")} // location = 1 , 2
                                                    onInputChange={Picklist.handleInputChange}
                                                    onChange={(value) => setSTBank(value)}
                                                    isDisabled
                                                >
                                                </AsyncSelect>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-md-3 col-6">
                                            <Form.Group>
                                                <Form.Label>Manual Number</Form.Label>
                                                <AsyncSelect
                                                    cacheOptions={false}
                                                    defaultOptions={false}
                                                    value={nManualNo}
                                                    getOptionLabel={e => e.label + ' '}
                                                    getOptionValue={e => e.id}
                                                    loadOptions={(search) => Picklist.fetchManualTypePicklist(search, stLocationValue, 'SP')} // location = 1 , 2
                                                    onInputChange={Picklist.handleInputChange}
                                                    onChange={handleManualNo}
                                                    isDisabled={scrMode === "Q"}
                                                >
                                                </AsyncSelect>
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>TT Value</Form.Label>
                                                <Form.Control disabled={scrMode === "Q"} value={nTTValue} onChange={e => setNTTValue(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>TT Date</Form.Label>
                                                <DatePicker className="form-control"
                                                    selected={nTTDate}
                                                    onChange={(date) => setNTTDate(date)}
                                                    dateFormat="dd/MM/yyyy"
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    useShortMonthInDropdown
                                                    dropdownMode="select"
                                                    peekNextMonth
                                                    disabled={scrMode === "Q"}
                                                    customInput={
                                                        <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Remark 1</Form.Label>
                                                <Form.Control type="text" maxLength={100} placeholder='Remark 1' disabled={scrMode === "Q"} value={nRemark1} onChange={e => setNRemark1(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Remark 2</Form.Label>
                                                <Form.Control type="text" maxLength={100} placeholder='Remark 2' disabled={scrMode === "Q"} value={nRemark2} onChange={e => setNRemark2(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>&nbsp;</Row>
                                    <Row>
                                        <Col>
                                            <Button variant='outline-secondary' className='buttonStyle' size='sm' onClick={() => setKey("SETTLEMENT")}>Product-&gt;</Button>
                                        </Col>
                                    </Row>
                                </Tab>
                                <Tab eventKey="SETTLEMENT" title="Settlement">
                                    <Row>
                                        <Col>
                                            <h4>Product Info</h4>
                                        </Col>
                                    </Row>
                                    <Row>&nbsp;</Row>
                                    {
                                        scrMode === "Q" ? null :
                                            <>
                                                <Row>
                                                    <Col className='col-md-6 col-12'>
                                                        <LocationSelector onDataFromChild={handleDataFromChild} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>Isd<span className="colorThemeRed">*</span></Form.Label>
                                                            <AsyncSelect
                                                                cacheOptions={false}
                                                                defaultOptions={false}
                                                                value={pIsd}
                                                                getOptionLabel={e => e.label + ' '}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={(search) => Picklist.fetchCurrencyAllowedPicklist(search, "")} // location = 1 , 2
                                                                onInputChange={Picklist.handleInputChange}
                                                                onChange={handleChangeIsd}
                                                            >
                                                            </AsyncSelect>
                                                        </Form.Group>
                                                    </Col>
                                                    {/* <Col className="col-md-3 col-6">
                                            <Form.Group>
                                                <Form.Label>Issuer<span className='colorThemeRed'>*</span></Form.Label>
                                                <AsyncSelect
                                                    cacheOptions={false}
                                                    defaultOptions={false}
                                                    value={pIssuer}
                                                    getOptionLabel={e => e.label + ' '}
                                                    getOptionValue={e => e.id}
                                                    loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 'ISSUER', vtLocationValue)} // location = 1 , 2
                                                    onInputChange={Picklist.handleInputChange}
                                                    onChange={handlePIssuer}
                                                >
                                                </AsyncSelect>
                                            </Form.Group>
                                        </Col> */}
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Options</Form.Label>
                                                            <Form.Select value={pOptions} onChange={e => handleOptions(e.target.value)}>
                                                                <option value="SEARCH">Search</option>
                                                                <option value="FIFO">FIFO</option>
                                                                <option value="SELECTIVE">Selective</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-md-3 col-6" style={{ display: pOptions === "SEARCH" ? "block" : "none" }}>
                                                        <Form.Group>
                                                            <Form.Label>Ref No<span className='colorThemeRed'>*</span></Form.Label>
                                                            <AsyncSelect
                                                                cacheOptions={false}
                                                                defaultOptions={false}
                                                                value={pRefNo}
                                                                getOptionLabel={e => e.label + ' '}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={(search) => Picklist.fetchVtSettled(search, {
                                                                    isd: pIsdId,
                                                                    location: pLocRadio === "S" ? pSelectedLocations : vtLocationValue,
                                                                    group: pIssuerGroup, account: pIssuerAccount, type: 'VT'
                                                                })}
                                                                onInputChange={Picklist.handleInputChange}
                                                                onChange={handleRefChange}
                                                            >
                                                            </AsyncSelect>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>Rate<span className='colorThemeRed'>*</span></Form.Label>
                                                            <Form.Control type='text' maxLength={10} value={pRate} onChange={e => Common.validateDecValue(e.target.value, setPRate)} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-md-3 col-6" style={{ display: pOptions === "SELECTIVE" && "none" }}>
                                                        <Form.Group>
                                                            <Form.Label>Value</Form.Label>
                                                            <Form.Control disabled={pOptions === "SEARCH"} type='text' maxLength={10} value={pValue} onChange={e => Common.validateDecValue(e.target.value, setPValue)} />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>&nbsp;</Row>
                                                <Row>
                                                    <Col>
                                                        <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addProduct("P")}>Add</Button>
                                                        &nbsp;
                                                        {/* <Button variant='outline-secondary' className='buttonStyle' size='sm' onClick={() => setKey("PAYMENT")} onKeyDown={switchToPaymentTab}>Payment-&gt;</Button> */}
                                                    </Col>
                                                </Row>
                                                <Row>&nbsp;</Row>
                                            </>
                                    }

                                    <Row>
                                        <Table striped responsive bordered>
                                            <thead>
                                                <tr>
                                                    {/* <th>Code</th> */}
                                                    <th>Location</th>
                                                    <th>Isd</th>
                                                    <th>Issuer</th>
                                                    <th>Ref no</th>
                                                    <th>Rate</th>
                                                    <th>Value</th>
                                                    <th>Amount</th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    settlementList.map(item => (
                                                        <tr>
                                                            {/* <td>{item.prd_srno}</td> */}
                                                            <td>{item.entity_name}</td>
                                                            <td>{item.prd_isd}</td>
                                                            <td>{item.mvli_ledger_name}</td>
                                                            <td>{item.prd_from}</td>
                                                            <td>{item.prd_rate}</td>
                                                            <td>{item.prd_deno}</td>
                                                            <td>{item.prd_amount}</td>
                                                            <td>
                                                                {
                                                                    scrMode === "Q" ? null :
                                                                        <span onClick={() => deleteProduct(item.uniquekey)} className='colorThemeBlue pointer'>
                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                        </span>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </Row>
                                </Tab>
                                <Tab eventKey="SURRENDER" title="Surrender">
                                    <Row>
                                        <Col>
                                            <h4>Surrender</h4>
                                        </Col>
                                    </Row>
                                    <Row>&nbsp;</Row>
                                    {
                                        scrMode === "Q" ? null :
                                            <>
                                                <Row>
                                                    <Col className='col-md-6 col-12'>
                                                        <LocationSelector onDataFromChild={handleDataFromChild} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>Isd<span className="colorThemeRed">*</span></Form.Label>
                                                            <AsyncSelect
                                                                cacheOptions={false}
                                                                defaultOptions={false}
                                                                value={surIsd}
                                                                getOptionLabel={e => e.label + ' '}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={(search) => Picklist.fetchCurrencyAllowedPicklist(search, "")} // location = 1 , 2
                                                                onInputChange={Picklist.handleInputChange}
                                                                onChange={handleChangeIsd}
                                                            >
                                                            </AsyncSelect>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Options</Form.Label>
                                                            <Form.Select value={surOptions} onChange={e => handleOptions(e.target.value)}>
                                                                <option value="SEARCH">Search</option>
                                                                <option value="FIFO">FIFO</option>
                                                                <option value="SELECTIVE">Selective</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-md-3 col-6" style={{ display: surOptions === "SEARCH" ? "block" : "none" }}>
                                                        <Form.Group>
                                                            <Form.Label>Ref No<span className='colorThemeRed'>*</span></Form.Label>
                                                            <AsyncSelect
                                                                cacheOptions={false}
                                                                defaultOptions={false}
                                                                value={surRefNo}
                                                                getOptionLabel={e => e.label + ' '}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={(search) => Picklist.fetchVtSettled(search, {
                                                                    isd: surIsdId,
                                                                    location: surLocRadio === "S" ? surSelectedLocations : evtLocationValue,
                                                                    group: pIssuerGroup, account: pIssuerAccount, type: 'EVT'
                                                                })}
                                                                onInputChange={Picklist.handleInputChange}
                                                                onChange={handleRefChange}
                                                            >
                                                            </AsyncSelect>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>Rate<span className='colorThemeRed'>*</span></Form.Label>
                                                            <Form.Control type='text' maxLength={10} value={surRate} onChange={e => Common.validateDecValue(e.target.value, setSurRate)} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-md-3 col-6" style={{ display: surOptions === "SELECTIVE" && "none" }}>
                                                        <Form.Group>
                                                            <Form.Label>Value</Form.Label>
                                                            <Form.Control disabled={surOptions === "SEARCH"} type='text' maxLength={10} value={surValue} onChange={e => Common.validateDecValue(e.target.value, setSurValue)} />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>&nbsp;</Row>
                                                <Row>
                                                    <Col>
                                                        <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addSurProduct("S")}>Add</Button>
                                                        &nbsp;
                                                        {/* <Button variant='outline-secondary' className='buttonStyle' size='sm' onClick={() => setKey("PAYMENT")} onKeyDown={switchToPaymentTab}>Payment-&gt;</Button> */}
                                                    </Col>
                                                </Row>
                                                <Row>&nbsp;</Row>
                                            </>
                                    }

                                    <Row>
                                        <Table striped responsive bordered>
                                            <thead>
                                                <tr>
                                                    {/* <th>Code</th> */}
                                                    <th>Location</th>
                                                    <th>Isd</th>
                                                    <th>Issuer</th>
                                                    <th>Ref no</th>
                                                    <th>Rate</th>
                                                    <th>Value</th>
                                                    <th>Amount</th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    surrenderList.map(item => (
                                                        <tr>
                                                            {/* <td>{item.prd_srno}</td> */}
                                                            <td>{item.entity_name}</td>
                                                            <td>{item.prd_isd}</td>
                                                            <td>{item.mvli_ledger_name}</td>
                                                            <td>{item.prd_from}</td>
                                                            <td>{item.prd_rate}</td>
                                                            <td>{item.prd_deno}</td>
                                                            <td>{item.prd_amount}</td>
                                                            <td>
                                                                {
                                                                    scrMode === "Q" ? null :
                                                                        <span onClick={() => deleteProduct(item.uniquekey)} className='colorThemeBlue pointer'>
                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                        </span>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </Row>
                                </Tab>
                            </Tabs>
                        </>
                }
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
            </Container>
            <span className="corner-span">{counter}</span>
            <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                {msgText}
            </Dialog>
            <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
                {msgTextYN}
            </DialogYesNo>
            <Modal size='lg' show={showGvtModal} onHide={() => setShowGvtModal(false)} animation={false} centered>
                <Modal.Body>
                    <Table striped responsive bordered>
                        <thead>
                            <tr>
                                <th>&nbsp;</th>
                                <th>Code</th>
                                <th>Location</th>
                                <th>Currency</th>
                                <th>Issuer</th>
                                <th>Ref No</th>
                                <th>Rate</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                selectiveVTList.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <Form.Check name='ischecked' onChange={e => updateSelectiveCheck(e, index, "ischecked")} />
                                        </td>
                                        <td>{item.prd_srno}</td>
                                        <td>{item.entity_name}</td>
                                        <td>{item.prd_isd}</td>
                                        <td>{item.mvli_ledger_name}</td>
                                        <td>{item.prd_from}</td>
                                        <td>{item.prd_rate}</td>
                                        <td>{item.prd_deno}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-success" onClick={addSelectiveGvt}>
                        Save
                    </Button>&nbsp;
                    <Button variant="outline-danger" onClick={() => { setShowGvtModal(false); setSelectiveVTList([]); }}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}

export default Tran_glvtm_settlement
