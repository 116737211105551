import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Table, Button } from 'react-bootstrap'
import * as Common from "../Common";
import { useNavigate } from 'react-router-dom';
import Login_header from '../Login_header';
import Menu from '../Menu';

function Master_ledger_link() {
    const sid = sessionStorage.getItem("sessionId");
    const navigate = useNavigate();
    const [linkData, setLinkData] = useState([]);
    const [userRight, setUserRight] = useState([]);

    useEffect(() => {
        Common.callApi(Common.apiRight, [sid, 'all', 'MASTER_LEDGER_LINK'], function (result) {
            let resp = JSON.parse(result);
            if (resp.query === "1") {
                setUserRight(resp);
                Common.callApi(Common.apiLedgerLink, [sid, "listlink"], result => {
                    setLinkData(JSON.parse(result));
                })
            } else {
                navigate('/'); // no query right
            }
        });
    }, []);

    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h2>Ledger Link</h2>
                    </Col>
                </Row>
                {/* <Row>
                    <Col>
                        {
                            userRight.query === "1" ?
                                <>&nbsp;
                                    <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={exportFile}>Export</Button>
                                </>
                                : null
                        }
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                 */}
                <Row>
                    <Col>
                        <Table responsive striped>
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Group</th>
                                    <th>Account</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    linkData.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.ln_type}</td>
                                            <td>{item.ln_group}</td>
                                            <td>{item.ln_account}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Master_ledger_link
