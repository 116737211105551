import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import * as Common from '../../Common';
import Dialog from '../../Dialog';
import Login_header from '../../Login_header';
import Menu from '../../Menu';
import * as Picklist from '../../Picklist';

const Flm1_report = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const startDate = sessionStorage.getItem('startDate');
    const endDate = sessionStorage.getItem('endDate');
    const branchDisable = sessionStorage.getItem('branchDisable');
    const [onceRun, setOnceRun] = useState(false);
    const [userRight, setUserRight] = useState([]);
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState('');

    const [flmLocation, setFlmLocation] = useState(null);
    const [locationCode, setLocationCode] = useState(0);
    const [flmFilter, setFlmFilter] = useState("1");
    const [flmViewType, setFlmViewType] = useState('V');
    const [flmDate, setFlmDate] = useState(new Date('2024-03-31'));

    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.getActiveBranch().then(response => {
                setFlmLocation(response);
                response != null && handleChangeLocation(response);
            });
            Common.callApi(Common.apiRight, [sid, 'all', 'REPORTS_RBI_FLM'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);

    const handleChangeLocation = (value) => {
        setFlmLocation(value);
        const loc = value.id.split('^');
        setLocationCode(loc[1]);
        sessionStorage.activeBranch = loc[1];
    }

    const handleGenerate = () => {
        if (flmLocation === null) {
            setMsgText("Please Select Location!");
            setMsgBox(true);
        } else if (Common.dateYMD(flmDate) < startDate || Common.dateYMD(flmDate) > endDate) {
            setMsgText("Date should be within financial year");
            setMsgBox(true);
        } else {
            const obj = {
                location: locationCode,
                date: Common.dateDMY(flmDate),
                flmFilter: flmFilter,
                type: flmViewType,
            }
            Common.callDownloadApiPost(Common.apiFlmReport, "post", [sid, 'getData', JSON.stringify(obj)]);
        }
    }

    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h3>FLMs1 Report</h3>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                <Row>
                    <Col>
                        <Button variant='outline-success' className='buttonStyle' size='sm' onClick={handleGenerate}>Generate</Button>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                <Row>
                    <Col className='col-md-3 col-6'>
                        <Form.Group>
                            <Form.Label>Location<span className="text-danger">*</span></Form.Label>
                            <AsyncSelect
                                cacheOptions={false}
                                defaultOptions={false}
                                value={flmLocation}
                                getOptionLabel={e => e.label + ' '}
                                getOptionValue={e => e.id}
                                loadOptions={Picklist.fetchLocationAllowedPicklist}
                                onInputChange={Picklist.handleInputChange}
                                onChange={handleChangeLocation}
                                isDisabled={branchDisable === "true"}
                            >
                            </AsyncSelect>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className='col-md-3 col-6'>
                        <Form.Group>
                            <Form.Label>FLM Filter<span className="text-danger">*</span></Form.Label>
                            <Form.Select value={flmFilter} onChange={e => setFlmFilter(e.target.value)}
                                required>
                                <option value="1">FLM1 Daily Summ and Bal.(FC)</option>
                                <option value="2">FLM2 Daily Summ and Bal.(TC)</option>
                                <option value="3">FLM3 Purchase from Public(FC)</option>
                                <option value="4">FLM4 from FFMC/RMC-FC</option>
                                <option value="5">FLM5 Sale to Public (FC/TC)</option>
                                <option value="6">FLM6 Sale to FFMC/RMC (FC)</option>
                                <option value="7">FLM7 TC's Surrender to AD's</option>
                                <option value="8">FLM8 Purchase and Sale of (FC)</option>
                                <option value="9">FLM9 TC's Purchase and Surrender</option>
                                <option value="10">FLM10 Sales(FC/TC) for B.Vst</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    {
                        flmFilter === "1" ?
                            <>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Date<span className="colorThemeRed">*</span></Form.Label><br />
                                        <DatePicker className="form-control"
                                            selected={flmDate}
                                            onChange={(date) => setFlmDate(date)}
                                            dateFormat="dd/MM/yyyy"
                                            showYearDropdown
                                            showMonthDropdown
                                            useShortMonthInDropdown
                                            dropdownMode="select"
                                            peekNextMonth
                                            customInput={
                                                <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                            </> : null
                    }
                    <Col className='col-md-3 col-6'>
                        <Form.Group>
                            <Form.Label>Mode<span className="text-danger">*</span></Form.Label>
                            <Form.Select value={flmViewType} onChange={e => setFlmViewType(e.target.value)}
                                required>
                                <option value="V">View</option>
                                <option value="D">Download</option>
                                <option value="P">PDF</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
            </Container>
            <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                {msgText}
            </Dialog>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
        </>
    )
}

export default Flm1_report;
