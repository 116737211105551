import React, { useState } from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import * as Common from "./Common";
import Login_header from './Login_header';
import Menu from './Menu';
import Dialog from './Dialog';

function Fin_year() {
    const sid = sessionStorage.getItem("sessionId");
    const [finYear, setFinYear] = useState("0");
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    const changeFinYear = () => {
        if (finYear === "0") {
            setMsgBox(true);
            setMsgText("Please select financial year!");
        } else {
            Common.callApi(Common.apiFinYear, [sid, "changefinyr", finYear], result => {
                console.log(result);
                let resp = JSON.parse(result);
                setMsgBox(true);
                setMsgText(resp.msg);
                sessionStorage.dbname = resp.dbname;
                sessionStorage.startDate = resp.startdate;
                sessionStorage.endDate = resp.enddate;
            });
        }
    }
    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col className='col-md-3 col-6'>
                        <Form.Group>
                            <Form.Label>Financial Year</Form.Label>
                            <Form.Select value={finYear} onChange={e => setFinYear(e.target.value)}>
                                <option value="0">Select</option>
                                <option value="2324">2324</option>
                                <option value="2425">2425</option>
                                <option value="2526">2526</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col style={{ marginTop: "31px" }}>
                        <Button className='buttonStyle' size='sm' variant='outline-primary' onClick={() => changeFinYear()}>Save</Button>
                    </Col>
                </Row>
            </Container>
            <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
            </div>
        </>
    )
}

export default Fin_year
