import { faEye } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from "jquery";
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import Dialog from '../Dialog';
import DialogYesNo from '../Dialog_Yes_No';
import Login_header from '../Login_header';
import Menu from '../Menu';
import * as Common from './../Common';
import * as Picklist from './../Picklist';

const Tran_evtm_surrender = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const branchDisable = sessionStorage.getItem('branchDisable');

    const [userRight, setUserRight] = useState([]);
    const [onceRun, setOnceRun] = useState(false);
    const [scrMode, setScrMode] = useState('');

    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    const [dataList, setDataList] = useState([]);
    const [productData, setProductData] = useState([]);
    const [paymentData, setPaymentData] = useState([]);
    const [radioType, setRadioType] = useState('S');
    const [evtmSurrenderLocation, setEvtmSurrenderLocation] = useState(null);
    const [locationCode, setLocationCode] = useState(0);
    const [location, setLocation] = useState('');
    const [evtmFromSrno, setEvtmFromSrno] = useState("");
    const [evtmToSrno, setEvtmToSrno] = useState("");
    const [evtmFromDate, setEvtmFromDate] = useState(new Date());
    const [evtmToDate, setEvtmToDate] = useState(new Date());
    const [ledger, setLedger] = useState("");
    const [date, setDate] = useState(new Date());
    const [total, setTotal] = useState("");
    const [faViewData, setFaViewData] = useState([]);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.getActiveBranch().then(response => {
                setEvtmSurrenderLocation(response);
                response != null && handleChangeLocation(response);
            });
            Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_EVTM_SURRENDER'], (result) => {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);

    const handleChangeLocation = (value) => {
        setEvtmSurrenderLocation(value);
        const loc = value.id.split('^');
        setLocationCode(loc[1]);
        setLocation(loc[2]);
        sessionStorage.activeBranch = loc[1];
    }

    const listData = () => {
        $(".loader").show();
        var msg = [], i = 0;
        if (evtmSurrenderLocation === null) {
            $(".loader").hide();
            setMsgText("Please Select Location!");
            setMsgBox(true);
        } else if ((radioType === 'S' && !evtmFromSrno) || (radioType === 'S' && !evtmToSrno) || (radioType === 'D' && !evtmFromDate) || (radioType === 'D' && !evtmToDate)) {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (radioType === 'S' && !evtmFromSrno ? " From." : '');
            msg[i++] = (radioType === 'S' && !evtmToSrno ? " To." : '');
            msg[i++] = (radioType === 'D' && !evtmFromDate ? " From Date." : '');
            msg[i++] = (radioType === 'D' && !evtmToDate ? " To Date." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const obj = {
                location: locationCode,
                filterRadioType: radioType,
                filterFromSrno: evtmFromSrno,
                filterToSrno: evtmToSrno,
                filterFromDate: Common.dateYMD(evtmFromDate),
                filterToDate: Common.dateYMD(evtmToDate),
            }
            Common.callApi(Common.apiTranEVTMSurrender, [sid, 'list', JSON.stringify(obj)], (result) => {
                $(".loader").hide();
                let resp = JSON.parse(result);
                setDataList(resp);
            });
        }
    }


    const cancelData = () => {
        setMsgBoxYN(false);
        setScrMode("");
    }


    const viewList = (hdrSrno, hdrType, hdrLocation, locationName, ledger, total, date) => {
        $(".loader").show();
        setScrMode('Q');
        setLocation(locationName);
        setLedger(ledger);
        setTotal(total);
        setDate(date);
        const obj = {
            srno: hdrSrno,
            location: hdrLocation,
            type: hdrType,
        }
        Common.callApi(Common.apiTranEVTMSurrender, [sid, 'view', JSON.stringify(obj)], (result) => {
            $(".loader").hide();
            let resp = JSON.parse(result);
            setProductData(resp.productData);
            setPaymentData(resp.paymentData);
        });
    }


    const handleFaView = () => {
        $(".loader").show();
        setShowModal(true);
        Common.callApi(Common.apiTranEVTMSurrender, [sid, 'faView'], (result) => {
            $(".loader").hide();
            let resp = JSON.parse(result);
            setFaViewData(resp);
        });
    }

    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h3> EVTM Surrender</h3>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                {
                    scrMode === '' ?
                        <>

                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Location<span className="colorThemeRed">*</span></Form.Label>
                                        <AsyncSelect
                                            cacheOptions={false}
                                            defaultOptions={false}
                                            value={evtmSurrenderLocation}
                                            getOptionLabel={e => e.label + ' '}
                                            getOptionValue={e => e.id}
                                            loadOptions={Picklist.fetchLocationAllowedPicklist}
                                            onInputChange={Picklist.handleInputChange}
                                            onChange={handleChangeLocation}
                                            isDisabled={branchDisable === "true"}
                                        >
                                        </AsyncSelect>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-4 col-6'>
                                    <Form.Group>
                                        <Row style={{ marginTop: "30px" }}>
                                            <Col>
                                                <Form.Check checked={radioType === "S"} onChange={() => setRadioType("S")} type='radio' label="SrNo" />
                                            </Col><Col>
                                                <Form.Check checked={radioType === "D"} onChange={() => setRadioType("D")} type='radio' label="Date" />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                {
                                    radioType === 'S' ?
                                        <>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>From<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control value={evtmFromSrno}
                                                        maxLength={20}
                                                        onChange={(e) => Common.validateNumValue(e.target.value, setEvtmFromSrno)}
                                                        placeholder="From" />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>To<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control value={evtmToSrno}
                                                        maxLength={20}
                                                        onChange={(e) => Common.validateNumValue(e.target.value, setEvtmToSrno)}
                                                        placeholder="To" />
                                                </Form.Group>
                                            </Col>
                                        </> :
                                        radioType === 'D' ?
                                            <>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                                                        <DatePicker className="form-control"
                                                            selected={evtmFromDate}
                                                            onChange={(date) => setEvtmFromDate(date)}
                                                            dateFormat="dd/MM/yyyy"
                                                            showYearDropdown
                                                            showMonthDropdown
                                                            useShortMonthInDropdown
                                                            dropdownMode="select"
                                                            peekNextMonth
                                                            customInput={
                                                                <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>To<span className="colorThemeRed">*</span></Form.Label>
                                                        <DatePicker className="form-control"
                                                            selected={evtmToDate}
                                                            onChange={(date) => setEvtmToDate(date)}
                                                            dateFormat="dd/MM/yyyy"
                                                            showYearDropdown
                                                            showMonthDropdown
                                                            useShortMonthInDropdown
                                                            dropdownMode="select"
                                                            peekNextMonth
                                                            customInput={
                                                                <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </> : null
                                }
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    {
                                        userRight.query === "1" ?
                                            <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => listData()}>List</Button>
                                            : null
                                    }
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                {userRight.edit === "1" ? <th></th> : null}
                                                <th>Location</th>
                                                <th>Ledger</th>
                                                <th>Date</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                dataList.map((res) => (
                                                    <tr key={res.hdr_srno}>
                                                        {userRight.edit === "1" ?
                                                            <td className="textCenter">
                                                                <span className="handCursor colorThemeBlue" onClick={() => viewList(res.hdr_srno, res.hdr_type, res.hdr_location, res.entity_name, res.ledger, res.hdr_forex_total, res.hdr_date)}>
                                                                    <FontAwesomeIcon icon={faEye} />
                                                                </span>
                                                            </td>
                                                            : null
                                                        }
                                                        <td>{res.entity_name}</td>
                                                        <td>{res.ledger}</td>
                                                        <td>{res.hdr_date}</td>
                                                        <td>{res.hdr_forex_total}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </> :
                        <>
                            <Row>
                                <Col>
                                    <Button variant='outline-danger' className='buttonStyle' size='sm' onClick={() => cancelData()}>Back</Button> {' '}
                                    <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => handleFaView()}>faView</Button>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Label><b>Location:</b> {location}</Form.Label>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Label><b>Date:</b> {date}</Form.Label>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Label><b>Ledger: </b>{ledger}</Form.Label>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Label><b>Total:</b> {total}</Form.Label>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    <h3>Product Details</h3>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                <th>Location</th>
                                                <th>ISD</th>
                                                <th>Ledger</th>
                                                <th>Date</th>
                                                <th>Product</th>
                                                <th>Denomination</th>
                                                <th>Quantity</th>
                                                <th>Rate</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                productData.map((res) => (
                                                    <tr key={res.prd_line_no}>
                                                        <td>{res.entity_name}</td>
                                                        <td>{res.isd}</td>
                                                        <td>{res.ledger}</td>
                                                        <td>{res.prd_date}</td>
                                                        <td>{res.prd_product}</td>
                                                        <td>{res.prd_deno}</td>
                                                        <td>{res.prd_qty}</td>
                                                        <td>{res.prd_rate}</td>
                                                        <td>{res.prd_amount}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    <h3>Payment Details</h3>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                <th>Location</th>
                                                <th>Ledger</th>
                                                <th>Payment Type</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                paymentData.map((res) => (
                                                    <tr key={res.tp_line_no}>
                                                        <td>{res.entity_name}</td>
                                                        <td>{res.ledger}</td>
                                                        <td>{res.tp_payment_type}</td>
                                                        <td>{res.tp_amount}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </>
                }

                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
            </Container>
            <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
                <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
                    {msgTextYN}
                </DialogYesNo>
            </div>
            <div>
                <Modal size='lg' show={showModal} onHide={() => setShowModal(false)} animation={false} centered>
                    <Modal.Body>
                        <Container fluid>
                            <Row>
                                <Col>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                <th>Location</th>
                                                <th>Type</th>
                                                <th>Date</th>
                                                <th>Flag</th>
                                                <th>Ledger</th>
                                                <th>Amount</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                faViewData.map((res) => (
                                                    <tr key={res.tfd_line_no}>
                                                        <td>{res.entity_name}</td>
                                                        <td>{res.tfd_type}</td>
                                                        <td>{res.tfd_date}</td>
                                                        <td>{res.tfd_flag === 'C' ? 'CREDIT' : 'DEBIT'}</td>
                                                        <td>{res.ledger}</td>
                                                        <td>{res.tfd_amount}</td>
                                                        <td>{res.tfd_active === '1' ? 'ACTIVE' : 'INACTIVE'}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-success" onClick={() => setShowModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}

export default Tran_evtm_surrender;
