import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Table, Form, Button, Modal, Badge } from 'react-bootstrap'
import * as Common from "../Common";
import Login_header from '../Login_header';
import Menu from '../Menu';
import { useNavigate } from 'react-router-dom';
import * as Picklist from "../Picklist";
import DatePicker from 'react-datepicker';
import AsyncSelect from 'react-select/async';
import $ from "jquery";
import Dialog from '../Dialog';
import Select from "react-select";
import DialogYesNo from '../Dialog_Yes_No';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye } from '@fortawesome/free-regular-svg-icons';
import { faCircleCheck, faCircleExclamation, faCircleXmark, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import ActivityLog from '../ActivityLog';
import Master_agent_linking from '../master/financial/Master_agent_linking';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';

function Tran_commission_gst_approval({ obj, holdGstData }) {
    const sid = sessionStorage.getItem("sessionId");
    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAll = (ischecked) => {
        setSelectAll(ischecked);
        var newArr = [];
        if (ischecked) {
            newArr = holdGstData.map(item => {
                return { ...item, 'selected': item.det_hgst_submit == "1" ? "0" : "1" };
            });
        } else {
            newArr = holdGstData.map(item => {
                // if (item.det_submit_approval === "0")
                return { ...item, 'selected': '0' };
            });
        }
        obj.setHoldGstData(newArr);
    }

    const sendForApproval = () => {
        $(".loader").show();
        var err = "Please select trasactions.";
        var isOk = false;
        const obj1 = {
            hdrsrno: obj.hdrsrno,
            location: obj.cmLocationValue,
            holdGstData: holdGstData,
            agentcode: obj.agentcode,
            holdgstlist: true
        }
        for (var i = 0; i < holdGstData.length; i++) {
            if (holdGstData[i].selected == "1") {
                isOk = true;
                err = "";
            }
            if (holdGstData[i].selected == "1" && holdGstData[i].det_hgst_status != "O") {
                isOk = false;
                err = "Please mark OK to each transaction before sending it for approval.";
                break;
            }
        }
        if (isOk) {
            Common.callApi(Common.apiCommission, [sid, "sendgstapproval", JSON.stringify(obj1)], result => {
                $(".loader").hide();
                let resp = JSON.parse(result);
                obj.setHoldGstData(resp.data);
                // let resp = JSON.parse(result);
                // setCommissionData(resp.commdata);
                // obj.setMsgBox(true);
                // obj.setMsgText(resp.msg);
            });
        } else {
            $(".loader").hide();
            obj.setMsgBox(true);
            obj.setMsgText(err);
        }
        handleSelectAll(false);
    }

    const btnActivityLog = () => {

    }

    const updateCheckChanges = (name, index, e) => {
        let newArr = holdGstData.map((item, i) => {
            if (index === i) {
                if (name === "selected") {
                    setSelectAll(false);
                    if (e.target.checked) return { ...item, [name]: '1' }
                    else return { ...item, [name]: '0' }
                }
            } else {
                return item;
            }
        });
        obj.setHoldGstData(newArr);
    }

    const updateStatus = (status, srno, lineno, location, certno, type) => {
        handleSelectAll(false);
        $(".loader").show();
        const obj1 = {
            status: status,
            trannumber: srno,
            lineno: lineno,
            location: location,
            certno: certno,
            type: type,
            paytype: "GST",
            holdgstlist: true,
            agentcode: obj.agentcode
        }
        Common.callApi(Common.apiCommission, [sid, "updatestatus", JSON.stringify(obj1)], result => {
            $(".loader").hide();
            let resp = JSON.parse(result);
            obj.setHoldGstData(resp.commissiondata);
        });
    }


    return (
        <>
            <Row>
                <Col className="col-md-2 col-4">
                    <span>Location : <b>{obj.location} </b></span>
                </Col>
                <Col className="col-md-2 col-4">
                    <span>Agent : <b>{obj.agentcode + " " + obj.agentname} </b></span>
                </Col>
                <Col className="col-md-2 col-4">
                    <span>GstIN : <b>{obj.gstin} </b></span>
                </Col>
                <Col className="col-md-2 col-4">
                    <span>SGST : <b>{obj.sgst} </b></span>
                </Col>
                <Col className="col-md-2 col-4">
                    <span>CGST : <b>{obj.cgst} </b></span>
                </Col>
                <Col className="col-md-2 col-4">
                    <span>IGST : <b>{obj.igst} </b></span>
                </Col>
                <Col className="col-md-2 col-4">
                    <span>Total GST : <b>{1 * obj.igst + 1 * obj.cgst + 1 * obj.sgst} </b></span>
                </Col>
                <Col className="col-md-2 col-4">
                    <span>Invoice Number : <b>{obj.invoicenum} </b></span>
                </Col>
                <Col className="col-md-2 col-4">
                    <span>Invoice Date : <b>{obj.invoicedate} </b></span>
                </Col>
            </Row>
            <Row>&nbsp;</Row>
            <Row>
                <Col className='col-md-6'>
                    <Button variant='outline-danger' size='sm' className='buttonStyle' onClick={() => obj.setScrMode("")}>Back</Button>
                    &nbsp;
                    <Button variant='outline-primary' size='sm' className='buttonStyle' onClick={() => sendForApproval()}>Send For Approval</Button>
                    &nbsp;
                    <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() => btnActivityLog("BM")}>Activity Log</Button>
                </Col>
            </Row>
            <Row>&nbsp;</Row>
            <Table responsive striped bordered>
                <thead>
                    <tr>
                        <th className='commissionCheck'>
                            <span>All&nbsp;</span>
                            <span><Form.Check type="checkbox" checked={selectAll} onChange={e => handleSelectAll(e.target.checked)} /></span>
                        </th>
                        <th>Action</th>
                        <th>Status</th>
                        <th>Cert</th>
                        <th>CGST</th>
                        <th>SGST</th>
                        <th>IGST</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        holdGstData.map((item, index) => (
                            <tr>
                                {
                                    item.det_hgst_submit === "1" ?
                                        <>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </>
                                        :
                                        <>
                                            <td>
                                                <div style={{ display: "block" }}>
                                                    <div style={{ display: "flex" }}>
                                                        <span>
                                                            <Form.Check type="checkbox" checked={selectAll || item.selected === "1" && "checked"} onClick={(e) => updateCheckChanges("selected", index, e)} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span className='handCursor' title="Ok" style={{ color: "green" }}>
                                                    <FontAwesomeIcon icon={faCircleCheck} onClick={() => updateStatus("O", item.det_srno, item.det_lineno, item.det_location, item.det_cert_no, item.det_type)} />
                                                </span>
                                                &nbsp;&nbsp;
                                                <span className='handCursor colorThemeBlue' title="Hold">
                                                    <FontAwesomeIcon icon={faCircleExclamation} onClick={() => updateStatus("H", item.det_srno, item.det_lineno, item.det_location, item.det_cert_no, item.det_type)} />
                                                </span>
                                            </td>
                                        </>
                                }
                                <td>{item.det_hgst_status == "O" ? "OK" :
                                    item.det_hgst_status == "A" ? "Approved" :
                                        item.det_hgst_status == "R" ? "Rejected" : "Hold"}</td>
                                <td>{item.det_type + ' ' + item.det_cert_no}</td>
                                <td>{item.det_cgst}</td>
                                <td>{item.det_sgst}</td>
                                <td>{item.det_igst}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </>
    )
}

export default Tran_commission_gst_approval
