import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import * as Common from './Common';

const Tran_data_unlock_request = (props) => {

    const sid = sessionStorage.getItem('sessionId');
    const [unlockData, setUnlockData] = useState([]);

    const [msg, setMsg] = useState('');

    useEffect(() => {
        if (props.show) {
            if (props.hdrNotExist) {
                // console.log(props.logObject);
              let obj = props.logObject;
                setUnlockData([{
                    hdr_type : obj.trantype,
                    hdr_srno: obj.trannumber,
                    entity_name: obj.entity_name,
                    hdr_date : obj.trandate,
                    tdu_status: obj.tdu_status
                }]);
            } else {
                Common.callApi(Common.apiDataUnlockRequest, [sid, "getData", JSON.stringify(props.logObject)], result => {
                    setUnlockData(JSON.parse(result));
                    setMsg(Common.getMessage('MSG0012'));
                });
            }
        }
    }, [props.show]);
    console.log(unlockData);

    const handleRequest = () => {
        Common.callApi(Common.apiDataUnlockRequest, [sid, "sendRequest", JSON.stringify(props.logObject)], result => {
            console.log(result);
        });
        props.onHide();
    }

    return (
        <div>
            <Modal  {...props} animation={false} size='lg' centered>
                <Modal.Header closeButton>
                    <Modal.Title>Data Unlock</Modal.Title>
                </Modal.Header>
                <Form.Label>{msg}</Form.Label>
                <Table responsive striped bordered>
                    <thead>
                        <tr>
                            <th>SrNo</th>
                            <th>Type</th>
                            <th>Location</th>
                            {
                                unlockData.map((item) =>
                                    item.tdu_status == null ?
                                        <th></th> : <th>Status</th>
                                )
                            }

                        </tr>
                    </thead>
                    <tbody>
                        {
                            unlockData.map((item, index) => (
                                <tr key={item.hdr_srno}>
                                    <td>{item.hdr_srno}</td>
                                    <td>{item.hdr_type}</td>
                                    <td>{item.entity_name}</td>

                                    {
                                       item.tdu_status == '' ||  item.tdu_status == null || item.tdu_status == 'R' || item.tdu_status == 'E' ?
                                            <td>
                                                <Form.Label>Do you want to send request ?</Form.Label><br />
                                                <Button variant="primary" onClick={handleRequest}>Yes</Button>{' '}
                                                <Button variant="primary" onClick={() => props.onHide()}>No</Button>
                                            </td> :
                                            <td>{item.tdu_status == 'P' ? 'Pending' : item.tdu_status == 'A' ? 'Approved' : item.tdu_status == 'R' ? 'Rejected' : ''}</td>
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Tran_data_unlock_request
