import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import Dialog from '../../Dialog';
import LocationSelector from '../../LocationSelector';
import Login_header from '../../Login_header';
import Menu from '../../Menu';
import * as Common from './../../Common';

const Manual_book_report = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const branchDisable = sessionStorage.getItem('branchDisable');
    const [onceRun, setOnceRun] = useState(false);
    const [userRight, setUserRight] = useState([]);
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState('');

    const [manualDate, setManualDate] = useState(new Date());
    const [manualFromDate, setManualFromDate] = useState(new Date());
    const [manualToDate, setManualToDate] = useState(new Date());
    const [manualType, setManualType] = useState("S");

    const [locationCode, setLocationCode] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);
    const [radioType, setRadioType] = useState("O");
    const [manualDownloadType, setManualDownloadType] = useState("V");
    const [scr, setScr] = useState("");
    const [manualReportList, setManualReportList] = useState([]);

    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.callApi(Common.apiRight, [sid, 'all', 'MANUAL_BOOK_REPORT'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);

    const handleDataFromChild = (newLocationCode, newSelectedItems, newRadioType) => {
        setLocationCode(newLocationCode);
        setSelectedItems(newSelectedItems);
        setRadioType(newRadioType);
    }

    const handleGenerate = () => {
        var msg = [], i = 0;
        if ((radioType === 'O' && locationCode === '0') || (radioType == 'S' && selectedItems == [])) {
            msg[i++] = ((radioType == 'O' && locationCode == '0') ? "Please select location." : '');
            msg[i++] = ((radioType == 'S' && selectedItems == []) ? "Please select atleast one location." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const obj = {
                date: Common.dateYMD(manualDate),
                fromDate: Common.dateYMD(manualFromDate),
                toDate: Common.dateYMD(manualToDate),
                type: manualType,
                radioTypeLocation: radioType,
                location: locationCode,
                multipleLocation: selectedItems,
            };
            // Common.callDownloadApiPost(Common.apiStockInHandReport, "post", [sid, 'getData', JSON.stringify(obj)], (result) => {
            //     if (stockType === 'V') {
            //         var newWindow = window.open();
            //         newWindow.document.write(result);
            //     } else {

            //     }
            // });


            if (manualType === 'D') {
                Common.callDownloadApiPost(Common.apiManualBookReport, "post", [sid, 'getData', JSON.stringify(obj)]);
            } else {
                Common.callApi(Common.apiManualBookReport, [sid, 'viewData', JSON.stringify(obj)], (result) => {
                    setScr('V');
                    const resData = JSON.parse(result);
                    setManualReportList(resData)
                });
            }
        }
    }

    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h3>Manual Book Report</h3>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                <Row>
                    <Col>
                        <Button variant='outline-success' className='buttonStyle' size='sm' onClick={handleGenerate}>Generate</Button>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                <Row>
                    <Col className='col-md-3 col-6'>
                        <Form.Group>
                            <Form.Label>Type<span className="text-danger">*</span></Form.Label>
                            <Form.Select value={manualDownloadType} onChange={e => setManualDownloadType(e.target.value)}
                                required>
                                <option value="S">In Stock</option>
                                <option value="U">Used</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    {
                        manualType === 'S' ?
                            <>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Date<span className="colorThemeRed">*</span></Form.Label><br />
                                        <DatePicker className="form-control"
                                            selected={manualDate}
                                            onChange={(date) => setManualDate(date)}
                                            dateFormat="dd/MM/yyyy"
                                            showYearDropdown
                                            showMonthDropdown
                                            useShortMonthInDropdown
                                            dropdownMode="select"
                                            peekNextMonth
                                            customInput={
                                                <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                            </> :
                            <>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>From Date<span className="colorThemeRed">*</span></Form.Label><br />
                                        <DatePicker className="form-control"
                                            selected={manualFromDate}
                                            onChange={(date) => setManualFromDate(date)}
                                            dateFormat="dd/MM/yyyy"
                                            showYearDropdown

                                            showMonthDropdown
                                            useShortMonthInDropdown
                                            dropdownMode="select"
                                            peekNextMonth
                                            customInput={
                                                <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                            }
                                        />
                                    </Form.Group>
                                </Col>

                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>To Date<span className="colorThemeRed">*</span></Form.Label><br />
                                        <DatePicker className="form-control"
                                            selected={manualToDate}
                                            onChange={(date) => setManualToDate(date)}
                                            dateFormat="dd/MM/yyyy"
                                            showYearDropdown
                                            showMonthDropdown
                                            useShortMonthInDropdown
                                            dropdownMode="select"
                                            peekNextMonth
                                            customInput={
                                                <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                            </>
                    }
                    <Col className='col-md-3 col-6'>
                        <Form.Group>
                            <Form.Label>Mode<span className="text-danger">*</span></Form.Label>
                            <Form.Select value={manualType} onChange={e => setManualType(e.target.value)}
                                required>
                                <option value="V">View</option>
                                <option value="D">Download</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                <Row>
                    <Col className='col-md-6 col-6'>
                        <Form.Label>Location Filter</Form.Label><br />
                        <LocationSelector onDataFromChild={handleDataFromChild} />
                    </Col>
                </Row>
                {scr === "V" ? <>
                    <Row>
                        <Col>
                            <Button onClick={() => setScr('')} variant="primary">Back</Button>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    &nbsp;
                    <Table responsive striped bordered>
                        <thead>
                            <tr>
                                <th>Branch</th>
                                <th>In Type</th>
                                <th>Prefix</th>
                                <th>In Date</th>
                                <th>Out Date</th>
                                <th>Out Serial no.</th>
                                <th>Out Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                manualReportList.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.tmb_location}</td>
                                        <td>{item.tmb_type}</td>
                                        <td>{item.tmb_prefix}</td>
                                        <td>{item.tmb_date}</td>
                                        <td>{item.tmbb_date}</td>
                                        <td>{item.tmbb_out_srno}</td>
                                        <td>{item.tmbb_out_type}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </> : null}

            </Container>
            <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                {msgText}
            </Dialog>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
        </>
    )
}

export default Manual_book_report;