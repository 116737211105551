import React, { useEffect, useState } from 'react';
import * as Common from "../../Common";
import { Button, Col, Container, Row, Form, Table } from 'react-bootstrap';

const Tran_fa_book_print = (props) => {

    const sid = sessionStorage.getItem('sessionId');
    const [printDtlData, setPrintDtlData] = useState([]);
    const [printHdrData, setPrintHdrData] = useState([]);
    const [creditValue, setCreditValue] = useState(0);

    useEffect(() => {
        const obj = {
            srno: props.srno,
            location: props.location,
            type: props.type,
        }
        Common.callApi(Common.apiBookEntry, [sid, "download", JSON.stringify(obj)], result => {
            let resp = JSON.parse(result);
            console.log(resp.header);
            console.log(resp.detail);
            setPrintDtlData(resp.detail);
            setPrintHdrData(resp.header[0]);
            setCreditValue(resp.credit);
            console.log(resp.header[0].tfh_total_credit);
        });
    }, []);

    const tableStyle = {
        borderCollapse: 'collapse',
        //marginLeft: '10px',
        //width: '100%',
        border: '1px solid black',
        fontFamily: 'Bookman Old Style',
    };

    const cellStyle = {
        border: '1px solid black',
        textAlign: 'left',
        fontFamily: 'Bookman Old Style',
    };

    const tbodyBorderStyle = {
        borderLeft: '1px solid black',
        borderRight: '1px solid black',
    };

    const handleBack = () => {
        props.hidePrint('');
    }

    return (
        <>
            <div className='Button'>
                <Button variant='outline-success' size='sm' onClick={() => handleBack()}>Back</Button>
            </div>


            <div className='container-fluid'>
                <p style={{ marginTop: '30px', fontFamily: 'Bookman Old Style' }}> ZENITH LEISURE HOLIDAYS LTD <br />
                    68, BALLYGUNGE, CIRCULAR ROAD, ANNAPURNA APARTMENT, 1ST FLOOR, <br />
                    KOLKOTA-700019</p>
                <p style={{ marginLeft: '260px', fontWeight: 'bold', fontFamily: 'Bookman Old Style', marginTop: '10px' }}>Bank Book Voucher</p>
                <table className='col-md-5 col-12' style={tableStyle}>
                    <thead>
                        <tr>
                            <td><span style={{ float: 'left' }}>Entered By:SUPPORTHO3</span></td>
                            <td></td>
                            <td><span style={{ float: 'right' }}>Trans. No.:{printHdrData.tfh_srno}</span></td>
                            <td></td>
                            <td style={{ colSpan: 3 }}><span style={{ float: 'right' }}>Date:{printHdrData.tfh_date}</span></td>
                        </tr>
                        <tr>
                            <th style={{ ...cellStyle, textAlign: 'center' }}>Account Name</th>
                            <th style={{ ...cellStyle, textAlign: 'center' }}>Debit</th>
                            <th style={{ ...cellStyle, textAlign: 'center' }}>Credit</th>
                            <th style={{ ...cellStyle, textAlign: 'center' }}>Cheque No.</th>
                            <th style={{ ...cellStyle, textAlign: 'center' }}>Bank Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {printDtlData.map((res) => (
                            <tr key={res.tfd_line_no}>
                                <td style={tbodyBorderStyle}>{res.mvli_ledger_name}</td>
                                <td style={{ ...tbodyBorderStyle, textAlign: 'right' }}>{res.tfd_flag == 'D' && res.tfd_amount}</td>
                                <td style={{ ...tbodyBorderStyle, textAlign: 'right' }}>{res.tfd_flag == 'C' && res.tfd_amount}</td>
                                <td style={tbodyBorderStyle}>{res.tfd_cheque_no}</td>
                                <td style={tbodyBorderStyle}>{res.tfd_cheque_bank_name}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td style={{ ...cellStyle, textAlign: 'right' }}>Total</td>
                            <td style={cellStyle}>{printHdrData.tfh_total_debit}</td>
                            <td style={cellStyle}>{printHdrData.tfh_total_credit}</td>
                            <td style={cellStyle}></td>
                            <td style={cellStyle}></td>
                        </tr>
                    </tfoot>
                </table>
                <p style={{ fontFamily: 'Bookman Old Style' }}><br />
                    Amount in Words:( {creditValue}) <br />
                    <span>
                        {printHdrData.tfh_narration}
                    </span>
                </p>
                <p><span>Prepared By</span>
                    <span style={{ marginLeft: '120px' }}>Authorized By</span>
                    <span style={{ marginLeft: '160px' }}>Payee's Signature</span>
                </p>
            </div>
        </>
    )
}

export default Tran_fa_book_print
