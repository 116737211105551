import React, { useState } from 'react'
import Menu from './Menu';
import Login_header from './Login_header';
import { Container, Row, Col } from 'react-bootstrap';
import LocationSelector from './LocationSelector';
import CurrencySelector from './CurrencySelector';

const Testing = () => {

    const [locationCode, setLocationCode] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);
    const [radioType, setRadioType] = useState('A');

    const [currencyCode, setCurrencyCode] = useState(0);
    const [selectedItems2, setSelectedItems2] = useState([]);
    const [radioType2, setRadioType2] = useState('A');

    const handleDataFromChild = (newLocationCode, newSelectedItems, newRadioType) => {
        setLocationCode(newLocationCode);
        setSelectedItems(newSelectedItems);
        setRadioType(newRadioType);
    }

    const handleDataFromChild2 = (newCurrencyCode, newSelectedItems2, newRadioType2) => {
        setCurrencyCode(newCurrencyCode);
        setSelectedItems2(newSelectedItems2);
        setRadioType2(newRadioType2);
    }

    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <LocationSelector onDataFromChild={handleDataFromChild} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4>Location from Picklist: {locationCode}</h4>
                        <h4>Location from Dropdown: {selectedItems}</h4>
                        <h4>Radio Button : {radioType}</h4>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
                <Row>
                    <Col>
                        <CurrencySelector onDataFromChild2={handleDataFromChild2} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4>Currency from Picklist: {currencyCode}</h4>
                        <h4>Currency from Dropdown: {selectedItems2}</h4>
                        <h4>Radio Button : {radioType2}</h4>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
            </Container>
        </>
    )
}

export default Testing
