import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Form, Button } from 'react-bootstrap';
import * as Common from "./Common";
import Dialog from './Dialog';
import Login_header from './Login_header';
import Menu from './Menu';
import $ from "jquery";

let ChngpwdBox = {
    width: '650px',
    maxWidth: '100%'
}


function Chngpwd() {
    const navigate = useNavigate();
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    const [oldPass, setOldPass] = useState("");
    const [newPass, setNewPass] = useState("");
    const [cnfNewPass, setCnfNewPass] = useState("");

    function onClickChngPass() {
        $(".loader").show();
        let i = 0;
        setMsgText("");
        if (!Common.validPassword.test(newPass)) {
            i++;
            setMsgText(Common.getMessage("MSG0005"));
        } else if ((oldPass === "") || (newPass === "") || (cnfNewPass === "")) {
            setMsgText(Common.getMessage("MSG0005"));
            i++;
        } else if (newPass !== cnfNewPass) {
            setMsgText(Common.getMessage("MSG0009"));
            i++;
        }
        if (i > 0) {
            $(".loader").hide();
            setMsgBox(true);
        } else {
            let obj = {
                old: oldPass,
                new: newPass,
                rnew: cnfNewPass
            }

            Common.callApi(Common.apiUser, [sessionStorage.sessionId, "chngpass", JSON.stringify(obj)], function (result) {
                $(".loader").hide();
                let resp = JSON.parse(result);
                if (resp.msg === "") {
                    setMsgText(resp.err);
                    setMsgBox(true);
                } else {
                    setMsgText(resp.msg);
                    setMsgBox(true);
                    console.log(resp, 'chngps')
                    if (resp.newr == "1" && sessionStorage.chngPassOnLogin === '1') {
                        sessionStorage.chngPassOnLogin = 0;
                        navigate("/home");
                    } else if (sessionStorage.chngPassOnLogin === '0' && resp.newr == "1") {
                        setMsgText("Password Change Succesfully");
                        setMsgBox(true);
                    }else {
                        navigate("/");
                    }
                }
            });
        }
    }
    return (
        <>
            {
                sessionStorage.chngPassOnLogin === '0' ?
                    <>
                        <Login_header />
                        <Menu />
                        {/* -------------------show menu-------------------- */}
                    </>
                    : <>
                        <Login_header />
                    </>
            }
            <div className="divContentCenter d-flex justify-content-center">
                <div style={ChngpwdBox} className="Shadow">
                    <Card>
                        <Card.Header><h6>Change Password</h6></Card.Header>
                        <Card.Body>
                            <Form>
                                <Form.Group className="mb-3">
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control type="Password" maxLength="20" autoComplete='off' onChange={(e) => setNewPass(e.target.value.trim())} ></Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control type="Password" maxLength="20" autoComplete='off' onChange={(e) => setCnfNewPass(e.target.value.trim())} ></Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Old Password</Form.Label>
                                    <Form.Control type="Password" maxLength="20" autoComplete='off' onChange={(e) => setOldPass(e.target.value.trim())} ></Form.Control>
                                </Form.Group>
                                <Form.Group >
                                    <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={onClickChngPass}>Change Password</Button>&nbsp;
                                </Form.Group>
                                <div>
                                    <br></br>
                                    <ul style={{ listStyleType: 'none' }}>
                                        <li>Password can not be blank</li>
                                        <li>Minimum 8 characters</li>
                                        <li>Atleast 1 capital character</li>
                                        <li>Atleast 1 small character</li>
                                        <li>Atleast 1 numeric character</li>
                                    </ul>
                                </div>
                            </Form>
                        </Card.Body>

                    </Card>
                </div>
            </div>
            <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
            </div>
        </>
    );
}
export default Chngpwd;