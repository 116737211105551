import $ from "jquery";
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import * as Common from "../../Common";
import Dialog from '../../Dialog';
import LocationSelector from '../../LocationSelector';
import Login_header from '../../Login_header';
import Menu from '../../Menu';
import * as Picklist from '../../Picklist';

const Trial_balance = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const [userRight, setUserRight] = useState([]);

    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());

    const [typeOption, setTypeOption] = useState('1');
    const [selectOption, setSelectOption] = useState('0');

    const [counter, setCounter] = useState(0);

    const [locationCode, setLocationCode] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);
    const [radioType, setRadioType] = useState('A');

    const [checkItem, setCheckItem] = useState(false);

    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    const [groupValue, setGroupValue] = useState(null);
    const [groupCode, setGroupCode] = useState('');
    const [individualGroup, setIndividualGroup] = useState([]);
    const [showGroupDD, setShowGroupDD] = useState(false);

    const [ledgerValue, setLedgerValue] = useState(null);
    const [grpCode, setGrpCode] = useState('');
    const [accountCode, setAccountCode] = useState('');
    const [individualLedger, setIndividualLedger] = useState([]);
    const [showLedgerDD, setShowLedgerDD] = useState(false);
    const [trialType, settrialType] = useState("V");
    const [scr, setScr] = useState("");
    const [trialList, settrialList] = useState([]);


    const [onceRun, setOnceRun] = useState(false);

    var msg = [], i = 0;

    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.callApi(Common.apiRight, [sid, 'all', 'TRIAL_BALANCE'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);

    const handleDataFromChild = (newLocationCode, newSelectedItems, newRadioType) => {
        setLocationCode(newLocationCode);
        setSelectedItems(newSelectedItems);
        setRadioType(newRadioType);
    }

    const handleTypeChange = (e) => {
        const value = e.target.value;
        setTypeOption(value);
        setShowGroupDD(false);
        setShowLedgerDD(false);
        setSelectOption('0');
    }

    const handleSelectChange = (e) => {
        const value = e.target.value;
        setSelectOption(value);
        if (typeOption == '1' && value == '1') {
            setShowGroupDD(true);
            setShowLedgerDD(false);
        } else if (typeOption == '2' && value == '1') {
            setShowLedgerDD(true);
            setShowGroupDD(false);
        } else {
            setShowGroupDD(false);
            setShowLedgerDD(false);
        }
    }

    const handleGroupValue = (value) => {
        setGroupValue(value);
        console.log(value);

        var grp = value.id.split('^');
        setGroupCode(grp[1]);

        if (typeOption == '1' && selectOption == '1') {
            setIndividualGroup(grp[1]);
            setIndividualLedger([]);
            setLedgerValue('');

            // setCount(count+1);
        }
    }

    const handleLedgerValue = (value) => {
        setLedgerValue(value);
        console.log(value);

        var ledger = value.id.split('^');
        setGrpCode(ledger[1]);
        setAccountCode(ledger[2]);

        if (typeOption == '2' && selectOption == '1') {
            const obj = {
                groupCode: ledger[1],
                accountCode: ledger[2],
            }
            setIndividualGroup([]);
            setGroupValue('');
            setIndividualLedger(obj);
            // setCount(count+1);
        }
    }

    const checkBoxHandler = (e) => {
        let checked = e.target.checked;
        let value = e.target.value;
        setCheckItem(checked);
    }

    const handleGenerate = () => {

        $(".loader").show();
        if (fromDate == null || toDate == null || (radioType == 'O' && locationCode == '0') ||
            (radioType == 'S' && selectedItems == []) || (typeOption == '1' && selectOption == '1' && groupValue == null) ||
            (typeOption == '2' && selectOption == '1' && ledgerValue == null)) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = ((radioType == 'O' && locationCode == '0') ? "Please select location." : '');
            msg[i++] = ((radioType == 'S' && selectedItems == []) ? "Please select atleast one location." : '');
            msg[i++] = (fromDate == null ? "Please select from date." : '');
            msg[i++] = (toDate == null ? "Please select to date." : '');
            msg[i++] = ((typeOption == '1' && selectOption == '1' && groupValue == null) ? "Please select group." : '');
            msg[i++] = ((typeOption == '2' && selectOption == '1' && ledgerValue == null) ? "Please select ledger." : '');

            $(".loader").hide();

            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {

            const obj = {
                fromDate: Common.dateYMD(fromDate),
                toDate: Common.dateYMD(toDate),

                locationCode: locationCode,
                selectedItems: selectedItems,

                ledgerType: typeOption,
                option: selectOption,
                radioType: radioType,
                checkItem: checkItem,

                individualGroup: individualGroup,
                individualLedger: individualLedger
            }
            console.log(obj);
            if (trialType === 'D') {
                Common.callDownloadApiPost(Common.apiTrialBalance, "post", [sid, 'getData', JSON.stringify(obj)]);
            } else {
                Common.callApi(Common.apiTrialBalance, [sid, 'viewData', JSON.stringify(obj)], (result) => {
                    setScr('V');
                    const resData = JSON.parse(result);
                    settrialList(resData)
                    // console.log(scr);
                });
            }
            $(".loader").hide();
        }
    }

    return (
        <div>
            <Login_header />
            <Menu />

            <Container fluid>
                {scr === "" ?
                    <>
                        <Row>
                            <Col>
                                <h2>Trial Balance<span style={{ color: "#f8f9fd" }}>{counter}</span> </h2>
                            </Col>
                        </Row>&nbsp;

                        <Row>
                            <Col className='col-md-6 col-12'>
                                <LocationSelector onDataFromChild={handleDataFromChild} />
                            </Col>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Mode<span className="text-danger">*</span></Form.Label>
                                    <Form.Select value={trialType} onChange={e => settrialType(e.target.value)}
                                        required>
                                        <option value="V">View</option>
                                        <option value="D">Download</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>&nbsp;
                        <Row>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>From Date<span className="colorThemeRed">*</span></Form.Label><br />
                                    <DatePicker className="form-control"
                                        selected={fromDate}
                                        onChange={(date) => setFromDate(date)}
                                        dateFormat="dd/MM/yyyy"
                                        showYearDropdown
                                        showMonthDropdown
                                        useShortMonthInDropdown
                                        dropdownMode="select"
                                        peekNextMonth
                                        customInput={
                                            <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>To Date<span className="colorThemeRed">*</span></Form.Label><br />
                                    <DatePicker className="form-control"
                                        selected={toDate}
                                        onChange={(date) => setToDate(date)}
                                        dateFormat="dd/MM/yyyy"
                                        showYearDropdown
                                        showMonthDropdown
                                        useShortMonthInDropdown
                                        dropdownMode="select"
                                        peekNextMonth
                                        customInput={
                                            <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col className='col-md-3 col-6'>
                                <Form.Label>Type<span className="colorThemeRed">*</span></Form.Label><br />
                                <select class="form-select" aria-label="Default select example" value={typeOption}
                                    onChange={(e) => handleTypeChange(e)} >
                                    <option value="1">Group</option>
                                    <option value="2">Ledger</option>
                                </select>
                            </Col>
                        </Row>&nbsp;

                        <Row>
                            <Col className='col-md-3 col-6'>
                                <Form.Label>Group Filter<span className="colorThemeRed">*</span></Form.Label><br />
                                <select class="form-select" aria-label="Default select example" value={selectOption}
                                    onChange={(e) => handleSelectChange(e)} disabled={typeOption == '0'}>
                                    <option value="0" >All</option>
                                    <option value="1">One</option>
                                </select>
                            </Col>

                            {
                                showGroupDD &&
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Group<span className="colorThemeRed">*</span></Form.Label><br />
                                        <AsyncSelect
                                            cacheOptions
                                            defaultOptions
                                            value={groupValue}
                                            getOptionLabel={e => e.label + ' '}
                                            getOptionValue={e => e.id}
                                            loadOptions={(search) => Picklist.fetchGroupPicklist(search, 0)}
                                            onInputChange={Picklist.handleInputChange}
                                            onChange={handleGroupValue}
                                        >
                                        </AsyncSelect>
                                    </Form.Group>
                                </Col>
                            }
                            {
                                showLedgerDD &&
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Ledger<span className="colorThemeRed">*</span></Form.Label><br />
                                        <AsyncSelect
                                            cacheOptions
                                            defaultOptions
                                            value={ledgerValue}
                                            getOptionLabel={e => e.label + ' '}
                                            getOptionValue={e => e.id}
                                            loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 0, 1)}
                                            onInputChange={Picklist.handleInputChange}
                                            onChange={handleLedgerValue}
                                        >
                                        </AsyncSelect>
                                    </Form.Group>
                                </Col>

                            }
                        </Row>&nbsp;
                        <Row>
                            <Col>
                                <Form.Check
                                    label="Account with zero balance"
                                    type="checkbox"
                                    value={checkItem}
                                    onChange={(e) => checkBoxHandler(e)}
                                />
                            </Col>
                        </Row>&nbsp;
                        <Row>
                            <Col className='col-md-3 col-6'>
                                <Button variant='outline-success' className='buttonStyle' size='sm' onClick={handleGenerate}
                                >Generate</Button>
                            </Col>
                        </Row>
                    </> : null}
                {scr === "V" ? <>
                    <Row>
                        <Col>
                            <Button onClick={() => setScr('')} variant="primary">Back</Button>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    &nbsp;
                    <Table responsive striped bordered>
                        <thead>

                            <tr>
                                <th>Group</th>
                                <th>Group Name</th>
                                <th>Opn Dr</th>
                                <th>Opn Cr</th>
                                <th>Per Dr</th>
                                <th>Per Cr</th>
                                {/* <th>Cls Dr</th>
                                <th>Cls Cr</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                trialList.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.mvg_srno}</td>           {/* Group */}
                                            <td>{item.mvg_group_name}</td>     {/* Group Name */}
                                            <td>{item.total_opn}</td>          {/* Opn Dr */}
                                            <td>{item.total_opn}</td>          {/* Opn Cr */}
                                            <td>{item.Total_Amount}</td>       {/* Per Dr */}
                                            <td>{item.Total_Amount}</td>       {/* Per Cr */}
                                            {/* <td>{item.tcbb_out_srno}</td>       Out Srno */}
                                            {/* <td>{item.tcbb_out_type}</td>       Out Type */}
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </Table>

                </> : null}
            </Container>

            <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                {msgText}
            </Dialog>


            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
        </div>
    )
}

export default Trial_balance
