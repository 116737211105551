import React, { useEffect, useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import * as Common from './Common';


const FaView = (props) => {

    const sid = sessionStorage.getItem('sessionId');
    const [faViewData, setFaViewData] = useState([]);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (props.show) {
            Common.callApi(Common.apiFaViewLog, [sid, "getlog", JSON.stringify(props.faViewObject)], result => {
                let resp = JSON.parse(result);
                console.log(resp);
                setFaViewData(resp);
            });
        }
    }, [props.show]);

    return (
        <div>
            <Modal {...props} size='lg' centered dialogClassName="custom-modal-width">
                <Modal.Header closeButton>
                    <Modal.Title>FA View</Modal.Title>
                </Modal.Header>
                <Table responsive striped bordered>
                    <thead>
                        <tr>
                            <th>Location</th>
                            <th>Tran No</th>
                            <th>Account Name</th>
                            <th>Cr/Dr</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            faViewData.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.location}</td>
                                    <td>{item.tranno}</td>
                                    <td>{item.data}</td>
                                    <td>{item.tfd_flag}</td>
                                    <td>{item.tfd_amount > 0 ? item.tfd_amount : -1 * item.tfd_amount}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
                <Modal.Footer>
                    <Button variant="primary" onClick={props.onHide}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default FaView
