import $ from "jquery";
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import * as Common from "../../Common";
import Dialog from '../../Dialog';
import LocationSelector from '../../LocationSelector';
import Login_header from '../../Login_header';
import Menu from '../../Menu';
import * as Picklist from '../../Picklist';


const Cheques_in_hand = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const [userRight, setUserRight] = useState([]);

    const [date, setDate] = useState(new Date());
    const [chequeType, setChequeType] = useState('1');
    const [chequeNum, setChequeNum] = useState('');

    const [locationCode, setLocationCode] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);
    const [radioType, setRadioType] = useState('A');
    const [bankValue, setBankValue] = useState(null);
    const [group, setGroup] = useState('')
    const [account, setAccount] = useState('');
    const [accountName, setAccountName] = useState('');
    const [chequesType, setChequesType] = useState("V");
    const [scr, setScr] = useState("");
    const [chequeReportList, setChequeReportList] = useState([]);

    var msg = [], i = 0;

    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState('');

    const [onceRun, setOnceRun] = useState(false);

    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.callApi(Common.apiRight, [sid, 'all', 'CHEQUE_IN_HAND_REPORT'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);

    const handleTypeChange = (e) => {
        const value = e.target.value;
        setChequeType(value);
    }

    const handleBankValue = (value) => {
        setBankValue(value);

        const bank = value.id.split('^');
        setGroup(bank[1]);
        setAccount(bank[2]);
        setAccountName(bank[3]);
    }

    const handleDataFromChild = (newLocationCode, newSelectedItems, newRadioType) => {
        setLocationCode(newLocationCode);
        setSelectedItems(newSelectedItems);
        setRadioType(newRadioType);
    }

    const handleGenerate = () => {
        $(".loader").show();
        if (!date || (chequeType == '2' && chequeNum == '') || (radioType == 'O' && locationCode == '0')
            || (radioType == 'S' && selectedItems == []) || (bankValue == null)) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (!date ? "Please select  date." : '');
            msg[i++] = ((bankValue == null) ? "Please select bank." : '');
            msg[i++] = ((chequeType == '2' && chequeNum == '') ? "Please enter cheque number ." : '');
            msg[i++] = ((radioType == 'O' && locationCode == '0') ? "Please select location." : '');
            msg[i++] = ((radioType == 'S' && selectedItems == []) ? "Please select atleast one location." : '');
            $(".loader").hide();
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const obj = {
                date: Common.dateYMD(date),

                chequeType: chequeType,
                chequeNum: chequeNum,

                radioType: radioType,
                location: locationCode,
                selectedItems: selectedItems,

                group: group,
                account: account,
                accountName: accountName
            }
            if (chequesType === 'D') {
                Common.callDownloadApiPost(Common.apiChequeInHandReport, "post", [sid, 'getData', JSON.stringify(obj)]);
            } else {
                Common.callApi(Common.apiChequeInHandReport, [sid, 'viewData', JSON.stringify(obj)], (result) => {
                    setScr('V');
                    const resData = JSON.parse(result);
                    setChequeReportList(resData)
                    // console.log(scr);
                });
            }
            $(".loader").hide();
        }
    }

    return (
        <div>
            <Login_header />
            <Menu />
            <Container fluid>
                {scr === "" ?
                    <>
                        <Row>
                            <Col>
                                <h2>Cheques In Hand</h2>
                            </Col>
                        </Row>&nbsp;

                        <Row>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Bank<span className="colorThemeRed">*</span></Form.Label><br />
                                    <AsyncSelect
                                        cacheOptions
                                        defaultOptions
                                        value={bankValue}
                                        getOptionLabel={e => e.label + ' '}
                                        getOptionValue={e => e.id}
                                        loadOptions={(search) => Picklist.fetchBankPicklist(search, 1)}
                                        onInputChange={Picklist.handleInputChange}
                                        onChange={handleBankValue}
                                    >
                                    </AsyncSelect>
                                </Form.Group>
                            </Col>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Mode<span className="text-danger">*</span></Form.Label>
                                    <Form.Select value={chequesType} onChange={e => setChequesType(e.target.value)}
                                        required>
                                        <option value="V">View</option>
                                        <option value="D">Download</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>&nbsp;

                        <Row>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Date<span className="colorThemeRed">*</span></Form.Label><br />
                                    <DatePicker className="form-control"
                                        selected={date}
                                        onChange={(date) => setDate(date)}
                                        dateFormat="dd/MM/yyyy"
                                        showYearDropdown
                                        showMonthDropdown
                                        useShortMonthInDropdown
                                        dropdownMode="select"
                                        peekNextMonth
                                        customInput={
                                            <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>&nbsp;
                        <Row>
                            <Col className='col-md-3 col-6'>
                                <Form.Label>Cheque Filter</Form.Label><br />
                                <select class="form-select" aria-label="Default select example" value={chequeType}
                                    onChange={(e) => handleTypeChange(e)} >
                                    <option value="1">All</option>
                                    <option value="2">One</option>
                                </select>
                            </Col>

                            {
                                chequeType == '2' &&
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Cheque No.<span className="colorThemeRed">*</span></Form.Label>
                                        <Form.Control value={chequeNum} placeholder='Cheque No.'
                                            onChange={(e) => Common.validateAlpNumValue(e.target.value, setChequeNum)}
                                        />
                                    </Form.Group>
                                </Col>
                            }
                        </Row>&nbsp;

                        <Row>
                            <Form.Label>Location Filter</Form.Label><br />
                            <Col className='col-md-6 col-12'>
                                <LocationSelector onDataFromChild={handleDataFromChild} />
                            </Col>
                        </Row>&nbsp;

                        <Row>
                            <Col className='col-md-3 col-6'>
                                <Button variant='outline-success' className='buttonStyle' size='sm' onClick={handleGenerate}
                                >Generate</Button>
                            </Col>
                        </Row>
                    </> : null}
                {scr === "V" ? <>
                    <Row>
                        <Col>
                            <Button onClick={() => setScr('')} variant="primary">Back</Button>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    &nbsp;
                    <Table responsive striped bordered>
                        <thead>
                            <tr>
                                <th>Location</th>    {/* Branch */}
                                <th>Srno</th>        {/* Voucher */}
                                <th>Type</th>        {/* Type */}
                                <th>Date</th>        {/* Date */}
                                <th>Cheque No.</th>  {/* Cheque No. */}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                chequeReportList.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.tcbb_location}</td>     {/* Location */}
                                            <td>{item.tcbb_srno}</td>         {/* Srno */}
                                            <td>{item.tcbb_type}</td>         {/* Type */}
                                            <td>{item.formatted_date}</td>    {/* Date */}
                                            <td>{item.tcbb_from}</td>         {/* Cheque No. */}
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </Table>

                </> : null}
            </Container>

            <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                {msgText}
            </Dialog>

            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
        </div>
    )
}

export default Cheques_in_hand
