import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Table, Form, Button } from 'react-bootstrap'
import * as Common from "../../Common";
import Login_header from '../../Login_header';
import Menu from '../../Menu';
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import Dialog from '../../Dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import $ from "jquery";
import DialogYesNo from '../../Dialog_Yes_No';

function Master_voucher_group() {
    const sid = sessionStorage.getItem("sessionId");
    const navigate = useNavigate();
    const [onceRun, setOnceRun] = useState(false);
    const [userRight, setUserRight] = useState([]);

    const [visiblePage, setVisiblePage] = useState("");

    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    const [vgType, setVgType] = useState({ value: 0, label: "None" });
    const [vGroupData, setVGroupData] = useState([]);
    const [vgCategory, setVgCategory] = useState("G");
    const [vgStatus, setVgStatus] = useState("1");
    const [vgSrno, setVgSrno] = useState("");
    const [vgName, setVgName] = useState("");
    const [vgParentGrpCode, setVgParentGrpCode] = useState({ value: 0, label: "Select" });
    const [vgParentGrpList, setVgParentGrpList] = useState([]);
    const [vgTypeOptions, setVgTypeOptions] = useState([{ value: 0, label: "NONE" }
        , { value: "DEALER", label: "DEALER" }
        , { value: "CORPORATE", label: "CORPORATE" }
        , { value: "AGENT", label: "AGENT" }
        , { value: "BANK", label: "BANK" }
        , { value: "CASH", label: "CASH" }
    ]);

    const [vgRemark, setVgRemark] = useState("");

    const [disableCategory, setDisableCategory] = useState(false);
    const [srchParentGrp, setSrchParentGrp] = useState({ value: 0, label: "All" });

    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");

    const [debtor, setDebtor] = useState('1');
    const [creditor, setCreditor] = useState('1');

    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.callApi(Common.apiRight, [sid, 'all', 'VOUCHERGROUP'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                    Common.callApi(Common.apiVoucher, [sid, "parentgrpjson"], result => {
                        setVgParentGrpList(JSON.parse(result));
                    });
                    listData();
                } else {
                    navigate('/'); // no query right
                }
            });
            setOnceRun(true);
        }
    }, [onceRun]);

    const listData = () => {
        const obj = {
            srchParentGrp: srchParentGrp.value
        }
        Common.callApi(Common.apiVoucher, [sid, "listdata", JSON.stringify(obj)], result => {
            setVGroupData(JSON.parse(result));
        });
    }

    const addNewForm = () => {
        Common.callApi(Common.apiVoucher, [sid, "parentgrpjson"], result => {
            setVgParentGrpList(JSON.parse(result));
        });
        setVisiblePage("A");
        setVgCategory("G");
        setVgName("");
        setVgParentGrpCode('');
        setVgStatus("1");
        setVgType({ value: 0, label: "NONE" });
        setVgSrno(0);
    }


    const saveData = () => {
        var obj = {
            srno: vgSrno,
            vgName: vgName,
            vgCategory: vgCategory,
            vgParentGrpCode: vgParentGrpCode.value,
            vgStatus: vgStatus,
            vgType: vgType.value,
            branch: 0,
            activitytype: vgSrno * 1 > 0 ? 'MODIFY' : 'CREATE',
            trancategory: 'M',
            trantype: 'MVGRP',
            trannumber: vgSrno,
            subtranno: 0,
            debtor: debtor,
            creditor: creditor,
            remark: vgRemark
        }
        console.log(obj);
        var msg = [], i = 0;
        if (vgName === "" || vgParentGrpCode.value == "0") {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (vgName === "" ? " Please fill group name." : '');
            msg[i++] = (vgParentGrpCode.value == "0" ? " Please select parent." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (visiblePage === 'E' && vgRemark === '') {
            setMsgText('Please fill remark!');
            setMsgBox(true);
        } else {
            Common.callApi(Common.apiVoucher, [sid, "savevgroup", JSON.stringify(obj)], result => {
                setVGroupData(JSON.parse(result));
                setVisiblePage("");
                setVgRemark("");
                setCreditor("1");
                setDebtor("1");
            });
        }
    }

    const cancelConfirm = () => {
        if (visiblePage === "A" || visiblePage === "E") {
            setMsgTextYN('Do you really want to exit ?');
            setMsgBoxYN(true);
        } else {
            cancelData();
        }
    }

    const cancelData = () => {
        setMsgBoxYN(false);
        setVisiblePage("");

    }

    const fillEditForm = (srno) => {
        $(".loader").show();
        setVisiblePage("Q");
        Common.callApi(Common.apiVoucher, [sid, "parentgrpjson"], result => {
            setVgParentGrpList(JSON.parse(result));
        });
        Common.callApi(Common.apiVoucher, [sid, "details", srno], result => {
            let resp = JSON.parse(result);
            if (resp.msg === 1) {
                $(".loader").hide();
                setVgName(resp.groupname);
                setVgCategory(resp.category);
                setVgSrno(srno);
                setVgStatus(resp.status);
                setVgParentGrpCode({ value: resp.parentgroup, label: resp.parentname });
                setVgType({ value: resp.type, label: resp.type });
                if (resp.parentexist) {
                    setDisableCategory(true);
                } else {
                    setDisableCategory(false);
                }
            }
        });
    }

    const editData = () => {
        setVisiblePage('E');
    }

    const exportFile = () => {
        var obj = {
            right: 'VOUCHERGROUP',
            srchParentGrp: srchParentGrp.value,
        }
        Common.callDownloadApiPost(Common.apiReport, "post", [sid, JSON.stringify(obj)]);
    }

    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h3>Voucher Group</h3>
                    </Col>
                </Row>
                {
                    visiblePage === "" ?
                        <>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Parent Group</Form.Label>
                                        <Select options={vgParentGrpList} value={srchParentGrp} onChange={v => setSrchParentGrp(v)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    {
                                        userRight.add === "1" ?
                                            <Button className='buttonStyle' size='sm' variant='outline-primary' onClick={() => addNewForm()}>Add New</Button>
                                            : null
                                    }
                                    &nbsp;
                                    <Button className='buttonStyle' size='sm' variant='outline-success' onClick={() => listData()}>List</Button> &nbsp;
                                    <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={() => exportFile()}>Export</Button>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    <Table responsive striped>
                                        <thead>
                                            <tr>
                                                <th>&nbsp;</th>
                                                <th>SrNo</th>
                                                <th>Group</th>
                                                <th>Parent Group</th>
                                                <th>Category</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                vGroupData.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {
                                                                userRight.edit === "1" ?
                                                                    <span className='colorThemeBlue' onClick={() => fillEditForm(item.mvg_srno)}>
                                                                        <FontAwesomeIcon icon={faEye} />
                                                                    </span> : null
                                                            }
                                                        </td>
                                                        <td>{item.mvg_srno}</td>
                                                        <td>{item.mvg_group_name}</td>
                                                        <td>{item.mvg_parent_group}</td>
                                                        <td>{item.mvg_category === "G" ? "Group" : "Ledger"}</td>
                                                        <td>{item.mvg_status == 1 ? "Active" : "Inactive"}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </>
                        :
                        <>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Srno</Form.Label>
                                        <Form.Control disabled={true} value={vgSrno} onChange={e => setVgSrno(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Group Name<span className="mandatory text-danger">*</span></Form.Label>
                                        <Form.Control type='text' maxLength={80} value={vgName}
                                            onChange={(e) => Common.validateAlpNumValue(e.target.value, setVgName)}
                                            disabled={visiblePage === 'Q'} />
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Parent Group<span className="mandatory text-danger">*</span></Form.Label>
                                        <Select options={vgParentGrpList} isDisabled={visiblePage === "E" || visiblePage === "Q"} value={vgParentGrpCode} onChange={v => setVgParentGrpCode(v)} />
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Category</Form.Label>
                                        <Form.Select value={vgCategory}
                                            // disabled={disableCategory} 
                                            disabled={visiblePage === 'Q'}
                                            onChange={e => setVgCategory(e.target.value)}>
                                            <option value="G">Group</option>
                                            <option value="L">Ledger</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Type</Form.Label>
                                        <Select options={vgTypeOptions} value={vgType} onChange={v => setVgType(v)}
                                            isDisabled={visiblePage === 'Q'} />
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Status</Form.Label>
                                        <Form.Select value={vgStatus} onChange={e => setVgStatus(e.target.value)}
                                            disabled={visiblePage === 'Q'}>
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Label>Debtor</Form.Label><br />
                                    <select class="form-select" aria-label="Default select example" value={debtor}
                                        onChange={(e) => setDebtor(e.target.value)} disabled={visiblePage === 'Q'} >
                                        <option value="1">Yes</option>
                                        <option value="0" >No</option>
                                    </select>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Label>Creditor</Form.Label><br />
                                    <select class="form-select" aria-label="Default select example" value={creditor}
                                        onChange={(e) => setCreditor(e.target.value)} disabled={visiblePage === 'Q'}>
                                        <option value="1">Yes</option>
                                        <option value="0" >No</option>
                                    </select>
                                </Col>
                            </Row>
                            {
                                visiblePage === 'E' ?
                                    <>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Remark<span className="mandatory text-danger">*</span></Form.Label>
                                                    <Form.Control type="text" placeholder="remark" maxLength={50}
                                                        value={vgRemark}
                                                        onChange={(e) => Common.validateAlpValue(e.target.value, setVgRemark)}
                                                        autoComplete="off"
                                                        required />
                                                </Form.Group>
                                            </Col>
                                            <Col>&nbsp;</Col>
                                            <Col>&nbsp;</Col>
                                            <Col>&nbsp;</Col>
                                        </Row>
                                    </> :
                                    null
                            }
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    {/* <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => saveData()}>Save</Button>
                                    &nbsp;
                                    <Button variant='outline-danger' className='buttonStyle' size='sm' onClick={() => setVisiblePage("")}>Cancel</Button> */}

                                    <Button variant="outline-danger" className='buttonStyle' size="sm" onClick={() =>
                                        cancelConfirm()}>Back</Button>&nbsp;&nbsp;

                                    {
                                        (userRight.edit === "1" && visiblePage === 'Q') ? <>
                                            <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                editData()}>Edit</Button>&nbsp;
                                        </> : null
                                    }
                                    {
                                        (userRight.edit === "1" && visiblePage === 'E') || (userRight.add === "1" && visiblePage === 'A') ? <>
                                            <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                saveData()}>Save</Button>&nbsp;
                                        </> : null
                                    }
                                </Col>
                            </Row>
                        </>
                }
            </Container>
            {/* <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
            </div> */}

            <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
                <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
                    {msgTextYN}
                </DialogYesNo>
            </div>
        </>
    )
}

export default Master_voucher_group
