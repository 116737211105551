import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Form, Button, Table, Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Menu from '../Menu';
import Login_header from '../Login_header';
import * as Common from '../Common';
import * as Picklist from '../Picklist';
import AsyncSelect from 'react-select/async';
import Dialog from '../Dialog';
import DialogYesNo from '../Dialog_Yes_No';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';

const Master_rate = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const startDate = sessionStorage.getItem('startDate');
    const endDate = sessionStorage.getItem('endDate');

    const [userRight, setUserRight] = useState([]);
    const [onceRun, setOnceRun] = useState(false);
    const [scrMode, setScrMode] = useState("");
    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");
    const [isErrBox, setErrBox] = useState(false);
    const [errText, setErrText] = useState("");

    const [srNo, setSrNo] = useState(0);
    const [radioType, setRadioType] = useState("L");
    const [headerData, setHeaderData] = useState([]);
    const [filterLocation, setFilterLocation] = useState(null);
    const [filterLocationCode, setFilterLocationCode] = useState(0);
    const [filterCurrency, setFilterCurrency] = useState(null);
    const [filterProduct, setFilterProduct] = useState("A");
    const [filterType, setFilterType] = useState('G');
    const [currencyCode, setCurrencyCode] = useState('');
    const [mRateLocation, setMRateLocation] = useState(null);
    const [locationCode, setLocationCode] = useState(0);
    const [mRateCurrency, setMRateCurrency] = useState(null);
    const [mRateProduct, setMRateProduct] = useState("A");
    const [mRateUnit, setMRateUnit] = useState('');
    const [mRateRate, setMRateRate] = useState(0);
    const [mRateMarginType, setMRateMarginType] = useState('F');
    const [mRateBuyMargin, setMRateBuyMargin] = useState('');
    const [mRateSellMargin, setMRateSellMargin] = useState('');
    const [mRateMaxBuyMargin, setMRateMaxBuyMargin] = useState('');
    const [mRateMaxSellMargin, setMRateMaxSellMargin] = useState('');
    const [mRateMinBuyMargin, setMRateMinBuyMargin] = useState('');
    const [mRateMinSellMargin, setMRateMinSellMargin] = useState('');
    const [type, setType] = useState('L');
    const [createrName, setCreaterName] = useState('');
    const [createdDateTime, setCreatedDateTime] = useState('');

    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.callApi(Common.apiRight, [sid, 'all', 'MARGIN'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);


    const handleChangeLocation = (value) => {
        setMRateLocation(value);
        const loc = value.id.split('^');
        setLocationCode(loc[1]);
    }

    const handleChangeFilterLocation = (value) => {
        setFilterLocation(value);
        const loc = value.id.split('^');
        setFilterLocationCode(loc[1]);
    }


    const handleCurrencyChange = (value) => {
        console.log(value);
        setMRateCurrency(value);
        const currency = value.id.split('^');
        setCurrencyCode(currency[1]); // set currency code
    }


    const handleFilterCurrencyChange = (value) => {
        console.log(value);
        setFilterCurrency(value);
        const currency = value.id.split('^');
        setCurrencyCode(currency[1]); // set currency code
    }

    const handleCurrencyBadge = () => {
        setFilterCurrency(null);
        setCurrencyCode("");
    }

    const handleLocationBadge = () => {
        setFilterLocation(null);
        setFilterLocationCode(0);
    }


    const addNew = () => {
        setScrMode('A');
        setMRateLocation(null);
        setMRateCurrency(null);
        setMRateUnit("");
        setMRateBuyMargin("");
        setMRateSellMargin("");
        setMRateMaxBuyMargin("");
        setMRateMaxSellMargin("");
        setMRateMinBuyMargin("");
        setMRateMinSellMargin("");
        setSrNo(0);
        setType('L');

    }

    // Fetching Data from Database--------------------------------------------------------------------------------------
    const list = () => {
        if (filterType === 'L' && filterLocation === null) {
            setMsgText("Please Select Location!");
            setMsgBox(true);
        } else if (filterType === 'L' && radioType === 'C' && filterCurrency === null) {
            setMsgText("Please Select Currecy!");
            setMsgBox(true);
        } else if (filterType === 'G' && radioType === 'C' && filterCurrency === null) {
            setMsgText("Please Select Currency!");
            setMsgBox(true);
        } else {
            const obj = {
                filterType: filterType,
                filterRadioType: radioType,
                filterCurrencyCode: currencyCode,
                filterProductType: filterProduct,
                filterLocation: filterLocationCode,
            }
            Common.callApi(Common.apiMargin, [sid, 'list', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                setHeaderData(resp);
            });
        }
    }

    // Save Data in database-----------------------------------------------------------------------------------------
    const saveData = () => {
        var msg = [], i = 0;
        if (mRateLocation === null || mRateCurrency === null || mRateUnit === "" || mRateBuyMargin === "" || mRateSellMargin === "" || mRateMaxBuyMargin === "" ||
            mRateMaxSellMargin === "" || mRateMinSellMargin === "" || mRateMinBuyMargin === "") {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (mRateLocation === null ? " Select Location!" : "");
            msg[i++] = (mRateCurrency === null ? " Select Currency!" : "");
            msg[i++] = (mRateUnit === "" ? " Unit." : "");
            msg[i++] = (mRateBuyMargin === "" ? " Buy Margin." : "");
            msg[i++] = (mRateSellMargin === "" ? " Sell Margin." : "");
            msg[i++] = (mRateMaxBuyMargin === "" ? " Max Buy Margin." : "");
            msg[i++] = (mRateMaxSellMargin === "" ? " Max Sell Margin." : "");
            msg[i++] = (mRateMinBuyMargin === "" ? "Min Buy Margin." : "");
            msg[i++] = (mRateMinSellMargin === "" ? " Min Sell Margin." : "");
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const obj = {
                filterType: filterType,
                filterRadioType: radioType,
                filterCurrencyCode: currencyCode,
                filterProductType: filterProduct,
                filterLocation: filterLocationCode,

                srno: srNo,
                location: locationCode,
                isd: currencyCode,
                unit: mRateUnit,
                rate: mRateRate,
                product: mRateProduct,
                marginType: mRateMarginType,
                buyMargin: mRateBuyMargin,
                sellMargin: mRateSellMargin,
                maxBuyMargin: mRateMaxBuyMargin,
                maxSellMargin: mRateMaxSellMargin,
                minBuyMargin: mRateMinBuyMargin,
                minSellMargin: mRateMinSellMargin,
            }
            Common.callApi(Common.apiMargin, [sid, 'save', srNo, JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                if (resp.margin) {
                    setHeaderData(resp.margin);
                    setScrMode('');
                }
            });
        }
    }

    // Edit Data ---------------------------------------------------------------------------------------------------
    const viewList = (srno, isd) => {
        setScrMode('Q');
        setCurrencyCode(isd);
        Common.callApi(Common.apiMargin, [sid, 'view', srno], (result) => {
            let resp = JSON.parse(result);
            setSrNo(resp.srno);
            setMRateLocation({ id: resp.locationCode, label: resp.location });
            setMRateCurrency({ id: resp.isdCode, label: resp.isd })
            setMRateUnit(resp.unit);
            setMRateProduct(resp.product);
            setMRateMarginType(resp.marginType);
            setMRateBuyMargin(resp.buyMargin);
            setMRateSellMargin(resp.sellMargin);
            setMRateMaxBuyMargin(resp.maxBuyMargin);
            setMRateMaxSellMargin(resp.maxSellMargin);
            setMRateMinBuyMargin(resp.minBuyMargin);
            setMRateMinSellMargin(resp.minSellMargin);
            // console.log(resp.userName)
            // console.log(resp.dateTime)

            setCreatedDateTime(resp.userName);
            setCreaterName(resp.dateTime);
            setLocationCode(resp.location.split(" ")[0]);
        });
    }


    const cancelConfirm = () => {
        if (scrMode === "A" || scrMode === "E") {
            setMsgTextYN('Do you really want to exit ?');
            setMsgBoxYN(true);
        } else {
            cancelData();
        }
    }


    const cancelData = () => {
        setMsgBoxYN(false);
        setScrMode("");
    }


    const editData = () => {
        setScrMode("E");
    }

    function typeHandler(e) {
        setType(e);
        if (e === 'G') {
            setLocationCode(0);
            setMRateLocation({ id: '^0^Zenith Leisure Holidays Ltd.^0^^^^^', label: '0 Zenith Leisure Holidays Ltd.' });
        } else {
            setMRateLocation({ id: '', label: 'Select' });
        }
    }


    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h3>Master Margin</h3>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                {
                    scrMode === "" ?
                        <>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Type<span className="text-danger">*</span></Form.Label>
                                        <Form.Select value={filterType} onChange={e => setFilterType(e.target.value)}
                                            required>
                                            <option value="G">Global</option>
                                            <option value="L">Location</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                {
                                    filterType === 'L' ?
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Location<span className="text-danger">*</span>
                                                    <span className="mx-2" ><Badge bg="secondary" onClick={() => handleLocationBadge()}>Reset</Badge></span>
                                                </Form.Label>
                                                <AsyncSelect
                                                    cacheOptions={false}
                                                    defaultOptions={false}
                                                    value={filterLocation}
                                                    getOptionLabel={e => e.label + ' '}
                                                    getOptionValue={e => e.id}
                                                    loadOptions={Picklist.fetchLocationAllowedPicklist}
                                                    onInputChange={Picklist.handleInputChange}
                                                    onChange={handleChangeFilterLocation}
                                                >
                                                </AsyncSelect>
                                            </Form.Group>
                                        </Col> : null
                                }
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Row style={{ marginTop: "30px" }}>
                                            <Col>
                                                <Form.Check checked={radioType === "C"} onChange={() => setRadioType("C")} type='radio' label="Currency" />
                                            </Col><Col>
                                                <Form.Check checked={radioType === "P"} onChange={() => setRadioType("P")} type='radio' label="Product" />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                {
                                    radioType === 'C' ?
                                        <>
                                            <Row>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Currency<span className="text-danger">*</span>
                                                            <span className="mx-2" ><Badge bg="secondary" onClick={() => handleCurrencyBadge()}>Reset</Badge></span>
                                                        </Form.Label>
                                                        <AsyncSelect
                                                            cacheOptions={false}
                                                            defaultOptions={false}
                                                            value={filterCurrency}
                                                            getOptionLabel={e => e.label + ' '}
                                                            getOptionValue={e => e.id}
                                                            loadOptions={(search) => Picklist.fetchCurrencyAllowedPicklist(search, 'CN')}
                                                            onInputChange={Picklist.handleInputChange}
                                                            onChange={handleFilterCurrencyChange}
                                                        >
                                                        </AsyncSelect>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </>
                                        : radioType === 'P' ?
                                            <Row>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Product<span className="text-danger">*</span></Form.Label>
                                                        <Form.Select value={filterProduct} onChange={e => setFilterProduct(e.target.value)}
                                                            required>
                                                            <option value="A">All</option>
                                                            <option value="C">CN</option>
                                                            <option value="R">Card</option>
                                                            <option value="T">TT</option>
                                                            <option value="D">DD</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            : null
                                }
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => list()}>List</Button>
                                    &nbsp;
                                    <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addNew()}>Add New</Button>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                {userRight.edit === "1" ? <th></th> : null}
                                                <th>ISD</th>
                                                <th>Location</th>
                                                <th>Unit</th>
                                                <th>Product</th>
                                                <th>Margin Type</th>
                                                <th>Buy Margin</th>
                                                <th>Sell Margin</th>
                                                <th>Max Buy Margin</th>
                                                <th>Max Sell Margin</th>
                                                <th>Min Buy Margin</th>
                                                <th>Min Sell Margin</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                headerData.map((res) => (
                                                    <tr key={res.mrt_srno}>
                                                        {userRight.edit === "1" ?
                                                            <td className="textCenter">
                                                                <span className="handCursor colorThemeBlue" onClick={() => viewList(res.mrt_srno, res.mrt_isd)}>
                                                                    <FontAwesomeIcon icon={faEye} />
                                                                </span>
                                                            </td>
                                                            : null
                                                        }
                                                        <td>{res.mrt_isd_name}</td>
                                                        <td>{res.mrt_location}</td>
                                                        <td>{res.mrt_unit}</td>
                                                        <td>
                                                            {
                                                                res.mrt_product === 'C'
                                                                    ? "CN"
                                                                    : res.mrt_product === 'D'
                                                                        ? "DD"
                                                                        : res.mrt_product === 'T'
                                                                            ? "TT"
                                                                            : "Card"
                                                            }
                                                        </td>

                                                        <td>{res.mrt_margin_type === 'F' ? "Fixed" : "Percentage"}</td>
                                                        <td>{res.mrt_buy_margin}</td>
                                                        <td>{res.mrt_sell_margin}</td>
                                                        <td>{res.mrt_max_buy_margin}</td>
                                                        <td>{res.mrt_max_sell_margin}</td>
                                                        <td>{res.mrt_min_buy_margin}</td>
                                                        <td>{res.mrt_min_sell_margin}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </>
                        :
                        <>
                            <Row>
                                <Col>
                                    {
                                        (userRight.edit === "1" && scrMode === "Q") ? <>
                                            <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() => editData()}>Edit</Button>
                                        </> : null
                                    }
                                    {
                                        (userRight.edit === "1" && scrMode === "E") || (userRight.add === "1" && scrMode === "A") ? <>
                                            <Button variant='outline-primary' size='sm' className='buttonStyle' onClick={() => saveData()}>Save</Button>
                                        </> : null
                                    }
                                    <Button variant='outline-danger' className='buttonStyle' size='sm' onClick={() => cancelConfirm()}>Back</Button>{' '}
                                    {/* <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={exportFile}>Export</Button> */}
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            {
                                scrMode === 'E' || scrMode === 'A' || scrMode === 'Q' ?
                                    <>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Type<span className="text-danger">*</span></Form.Label>
                                                    <Form.Select value={type} onChange={e => typeHandler(e.target.value)}
                                                        required disabled={scrMode === 'E' || scrMode === 'Q'}>
                                                        <option value="G">Global</option>
                                                        <option value="L">Location</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                {(scrMode === 'Q' || scrMode === 'E') && <Form.Group>
                                                    <Form.Label>UserName<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control value={createrName} type='text' disabled={true} />
                                                </Form.Group>}
                                            </Col>
                                            <Col>
                                                {(scrMode === 'Q' || scrMode === 'E') && <Form.Group>
                                                    <Form.Label>Date & Time<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control value={createdDateTime} type='text' disabled={true} />
                                                </Form.Group>}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='col-md-4 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Location<span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <AsyncSelect
                                                        cacheOptions={false}
                                                        defaultOptions={false}
                                                        value={mRateLocation}
                                                        getOptionLabel={e => e.label + ' '}
                                                        getOptionValue={e => e.id}
                                                        loadOptions={Picklist.fetchLocationAllowedPicklist}
                                                        onInputChange={Picklist.handleInputChange}
                                                        onChange={handleChangeLocation}
                                                        isDisabled={type === 'G' || scrMode === 'E' || scrMode === 'Q'}

                                                    >
                                                    </AsyncSelect>
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-4 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Currency<span className="text-danger">*</span>
                                                    </Form.Label>
                                                    <AsyncSelect
                                                        cacheOptions={false}
                                                        defaultOptions={false}
                                                        value={mRateCurrency}
                                                        getOptionLabel={e => e.label + ' '}
                                                        getOptionValue={e => e.id}
                                                        loadOptions={(search) => Picklist.fetchCurrencyAllowedPicklist(search, 'CN')} // location = 1 , 2
                                                        onInputChange={Picklist.handleInputChange}
                                                        onChange={handleCurrencyChange}
                                                        isDisabled={scrMode === 'E' || scrMode === 'Q'}
                                                    >
                                                    </AsyncSelect>
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-4 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Unit<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control
                                                        value={mRateUnit}
                                                        onChange={e => Common.validateNumValue(e.target.value, setMRateUnit)}
                                                        maxLength={5} disabled={scrMode === 'E' || scrMode === 'Q'}
                                                        placeholder="Unit" />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-6 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Product<span className="text-danger">*</span></Form.Label>
                                                    <Form.Select value={mRateProduct} onChange={e => setMRateProduct(e.target.value)}
                                                        required disabled={scrMode === 'E' || scrMode === 'Q'} >
                                                        <option value="A">All</option>
                                                        <option value="C">CN</option>
                                                        <option value="R">Card</option>
                                                        <option value="T">TT</option>
                                                        <option value="D">DD</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-6 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Margin Type<span className="text-danger">*</span></Form.Label>
                                                    <Form.Select value={mRateMarginType} onChange={e => setMRateMarginType(e.target.value)}
                                                        disabled={scrMode === 'Q'} required>
                                                        <option value="F">Fixed</option>
                                                        <option value="P">Percentage</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='col-md-6 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Buy Margin<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control
                                                        value={mRateBuyMargin}
                                                        onChange={(e) => Common.validateDecValue(e.target.value, setMRateBuyMargin)}
                                                        maxLength={20}
                                                        placeholder="Buy Margin"
                                                        disabled={scrMode === 'Q'} />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-6 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Sell Margin<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control
                                                        value={mRateSellMargin}
                                                        onChange={(e) => Common.validateDecValue(e.target.value, setMRateSellMargin)}
                                                        maxLength={20}
                                                        placeholder="Sell Margin"
                                                        disabled={scrMode === 'Q'} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='col-md-6 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Max Buy Margin<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control
                                                        value={mRateMaxBuyMargin}
                                                        onChange={(e) => Common.validateDecValue(e.target.value, setMRateMaxBuyMargin)}
                                                        maxLength={20}
                                                        placeholder="Max Buy Margin"
                                                        disabled={scrMode === 'Q'} />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-6 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Max Sell Margin<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control
                                                        value={mRateMaxSellMargin}
                                                        onChange={(e) => Common.validateDecValue(e.target.value, setMRateMaxSellMargin)}
                                                        maxLength={20}
                                                        placeholder="Max Sell Margin"
                                                        disabled={scrMode === 'Q'} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='col-md-6 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Min Buy Margin<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control
                                                        value={mRateMinBuyMargin}
                                                        onChange={(e) => Common.validateDecValue(e.target.value, setMRateMinBuyMargin)}
                                                        maxLength={20}
                                                        placeholder="Min Buy Margin"
                                                        disabled={scrMode === 'Q'} />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-6 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Min Sell Margin<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control
                                                        value={mRateMinSellMargin}
                                                        onChange={(e) => Common.validateDecValue(e.target.value, setMRateMinSellMargin)}
                                                        maxLength={20}
                                                        placeholder="Min Sell Margin"
                                                        disabled={scrMode === 'Q'} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </> :
                                    null
                            }
                            <Row>&nbsp;</Row>
                        </>
                }
            </Container>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
                <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
                    {msgTextYN}
                </DialogYesNo>
                <Dialog isOpen={isErrBox} onClose={(e) => setErrBox(false)}>
                    {errText}
                </Dialog>
            </div>
        </>
    )
}

export default Master_rate;
