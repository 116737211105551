import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from "jquery";
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import Dialog from '../Dialog';
import Login_header from '../Login_header';
import Menu from '../Menu';
import * as Common from "./../Common";
import * as Picklist from './../Picklist';

const Fx_document = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const branchDisable = sessionStorage.getItem('branchDisable');

    const [userRight, setUserRight] = useState([]);
    const [onceRun, setOnceRun] = useState(false);
    const [fxDocData, setFxDocData] = useState([]);
    const [docLocation, setDocLocation] = useState(null);
    const [locationCode, setLocationCode] = useState(0);
    const [filterdocLocation, setFilterDocLocation] = useState(null);
    const [filterLocationCode, setFilterLocationCode] = useState(0);
    const [filterDocRemark, setFilterDocRemark] = useState('');
    const [docFile, setDocFile] = useState("");
    const [docSrno, setDocSrno] = useState('');
    const [docFromSrno, setDocFromSrno] = useState('');
    const [docToSrno, setDocToSrno] = useState('');
    const [docType, setDocType] = useState('PP');
    const [filterDocType, setFilterDocType] = useState('PP');
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");
    const [isUploadClicked, setIsUploadClicked] = useState(false);
    const [docGrpType, setDocGrpType] = useState({ value: 0, label: "Select" });
    const [docGrpList, setDocGrpList] = useState([]);
    const [docRemark1, setDocRemark1] = useState('');
    const [docRemark2, setDocRemark2] = useState('');
    const [fileName, setFileName] = useState('');
    const [fileExt, setFileExt] = useState('');

    const [documentToView, setDocumentToView] = useState("");
    const [documentTypeToView, setDocumentTypeToView] = useState("");
    const [documentDescToView, setDocumentDescToView] = useState("");
    const [documentNameToView, setDocumentNameToView] = useState("");
    const [showDocument, setShowDocument] = useState(false);

    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.getActiveBranch().then(response => {
                setFilterDocLocation(response);
                response != null && handleChangefilterLocation(response);
            });
            Common.callApi(Common.apiRight, [sid, 'all', 'FX_DOCUMENT_UPLOAD'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
                const obj = {
                    pageright: "FX_DOCUMENT_UPLOAD"
                }
                Common.callApi(Common.apiDocument, [sid, "getdocs", JSON.stringify(obj)], result => {
                    setDocGrpList(JSON.parse(result));
                });
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);


    const handleChangefilterLocation = (value) => {
        setFilterDocLocation(value);
        const loc = value.id.split('^');
        setFilterLocationCode(loc[1]);
        sessionStorage.activeBranch = loc[1];
    }


    const handleChangeLocation = (value) => {
        setDocLocation(value);
        const loc = value.id.split('^');
        setLocationCode(loc[1]);     
    }

    const list = () => {
        var msg = [], i = 0;
        if (filterdocLocation === null && filterDocRemark) {
            setMsgText("Please Select Location!");
            setMsgBox(true);
        } else if ((!filterDocRemark && filterdocLocation === null) || (!filterDocRemark && !docFromSrno) || (!filterDocRemark && !docToSrno)) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = ((filterDocRemark === "" && filterdocLocation === null) ? " Please Select Location!" : '');
            msg[i++] = ((!filterDocRemark && !docFromSrno) ? " From Srno." : '');
            msg[i++] = ((!filterDocRemark && !docToSrno) ? " To Srno." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const obj = {
                location: filterLocationCode,
                filtertype: filterDocType,
                filterFromSrno: docFromSrno,
                filterToSrno: docToSrno,
                filterRemark: filterDocRemark,
            }
            Common.callApi(Common.apiFxDocumentUpload, [sid, 'list', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                setFxDocData(resp);
            });
        }
    }

    const handleUpload = () => {
        setIsUploadClicked(true);
    }

    const handleSubmit = () => {
        // console.log(docGrpType);
        var msg = [], i = 0;
        console.log(document.getElementById('importExcel').files[0]);
        // console.log(document.getElementById('importExcel').files[0].name);
        $('.loader').show();
        if (document.getElementById('importExcel').files[0] === undefined || docGrpType.value == 0 || docLocation === null || docSrno === "" || docRemark1 === "" || docRemark2 === "") {
            $('.loader').hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (docLocation === null ? "Please Select Location!" : "");
            msg[i++] = (docSrno === "" ? "Serial No." : "");
            msg[i++] = (docGrpType.value == 0 ? " Please Select Document." : "");
            msg[i++] = (document.getElementById('importExcel').files[0] === undefined ? " Please select a file to upload." : "");
            msg[i++] = (docRemark1 === "" ? "Remark 1" : "");
            msg[i++] = (docRemark2 === "" ? "Remark 2" : "");
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const filename = document.getElementById('importExcel').files[0].name;
            const file = filename.split('.');
            // setFileName(file[0]);
            // setFileExt(file[1]);
            const obj = {
                uploadType: "uploadFxDoc",
                pageright: "FX_DOCUMENT_UPLOAD",
                sid: sid,
                srno: docSrno,
                location: locationCode,
                fileName: file[0],
                fileExt: file[1],
                docType: docType,
                docGrpType: docGrpType.value,
                docGrpName: docGrpType.label,
                remark1: docRemark1,
                remark2: docRemark2,
            }
            Common.uploadApi(JSON.stringify(obj), 'importExcel', (result) => {
                let resp = JSON.parse(result);
                console.log(resp);
                $('.loader').hide();
                setMsgText(resp.msg);
                setMsgBox(true);
                setFxDocData(resp.docdetail);
                setDocFile('');
                setDocLocation(null);
                setDocSrno('');
                setDocGrpType({ value: 0, label: "Select" });
                setDocRemark1('');
                setDocRemark2('');
            });
        }
    }

    const btnDocumentView = (srno, filename) => {
        const obj = {
            srno: srno,
            filename: filename,
        }
        Common.callDocumentViewApi(Common.apiFxDocumentUpload, [sid, 'docView', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);
            setDocumentToView(resp.bs64);
            setDocumentTypeToView(resp.typ);
            setDocumentDescToView(resp.desc);
            setDocumentNameToView(resp.fname);
            setShowDocument(true);
        });
    }

    const btnDocumentDownload = (srno, filename) => {
        const obj = {
            srno: srno,
            filename: filename,
        }
        Common.callDownloadApiPost(Common.apiFxDocumentUpload, "post", [sid, 'docDownload', JSON.stringify(obj)]);
    }

    const handleBack = () => {
        setIsUploadClicked(false);
    }


    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h3>Fx Document Upload</h3>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                {
                    isUploadClicked &&
                    <>
                        <Row>
                            <Col>
                                <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => handleBack()}>Back</Button>
                            </Col>
                        </Row>
                        <Row>&nbsp;</Row>
                    </>
                }
                {/* <Row>&nbsp;</Row> */}
                {
                    userRight.add === '1' && isUploadClicked ?
                        <>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Location<span className="text-danger">*</span></Form.Label>
                                        <AsyncSelect
                                            cacheOptions={false}
                                            defaultOptions={false}
                                            value={docLocation}
                                            getOptionLabel={e => e.label + ' '}
                                            getOptionValue={e => e.id}
                                            loadOptions={Picklist.fetchLocationAllowedPicklist}
                                            onInputChange={Picklist.handleInputChange}
                                            onChange={handleChangeLocation}
                                        >
                                        </AsyncSelect>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Serial No.<span className="text-danger">*</span></Form.Label>
                                        <Form.Control value={docSrno}
                                            maxLength={20}
                                            onChange={(e) => Common.validateNumValue(e.target.value, setDocSrno)}
                                            placeholder="Serial No." />
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Type<span className="text-danger">*</span></Form.Label>
                                        <Form.Select value={docType} onChange={e => setDocType(e.target.value)}
                                            required>
                                            <option value="PP">PP (Purchase from Public)</option>
                                            <option value="PB">PB (Purchase from Bulk)</option>
                                            <option value="SP">SP (Sale to Public)</option>
                                            <option value="SB">SB (Sale to Bulk)</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Document<span className="mandatory text-danger">*</span></Form.Label>
                                        <Select
                                            options={docGrpList} value={docGrpType} onChange={v => setDocGrpType(v)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group controlId="importExcel">
                                        <Form.Label>Choose File<span className="text-danger">*</span></Form.Label>
                                        <Form.Control type="file" value={docFile} onChange={(e) => setDocFile(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Remark 1<span className="text-danger">*</span></Form.Label>
                                        <Form.Control value={docRemark1}
                                            maxLength={100}
                                            onChange={(e) => Common.validateAlpNumValue(e.target.value, setDocRemark1)}
                                            placeholder="Remark 1" />
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Remark 2<span className="text-danger">*</span></Form.Label>
                                        <Form.Control value={docRemark2}
                                            maxLength={100}
                                            onChange={(e) => Common.validateAlpNumValue(e.target.value, setDocRemark2)}
                                            placeholder="Remark 2" />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </> : null
                }
                {
                    userRight.query === '1' && !isUploadClicked ?
                        <>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Location<span className="text-danger">*</span></Form.Label>
                                        <AsyncSelect
                                            cacheOptions={false}
                                            defaultOptions={false}
                                            value={filterdocLocation}
                                            getOptionLabel={e => e.label + ' '}
                                            getOptionValue={e => e.id}
                                            loadOptions={Picklist.fetchLocationAllowedPicklist}
                                            onInputChange={Picklist.handleInputChange}
                                            onChange={handleChangefilterLocation}
                                            isDisabled={branchDisable === "true"}
                                        >
                                        </AsyncSelect>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Remark<span className="text-danger">*</span></Form.Label>
                                        <Form.Control value={filterDocRemark}
                                            maxLength={100}
                                            onChange={(e) => Common.validateAlpNumValue(e.target.value, setFilterDocRemark)}
                                            placeholder="Remark" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Type<span className="text-danger">*</span></Form.Label>
                                        <Form.Select value={filterDocType} onChange={e => setFilterDocType(e.target.value)}
                                            required>
                                            <option value="PP">PP (Purchase from Public)</option>
                                            <option value="PB">PB (Purchase from Bulk)</option>
                                            <option value="SP">SP (Sale to Public)</option>
                                            <option value="SB">SB (Sale to Bulk)</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                {
                                    filterDocRemark === '' &&
                                    <>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>From<span className="text-danger">*</span></Form.Label>
                                                <Form.Control value={docFromSrno}
                                                    maxLength={20}
                                                    onChange={(e) => Common.validateNumValue(e.target.value, setDocFromSrno)}
                                                    placeholder="From" />
                                            </Form.Group>
                                        </Col>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>To<span className="text-danger">*</span></Form.Label>
                                                <Form.Control value={docToSrno}
                                                    maxLength={20}
                                                    onChange={(e) => Common.validateNumValue(e.target.value, setDocToSrno)}
                                                    placeholder="To" />
                                            </Form.Group>
                                        </Col>
                                    </>
                                }
                                <Col style={{ marginTop: "34px" }}>
                                    <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => list()}>List</Button>
                                </Col>
                            </Row>
                        </>
                        : null
                }
                <Row>&nbsp;</Row>
                <Row>
                    <Col>
                        {
                            userRight.add === "1" && !isUploadClicked ?
                                <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => handleUpload()}>Upload</Button> : null
                        }
                        {
                            userRight.add === "1" && isUploadClicked ?
                                <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => handleSubmit()}>Submit</Button> : null
                        }
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                <Row>
                    <Col>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>Location</th>
                                    <th>Type</th>
                                    <th>Srno</th>
                                    <th>File Name</th>
                                    <th>Document</th>
                                    <th>Remark 1</th>
                                    <th>Remark 2</th>
                                    <td>&nbsp;</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    fxDocData.map((res) => (
                                        <tr key={res.mfd_srno}>
                                            <td>{res.entity_name}</td>
                                            <td>{res.mfd_type}</td>
                                            <td>{res.mfd_srno}</td>
                                            <td>{res.mfd_file_name}</td>
                                            <td>{res.docm_desc}</td>
                                            <td>{res.mfd_remark1}</td>
                                            <td>{res.mfd_remark2}</td>
                                            <td>
                                                <div className="colorThemeBlue">
                                                    <span className="handCursor" onClick={(e) => btnDocumentView(res.mfd_srno, res.mfd_file_name)}>
                                                        <FontAwesomeIcon icon={faEye} />
                                                    </span>&nbsp;
                                                    <span className="handCursor" onClick={(e) => btnDocumentDownload(res.mfd_srno, res.mfd_file_name)}>
                                                        <FontAwesomeIcon icon={faDownload} />
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
            </div>
            <Modal
                show={showDocument}
                onHide={() => setShowDocument(false)}
                size="xl"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {documentDescToView + " : " + documentNameToView}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <embed src={documentToView} type={documentTypeToView} style={{ minHeight: "100vh", minWidth: "100%" }} ></embed>
                    {
                        /*
                        type="image/png" type="application/pdf"
                        width="100%" height="100%"
                        
                        <object data={documentToView} type={documentTypeToView} class="internal">
     
                        </object>
                        */
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Fx_document;
