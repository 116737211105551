import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import Dialog from '../../Dialog';
import LocationSelector from '../../LocationSelector';
import Login_header from '../../Login_header';
import Menu from '../../Menu';
import * as Common from './../../Common';

const Tran_payment_report = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const [onceRun, setOnceRun] = useState(false);
    const [userRight, setUserRight] = useState([]);
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    const [paymentFromDate, setPaymentFromDate] = useState(new Date());
    const [paymentToDate, setPaymentToDate] = useState(new Date());
    const [paymentViewType, setPaymentViewType] = useState('V');

    const [locationCode, setLocationCode] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);
    const [radioType, setRadioType] = useState('A');
    const [viewType, setViewType] = useState("V");
    const [scr, setScr] = useState("");
    const [viewList, setViewList] = useState([]);

    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_PAYMENT_REPORT'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);

    const handleDataFromChild = (newLocationCode, newSelectedItems, newRadioType) => {
        setLocationCode(newLocationCode);
        setSelectedItems(newSelectedItems);
        setRadioType(newRadioType);
    }

    const handleGenerate = () => {
        var msg = [], i = 0;
        if ((radioType === 'O' && locationCode === '0') || (radioType == 'S' && selectedItems == [])) {
            msg[i++] = ((radioType == 'O' && locationCode == '0') ? "Please select location." : '');
            msg[i++] = ((radioType == 'S' && selectedItems == []) ? "Please select atleast one location." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const obj = {
                fromDate: Common.dateYMD(paymentFromDate),
                toDate: Common.dateYMD(paymentToDate),
                type: viewType,
                radioTypeLocation: radioType,
                location: locationCode,
                multipleLocation: selectedItems,
            };
            if (viewType === 'D') {
                Common.callDownloadApiPost(Common.apiPaymentReport, "post", [sid, 'getData', JSON.stringify(obj)]);
            } else {
                Common.callApi(Common.apiPaymentReport, [sid, 'getData', JSON.stringify(obj)], (result) => {
                    setScr('V');
                    const resData = JSON.parse(result);
                    setViewList(resData)
                    // console.log(scr);
                });
            }
        }
    }

    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                {scr === "" ?
                    <>
                        <Row>
                            <Col>
                                <h4>Tran Payment Module Report</h4>
                            </Col>
                        </Row>
                        <Row>&nbsp;</Row>
                        <Row>
                            <Col>
                                <Button variant='outline-success' className='buttonStyle' size='sm' onClick={handleGenerate}>Generate</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>From Date<span className="colorThemeRed">*</span></Form.Label><br />
                                    <DatePicker className="form-control"
                                        selected={paymentFromDate}
                                        onChange={(date) => setPaymentFromDate(date)}
                                        dateFormat="dd/MM/yyyy"
                                        showYearDropdown
                                        showMonthDropdown
                                        useShortMonthInDropdown
                                        dropdownMode="select"
                                        peekNextMonth
                                        customInput={
                                            <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                        }
                                    />
                                </Form.Group>
                            </Col>

                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>To Date<span className="colorThemeRed">*</span></Form.Label><br />
                                    <DatePicker className="form-control"
                                        selected={paymentToDate}
                                        onChange={(date) => setPaymentToDate(date)}
                                        dateFormat="dd/MM/yyyy"
                                        showYearDropdown
                                        showMonthDropdown
                                        useShortMonthInDropdown
                                        dropdownMode="select"
                                        peekNextMonth
                                        customInput={
                                            <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Mode<span className="text-danger">*</span></Form.Label>
                                    <Form.Select value={viewType} onChange={e => setViewType(e.target.value)}
                                        required>
                                        <option value="V">View</option>
                                        <option value="D">Download</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>&nbsp;</Row>
                        <Row>
                            <Col className='col-md-6 col-6'>
                                <Form.Label>Location Filter</Form.Label><br />
                                <LocationSelector onDataFromChild={handleDataFromChild} />
                            </Col>
                            {/* <Col className='col-md-6 col-6'>
                        <Form.Label>Currency Filter</Form.Label><br />
                        <CurrencySelector onDataFromChild2={handleDataFromChild2} />
                    </Col> */}
                        </Row>
                        {/* <Row>
                    <Table>
                        <thead>
                            <li>
                            <th>SrNo</th>
                                <th>SrNo</th>
                            </li>
                        </thead>
                    </Table>
                </Row> */}
                    </> : null}
                {scr === "V" ? <>
                    <Row>
                        <Col>
                            <Button onClick={() => setScr('')} variant="primary">Back</Button>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    &nbsp;
                    <Table responsive striped bordered>
                        <thead>
                            <tr>
                                <th>Serial Number</th>
                                <th>Branch</th>
                                <th>Reference Number</th>
                                <th>Request Date</th>
                                <th>Type</th>
                                <th>Remark</th>
                                <th>Pay Category</th>
                                <th>Amount</th>
                                <th>Account Name</th>
                                <th>Account Number</th>
                                <th>IFSC Code</th>
                                <th>Encashment Type</th>
                                <th>Encashment Number</th>
                                <th>From Bank Group</th>
                                <th>From Bank Account</th>
                                <th>To Bank Group</th>
                                <th>To Bank Account</th>
                                <th>Depositor Name</th>
                                <th>Transaction Desc</th>
                                <th>Relationship Manager</th>
                                <th>Document Collected</th>
                                <th>Approval Level 1</th>
                                <th>Approval Level 2</th>
                                <th>Downloaded Bank File</th>
                                <th>Active</th>
                                <th>GST</th>
                                <th>TDS</th>
                                <th>Net Payable</th>
                                <th>Other Charges</th>
                                <th>Party Group</th>
                                <th>Party Account</th>
                                <th>Download New Fa</th>
                                <th>Value Date</th>
                                <th>User</th>
                                <th>Checker1</th>
                                <th>Checker2</th>
                                <th>Checker1 Timestamp</th>
                                <th>Checker2 Timestamp</th>
                                <th>To Location</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                viewList.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.tmp_srno}</td>
                                            <td>{item.tmp_location}</td>
                                            <td>{item.tmp_ref_no}</td>
                                            <td>{item.tmp_request_date}</td>
                                            <td>{item.tmp_type}</td>
                                            <td>{item.tmp_remark}</td>
                                            <td>{item.tpc_name}</td>
                                            <td>{item.tmp_amount}</td>
                                            <td>{item.tmp_account_name}</td>
                                            <td>{item.tmp_account_number}</td>
                                            <td>{item.tmp_ifsc}</td>
                                            <td>{item.tmp_encashment_type}</td>
                                            <td>{item.tmp_encashment_number}</td>
                                            <td>{item.tmp_from_ftbank_group}</td>
                                            <td>{item.tmp_from_ftbank_account}</td>
                                            <td>{item.tmp_to_ftbank_group}</td>
                                            <td>{item.tmp_to_ftbank_account}</td>
                                            <td>{item.tmp_tpdepositor_name}</td>
                                            <td>{item.tmp_tptransaction_desc}</td>
                                            <td>{item.tmp_tprelationship_manager}</td>
                                            <td>{item.tmp_document_collected}</td>
                                            <td>{item.tmp_approval_level1}</td>
                                            <td>{item.tmp_approval_level2}</td>
                                            <td>{item.tmp_bank_file_downloaded}</td>
                                            <td>{item.tmp_active}</td>
                                            <td>{item.tmp_gst}</td>
                                            <td>{item.tmp_tds}</td>
                                            <td>{item.tmp_netpayable}</td>
                                            <td>{item.tmp_othcharges}</td>
                                            <td>{item.tmp_party_group}</td>
                                            <td>{item.tmp_party_account}</td>
                                            <td>{item.tmp_download_new_fa}</td>
                                            <td>{item.tmp_value_date}</td>
                                            <td>{item.tmp_user}</td>
                                            <td>{item.tmp_checker1}</td>
                                            <td>{item.tmp_checker2}</td>
                                            <td>{item.tmp_checker1_timestamp}</td>
                                            <td>{item.tmp_checker2_timestamp}</td>
                                            <td>{item.tmp_to_location}</td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </Table>

                </> : null}
            </Container>
            <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                {msgText}
            </Dialog>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
        </>
    )
}

export default Tran_payment_report;
