import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Table, Container, Row, Col, Form } from 'react-bootstrap';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';

import Login_header from '../Login_header';
import Menu from '../Menu';
import * as Common from '../Common';
import Dialog from '../Dialog';
import DialogYesNo from '../Dialog_Yes_No';
import ActivityLog from '../ActivityLog';


function Master_country_list() {
    const navigate = useNavigate();
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    const sid = sessionStorage.getItem('sessionId');
    const [onceRun, setOnceRun] = useState(false);

    const [countryData, setCountryData] = useState([]);
    const [showAddEditForm, setAddEditFormShow] = useState(false);
    const [showAddNewBtn, setShowAddNewBtn] = useState(true);

    const [srNo, setSrNo] = useState(0);
    const [country, setCountry] = useState("");
    const [nationality, setNationality] = useState("");
    const [swiftCode, setSwiftCode] = useState("");

    const [remark, setRemark] = useState("");

    const [status, setStatus] = useState(0);
    const [ddActiveOptions, setDdActiveOptions] = useState([]);

    const [fatf, setFatf] = useState(0);
    const [ddFatfOptions, setDdFatfOptions] = useState([]);

    const [userRight, setUserRight] = useState([]);

    const [classData, setClassData] = useState([]);
    const [cClass, setCClass] = useState(0);

    const [formMode, setFormMode] = useState('Q'); // Q A E 

    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");

    const [showActivityLog, setShowActivityLog] = useState(false);
    const [logObject, setLogObject] = useState({ trantype: "", trannumber: "", trancategory: "" });
    // const [activityLog, setActivityLog] = useState([]);

    useEffect(() => {
        if (onceRun) {
            return;
        } else {

            setDdActiveOptions([{ name: 'Active', value: "1" }, { name: 'Inactive', value: "0" }]);
            setDdFatfOptions([{ name: 'YES', value: '1' }, { name: 'No', value: '0' }]);
            Common.callApi(Common.apiCountry, [sid, 'listjson'], function (result) {
                setCountryData(JSON.parse(result));
            });
            Common.callApi(Common.apiRight, [sid, 'all', 'COUNTRY'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/'); // no query right
                }
            });
            Common.callApi(Common.apiCountry, [sid, 'listclass'], function (result) {
                setClassData(JSON.parse(result));
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);

    function getList() {

        Common.callApi(Common.apiCountry, [sid, 'listjson'], function (result) {
            setCountryData(JSON.parse(result));
        });
    }

    function addNew() {
        setSrNo(0);
        // setActivityLog([]);
        setCountry("");
        setNationality("");
        setSwiftCode("");
        setFatf(0);
        setStatus(1);
        setFormMode('A');
        setCClass(0);

        setAddEditFormShow(true);
        setShowAddNewBtn(false);
    }

    const saveData = () => {
        var msg = [], i = 0;
        if (country === "" || nationality === "" || swiftCode === "") {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (country === "" ? "Country." : '');
            msg[i++] = (nationality === "" ? "Nationality." : '');
            msg[i++] = (swiftCode === "" ? "Swift Code." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (formMode === "E" && remark === "") {
            setMsgText("Please fill Remark!");
            setMsgBox(true);
        } else {
            var object1 = {
                cnt: country,
                act: status,
                cls: cClass,
                nat: nationality,
                swtcd: swiftCode,
                fatf: fatf,
                branch: 0,
                activitytype: srNo * 1 > 0 ? 'MODIFY' : 'CREATE',
                trancategory: 'M',
                trantype: 'MCNT',
                trannumber: srNo,
                subtranno: 0,
                remark: remark
            };
            Common.callApi(Common.apiCountry, [sid, 'save', srNo, JSON.stringify(object1)], function (result) {
                let resp = JSON.parse(result);
                if (resp.err === "") {
                    setAddEditFormShow(false);
                    setShowAddNewBtn(true);
                    setRemark("");
                    getList();
                } else {
                    setMsgText(resp.err);
                    setMsgBox(true);
                }
            });
        }
    }

    const cancelConfirm = () => {
        if (formMode === "A" || formMode === "E") {
            setMsgTextYN('Do you really want to exit ?');
            setMsgBoxYN(true);
        } else {
            cancelData();
        }
    }

    const cancelData = () => {
        setMsgBoxYN(false);
        setAddEditFormShow(false);
        setShowAddNewBtn(true);
        setShowActivityLog(false);
    }

    // function cancelData() {
    //     setAddEditFormShow(false);
    //     setShowAddNewBtn(true);
    // }

    function fillEditForm(id) {
        setFormMode('Q');
        setShowActivityLog(false);
        Common.callApi(Common.apiCountry, [sid, 'detail', id], function (result) {
            let resp = JSON.parse(result);
            setSrNo(resp.srno);
            setCountry(resp.country);
            setNationality(resp.natlty);
            setSwiftCode(resp.swift);
            setFatf(resp.fatf);
            setStatus(resp.active);
            setCClass(resp.cclass);
            /* cclass           */
            setAddEditFormShow(true);
            setShowAddNewBtn(false);
        });
    }

    const editData = () => {
        setFormMode('E');
    }

    const exportFile = () => {
        var obj = {
            right: 'COUNTRY'
        }
        Common.callDownloadApiPost(Common.apiReport, "post", [sid, JSON.stringify(obj)]);
    }

    const btnActivityLog = () => {
        setShowActivityLog(true);
        setLogObject({ trantype: "MCNT", trannumber: srNo, trancategory: "M" });
    }

    return (
        <>
            <Login_header />
            <Menu></Menu>
            <Container fluid >
                <Row>
                    <Col>
                        <h1>Country Master</h1>
                    </Col>
                </Row>
                {showAddNewBtn ?
                    <Row>
                        <Col>
                            {userRight.add === "1" ?
                                <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={addNew}>Add New</Button>
                                : null
                            }
                            {
                                userRight.query === "1" ?
                                    <>&nbsp;
                                        <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={exportFile}>Export</Button>
                                    </>
                                    : null
                            }
                        </Col>
                    </Row>
                    : null
                }

                <Row><Col>&nbsp;</Col></Row>
                {showAddEditForm ?
                    <>
                        <Row>
                            <Col className="divContentCenter">
                                {/*Add Edit Form  className="mb-3" className="divContentCenter" */}
                                <div className="maxFormWidth">
                                    <Form>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Serial Number</Form.Label>
                                                    <Form.Control type="text" placeholder="Serial Number" value={srNo} disabled />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Country<span className="mandatory text-danger">*</span></Form.Label>
                                                    <Form.Control type="text" placeholder="Country" maxLength={50}
                                                        value={country}
                                                        onChange={(e) => Common.validateAlpValue(e.target.value, setCountry)}
                                                        autoComplete="off" disabled={formMode === 'Q'}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Nationality<span className="mandatory text-danger">*</span></Form.Label>
                                                    <Form.Control type="text" placeholder="Nationality" maxLength={50}
                                                        value={nationality}
                                                        onChange={(e) => Common.validateAlpValue(e.target.value, setNationality)}
                                                        autoComplete="off"
                                                        disabled={formMode === 'Q'}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Swift Code<span className="mandatory text-danger">*</span></Form.Label>
                                                    <Form.Control type="text" placeholder="Swift Code" maxLength={10}
                                                        value={swiftCode}
                                                        onChange={(e) => Common.validateAlpNumValue(e.target.value, setSwiftCode)}
                                                        autoComplete="off"
                                                        disabled={formMode === 'Q'}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>FATF</Form.Label>
                                                    <Form.Select
                                                        value={fatf}
                                                        onChange={(e) => setFatf(e.target.value)}
                                                        disabled={formMode === 'Q'}
                                                    >
                                                        {ddFatfOptions.map((res) =>
                                                            <option key={res.value} value={res.value}>{res.name}</option>
                                                        )}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Status</Form.Label>
                                                    <Form.Select
                                                        value={status}
                                                        onChange={(e) => setStatus(e.target.value)}
                                                        disabled={formMode === 'Q'}
                                                    >
                                                        {ddActiveOptions.map((res) =>
                                                            <option key={res.value} value={res.value}>{res.name}</option>
                                                        )}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='col-md-6'>
                                                <Form.Group>
                                                    <Form.Label>Class</Form.Label>
                                                    <Form.Select
                                                        value={cClass}
                                                        onChange={(e) => setCClass(e.target.value)}
                                                        disabled={formMode === 'Q'}
                                                    >
                                                        {classData.map((res) =>
                                                            <option key={res.value} value={res.value}>{res.name}</option>
                                                        )}
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        {
                                            formMode === 'E' ?
                                                <>
                                                    <Row>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>Remark<span className="mandatory text-danger">*</span></Form.Label>
                                                                <Form.Control type="text" placeholder="Remark" maxLength={50}
                                                                    value={remark}
                                                                    onChange={(e) => Common.validateAlpValue(e.target.value, setRemark)}
                                                                    autoComplete="off"
                                                                    required />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>&nbsp;</Col>
                                                        <Col>&nbsp;</Col>
                                                        <Col>&nbsp;</Col>
                                                    </Row>
                                                </> :
                                                null
                                        }
                                        <Row><Col>&nbsp;</Col></Row>
                                        <Row>
                                            <Col>
                                                {/* <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() => saveData()}>Save</Button>&nbsp;
                                                <Button variant="outline-danger" className='buttonStyle' size="sm" onClick={() => cancelData()}>Cancel</Button> */}

                                                <Button variant="outline-danger" className='buttonStyle' size="sm" onClick={() =>
                                                    cancelConfirm()}>Back</Button>&nbsp;&nbsp;

                                                {
                                                    (userRight.edit === "1" && formMode === 'Q') ? <>
                                                        <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                            editData()}>Edit</Button>&nbsp;
                                                    </> : null
                                                }
                                                {
                                                    (userRight.edit === "1" && formMode === 'E') || (userRight.add === "1" && formMode === 'A') ? <>
                                                        <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                            saveData()}>Save</Button>&nbsp;
                                                    </> : null
                                                }
                                                {
                                                    (userRight.query === "1" && (formMode === 'E' || formMode === 'Q')) ? <>
                                                        <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                            btnActivityLog()}>Activity Log</Button>&nbsp;
                                                    </> : null
                                                }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>&nbsp;</Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            {
                                showActivityLog &&
                                <>
                                    <ActivityLog logObject={logObject} show={showActivityLog} onHide={() => setShowActivityLog(false)} />
                                </>
                            }
                        </Row>
                    </>
                    :
                    <Row>
                        <Col >
                            <Table responsive striped bordered hover>
                                <thead>
                                    <tr>
                                        {userRight.edit === "1" ? <th></th> : null}
                                        <th className="textRight">Sr No</th>
                                        <th >Country</th>
                                        <th >Nationality</th>
                                        <th >Swift Code</th>
                                        <th >FATF</th>
                                        <th >Status</th>
                                    </tr>
                                </thead>
                                <tbody id="tblBody">
                                    {countryData.map((res) =>
                                        <tr key={res.cnt_srno}>
                                            {userRight.edit === "1" ?
                                                <td className="textCenter" >
                                                    <span className="handCursor" onClick={() => fillEditForm(res.cnt_srno)} >
                                                        <div className="colorThemeBlue"><FontAwesomeIcon icon={faEye} /></div>
                                                    </span>
                                                </td>
                                                : null
                                            }
                                            <td className="textRight">{res.cnt_srno}</td>
                                            <td>{res.cnt_name}</td>
                                            <td>{res.cnt_nationality}</td>
                                            <td>{res.cnt_swiftcode}</td>
                                            <td>{res.cnt_fatf}</td>
                                            <td>{res.cnt_active}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                }
            </Container>
            {/* <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
            </div> */}
            <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
                <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
                    {msgTextYN}
                </DialogYesNo>
            </div>
        </>
    );
}
export default Master_country_list;