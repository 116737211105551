import React, { useEffect, useState } from 'react';
import Login_header from '../Login_header';
import Menu from '../Menu';
import { Container, Row, Col, Table, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as Common from '../Common';
import * as Picklist from '../Picklist';
import $ from "jquery";
import AsyncSelect from 'react-select/async';
import Dialog from '../Dialog';
import DialogYesNo from '../Dialog_Yes_No';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';

const Master_entity = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const startDate = sessionStorage.getItem('startDate');
    const endDate = sessionStorage.getItem('endDate');

    const [dataList, setDataList] = useState([]);
    const [userRight, setUserRight] = useState([]);
    const [onceRun, setOnceRun] = useState(false);
    const [scrMode, setScrMode] = useState("");

    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");
    const [isErrBox, setErrBox] = useState(false);
    const [errText, setErrText] = useState("");

    const [enType, setEnType] = useState("B");
    const [enId, setEnId] = useState(0);
    const [enName, setEnName] = useState("");
    const [enPan, setEnPan] = useState("");
    const [enActive, setEnActive] = useState('1');
    const [enCountry, setEnCountry] = useState(null);
    const [cntCode, setCntCode] = useState(0);
    const [enState, setEnState] = useState(null);
    const [stateCode, setStateCode] = useState(0);
    const [enDistrict, setEnDistrict] = useState(null);
    const [distCode, setDistCode] = useState(0);
    const [enCity, setEnCity] = useState(null);
    const [cityCode, setCityCode] = useState(0);
    const [enAddress1, setEnAddress1] = useState("");
    const [enAddress2, setEnAddress2] = useState("");
    const [enAddress3, setEnAddress3] = useState("");
    const [enPhoneno, setEnPhoneno] = useState("");
    const [enMobileno, setEnMobileno] = useState("");
    const [enEmail, setEnEmail] = useState("");
    const [enAlphaCode, setEnAlphaCode] = useState("");

    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.callApi(Common.apiRight, [sid, 'all', 'ENTITY'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
            });
            setOnceRun(true);
        }
        Common.callApi(Common.apiEntity, [sid, 'list', enType], (result) => {
            let resp = JSON.parse(result);
            setDataList(resp);
        });
    }, [onceRun, sid, navigate]);

    const handleCountryValue = (value) => {
        setEnCountry(value);
        const cnt = value.id.split('^');
        setCntCode(cnt[1]);
        setEnState(null);
        setEnDistrict(null);
        setEnCity(null);
    }

    const handleStateValue = (value) => {
        setEnState(value);
        const stt = value.id.split('^');
        setStateCode(stt[1]);
        setEnDistrict(null);
        setEnCity(null);
    }

    const handleDistrictValue = (value) => {
        setEnDistrict(value);
        const dist = value.id.split('^');
        setDistCode(dist[1]);
        setEnCity(null);
    }

    const handleCityValue = (value) => {
        setEnCity(value);
        const ct = value.id.split('^');
        setCityCode(ct[1]);
    }


    function addNew() {
        setScrMode("A");
        setEnType("B");
        setEnId(0);
        setEnName("");
        setEnPan("");
        setEnActive('1');
        setEnCountry(null);
        setEnState(null);
        setEnDistrict(null);
        setEnCity(null);
        setEnAddress1("");
        setEnAddress2("");
        setEnAddress3("");
        setEnPhoneno("");
        setEnMobileno("");
        setEnEmail("");
        setEnAlphaCode("");
    }

    function saveData() {
        var msg = [], i = 0;
        if (enName === "" || enAddress1 === "" || enCountry === null || enDistrict === null || enState === null || enCity === null) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (enName === '' ? " Name." : '');
            msg[i++] = (enAddress1 === "" ? " Address1." : '');
            msg[i++] = (enCountry === null ? " Country." : '');
            msg[i++] = (enState === null ? " State." : '');
            msg[i++] = (enCity === null ? " City." : '');
            msg[i++] = (enDistrict === null ? " District." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const obj = {
                enType: enType,
                enId: enId,
                enName: enName,
                enPan: enPan,
                enActive: enActive,
                enCountry: cntCode,
                enState: stateCode,
                enDistrict: distCode,
                enCity: cityCode,
                enAddress1: enAddress1,
                enAddress2: enAddress2,
                enAddress3: enAddress3,
                enPhoneno: enPhoneno,
                enMobileno: enMobileno,
                enEmail: enEmail,
                enAlphaCode: enAlphaCode,
            };
            Common.callApi(Common.apiEntity, [sid, 'save', enId, JSON.stringify(obj)], function (result) {
                let resp = JSON.parse(result);
                setDataList(resp);
                setScrMode('');
            });
        }
    }

    const viewList = (id) => {
        setEnId(id);
        setScrMode('Q');
        Common.callApi(Common.apiEntity, [sid, 'view', id], (result) => {
            let resp = JSON.parse(result);
            setEnId(resp.srno);
            setEnName(resp.entName);
            setEnAlphaCode(resp.entAlphaCode);
            setEnPan(resp.entPan);
            setEnAddress1(resp.entAddress1);
            setEnAddress2(resp.entAddress2);
            setEnAddress3(resp.entAddress3);

            setEnCountry({ value: resp.entCountry, label: resp.country });
            setEnState({ value: resp.entState, label: resp.state });
            setEnCity({ value: resp.entCity, label: resp.city });
            setEnDistrict({ value: resp.entDistrict, label: resp.district });

            setCntCode(resp.entCountry);
            setStateCode(resp.entState);
            setCityCode(resp.entCity);
            setDistCode(resp.entDistrict);

            setEnPhoneno(resp.entPhone);
            setEnMobileno(resp.entMobile);
            setEnEmail(resp.entEmail);
            setEnActive(resp.entActive);
        });
    }

    const cancelData = () => {
        setMsgBoxYN(false);
        setScrMode("");
    }

    const cancelConfirm = () => {
        if (scrMode === "A" || scrMode === "E") {
            setMsgTextYN('Do you really want to exit ?');
            setMsgBoxYN(true);
        } else {
            cancelData();
        }
    }

    const editData = () => {
        setScrMode("E");
    }

    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h3>Master Entity</h3>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                {
                    scrMode === '' ?
                        <>
                            <Row>
                                <Col>
                                    <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addNew()}>Add New</Button>&nbsp;
                                    {/* <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => list()}>List</Button> */}
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                {userRight.edit === "1" ? <th></th> : null}
                                                <th>Type</th>
                                                <th>Sr No</th>
                                                <th>Entity</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                dataList.map((res) => (
                                                    <tr key={res.entity_id}>
                                                        {userRight.edit === "1" ?
                                                            <td className="textCenter">
                                                                <span className="handCursor colorThemeBlue" onClick={() => viewList(res.entity_id)}>
                                                                    <FontAwesomeIcon icon={faEye} />
                                                                </span>
                                                            </td>
                                                            : null
                                                        }
                                                        <td>{res.entity_type}</td>
                                                        <td>{res.entity_id}</td>
                                                        <td>{res.entity_name}</td>
                                                        <td>{res.entity_active === '1' ? 'ACTIVE' : 'INACTIVE'}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </>
                        :
                        <>
                            <Row>
                                <Col>
                                    <Button variant='outline-danger' className='buttonStyle' size='sm' onClick={() => cancelConfirm()}>Back</Button>{' '}
                                    {
                                        (userRight.edit === "1" && scrMode === "Q") ? <>
                                            <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() => editData()}>Edit</Button>
                                        </> : null
                                    }
                                    {
                                        (userRight.edit === "1" && scrMode === "E") || (userRight.add === "1" && scrMode === "A") ? <>
                                            <Button variant='outline-primary' size='sm' className='buttonStyle' onClick={() => saveData()}>Save</Button>
                                        </> : null
                                    }
                                    {/* <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={exportFile}>Export</Button> */}
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            {
                                scrMode === "E" || scrMode === "A" || scrMode === "Q" ?
                                    <>
                                        <Row>
                                            <Col className='col-md-3 col-6' style={{ display: 'none' }} >
                                                <Form.Group>
                                                    <Form.Label>Type<span className="text-danger">*</span></Form.Label>
                                                    <Form.Select value={enType} onChange={e => setEnType(e.target.value)}
                                                        required>
                                                        <option value="B">Branch</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Serial Number</Form.Label>
                                                    <Form.Control type="text" value={enId} disabled />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Name<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control type="text"
                                                        placeholder="Name"
                                                        maxLength={100}
                                                        value={enName}
                                                        disabled={scrMode === 'Q'}
                                                        onChange={(e) => Common.validateAlpValue(e.target.value, setEnName)} />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Alpha code</Form.Label>
                                                    <Form.Control type="text"
                                                        placeholder="Alpha Code"
                                                        maxLength={100}
                                                        value={enAlphaCode}
                                                        disabled={scrMode === 'Q'}
                                                        onChange={(e) => Common.validateAlpValue(e.target.value, setEnAlphaCode)} />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>PAN</Form.Label>
                                                    <Form.Control type="text"
                                                        placeholder="PAN"
                                                        maxLength={10}
                                                        value={enPan}
                                                        disabled={scrMode === 'Q'}
                                                        onChange={(e) => setEnPan(e.target.value)}
                                                        onBlur={(e) => Common.validatePan(e.target.value, setEnPan)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Address Line 1<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control type="text"
                                                        placeholder="Line 1"
                                                        maxLength={100}
                                                        value={enAddress1}
                                                        disabled={scrMode === 'Q'}
                                                        onChange={(e) => setEnAddress1(e.target.value)} />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Address Line 2</Form.Label>
                                                    <Form.Control type="text"
                                                        placeholder="Line 2"
                                                        maxLength={100}
                                                        value={enAddress2}
                                                        disabled={scrMode === 'Q'}
                                                        onChange={(e) => setEnAddress2(e.target.value)} />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Address Line 3</Form.Label>
                                                    <Form.Control type="text"
                                                        placeholder="Line 3"
                                                        maxLength={100}
                                                        value={enAddress3}
                                                        disabled={scrMode === 'Q'}
                                                        onChange={(e) => setEnAddress3(e.target.value)} />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Country<span className="text-danger">*</span></Form.Label>
                                                    <AsyncSelect
                                                        isDisabled={scrMode === 'Q'}
                                                        cacheOptions={false}
                                                        defaultOptions={false}
                                                        value={enCountry}
                                                        getOptionLabel={e => e.label + ' '}
                                                        getOptionValue={e => e.id}
                                                        loadOptions={(search) => Picklist.fetchCountryPicklist(search, '')}
                                                        onInputChange={Picklist.handleInputChange}
                                                        onChange={handleCountryValue}
                                                    >
                                                    </AsyncSelect>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>State<span className="text-danger">*</span></Form.Label>
                                                    <AsyncSelect
                                                        isDisabled={scrMode === 'Q'}
                                                        cacheOptions={false}
                                                        defaultOptions={false}
                                                        value={enState}
                                                        getOptionLabel={e => e.label + ' '}
                                                        getOptionValue={e => e.id}
                                                        loadOptions={(search) => Picklist.fetchStatePicklist(search, cntCode)}
                                                        onInputChange={Picklist.handleInputChange}
                                                        onChange={handleStateValue}
                                                    >
                                                    </AsyncSelect>
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>District<span className="text-danger">*</span></Form.Label>
                                                    <AsyncSelect
                                                        isDisabled={scrMode === 'Q'}
                                                        cacheOptions={false}
                                                        defaultOptions={false}
                                                        value={enDistrict}
                                                        getOptionLabel={e => e.label + ' '}
                                                        getOptionValue={e => e.id}
                                                        loadOptions={(search) => Picklist.fetchDistrictPicklist(search, stateCode)}
                                                        onInputChange={Picklist.handleInputChange}
                                                        onChange={handleDistrictValue}
                                                    >
                                                    </AsyncSelect>
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>City<span className='text-danger'>*</span></Form.Label>
                                                    <AsyncSelect
                                                        isDisabled={scrMode === 'Q'}
                                                        cacheOptions={false}
                                                        defaultOptions={false}
                                                        value={enCity}
                                                        getOptionLabel={e => e.label + ' '}
                                                        getOptionValue={e => e.id}
                                                        loadOptions={(search) => Picklist.fetchCityPicklist(search, distCode)}
                                                        onInputChange={Picklist.handleInputChange}
                                                        onChange={handleCityValue}
                                                    >
                                                    </AsyncSelect>
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Phone No </Form.Label>
                                                    <Form.Control type="text"
                                                        placeholder="Phone"
                                                        maxLength={50}
                                                        value={enPhoneno}
                                                        disabled={scrMode === 'Q'}
                                                        onChange={(e) => Common.validateNumValue(e.target.value, setEnPhoneno)} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Mobile No </Form.Label>
                                                    <Form.Control type="text"
                                                        placeholder="Mobile"
                                                        maxLength={50}
                                                        value={enMobileno}
                                                        disabled={scrMode === 'Q'}
                                                        onChange={(e) => Common.validateNumValue(e.target.value, setEnMobileno)} />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Email </Form.Label>
                                                    <Form.Control type="email"
                                                        placeholder="Email"
                                                        maxLength={100}
                                                        value={enEmail}
                                                        disabled={scrMode === 'Q'}
                                                        onChange={(e) => setEnEmail(e.target.value)}
                                                        onBlur={(e) => Common.validtateEmail(e.target.value, setEnEmail)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Status</Form.Label>
                                                    <Form.Select value={enActive} onChange={e => setEnActive(e.target.value)}
                                                        disabled={scrMode === 'Q'}
                                                        required>
                                                        <option value="1">Active</option>
                                                        <option value="0">Inactive</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </>
                                    : null
                            }
                        </>
                }
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
            </Container>
            <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
                <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
                    {msgTextYN}
                </DialogYesNo>
                <Dialog isOpen={isErrBox} onClose={(e) => setErrBox(false)}>
                    {errText}
                </Dialog>
            </div>
        </>
    )
}

export default Master_entity;
