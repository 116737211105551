import { faEdit, faEye } from '@fortawesome/free-regular-svg-icons';
import { faCircleCheck, faCircleExclamation, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from "jquery";
import React, { useEffect, useState } from 'react';
import { Badge, Button, Col, Container, Form, Modal, Row, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import ActivityLog from '../ActivityLog';
import * as Common from "../Common";
import Dialog from '../Dialog';
import DialogYesNo from '../Dialog_Yes_No';
import Login_header from '../Login_header';
import Master_agent_linking from '../master/financial/Master_agent_linking';
import Menu from '../Menu';
import * as Picklist from "../Picklist";
import Tran_commission_gst_approval from './Tran_commission_gst_approval';

function Tran_commission_module() {
    const flag = true;

    const sid = sessionStorage.getItem("sessionId");
    const navigate = useNavigate();
    const finfromdate = sessionStorage.getItem("startDate");
    const fintodate = sessionStorage.getItem("endDate");
    const branchDisable = sessionStorage.getItem('branchDisable');

    //#region All right states
    const [userRight, setUserRight] = useState([]);
    const [bmApprovalRight, setBMApprovalRight] = useState([]);
    const [gstApprovalRight, setGstApprovalRight] = useState([]);
    const [gstApproval2Right, setGstApproval2Right] = useState([]);
    const [zmApprovalRight, setZMApprovalRight] = useState([]);
    //#endregion all right states
    const [cmLocation, setCMLocation] = useState(null);
    const [radioType, setRadioType] = useState("N");
    const [srnoFrom, setSrnoFrom] = useState("");
    const [srnoTo, setSrnoTo] = useState("");
    const [frmDate, setFrmDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const [scrMode, setScrMode] = useState("");
    const [msgText, setMsgText] = useState("");
    const [isMsgBox, setMsgBox] = useState(false);

    const [cmLocationValue, setCMLocationValue] = useState("");
    const [cmStateValue, setCMStateValue] = useState("");
    const [hdrLocation, setHdrLocation] = useState("");
    const [nFile, setNFile] = useState("");
    const [commissionData, setCommissionData] = useState([]);
    const [tranType, setTranType] = useState("A");
    const [searchFilter, setSearchFilter] = useState("");
    const [childRadioType, setChildRadioType] = useState("U");

    const [approvalStatusFilter, setApprovalStatusFilter] = useState("P");
    const [frmApprovalDate, setFrmApprovalDate] = useState(new Date());
    const [toApprovalDate, setToApprovalDate] = useState(new Date());

    const [hdrData, setHdrData] = useState([]);
    const [hdrSrno, setHdrSrno] = useState("");
    const [hdrDate, setHdrDate] = useState("");
    const [hdrFromDate, setHdrFromDate] = useState("");
    const [hdrFromDateDMY, setHdrFromDateDMY] = useState("");
    const [hdrToDate, setHdrToDate] = useState("");
    const [hdrToDateDMY, setHdrToDateDMY] = useState("");
    const [isMsgBoxFinal, setMsgBoxFinal] = useState(false);
    const [msgTextFinal, setMsgTextFinal] = useState("");
    const [bmDetailData, setBMDetailData] = useState([]);
    const [filterStatus, setFilterStatus] = useState("1");
    const [bmStatus, setBMStatus] = useState("");
    const [bmLineNo, setBmLineNo] = useState("");
    const [finalMsgSrc, setFinalMsgSrc] = useState("");

    const [reportType, setReportType] = useState("TW");

    const [BMHeaderData, setBMHeaderData] = useState([]);
    const [gstData, setGstData] = useState([]);

    const [bmAppTotalPayable, setBmAppTotalPayable] = useState("");
    const [bmAppTotalCommission, setBmAppTotalCommission] = useState("");
    const [bmAppTotalTds, setBmAppTotalTds] = useState("");
    const [bmAccount, setBmAccount] = useState("");
    const [bmIfsc, setBmIfsc] = useState("");
    const [bmAppAccName, setBmAppAccName] = useState("");
    const [bmAppAgent, setBmAppAgent] = useState("");
    const [bmFaType, setBmFaType] = useState("");
    const [bmGSTType, setBmGSTType] = useState("");
    const [bmGST, setBmGST] = useState("");
    const [bmAgentCode, setBmAgentCode] = useState("");

    const [selectAll, setSelectAll] = useState(false);

    const [logObject, setLogObject] = useState({ trantype: "", trannumber: "", trancategory: "" });
    const [showActivityLog, setShowActivityLog] = useState(false);

    const [agentProps, setAgentProps] = useState('');
    const [showAgentForm, setShowAgentForm] = useState(false);
    const [commissionSummary, setCommissionSummary] = useState([]);
    const [summaryCommissionTotal, setSummaryCommissionTotal] = useState("");
    const [summaryPayable, setSummaryPayable] = useState("");
    const [summaryTDSTotal, setSummaryTDSTotal] = useState("");

    const [holdGstData, setHoldGstData] = useState([]);
    const [showSummaryModal, setShowSummaryModal] = useState(false);
    const [gstStatus, setGstStatus] = useState("");
    const [propObject, setPropObject] = useState({});
    const [paymentType, setPaymentType] = useState("");
    const [approvalType, setApprovalType] = useState("");
    const [hdrGstin, setHdrGstin] = useState("");

    const [showInvoiceModal, setShowInvoiceModal] = useState(false);
    const [invoiceData, setInvoiceData] = useState([]);
    const [faDownloadType, setFaDownloadType] = useState("N");
    const [faType, setFaType] = useState("");
    const [openFaModal, setOpenFaModal] = useState(false);
    const [openApprovalModal, setOpenApprovalModal] = useState(false);
    const [approvalSummary, setApprovalSummary] = useState([]);

    const [bmInvoiceDate, setBmInvoiceDate] = useState("");
    const [bmInvoiceNum, setBmInvoiceNum] = useState("");

    const [selectAllBM, setSelectAllBM] = useState(false);
    const [summaryStatusFilter, setSummaryStatusFilter] = useState("O");

    const [filterGstType, setFilterGstType] = useState("1");

    useEffect(() => {
        Common.getActiveBranch().then(response => {
            setCMLocation(response);
            response != null && handleLocationChange(response);
        });
        var userright = [];
        var bmright = [];
        var gstright = [];
        var gstapprovalright = [];
        var zmright = [];

        Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_COMMISSION_BM_APPROVAL'], function (result) {
            let resp = JSON.parse(result);
            bmright = resp;
            if (resp.query === "1") {
                setRadioType("A");
                setBMApprovalRight(resp);
                //if (radiotype === "") radiotype = "A";
            }
        });
        Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_COMMISSION_ZM_APPROVAL'], function (result) {
            let resp = JSON.parse(result);
            zmright = resp;
            if (resp.query === "1") {
                setRadioType("A");
                setZMApprovalRight(resp);
                //if (radiotype === "") radiotype = "A";
            }
        });
        Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_COMMISSION_GST'], function (result) {
            let resp = JSON.parse(result);
            gstright = resp;
            if (resp.query === "1") {
                setRadioType("H");
                setGstApprovalRight(resp);
                //if (radiotype === "") radiotype = "H";
            }
        });
        Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_COMMISSION_GST_APPROVAL'], function (result) {
            let resp = JSON.parse(result);
            gstapprovalright = resp;
            if (resp.query === "1") {
                setRadioType("H");
                setGstApproval2Right(resp);
                //if (radiotype === "") radiotype = "H";
            }
        });
        Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_COMMISSION'], function (result) {
            let resp = JSON.parse(result);
            userright = resp;
            if (resp.query === "1") {
                setUserRight(resp);
            }
            if (resp.add === "1") setRadioType("N");;
        });
        if (userright === "0" && bmright === "0" && gstright === "0" && gstapprovalright === "0" && zmright === "0") {
            navigate("/");
        }
    }, []);

    // #region Others
    const showSearchOptions = () => {
        setMsgBox(true);
        setMsgText("Search on : Agent Number, Agent Name");
    }

    const downloadDirectExpenseFormat = () => {
        const obj = {
            downloadType: "directexpensecommission",
            pageright: "TRAN_COMMISSION"
        }
        Common.callDownloadApiPost(Common.apiFormat, "post", [sid, "directexpensecommission", JSON.stringify(obj)]);
    }

    const btnActivityLog = (viewtype) => {
        setShowActivityLog(true);
        setLogObject({ trantype: "CM", trannumber: hdrSrno, trancategory: "T", location: cmLocationValue });
    }

    const handleLocationChange = (value) => {
        setCMLocation(value);
        var id = value.id;
        var location = id.split("^");
        setCMLocationValue(location[1]);
        setCMStateValue(location[3] == null ? 0 : location[3]);
        //setPPStateType(location[4]);
        //setPPStateName(location[5] === "" ? "Select" : location[5]);
        setHdrLocation(location[2]);
        sessionStorage.activeBranch = location[1];
    }
    // #endregion Others




    //#region AM Work ****************************************************
    const uploadFile = () => {
        $(".loader").show();
        var msg = [], i = 0;
        var selectedFile = document.getElementById('uploadCommission').files[0];
        if (cmLocation === null) {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (cmLocation === null ? "Location." : "");
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (selectedFile === undefined) {
            $('.loader').hide();
            setMsgText("Please select a file to upload.");
            setMsgBox(true);
        } else if ((selectedFile.name.split('.')).length > 2) {
            $('.loader').hide();
            setMsgText("(.) not allowed in file name.");
            setMsgBox(true);
        } else if (selectedFile.name.split('.')[1] !== "xlsx") {
            $('.loader').hide();
            setMsgText("File extension must be xlsx.");
            setMsgBox(true);
        } else if (selectedFile.size > 10 * 1024 * 1024) {  // 10 MB
            $('.loader').hide();
            setMsgText("Please select a file with in 10 MB size.");
            setMsgBox(true);
        } else {
            setCommissionData([]);
            const obj = {
                sid: sid,
                pageright: "TRAN_COMMISSION",
                uploadType: "uploadcommission",
                location: cmLocationValue,
                locationname: hdrLocation,
                frmDate: hdrFromDate,
                toDate: hdrToDate,
                reportType: reportType,
                hdrsrno: hdrSrno,
                type: "CM",
                filename: nFile,
                commissionData: commissionData,
                state: cmStateValue
            }
            Common.uploadApi(JSON.stringify(obj), "uploadCommission", (result) => {
                $(".loader").hide();
                let resp = JSON.parse(result);
                setMsgBox(true);
                setMsgText(resp.msg);
                setCommissionData(resp.docdetail);
                setNFile("");
            });
        }
    }

    //#region Header
    const headerSaveCheck = () => {
        setMsgBoxFinal(true);
        setMsgTextFinal(`Do you want to create entry for period ${Common.dateDMY(frmDate)} to ${Common.dateDMY(toDate)}.`);
        setFinalMsgSrc("A");
    }

    const addNewHeader = () => {
        //setScrMode("A");
        var msg = [], i = 0;
        var frmdateymd = Common.dateYMD(frmDate);
        var todateymd = Common.dateYMD(toDate);
        if (frmDate === null || toDate === null || cmLocation == null) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (cmLocation === null ? "Location" : "");
            msg[i++] = (frmDate === null ? "From date" : "");
            msg[i++] = (toDate === null ? "To date" : "");
            setMsgBox(true);
            setMsgText(Common.buildMessageFromArray(msg));
        } else if (frmdateymd < finfromdate || todateymd > fintodate) {
            setMsgBox(true);
            setMsgText(Common.getMessage("MSG0010"));
        } else {
            const obj = {
                location: cmLocationValue,
                todate: Common.dateYMD(toDate),
                frmdate: Common.dateYMD(frmDate),
                frmsrno: srnoFrom,
                radioType: radioType,
                tosrno: srnoTo
            }
            Common.callApi(Common.apiCommission, [sid, "saveheader", JSON.stringify(obj)], result => {
                setHdrData(JSON.parse(result));
            });
        }
    }

    const viewHeaderDetails = (hdrsrno, location) => {
        setInvoiceData([]);
        setHdrSrno(hdrsrno);
        setHdrDate("dd-mm-yyyy");
        setScrMode("Q");
        setSearchFilter("");
        setNFile("");
        setFilterGstType("1");
        const obj = {
            hdrsrno: hdrsrno,
            location: location,
            state: cmStateValue
        }
        Common.callApi(Common.apiCommission, [sid, "hdrdetails", JSON.stringify(obj)], result => {
            let resp = JSON.parse(result);
            let hdrdata = resp.hdrdata[0];
            setHdrFromDate(hdrdata.hdr_from_date);
            setHdrToDate(hdrdata.hdr_to_date);
            setHdrFromDateDMY(hdrdata.hdr_from_date_dmy);
            setHdrToDateDMY(hdrdata.hdr_to_date_dmy);
            setCommissionData(resp.commdata);
        });
    }
    //#endregion Header




    //#region Detail Work
    const finalDeleteBox = () => {
        setMsgBoxFinal(true);
        setMsgTextFinal("Do you really want to delete the selected records?");
        setFinalMsgSrc("D");
    }

    const finalCheck = () => {
        setMsgBoxFinal(false);
        if (finalMsgSrc === "D") deleteChecked();
        else if (finalMsgSrc === "A") addNewHeader();
    }

    const handleFileChange = (v) => {
        setNFile(v);
    }

    const listCommissionHeader = () => {
        $(".loader").show();
        setBMHeaderData([]);
        setGstData([]);
        const obj = {
            radioType: radioType,
            frmdate: Common.dateYMD(frmDate),
            todate: Common.dateYMD(toDate),
            frmsrno: srnoFrom,
            tosrno: srnoTo,
            type: tranType,
            location: cmLocationValue
        }
        var msg = [], i = 0;
        var frmdateymd = Common.dateYMD(frmDate);
        var todateymd = Common.dateYMD(toDate);
        if (radioType === "S" && (srnoFrom === "" || srnoTo === "")) {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (srnoFrom === null ? "From Srno" : "");
            msg[i++] = (srnoTo === null ? "To Srno" : "");
            setMsgBox(true);
            setMsgText(Common.buildMessageFromArray(msg));
        } else if (radioType === "D" && (frmdateymd < finfromdate || todateymd > fintodate)) {
            $(".loader").hide();
            setMsgBox(true);
            setMsgText(Common.getMessage("MSG0010"));
        } else {
            Common.callApi(Common.apiCommission, [sid, "listheader", JSON.stringify(obj)], result => {
                $(".loader").hide();
                //console.log(result);
                //setCommissionData(JSON.parse(result));
                setHdrData(JSON.parse(result));
            });
        }
    }

    const handleSelectAll = (ischecked) => {
        setSelectAll(ischecked);
        var newArr = [];
        if (ischecked) {
            newArr = commissionData.map(item => {
                // if (item.det_submit_approval === "0")
                return { ...item, 'selected': item.det_submit_approval === "1" || item.det_fa_type === 'NOFA' ? '0' : "1" };
            });
        } else {
            newArr = commissionData.map(item => {
                // if (item.det_submit_approval === "0")
                return { ...item, 'selected': '0' };
            });
        }
        console.log(newArr);
        setCommissionData(newArr);
    }

    const updateCheckChanges = (name, index, e) => {
        let newArr = commissionData.map((item, i) => {
            if (index === i) {
                if (name === "selected") {
                    setSelectAll(false);
                    if (e.target.checked) return { ...item, [name]: '1' }
                    else return { ...item, [name]: '0' }
                }
            } else {
                return item;
            }
        });
        setCommissionData(newArr);
    }

    const deleteChecked = () => {
        $(".loader").show();
        const obj = {
            hdrsrno: hdrSrno,
            location: cmLocationValue,
            radioType: radioType,
            frmdate: Common.dateYMD(frmDate),
            todate: Common.dateYMD(toDate),
            frmsrno: srnoFrom,
            tosrno: srnoTo,
            type: tranType,
            searchFilter: searchFilter.trim(),
            filterStatus: filterStatus,
            filterGstType: filterGstType
        }
        var err = "Please select transactions.";
        var filteredCommission = commissionData.map(item => {
            if (item.selected === "1") {
                err = "";
                if (item.det_status == "A") err = "Not able to delete, transaction with certno " + item.det_cert_no + " already approved.";
                return [item.det_cert_no, item.det_lineno, item.det_location, item.det_srno];
            }
        }).filter(item => item != undefined);

        if (err !== "") {
            $(".loader").hide();
            setMsgBox(true);
            setMsgText(err);
        } else {
            Common.callApi(Common.apiCommission, [sid, "delete", JSON.stringify(filteredCommission), JSON.stringify(obj)], result => {
                $(".loader").hide();
                setCommissionData(JSON.parse(result));
                setSelectAll(false);
            });
        }
    }

    const openSendForApprovalModal = () => {
        $(".loader").show();
        var count = 0;
        for (var i = 0; i < commissionData.length; i++) {
            if (commissionData[i].selected == "1") {
                count++;
                break;
            }
        }
        if (count > 0) {
            setOpenApprovalModal(true);
            $(".loader").show();
            const obj = {
                hdrsrno: hdrSrno,
                location: cmLocationValue,
                commissionData: commissionData,
                searchFilter: searchFilter.trim(),
                filterStatus: filterStatus,
                filterGstType: filterGstType
            }
            Common.callApi(Common.apiCommission, [sid, "getapprovalsummary", JSON.stringify(obj)], result => {
                $(".loader").hide();
                setApprovalSummary(JSON.parse(result));
            });
        } else {
            $(".loader").hide();
            setMsgBox(true);
            setMsgText("Please select one or more agent.");
        }
    }

    const sendForApproval = () => {
        $(".loader").show();
        const obj = {
            hdrsrno: hdrSrno,
            location: cmLocationValue,
            commissionData: commissionData,
            searchFilter: searchFilter.trim(),
            filterStatus: filterStatus,
            filterGstType: filterGstType
        }
        var isOk = false;
        var err = "Please select transactions.";
        for (var i = 0; i < commissionData.length; i++) {
            if (commissionData[i].selected == "1") {
                isOk = true;
                err = "";
            }
            if (commissionData[i].selected == "1" && commissionData[i].det_status != "O") {
                isOk = false;
                err = "Please mark OK to each transaction before sending it to BM.";
                break;
            }
            if (commissionData[i].selected == "1" && commissionData[i].det_status == "O") {
                if ((commissionData[i].det_gst_type === "WGST" || commissionData[i].det_gst_type === "HGST") &&
                    (commissionData[i].det_agent_invoice_num === "" || commissionData[i].det_agent_invoice_date === null)) {
                    isOk = false;
                    err = "Please fill invoice details of transactions with WGST and HGST records.";
                    break;
                }
            }
        }
        if (isOk) {
            Common.callApi(Common.apiCommission, [sid, "sendtoapproval", JSON.stringify(obj)], result => {
                $(".loader").hide();
                //console.log(result);
                let resp = JSON.parse(result);
                setCommissionData(resp.commdata);
                setMsgBox(true);
                setMsgText(resp.msg);
            });
        } else {
            $(".loader").hide();
            setMsgBox(true);
            setMsgText(err);
        }
        setOpenApprovalModal(false);
        handleSelectAll(false);
    }

    const updateStatus = (status, srno, lineno, location, certno, type) => {
        handleSelectAll(false);
        $(".loader").show();
        const obj = {
            status: status,
            trannumber: srno,
            lineno: lineno,
            location: location,
            certno: certno,
            type: type,
            searchFilter: searchFilter.trim(),
            filterStatus: filterStatus,
            paytype: "COMMISSION",
            filterGstType: filterGstType
        }
        Common.callApi(Common.apiCommission, [sid, "updatestatus", JSON.stringify(obj)], result => {
            $(".loader").hide();
            let resp = JSON.parse(result);
            setCommissionData(resp.commissiondata);
        });
    }

    const handleSearchChange = (v) => {
        Common.validateAlpNumSplValue(v, setSearchFilter);
    }

    const searchCommission = (srchtype) => {
        $(".loader").show();
        handleSelectAll(false);
        if (srchtype === "R") {
            setSearchFilter("");
            setFilterGstType("1");
            setFilterStatus("1");
        }
        const obj = {
            hdrsrno: hdrSrno,
            location: cmLocationValue,
            searchFilter: srchtype === "R" ? "" : searchFilter.trim(),
            filterStatus: srchtype === "R" ? "1" : filterStatus,
            filterGstType: srchtype === "R" ? "1" : filterGstType
        }
        Common.callApi(Common.apiCommission, [sid, "filtersearch", JSON.stringify(obj)], result => {
            $(".loader").hide();
            setCommissionData(JSON.parse(result));
        });
    }

    const agentDetailsForm = (agentcode) => {
        setShowAgentForm(true);
        setAgentProps({ agentcode: agentcode, location: cmLocationValue, locationname: hdrLocation, setShowAgentForm: setShowAgentForm });
    }

    const updateAgentDetails = (agentcode) => {
        viewHeaderDetails(hdrSrno, cmLocationValue);
    }

    const summarizeCommission = (v) => {
        $(".loader").show();
        setSummaryStatusFilter(v);
        setShowSummaryModal(true);
        const obj = {
            hdrsrno: hdrSrno,
            location: cmLocationValue,
            listtype: "summary",
            statusfilter: v
        }
        Common.callApi(Common.apiCommission, [sid, "summary", JSON.stringify(obj)], result => {
            $(".loader").hide();
            let res = JSON.parse(result);
            setCommissionSummary(res.data);
            setSummaryCommissionTotal(res.totalcommission);
            setSummaryTDSTotal(res.totaltds);
            setSummaryPayable(res.netcommission);
        });
    }

    const downloadSummary = () => {
        const obj = {
            hdrsrno: hdrSrno,
            location: cmLocationValue,
            listtype: "summary",
            statusfilter: summaryStatusFilter
        }
        Common.callDownloadApiPost(Common.apiCommission, "post", [sid, "summarydownload", JSON.stringify(obj)]);
    }
    //#endregion Detail Word




    //#region Invoice details
    const updateInvoice = () => {
        var count = 0;
        for (var i = 0; i < commissionData.length; i++) {
            if (commissionData[i].selected == "1") {
                count++;
                break;
            }
        }
        if (count < 1) {
            setMsgBox(true);
            setMsgText("Please select one or more agent to update invoice details.");
        } else {
            setShowInvoiceModal(true);
            const obj = {
                hdrsrno: hdrSrno,
                location: cmLocationValue,
                commissionData: commissionData,
                listtype: "invoice",
                searchFilter: searchFilter.trim(),
                filterStatus: filterStatus,
                filterGstType: filterGstType
            }

            Common.callApi(Common.apiCommission, [sid, "invoicedetails", JSON.stringify(obj)], result => {
                let res = JSON.parse(result);
                setInvoiceData(res);
            });
        }
    }

    const updateInvoiceValues = (val, index, name) => {
        let newArr = invoiceData.map((item, i) => {
            if (i === index) {
                if (name === "invoicenum") {
                    return { ...item, [name]: val }
                } else if (name === "invoicedate") {
                    return { ...item, [name]: val }
                }
            } else {
                return item;
            }
        });
        //console.log(newArr);
        setInvoiceData(newArr);
    }

    const finalUpdateInvoice = () => {
        $(".loader").show();
        var isOk = true;
        if (isOk) {
            const obj = {
                invoiceData: invoiceData,
                hdrsrno: hdrSrno,
                location: cmLocationValue,
                searchFilter: searchFilter.trim(),
                filterStatus: filterStatus,
                filterGstType: filterGstType
            }
            Common.callApi(Common.apiCommission, [sid, "updateinvoice", JSON.stringify(obj)], result => {
                $(".loader").hide();
                let resp = JSON.parse(result);
                handleSelectAll(false);
                setShowInvoiceModal(false);
                setMsgBox(true);
                setMsgText("Invoice details updated successfully.");
                setCommissionData(resp.data);
            });
        } else {
            $(".loader").hide();
            handleSelectAll(false);
            setShowInvoiceModal(false);
            setMsgBox(true);
            setMsgText("Please select one or more agent to update invoice details.");
        }
    }
    //#endregion Invoice details




    //#region FA Download
    const downloadFAModal = (fatype) => {
        setOpenFaModal(true);
        setFaType(fatype);
    }

    const finalDownloadFa = () => {
        const obj = {
            fatype: faType,
            faDownloadType: faDownloadType,
            location: cmLocationValue,
            hdrsrno: hdrSrno
        }
        Common.callDownloadApiPost(Common.apiCommission, "post", [sid, "downloadfa", JSON.stringify(obj)]);
        setOpenFaModal(false);
    }
    //#endregion FA Download




    //#region Hold GST
    const listHoldHGST = () => {
        $(".loader").show();
        setBMHeaderData([]);
        setHdrData([]);
        const obj = {
            location: cmLocationValue
        }
        Common.callApi(Common.apiCommission, [sid, "listgst", JSON.stringify(obj)], result => {
            $(".loader").hide();
            setGstData(JSON.parse(result));
        });
    }

    const viewHoldGstDetails = (agentcode, srno, location, agentname, sgst, cgst, igst, gstin, invoicenum, invoicedate) => {
        $(".loader").show();
        setHdrSrno(srno);
        setScrMode("GST");
        const obj = {
            agentcode: agentcode,
            hdrsrno: srno,
            location: location,
            holdgstlist: true
        }
        Common.callApi(Common.apiCommission, [sid, "viewholdgst", JSON.stringify(obj)], result => {
            $(".loader").hide();
            var resp = JSON.parse(result);
            setHoldGstData(resp);
        });
        setPropObject({
            hdrsrno: srno,
            setScrMode: setScrMode,
            setHoldGstData: setHoldGstData,
            gstStatus: gstStatus,
            agentcode: agentcode,
            agentname: agentname,
            location: hdrLocation,
            cgst: cgst,
            sgst: sgst,
            igst: igst,
            setMsgBox: setMsgBox,
            setMsgText: setMsgText,
            cmLocationValue: cmLocationValue,
            gstin: gstin,
            invoicenum: invoicenum,
            invoicedate: invoicedate
        });
    }
    //#endregion Hold GST
    //#endregion AM Work****************************************************



    // #region BM/ZM Work****************************************************
    const bmHdrList = (paytype, approvaltype) => {
        setGstData([]);
        setHdrData([]);
        setPaymentType(paytype);
        setApprovalType(approvaltype);
        handleSelectAllBM(false);
        $(".loader").show();
        const obj = {
            location: cmLocationValue,
            paytype: paytype,
            approvalType: approvaltype,
            frmApprovalDate: Common.dateYMD(frmApprovalDate),
            toApprovalDate: Common.dateYMD(toApprovalDate),
            approvalStatusFilter: approvalStatusFilter
        }
        Common.callApi(Common.apiCommission, [sid, "bmlist", JSON.stringify(obj)], result => {
            $(".loader").hide();
            setHdrData([]);
            setBMHeaderData(JSON.parse(result));
        });
    }

    const viewBMHdrDetails = (app_lineno, status, totalcommission, tds, payable, app_account_name, app_agent_code, app_agent_name, app_account_no, app_ifsc, app_gst_type, app_fa_type, app_igst, app_sgst, app_cgst, app_srno, app_location, gst_status, gstin, invoicenum, invoicedate) => {
        $(".loader").show();
        setGstStatus(gst_status);
        setHdrSrno(app_srno);
        setBmLineNo(app_lineno);
        setScrMode("BmQ");
        setBMStatus(status);
        setBmAgentCode(app_agent_code);
        setBmAppTotalCommission(totalcommission);
        setBmAppTotalTds(tds);
        setBmAppTotalPayable(payable);
        setBmAppAccName(app_account_name);
        setBmAppAgent(app_agent_code + ' ' + app_agent_name);
        setBmAccount(app_account_no);
        setBmIfsc(app_ifsc);
        setBmGSTType(app_gst_type);
        setBmFaType(app_fa_type);
        setBmGST(app_igst * 1 + app_cgst * 1 + app_sgst * 1);
        setHdrGstin(gstin);
        setBmInvoiceNum(invoicenum);
        setBmInvoiceDate(invoicedate);
        const obj = {
            hdrsrno: app_srno,
            location: app_location,
            agencode: app_agent_code,
            lineno: app_lineno,
            isbmlist: true,
            paytype: paymentType
        }
        Common.callApi(Common.apiCommission, [sid, "filtersearch", JSON.stringify(obj)], result => {
            $(".loader").hide();
            setBMDetailData(JSON.parse(result));
        });
    }

    const updateTransactionBMZM = (updatetype, allOne) => {
        $(".loader").show();
        // const hdrdetails = BMHeaderData.filter((item) => item.selective * 1 > 0);
        // console.log(hdrdetails);
        const obj = {
            hdrsrno: hdrSrno,
            location: cmLocationValue,
            updatetype: updatetype,
            bmLineNo: bmLineNo,
            bmAgentCode: bmAgentCode,
            paytype: paymentType,
            approvalType: approvalType,
            frmApprovalDate: Common.dateYMD(frmApprovalDate),
            toApprovalDate: Common.dateYMD(toApprovalDate),
            approvalStatusFilter: approvalStatusFilter,
            BMHeaderData: BMHeaderData,
            allOne: allOne
        }
        var isOk = true;
        if (allOne === 'A') {
            isOk = false;
            for (var i = 0; i < BMHeaderData.length; i++) {
                if (BMHeaderData[i].selected === "1") {
                    isOk = true;
                    break;
                }
            }
        }
        if (isOk) {
            Common.callApi(Common.apiCommission, [sid, "updatepaymentbm", JSON.stringify(obj)], result => {
                $(".loader").hide();
                let resp = JSON.parse(result);
                if (resp.msg == "1") {
                    setScrMode("");
                    setBMHeaderData(resp.bmapprlist);
                }
            });
        } else {
            $(".loader").hide();
            setMsgBox(true);
            setMsgText("Please select transactions.");
        }
        handleSelectAllBM(false);
    }

    const handleSelectAllBM = (ischecked) => {
        setSelectAllBM(ischecked);
        var newArr = [];
        if (ischecked) {
            newArr = BMHeaderData.map(item => {
                // if (item.det_submit_approval === "0")
                if (approvalType === "BM")
                    return { ...item, 'selected': item.app_bm_approved === "1" ? '0' : "1" };
                else
                    return { ...item, 'selected': item.app_zm_approved === "1" ? '0' : "1" };
            });
        } else {
            newArr = BMHeaderData.map(item => {
                // if (item.det_submit_approval === "0")
                return { ...item, 'selected': '0' };
            });
        }
        console.log(newArr);
        setBMHeaderData(newArr);
    }

    const updateCheckChangesBM = (name, index, e) => {
        let newArr = BMHeaderData.map((item, i) => {
            if (index === i) {
                if (name === "selected") {
                    setSelectAllBM(false);
                    if (e.target.checked) return { ...item, [name]: '1' }
                    else return { ...item, [name]: '0' }
                }
            } else {
                return item;
            }
        });
        setBMHeaderData(newArr);
    }
    // #endregion BM/ZM Work****************************************************



    return (
        <>
            {
                showAgentForm ?
                    <Master_agent_linking cmmodule={agentProps} updateAgentDetails={updateAgentDetails} />
                    :
                    <>
                        <Login_header />
                        <Menu />
                        <Container fluid>
                            <Row>
                                <Col>
                                    <h2>Commission Module</h2>
                                    {/* <span>{counter}</span> */}
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            {
                                scrMode === "" ?
                                    <>
                                        <Row>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Location<span className="colorThemeRed">*</span></Form.Label>
                                                    <AsyncSelect
                                                        cacheOptions={false}
                                                        defaultOptions={false}
                                                        value={cmLocation}
                                                        getOptionLabel={e => e.label + ' '}
                                                        getOptionValue={e => e.id}
                                                        loadOptions={Picklist.fetchLocationAllowedPicklist}
                                                        onInputChange={Picklist.handleInputChange}
                                                        onChange={handleLocationChange}
                                                        isDisabled={branchDisable === "true"}
                                                    >
                                                    </AsyncSelect>
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-4 col-6'>
                                                <Form.Group>
                                                    <Row style={{ marginTop: "30px" }}>
                                                        {
                                                            userRight.query === "1" &&
                                                            <>
                                                                {
                                                                    userRight.add === "1" &&
                                                                    <Col>
                                                                        <Form.Check checked={radioType === "N"} onChange={() => setRadioType("N")} type='radio' label="New" />
                                                                    </Col>
                                                                }
                                                                <Col>
                                                                    <Form.Check checked={radioType === "S"} onChange={() => setRadioType("S")} type='radio' label="SrNo" />
                                                                </Col>
                                                                <Col>
                                                                    <Form.Check checked={radioType === "D"} onChange={() => setRadioType("D")} type='radio' label="Date" />
                                                                </Col>
                                                                {
                                                                    (gstApprovalRight.query === "1" || gstApproval2Right.query === "1") ?
                                                                        <Col>
                                                                            <Form.Check checked={radioType === "H"} onChange={() => setRadioType("H")} type='radio' label="Hold GST" />
                                                                        </Col>
                                                                        : null
                                                                }

                                                                {
                                                                    (bmApprovalRight.query === "1" || zmApprovalRight.query === "1") ?
                                                                        <Col>
                                                                            <Form.Check checked={radioType === "A"} onChange={() => setRadioType("A")} type='radio' label="Approve" />
                                                                        </Col>
                                                                        : null
                                                                }
                                                            </>
                                                        }
                                                    </Row>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            {
                                                radioType === "N" || radioType === "D" || radioType === "T" ?
                                                    <>
                                                        <Col className='col-md-3 col-6'>
                                                            <Form.Group>
                                                                <Form.Label>From Date</Form.Label>
                                                                <DatePicker className="form-control"
                                                                    selected={frmDate}
                                                                    onChange={(date) => setFrmDate(date)}
                                                                    dateFormat="dd/MM/yyyy"
                                                                    showYearDropdown
                                                                    showMonthDropdown
                                                                    useShortMonthInDropdown
                                                                    dropdownMode="select"
                                                                    peekNextMonth
                                                                    customInput={
                                                                        <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col className='col-md-3 col-6'>
                                                            <Form.Group>
                                                                <Form.Label>To Date</Form.Label>
                                                                <DatePicker className="form-control"
                                                                    selected={toDate}
                                                                    onChange={(date) => setToDate(date)}
                                                                    dateFormat="dd/MM/yyyy"
                                                                    showYearDropdown
                                                                    showMonthDropdown
                                                                    useShortMonthInDropdown
                                                                    dropdownMode="select"
                                                                    peekNextMonth
                                                                    customInput={
                                                                        <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </>
                                                    : radioType === "S" ?
                                                        <>
                                                            <Col className='col-md-3 col-6'>
                                                                <Form.Group>
                                                                    <Form.Label>From Srno<span className="colorThemeRed">*</span></Form.Label>
                                                                    <Form.Control type='text' maxLength={10} value={srnoFrom} onChange={e => Common.validateNumValue(e.target.value, setSrnoFrom)} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className='col-md-3 col-6'>
                                                                <Form.Group>
                                                                    <Form.Label>To srno<span className="colorThemeRed">*</span></Form.Label>
                                                                    <Form.Control type='text' maxLength={10} value={srnoTo} onChange={e => Common.validateNumValue(e.target.value, setSrnoTo)} />
                                                                </Form.Group>
                                                            </Col>
                                                        </>
                                                        : radioType === "A" ?
                                                            <>
                                                                <Col className='col-md-3 col-6'>
                                                                    <Form.Group>
                                                                        <Form.Label>Status</Form.Label>
                                                                        <Form.Select value={approvalStatusFilter} onChange={e => setApprovalStatusFilter(e.target.value)}>
                                                                            <option value="P">Pending BM</option>
                                                                            <option value="B">Pending ZM</option>
                                                                            <option value="A">Approved</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                </Col>
                                                                {
                                                                    approvalStatusFilter === "A" &&
                                                                    <>
                                                                        <Col className='col-md-3 col-6'>
                                                                            <Form.Group>
                                                                                <Form.Label>From Date</Form.Label>
                                                                                <DatePicker className="form-control"
                                                                                    selected={frmApprovalDate}
                                                                                    onChange={(date) => setFrmApprovalDate(date)}
                                                                                    dateFormat="dd/MM/yyyy"
                                                                                    showYearDropdown
                                                                                    showMonthDropdown
                                                                                    useShortMonthInDropdown
                                                                                    dropdownMode="select"
                                                                                    peekNextMonth
                                                                                    customInput={
                                                                                        <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                                    }
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col className='col-md-3 col-6'>
                                                                            <Form.Group>
                                                                                <Form.Label>To Date</Form.Label>
                                                                                <DatePicker className="form-control"
                                                                                    selected={toApprovalDate}
                                                                                    onChange={(date) => setToApprovalDate(date)}
                                                                                    dateFormat="dd/MM/yyyy"
                                                                                    showYearDropdown
                                                                                    showMonthDropdown
                                                                                    useShortMonthInDropdown
                                                                                    dropdownMode="select"
                                                                                    peekNextMonth
                                                                                    customInput={
                                                                                        <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                                    }
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </>
                                                                }
                                                            </>
                                                            : null
                                            }
                                        </Row>
                                        <Row>&nbsp;</Row>
                                        <Row>
                                            <Col className='display-flex'>
                                                <>
                                                    {
                                                        userRight.query === "1" && radioType !== "N" && radioType !== "H" && radioType !== "A" && radioType !== "F" ?
                                                            <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => listCommissionHeader()}>List</Button> : null
                                                    }
                                                    &nbsp;
                                                    {
                                                        userRight.add === "1" && radioType === "N" ?
                                                            <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => headerSaveCheck()}>Add New</Button>
                                                            : null
                                                    }
                                                    &nbsp;
                                                    {
                                                        gstApprovalRight.query === "1" && radioType === "H" ?
                                                            <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => listHoldHGST()}>List Hold GST</Button>
                                                            : null
                                                    }
                                                    {
                                                        gstApproval2Right.query === "1" && radioType === "H" ?
                                                            <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => bmHdrList("GST", "ZM")}>Approve GST</Button>
                                                            : null
                                                    }
                                                    {
                                                        zmApprovalRight.query === "1" && radioType === "A" &&
                                                        <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => bmHdrList("COMMISSION", "ZM")}>ZM Approval List</Button>
                                                    }
                                                    {
                                                        bmApprovalRight.query === "1" && radioType === "A" &&
                                                        <>&nbsp;
                                                            <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => bmHdrList("COMMISSION", "BM")}>BM Approval List</Button>
                                                        </>
                                                    }
                                                    {
                                                        (bmApprovalRight.query === "1" || zmApprovalRight.query === "1") && radioType === "A" &&
                                                        <>&nbsp;
                                                            <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => updateTransactionBMZM("A", "A")}>All Approve</Button>
                                                        </>
                                                    }
                                                </>
                                            </Col>
                                        </Row>
                                        <Row>&nbsp;</Row>
                                        <Row>
                                            {
                                                (bmApprovalRight.query === "1" || zmApprovalRight.query === "1" || gstApproval2Right.query === "1") && BMHeaderData.length > 0
                                                &&
                                                < Col >
                                                    <Table responsive striped bordered>
                                                        <thead>
                                                            <tr>
                                                                <th>&nbsp;</th>
                                                                <th>
                                                                    <div style={{ display: (approvalType === "ZM" && paymentType == "GST") && "none" }}>
                                                                        <span>All&nbsp;</span>
                                                                        <span><Form.Check type="checkbox" checked={selectAllBM} onChange={e => handleSelectAllBM(e.target.checked)} /></span>
                                                                    </div>
                                                                </th>
                                                                {/* <th>Srno</th> */}
                                                                <th>Location</th>
                                                                <th>Agent</th>
                                                                <th>Account Name</th>
                                                                <th>Account Number</th>
                                                                <th>IFSC</th>
                                                                {
                                                                    paymentType === "COMMISSION" ?
                                                                        <>
                                                                            <th>Pur Commission</th>
                                                                            <th>Sale Commission</th>
                                                                            <th>Net Margin</th>
                                                                            <th>Total Payable</th>
                                                                        </> :
                                                                        <>
                                                                            <th>Invoice Num</th>
                                                                            <th>Invoice Date</th>
                                                                            <th>GstIN</th>
                                                                            <th>SGST</th>
                                                                            <th>IGST</th>
                                                                            <th>CGST</th>
                                                                            <th>Total GST</th>
                                                                        </>
                                                                }
                                                                <th>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                BMHeaderData.map((item, index) => (
                                                                    <tr>
                                                                        <td>
                                                                            <span onClick={() => viewBMHdrDetails(item.app_lineno, item.app_status
                                                                                , item.app_total_commission, item.app_tds, item.app_payable
                                                                                , item.app_account_name, item.app_agent_code, item.app_agent_name
                                                                                , item.app_account_no, item.app_ifsc, item.app_gst_type, item.app_fa_type
                                                                                , item.app_igst, item.app_sgst, item.app_cgst, item.app_srno, item.app_location
                                                                                , item.app_hgst_status, item.app_gstin, item.app_agent_invoice_num, item.app_agent_invoice_date
                                                                            )}
                                                                                className='handCursor colorThemeBlue'
                                                                            >
                                                                                <FontAwesomeIcon icon={faEye} />
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <span style={{
                                                                                display: ((item.approval_type === "BM" && item.app_bm_approved == "1") ||
                                                                                    (item.approval_type === "ZM" && item.app_zm_approved == "1") ||
                                                                                    (approvalType === "ZM" && paymentType == "GST")) ? "none" : "block"
                                                                            }}>
                                                                                <Form.Check type="checkbox" checked={selectAllBM || item.selected === "1" && "checked"} onClick={(e) => updateCheckChangesBM("selected", index, e)} />
                                                                            </span>
                                                                        </td>
                                                                        {/* <td>{item.app_srno}</td> */}
                                                                        <td>{item.entity_name}</td>
                                                                        <td>{item.app_agent_code + " " + item.app_agent_name}</td>
                                                                        <td>{item.app_account_name}</td>
                                                                        <td>{item.app_account_no}</td>
                                                                        <td>{item.app_ifsc}</td>
                                                                        {
                                                                            paymentType === "COMMISSION" ?
                                                                                <>
                                                                                    <td>{item.app_commission_purchase}</td>
                                                                                    <td>{item.app_commission_sale}</td>
                                                                                    <td>{item.app_total_margin}</td>
                                                                                    <td>{item.app_payable}</td>
                                                                                    <td>{item.app_status == "A" ? "Approved" : item.app_status == "B" ? "Pending ZM" : "Pending BM"}</td>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <td>{item.app_agent_invoice_num}</td>
                                                                                    <td>{item.app_agent_invoice_date}</td>
                                                                                    <td>{item.app_gstin}</td>
                                                                                    <td>{item.app_sgst}</td>
                                                                                    <td>{item.app_igst}</td>
                                                                                    <td>{item.app_cgst}</td>
                                                                                    <td>{1 * item.app_sgst + 1 * item.app_cgst + 1 * item.app_igst + item.app_ugst * 1}</td>
                                                                                    <td>{item.app_hgst_status == "P" ? "Pending" : "Approved"}</td>
                                                                                </>
                                                                        }

                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            }
                                            {
                                                userRight.query === "1" && hdrData.length > 0
                                                &&
                                                <Col>
                                                    <Table responsive striped bordered>
                                                        <thead>
                                                            <tr>
                                                                <th>&nbsp;</th>
                                                                <th>Srno</th>
                                                                <th>From Date</th>
                                                                <th>From To</th>
                                                                {/* <th>Status</th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                hdrData.map(item => (
                                                                    <tr>
                                                                        <td>
                                                                            {
                                                                                userRight.edit === "1" &&
                                                                                <span onClick={() => viewHeaderDetails(item.hdr_srno, item.hdr_location)} className='handCursor colorThemeBlue'>
                                                                                    <FontAwesomeIcon icon={faEdit} />
                                                                                </span>
                                                                            }
                                                                        </td>
                                                                        <td>{item.hdr_srno}</td>
                                                                        <td>{item.hdr_from_date}</td>
                                                                        <td>{item.hdr_to_date}</td>
                                                                        {/* <td>{item.hdr_status}</td> */}
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            }
                                            {
                                                gstApprovalRight.query === "1" && gstData.length > 0
                                                &&
                                                <Col>
                                                    <Table responsive striped bordered>
                                                        <thead>
                                                            <tr>
                                                                <th>&nbsp;</th>
                                                                <th>Agent Code</th>
                                                                <th>Agent Name</th>
                                                                <th>Invoice Num</th>
                                                                <th>Invoice Date</th>
                                                                <th>GstIn</th>
                                                                <th>SGST</th>
                                                                <th>CGST</th>
                                                                <th>IGST</th>
                                                                <th>UGST</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                gstData.map(item => (
                                                                    <tr>
                                                                        <td>
                                                                            <span onClick={() => viewHoldGstDetails(item.det_agent_code, item.det_srno, item.det_location, item.det_agent_name, item.sgst, item.cgst, item.igst, item.det_gstin, item.det_agent_invoice_num, item.det_agent_invoice_date)} className='handCursor colorThemeBlue'><FontAwesomeIcon icon={faEye} /></span>
                                                                        </td>
                                                                        <td>{item.det_agent_code}</td>
                                                                        <td>{item.det_agent_name}</td>
                                                                        <td>{item.det_agent_invoice_num}</td>
                                                                        <td>{item.det_agent_invoice_date}</td>
                                                                        <td>{item.det_gstin}</td>
                                                                        <td>{item.sgst}</td>
                                                                        <td>{item.cgst}</td>
                                                                        <td>{item.igst}</td>
                                                                        <td>{item.ugst}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            }
                                        </Row>
                                    </>
                                    //----------------- AM View----------------
                                    : scrMode === "Q" ?
                                        <>
                                            <Row>
                                                <Col className="col-md-2 col-4">
                                                    <span>Sr No : <b>{hdrSrno} </b></span>
                                                </Col>

                                                <Col className="col-md-2 col-4">
                                                    <span>Location : <b>{hdrLocation} </b></span>
                                                </Col>
                                                <Col className="col-md-2 col-4">
                                                    <span>From Date : <b>{hdrFromDateDMY}</b></span>
                                                </Col>
                                                <Col className="col-md-2 col-4">
                                                    <span>To Date : <b>{hdrToDateDMY}</b></span>
                                                </Col>
                                            </Row>
                                            <Row>&nbsp;</Row>
                                            <Row>
                                                <Col className='col-md-6'>
                                                    <Button variant='outline-danger' size='sm' className='buttonStyle' onClick={() => setScrMode("")}>Back</Button>
                                                    &nbsp;
                                                    <Button variant='outline-danger' size='sm' className='buttonStyle' onClick={() => finalDeleteBox()}>Delete Checked</Button>
                                                    &nbsp;
                                                    <Button variant='outline-primary' size='sm' className='buttonStyle' onClick={() => openSendForApprovalModal()}>Send to Approval</Button>
                                                    &nbsp;
                                                    <Button variant='outline-primary' size='sm' className='buttonStyle' onClick={() => updateInvoice()}>Update Invoice</Button>
                                                    &nbsp;
                                                </Col>
                                            </Row>
                                            <Row>&nbsp;</Row>
                                            <Row>
                                                <Col>
                                                    {
                                                        (userRight.query === "1" && (scrMode === 'E' || scrMode === 'Q')) ?
                                                            <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                                btnActivityLog("AM")}>Activity Log</Button>
                                                            : null
                                                    }
                                                    <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => downloadFAModal("COMMISSION")}>Download Commission FA</Button>
                                                    &nbsp;
                                                    <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => downloadFAModal("GST")}>Download GST FA</Button>
                                                </Col>
                                            </Row>

                                            <Row>&nbsp;</Row>
                                            <hr />
                                            <Row>
                                                <Col className='col-md-4 col-6'>
                                                    <Form.Group>
                                                        <Row style={{ marginTop: "30px" }}>
                                                            <Col style={{ display: "flex" }}>
                                                                <Form.Check checked={childRadioType === "U"} onChange={() => setChildRadioType("U")} type='radio' label="Upload" />
                                                                &nbsp;
                                                                <Form.Check checked={childRadioType === "F"} onChange={() => setChildRadioType("F")} type='radio' label="Filter" />
                                                                &nbsp;
                                                                <span>
                                                                    <Badge bg="secondary" className="handCursor" onClick={() => summarizeCommission("O")}>Summary</Badge>
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            {
                                                childRadioType === "U" ?
                                                    <>
                                                        <Row>
                                                            <Col className='col-md-3 col-6'>
                                                                <Form.Group>
                                                                    <Form.Label>Type&nbsp;
                                                                        {reportType === "OC" && <Badge bg="primary" className="handCursor" onClick={downloadDirectExpenseFormat}>Format</Badge>}
                                                                    </Form.Label>
                                                                    <Form.Select value={reportType} onChange={e => setReportType(e.target.value)}>
                                                                        <option value="TW">Transaction Wise</option>
                                                                        <option value="CW">Commission Wise</option>
                                                                        <option value="OC">Other Commission</option>
                                                                    </Form.Select>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className='col-md-3 col-6'>
                                                                <Form.Group controlId='uploadCommission'>
                                                                    <Form.Label>File* (MAX 10MB)</Form.Label>
                                                                    <Form.Control type='file' value={nFile} onChange={e => handleFileChange(e.target.value)} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className='col-md-3 col-6'>
                                                                <Button style={{ marginTop: "33px" }} variant='outline-primary' className='buttonStyle' size='sm' onClick={() => uploadFile()}>Upload</Button>
                                                            </Col>
                                                        </Row>
                                                        <Row style={{ fontSize: "13px" }}>Please upload transaction wise report before commission report.
                                                        </Row>
                                                    </>
                                                    :
                                                    <Row>
                                                        <Col className='col-md-3 col-6'>
                                                            <Form.Group>
                                                                <Form.Label>Search..<span className="handCursor colorThemeBlue" title="Help"
                                                                    onClick={(e) => showSearchOptions()}><FontAwesomeIcon icon={faInfoCircle} />
                                                                </span>
                                                                </Form.Label>
                                                                <Form.Control placeholder='Search' type='text' value={searchFilter} onChange={e => handleSearchChange(e.target.value)} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col className='col-md-3 col-6'>
                                                            <Form.Group>
                                                                <Form.Label>Status</Form.Label>
                                                                <Form.Select value={filterStatus} onChange={e => setFilterStatus(e.target.value)}>
                                                                    <option value="1">All</option>
                                                                    <option value="P">Pending</option>
                                                                    <option value="O">Ok</option>
                                                                    <option value="A">Approve</option>
                                                                    <option value="R">Rejected</option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col className='col-md-3 col-6'>
                                                            <Form.Group>
                                                                <Form.Label>Gst Type</Form.Label>
                                                                <Form.Select value={filterGstType} onChange={e => setFilterGstType(e.target.value)}>
                                                                    <option value="1">All</option>
                                                                    <option value="WOGST">Without GST</option>
                                                                    <option value="HGST">Hold GST</option>
                                                                    <option value="WGST">With GST</option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col className='col-md-3 col-6'>
                                                            <Button style={{ marginTop: "33px" }} variant='outline-primary' size='sm' className='buttonStyle' onClick={() => searchCommission("S")}>Search</Button>
                                                            &nbsp;
                                                            <Button style={{ marginTop: "33px" }} variant='outline-success' size='sm' className='buttonStyle' onClick={() => searchCommission("R")}>Reset</Button>
                                                        </Col>
                                                    </Row>
                                            }
                                            <Row>&nbsp;</Row>
                                            <Row>
                                                <Col>
                                                    <Table responsive striped bordered>
                                                        <thead>
                                                            <tr>
                                                                <th className='commissionCheck'>
                                                                    <span>All&nbsp;</span>
                                                                    <span><Form.Check type="checkbox" checked={selectAll} onChange={e => handleSelectAll(e.target.checked)} /></span></th>
                                                                <th>Action</th>
                                                                <th>Status</th>
                                                                <th>Agent</th>
                                                                <th>Gst Type</th>
                                                                <th>Fa Type</th>
                                                                {
                                                                    flag &&
                                                                    <>
                                                                        <th>Invoice Number</th>
                                                                        <th>Invoice Date</th>
                                                                    </>
                                                                }
                                                                <th>Cert No</th>
                                                                {/*<th>Client Name</th>
                                                                <th>Isd</th> */}
                                                                <th>Tran Date</th>
                                                                <th>Net Margin</th>
                                                                <th>Commission</th>
                                                                <th>IGST</th>
                                                                <th>CGST</th>
                                                                <th>SGST</th>
                                                                <th>TDS</th>
                                                                <th>Total Payable</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                commissionData.map((item, index) => (
                                                                    <>
                                                                        <tr key={item.det_cert_no}>
                                                                            <td>
                                                                                <div style={{ display: item.det_submit_approval == "1" || item.det_fa_type === "NOFA" ? "none" : "block" }}>
                                                                                    <div style={{ display: "flex" }}>
                                                                                        <span>
                                                                                            <Form.Check type="checkbox" checked={selectAll || item.selected === "1" && "checked"} onClick={(e) => updateCheckChanges("selected", index, e)} />
                                                                                        </span>&nbsp;
                                                                                        {
                                                                                            item.det_agent_name === "" &&
                                                                                            <span title='Add Agent Details' className='handCursor colorThemeBlue' onClick={() => agentDetailsForm(item.det_agent_code)}>
                                                                                                <FontAwesomeIcon icon={faEdit} />
                                                                                            </span>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className='commissionCheck'>
                                                                                {
                                                                                    item.det_submit_approval == "1" || item.det_fa_type === "NOFA" ? null
                                                                                        :
                                                                                        <>
                                                                                            <span className='handCursor' title="Approve" style={{ color: "green" }}>
                                                                                                <FontAwesomeIcon icon={faCircleCheck} onClick={() => updateStatus("O", item.det_srno, item.det_lineno, item.det_location, item.det_cert_no, item.det_type)} />
                                                                                            </span>
                                                                                            {/* &nbsp;&nbsp;
                                                                <span className='handCursor' style={{ color: "red" }} title="Reject">
                                                                    <FontAwesomeIcon icon={faCircleXmark} onClick={() => updateStatus("R", item.det_srno, item.det_lineno, item.det_location, item.det_cert_no)} />
                                                                </span> */}
                                                                                            &nbsp;&nbsp;
                                                                                            <span className='handCursor colorThemeBlue' title="Hold">
                                                                                                <FontAwesomeIcon icon={faCircleExclamation} onClick={() => updateStatus("H", item.det_srno, item.det_lineno, item.det_location, item.det_cert_no, item.det_type)} />
                                                                                            </span>
                                                                                        </>
                                                                                }
                                                                            </td>
                                                                            <td>{item.det_status === "P" ? "Pending" :
                                                                                item.det_status === "R" ? "Rejected" :
                                                                                    item.det_status === "A" ? "Approve" :
                                                                                        item.det_status === "H" ? "Hold" :
                                                                                            item.det_status === "O" ? "Ok" : ""}</td>
                                                                            <td style={{ backgroundColor: (1 * item.det_commission_total - Math.round(item.det_commission_total * 0.05)) < 0 && "red" }}>
                                                                                {item.det_agent_code + ' ' + item.det_agent_name}
                                                                            </td>
                                                                            <td>{item.det_gst_type}</td>
                                                                            <td>{item.det_fa_type}</td>
                                                                            {flag &&
                                                                                <>
                                                                                    <td>{item.det_agent_invoice_num}</td>
                                                                                    <td>{item.det_agent_invoice_date}</td>
                                                                                </>
                                                                            }
                                                                            {
                                                                                item.det_cert_no == "0" ?
                                                                                    <td>{item.det_key}</td>
                                                                                    : <td>{item.det_type + " " + item.det_cert_no}</td>
                                                                            }
                                                                            {/* <td>{item.det_client_name}</td> */}
                                                                            {/* <td>{item.det_isd}</td> */}
                                                                            <td>{item.det_tran_date}</td>
                                                                            <td>{item.det_total_margins}</td>
                                                                            <td>{item.det_commission_total}</td>
                                                                            <td>{item.det_igst}</td>
                                                                            <td>{item.det_cgst}</td>
                                                                            <td>{item.det_sgst}</td>
                                                                            <td>{item.det_tds}</td>
                                                                            <td>{item.det_commission_net}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan={17}>
                                                                                Account No: <b>{item.det_account_no},</b>
                                                                                &nbsp;
                                                                                IFSC: <b>{item.det_ifsc},</b>
                                                                                &nbsp;
                                                                                Account Name: <b>{item.det_account_name}</b>
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                        </>
                                        //-------------- BM detail View--------------------
                                        : scrMode === "BmQ" ?
                                            <>
                                                <Row>
                                                    <Col className="col-md-2 col-4">
                                                        <span>Location : <b>{hdrLocation} </b></span>
                                                    </Col>
                                                    <Col className="col-md-2 col-4">
                                                        {
                                                            paymentType === "COMMISSION" ?
                                                                <span>Status : <b>{bmStatus === "A" ? "Approved" : bmStatus === "B" ? "Pending ZM" : "Pending BM"}</b></span>
                                                                :
                                                                <span>Status : <b>{gstStatus === "A" ? "Approved" : "Pending"}</b></span>
                                                        }
                                                    </Col>
                                                    <Col className="col-md-4 col-4">
                                                        <span>Agent : <b>{bmAppAgent}</b></span>
                                                    </Col>
                                                    <Col className="col-md-2 col-4">
                                                        <span>Account No : <b>{bmAccount}</b></span>
                                                    </Col>
                                                    <Col className="col-md-2 col-4">
                                                        <span>IFSC : <b>{bmIfsc}</b></span>
                                                    </Col>
                                                </Row>
                                                <Row>&nbsp;</Row>
                                                {
                                                    paymentType === "COMMISSION" ?
                                                        <Row>
                                                            <Col className="col-md-2 col-4">
                                                                <span>GST Type : <b>{bmGSTType}</b></span>
                                                            </Col>
                                                            <Col className="col-md-2 col-4">
                                                                <span>FA Type : <b>{bmFaType}</b></span>
                                                            </Col>
                                                            <Col className="col-md-2 col-4">
                                                                <span>Total Commission : <b>{bmAppTotalCommission}</b></span>
                                                            </Col>
                                                            <Col className="col-md-2 col-4">
                                                                <span>GST : <b>{bmGST}</b></span>
                                                            </Col>
                                                            <Col className="col-md-2 col-4">
                                                                <span>TDS : <b>{bmAppTotalTds}</b></span>
                                                            </Col>
                                                            <Col className="col-md-2 col-4">
                                                                <span>Net Payable : <b>{bmAppTotalPayable}</b></span>
                                                            </Col>
                                                        </Row>
                                                        :
                                                        <Row>
                                                            <Col className="col-md-2 col-4">
                                                                <span>Invoice Number : <b>{bmInvoiceNum}</b></span>
                                                            </Col>
                                                            <Col className="col-md-2 col-4">
                                                                <span>Invoice Date : <b>{bmInvoiceDate}</b></span>
                                                            </Col>
                                                            <Col className="col-md-2 col-4">
                                                                <span>GstIN : <b>{hdrGstin}</b></span>
                                                            </Col>
                                                            <Col className="col-md-2 col-4">
                                                                <span>Total GST : <b>{bmGST}</b></span>
                                                            </Col>
                                                        </Row>

                                                }
                                                <Row>&nbsp;</Row>
                                                <Row>
                                                    <Col className='col-md-6'>
                                                        <Button variant='outline-danger' size='sm' className='buttonStyle' onClick={() => setScrMode("")}>Back</Button>
                                                        &nbsp;
                                                        {
                                                            (paymentType === "COMMISSION" && ((approvalType === "BM" && bmStatus === "B") || (bmStatus === "A")))
                                                                || (paymentType === "GST" && gstStatus === "A") ? null
                                                                :
                                                                <>
                                                                    <Button variant='outline-primary' size='sm' className='buttonStyle' onClick={() => updateTransactionBMZM("A", "O")}>Approve</Button>
                                                                    &nbsp;
                                                                    <Button variant='outline-primary' size='sm' className='buttonStyle' onClick={() => updateTransactionBMZM("R", "O")}>Reject</Button>
                                                                </>
                                                        }
                                                        {
                                                            bmApprovalRight.query === "1" ? <>
                                                                &nbsp;
                                                                <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                                    btnActivityLog("BM")}>Activity Log</Button>&nbsp;
                                                            </> : null
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row>&nbsp;</Row>
                                                <Row>
                                                    <Col>
                                                        <Table responsive striped bordered>
                                                            <thead>
                                                                <tr>
                                                                    {/* <th className='commissionCheck'>
                                                            <span>All&nbsp;</span>
                                                            <span><Form.Check type="checkbox" checked={selectAllBM} onChange={e => handleSelectAllBM(e.target.checked)} /></span>
                                                        </th> */}
                                                                    <th>Agent</th>
                                                                    <th>Cert No</th>
                                                                    <th>Client Name</th>
                                                                    <th>Isd</th>
                                                                    <th>Tran Date</th>
                                                                    {
                                                                        paymentType === "COMMISSION" ?
                                                                            <>
                                                                                <th>Net Margin</th>
                                                                                <th>Commission</th>
                                                                                <th>TDS</th>
                                                                                <th>Total Payable</th>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <th>sgst</th>
                                                                                <th>cgst</th>
                                                                                <th>igst</th>
                                                                                <th>Total Gst</th>
                                                                            </>
                                                                    }
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    bmDetailData.map((item, index) => (
                                                                        <tr key={item.det_cert_no}>
                                                                            {/* <td>
                                                                    <span>
                                                                        <Form.Check type="checkbox" checked={selectAllBM || item.selected === "1" && "checked"} onClick={(e) => updateCheckChangesBM("selected", index, e)} />
                                                                    </span>
                                                                </td> */}
                                                                            <td>{item.det_agent_code + ' ' + item.det_agent_name}</td>
                                                                            <td>{item.det_type + " " + item.det_cert_no}</td>
                                                                            <td>{item.det_client_name}</td>
                                                                            <td>{item.det_isd}</td>
                                                                            <td>{item.det_tran_date}</td>
                                                                            {
                                                                                paymentType === "COMMISSION" ?
                                                                                    <>
                                                                                        <td>{item.det_total_margins}</td>
                                                                                        <td>{item.det_commission_total}</td>
                                                                                        <td>{item.det_tds}</td>
                                                                                        <td>{item.det_commission_net}</td>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <td>{item.det_sgst}</td>
                                                                                        <td>{item.det_cgst}</td>
                                                                                        <td>{item.det_igst}</td>
                                                                                        <td>{item.det_sgst * 1 + item.det_cgst * 1 + item.det_igst * 1}</td>
                                                                                    </>
                                                                            }

                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </Col>
                                                </Row>
                                            </>
                                            :
                                            scrMode === "GST" ?
                                                <Tran_commission_gst_approval obj={propObject} holdGstData={holdGstData} />
                                                : null
                            }
                        </Container >
                    </>
            }
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Modal show={showSummaryModal} size='xl' onHide={() => setShowSummaryModal(false)} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <Row>
                            <Col>
                                <span>Payable Summary</span>
                                <span>&nbsp;</span>
                                <span className="handCursor colorThemeBlue" onClick={downloadSummary}><FontAwesomeIcon icon={faDownload} /></span>
                            </Col>
                        </Row>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col className="col-md-5">
                            <Form.Group>
                                <Form.Label>Status&nbsp;
                                </Form.Label>
                                <Form.Select value={summaryStatusFilter} onChange={(e) => summarizeCommission(e.target.value)} >
                                    <option value="ALL">ALL</option>
                                    <option value="O">OK</option>
                                    <option value="A">APPROVE</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>&nbsp;</Row>
                    <Row>
                        <Col>
                            <span>Total Commission: <b>{summaryCommissionTotal}</b></span>&nbsp;&nbsp;
                            <span>Total TDS: <b>{summaryTDSTotal}</b></span>&nbsp;&nbsp;
                            <span>Total Payable: <b>{summaryPayable}</b></span>
                        </Col>
                    </Row>
                    <Row>
                        <Table striped responsive bordered>
                            <thead>
                                <tr>
                                    <th>Agent Code</th>
                                    <th>Agent Name</th>
                                    <th>GST Type</th>
                                    <th>FA Type</th>
                                    <th>Pur Commission</th>
                                    <th>Sale Comission</th>
                                    <th>Net Margin(Sale)</th>
                                    <th>Total Commission</th>
                                    <th>Total TDS</th>
                                    <th>Total GST</th>
                                    <th>Total Payable</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    commissionSummary.map(item => (
                                        <tr key={item.det_agent_code}>
                                            <td>{item.det_agent_code}</td>
                                            <td>{item.det_agent_name}</td>
                                            <td>{item.det_gst_type}</td>
                                            <td>{item.det_fa_type}</td>
                                            <td>{item.det_commission_purchase}</td>
                                            <td>{item.det_commission_sale}</td>
                                            <td>{item.totalmargin}</td>
                                            <td>{item.totalcommission}</td>
                                            <td>{item.totaltds}</td>
                                            <td>{1 * item.det_igst + 1 * item.det_cgst + 1 * item.det_sgst}</td>
                                            <td>{item.netcommission}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Row>
                </Modal.Body>
            </Modal>
            <Modal show={openFaModal} size='lg' onHide={() => setOpenFaModal(false)} backdrop="static">
                <Modal.Header closeButton>
                    Download Type : {faType}
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col className='col-6'>
                            <Form.Group>
                                <Form.Select value={faDownloadType} onChange={e => setFaDownloadType(e.target.value)}>
                                    <option value="N">New</option>
                                    <option value="A">All</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>&nbsp;</Row>
                    <Row>
                        <Col>
                            <Button className='col-md-6' variant='outline-primary' onClick={finalDownloadFa}>Download</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Modal show={showInvoiceModal} size='xl' onHide={() => setShowInvoiceModal(false)} backdrop="static">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Table striped bordered>
                            <thead>
                                <tr>
                                    <th>Agent Code</th>
                                    <th>Commission</th>
                                    <th>TDS</th>
                                    <th>GST</th>
                                    <th>Net Payable</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    invoiceData.map((item, index) => (
                                        <>
                                            <tr>
                                                <td>{item.det_agent_code + " " + item.det_agent_name}</td>
                                                <td>{item.totalcommission}</td>
                                                <td>{item.totaltds}</td>
                                                <td>{item.det_igst * 1 + item.det_cgst * 1 + item.det_sgst * 1}</td>
                                                <td>{item.netcommission}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={15}>
                                                    <Row>
                                                        <Col className="col-md-3 col-6">
                                                            <Form.Group>
                                                                <Form.Label>Invoice Number</Form.Label>
                                                                <Form.Control type='text' name='invoicenum' value={item.invoicenum} onChange={e => updateInvoiceValues(e.target.value, index, 'invoicenum')} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col className="col-md-3 col-6">
                                                            <Form.Group>
                                                                <Form.Label>Invoice Date</Form.Label>
                                                                <DatePicker className="form-control"
                                                                    selected={item.invoicedate === null ? null : new Date(item.invoicedate)}
                                                                    onChange={(date) => updateInvoiceValues(Common.dateYMD(date), index, "invoicedate")}
                                                                    dateFormat="dd/MM/yyyy"
                                                                    showYearDropdown
                                                                    showMonthDropdown
                                                                    useShortMonthInDropdown
                                                                    dropdownMode="select"
                                                                    peekNextMonth
                                                                    customInput={
                                                                        <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </td>
                                            </tr>
                                        </>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Row>
                    <Row>&nbsp;</Row>
                    <Row>
                        <Col className='col-md-6'>
                            <Button variant='outline-primary' size='sm' className='buttonStyles' onClick={() => finalUpdateInvoice()}>Update</Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Modal show={openApprovalModal} size='xl' onHide={() => setOpenApprovalModal(false)} backdrop="static">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Table responsive bordered striped>
                            <thead>
                                <tr>
                                    <th>Agent</th>
                                    <th>GST Type</th>
                                    <th>Commission</th>
                                    <th>TDS</th>
                                    <th>GST</th>
                                    <th>Net Payable</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    approvalSummary.map((item, index) => (
                                        <tr>
                                            <td>{item.det_agent_code + " " + item.det_agent_name}</td>
                                            <td>{item.app_gst_type}</td>
                                            <td>{item.app_total_commission}</td>
                                            <td>{item.app_tds}</td>
                                            <td>{item.app_igst * 1 + item.app_sgst * 1 + item.app_cgst * 1}</td>
                                            <td>{item.app_payable}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Row>
                    <Row>
                        <Button variant='primary' onClick={sendForApproval}>Send For Approval</Button>
                    </Row>
                </Modal.Body>
            </Modal>
            <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                {msgText}
            </Dialog>
            <DialogYesNo isOpen={isMsgBoxFinal} onYes={(e) => finalCheck()} onNo={(e) => setMsgBoxFinal(false)}>
                {msgTextFinal}
            </DialogYesNo>
            <ActivityLog logObject={logObject} show={showActivityLog} onHide={() => setShowActivityLog(false)} />
        </>
    )
}

export default Tran_commission_module
