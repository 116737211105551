import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import * as Common from "../Common";
import Dialog from '../Dialog';
import DialogYesNo from '../Dialog_Yes_No';
import Login_header from '../Login_header';
import Menu from '../Menu';
import * as Picklist from "../Picklist";
import Tran_data_unlock_request from '../Tran_data_unlock_request';

const Bank_cheque_book_transfer_in = () => {

    const sid = sessionStorage.getItem("sessionId");
    const navigate = useNavigate();
    const startDate = sessionStorage.getItem('startDate');
    const endDate = sessionStorage.getItem('endDate');
    const branchDisable = sessionStorage.getItem('branchDisable');
    const [onceRun, setOnceRun] = useState(false);
    const [userRight, setUserRight] = useState([]);
    const [scrMode, setScrMode] = useState("");

    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");
    const [isErrBox, setErrBox] = useState(false);
    const [errText, setErrText] = useState("");
    const [showModal, setShowModal] = useState(false);

    const [cbInlocation, setCbInLocation] = useState(null);
    const [cbInBank, setCbInBank] = useState(null);
    const [radioType, setRadioType] = useState('N');
    const [cbInFromDate, setCbInFromDate] = useState(new Date());
    const [cbInToDate, setCbInToDate] = useState(new Date());
    const [cbIndate, setCbInDate] = useState(new Date());
    const [locationCode, setLocationCode] = useState(0);
    const [location, setLocation] = useState('');
    const [bankName, setBankName] = useState('');
    const [cbInFromSrNo, setCbInFromSrno] = useState('');
    const [cbInToSrno, setCbInToSrno] = useState('');

    const [headerData, setHeaderData] = useState([]);
    const [innerData, setInnerData] = useState([]);
    const [subInnerData, setSubInnerData] = useState([]);

    const [cbInQuantity, setCbInQuantity] = useState('');
    const [cbInFrom, setCbInFrom] = useState('');
    const [cbInTo, setCbInTo] = useState('');
    const [srNo, setSrNo] = useState(0);
    const [counter, setCounter] = useState(0);
    const [lineNo, setLineNo] = useState(0);

    const [viewSrno, setViewSrno] = useState(0);
    const [viewDate, setViewDate] = useState('');
    const currentDate = new Date();

    const [showEditRequest, setShowEditRequest] = useState(false);
    const [editLogObject, setEditLogObject] = useState({ trantype: "", trannumber: "", trancategory: "", tranDate: "" });

    var msg = [], i = 0;


    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.getActiveBranch().then(response => {
                setCbInLocation(response);
                response != null && handleChangeLocation(response);
            });
            Common.callApi(Common.apiRight, [sid, 'all', 'BANK_TRANSFER_IN'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/'); // no query right
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);


    const handleChangeLocation = (value) => {
        setCbInLocation(value);

        var id = value.id;
        var locationVal = value.id.split('^');
        setLocationCode(locationVal[1]);
        setLocation(locationVal[2]);
        setCbInBank(null);
        sessionStorage.activeBranch = locationVal[1];
    }


    const handleBankValue = (value) => {
        setCbInBank(value);
        const bank = value.id.split('^');
        setBankName(bank[5]);
    }


    const handleChangeQuantity = (e) => {
        Common.validateNumValue(e.target.value, setCbInQuantity);
        if (e.target.value === '' || cbInFrom === '') {
            setCbInTo('');
        } else {
            setCbInTo(1 * e.target.value + (1 * cbInFrom - 1));
        }
    }


    const handleChangeFrom = (e) => {
        Common.validateNumValue(e.target.value, setCbInFrom);
        if (cbInQuantity === '' || e.target.value === '') {
            setCbInTo('');
        } else {
            setCbInTo((1 * cbInQuantity) + (1 * e.target.value - 1));
        }
    }


    const list = () => {
        if (!cbInlocation || (radioType === 'S' && !cbInFromSrNo) || (radioType === 'S' && !cbInToSrno) ||
            (radioType === 'D' && !cbInFromDate) || (radioType === 'D' && !cbInToDate)) {
            msg[i++] = Common.getMessage('MSG0006');
            msg[i++] = (!cbInlocation ? "Location." : '');
            msg[i++] = (radioType === 'S' && !cbInFromSrNo ? "From Srno." : '');
            msg[i++] = (radioType === 'S' && !cbInToSrno ? "To Srno." : '');
            msg[i++] = (radioType === 'D' && !cbInFromDate ? "From Date." : '');
            msg[i++] = (radioType === 'D' && !cbInToDate ? "To Date." : '');

            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const obj = {
                radioType: radioType,
                location: locationCode,
                fromDate: Common.dateYMD(cbInFromDate),
                toDate: Common.dateYMD(cbInToDate),
                fromSrno: cbInFromSrNo,
                toSrno: cbInToSrno,
            }
            Common.callApi(Common.apiBankChequeBookIn, [sid, 'list', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                setHeaderData(resp);
            });
        }
    }


    const addNew = () => {
        var date = Common.dateYMD(cbIndate);
        if (!cbInlocation || !cbInBank || !cbIndate) {
            msg[i++] = Common.getMessage('MSG0006');
            msg[i++] = (!cbInlocation ? "Location." : '');
            msg[i++] = (!cbInBank ? "Bank." : '');
            msg[i++] = (!cbIndate ? "Date." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (date > endDate || date < startDate) {
            setMsgBox(true);
            setMsgText(Common.getMessage("MSG0010"));
        } else {
            setScrMode('A');
            setCbInQuantity('');
            setCbInFrom('');
            setCbInTo('');
        }
    }


    const addDetail = () => {
        var msg = [], i = 0;
        if (cbInQuantity === '' || cbInFrom === '') {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (cbInQuantity === '' ? 'Quantity' : '');
            msg[i++] = (cbInFrom === '' ? 'From' : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (cbInQuantity >= 101) {
            setMsgText("Quantity Should be less than 101!");
            setMsgBox(true);
        } else {
            console.log(cbInBank);
            const bank = cbInBank.id.split('^');
            const groupCode = bank[1];
            const bankCode = bank[2];
            const obj = {
                unique_no: Common.getRandomString(5),
                tcb_srno: srNo,
                tcb_account: bankCode,
                tcb_group: groupCode,
                tcb_bankname: bankName,
                tcb_location: locationCode,
                tcb_quantity: cbInQuantity,
                tcb_from: cbInFrom,
                tcb_to: cbInTo,
                tcb_date: Common.dateDMY(cbIndate),
                tcb_line_no: 1 * lineNo + 1,
                del: "Y",
            };
            setInnerData(Common.arrayAddItem(innerData, obj));
            console.log(innerData);
            setCbInQuantity('');
            setCbInFrom('');
            setCbInTo('');
            setLineNo(lineNo + 1);
            // setCounter(counter + 1);
            //alert('addddddd!');
        }
    }


    const saveData = () => {
        if (innerData.length < 1) {
            setMsgText("Empty List!");
            setMsgBox(true);
        } else {
            const bank = cbInBank.id.split('^');
            const groupCode = bank[1];
            const bankCode = bank[2];
            const obj = {
                group: groupCode,
                bank: bankCode,
                location: locationCode,
                srno: srNo,
                date: Common.dateYMD(cbIndate),
                data: innerData,

                radioType: radioType,
                location: locationCode,
                fromDate: Common.dateYMD(cbInFromDate),
                toDate: Common.dateYMD(cbInToDate),
                fromSrno: cbInFromSrNo,
                toSrno: cbInToSrno,
            }
            Common.callApi(Common.apiBankChequeBookIn, [sid, 'save', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                setHeaderData(resp);
                // setCbInLocation(null);
                setCbInBank(null);
                setInnerData([]);
                setScrMode("");
            });

            if (scrMode == 'E') {
                const obj = {
                    srno: srNo,
                    type: 'CHQIN',
                    status: 'E',
                    currentDate: Common.dateYMD(currentDate),
                    pageright: 'BANK_TRANSFER_IN'
                }
                Common.callApi(Common.apiUnlockEdit, [sid, 'expireUnlock', JSON.stringify(obj)], (result) => {
                    console.log(result);
                })

            }
        }
    }


    const deleteList = (value) => {
        setInnerData(Common.arrayRemoveItem(innerData, "unique_no", value));
        setCounter(counter + 1);
    }


    const viewList = (hdrSrno, location, type, date) => {
        setScrMode('Q');
        setSrNo(hdrSrno);
        setViewSrno(hdrSrno);
        setViewDate(date);
        const obj = {
            srno: hdrSrno,
            location: location,
            type: type,
        }
        Common.callApi(Common.apiBankChequeBookIn, [sid, 'view', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);
            if (resp.msg === 'MSG0000') {
                setInnerData(resp.chequeList);
                setLineNo(resp.maxlineno);
                setLocation(resp.locationname);
                setBankName(resp.ledgername);
                // setCbInBank(resp.bank);
                setCbInBank({ id: resp.bank });
            }
        });
    }


    const viewDetails = (srno, lineno, location, type) => {
        const obj = {
            srno: srno,
            lineno: lineno,
            location: location,
            type: type,
        }
        Common.callApi(Common.apiBankChequeBookIn, [sid, 'viewDtls', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);
            setSubInnerData(resp);
        });
        setShowModal(true);
    }


    const cancelConfirm = () => {
        if (scrMode === "A" || scrMode === "E") {
            setMsgTextYN('Do you really want to exit ?');
            setMsgBoxYN(true);
        } else {
            cancelData();
        }
    }


    const cancelData = () => {
        setMsgBoxYN(false);
        setScrMode("");
        setCbInBank(null);
    }


    const editData = () => {
        setScrMode("E");
        setCbInQuantity('');
        setCbInFrom('');
        setCbInTo('');
    }

    const btnEditRequest = () => {
        const currDate = new Date();
        const newDate = viewDate.split('/');

        const newViewDate = newDate[2] + '-' + newDate[1] + '-' + newDate[0]
        const newCurrDate = Common.dateYMD(currDate);

        const dateA = new Date(newViewDate);
        const dateB = new Date(newCurrDate);

        // Calculate the difference in milliseconds
        const differenceInMilliseconds = dateB - dateA;

        // Convert milliseconds difference to days
        const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 3600 * 24));

        console.log(differenceInDays);

        const obj = {
            srno: viewSrno,
            type: 'CHQIN',
            pageright: 'BANK_TRANSFER_IN'
        }
        // const message = 'unlock'
        Common.callApi(Common.apiUnlockEdit, [sid, 'checkUnlock', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);

            if (Common.editDateLimit == differenceInDays || resp == "unlock") {
                setScrMode('E');
                setCbInQuantity('');
                setCbInFrom('');
                setCbInTo('');

            } else {
                setShowEditRequest(true);
                setEditLogObject({ trantype: 'CHQIN', trannumber: viewSrno, location: locationCode, tranDate: Common.dateYMD(currDate) });
            }
        })


    }

    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h2>Cheque Book Transfer In<span style={{ color: 'white' }}>{counter}</span></h2>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                {
                    scrMode === '' ?
                        <>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Location<span className="text-danger">*</span></Form.Label>
                                        <AsyncSelect
                                            cacheOptions={false}
                                            defaultOptions={false}
                                            value={cbInlocation}
                                            getOptionLabel={e => e.label + ' '}
                                            getOptionValue={e => e.id}
                                            loadOptions={Picklist.fetchLocationAllowedPicklist}
                                            onInputChange={Picklist.handleInputChange}
                                            onChange={handleChangeLocation}
                                            isDisabled={branchDisable === "true"}
                                        >
                                        </AsyncSelect>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-4 col-6'>
                                    <Form.Group>
                                        <Row style={{ marginTop: "30px" }}>
                                            <Col>
                                                <Form.Check checked={radioType === "N"} onChange={() => setRadioType("N")} type='radio' label="New" />
                                            </Col><Col>
                                                <Form.Check checked={radioType === "S"} onChange={() => setRadioType("S")} type='radio' label="SrNo" />
                                            </Col><Col>
                                                <Form.Check checked={radioType === "D"} onChange={() => setRadioType("D")} type='radio' label="Date" />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                {
                                    radioType === 'N' ?
                                        <>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Date<span className="text-danger">*</span></Form.Label>
                                                    <DatePicker className="form-control"
                                                        selected={cbIndate}
                                                        minDate={new Date(startDate)}
                                                        maxDate={new Date(endDate)}
                                                        onChange={(date) => setCbInDate(date)}
                                                        dateFormat="dd/MM/yyyy"
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        useShortMonthInDropdown
                                                        dropdownMode="select"
                                                        peekNextMonth
                                                        customInput={
                                                            <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Bank<span className="text-danger">*</span></Form.Label>
                                                    <AsyncSelect
                                                        cacheOptions={false}
                                                        defaultOptions={false}
                                                        value={cbInBank}
                                                        getOptionLabel={e => e.label + ' '}
                                                        getOptionValue={e => e.id}
                                                        loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 'BANK', locationCode)}
                                                        onInputChange={Picklist.handleInputChange}
                                                        onChange={handleBankValue}
                                                    >
                                                    </AsyncSelect>
                                                </Form.Group>
                                            </Col>
                                        </>
                                        :
                                        radioType === 'S' ?
                                            <>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>From<span className="text-danger">*</span></Form.Label>
                                                        <Form.Control value={cbInFromSrNo}
                                                            maxLength={20}
                                                            onChange={(e) => Common.validateNumValue(e.target.value, setCbInFromSrno)}
                                                            placeholder="From" />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>To<span className="text-danger">*</span></Form.Label>
                                                        <Form.Control value={cbInToSrno}
                                                            maxLength={20}
                                                            onChange={(e) => Common.validateNumValue(e.target.value, setCbInToSrno)}
                                                            placeholder="To" />
                                                    </Form.Group>
                                                </Col>
                                            </>
                                            :
                                            radioType === 'D' ?
                                                <>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                                                            <DatePicker className="form-control"
                                                                selected={cbInFromDate}
                                                                onChange={(date) => setCbInFromDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>To<span className="colorThemeRed">*</span></Form.Label>
                                                            <DatePicker className="form-control"
                                                                selected={cbInToDate}
                                                                onChange={(date) => setCbInToDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </>
                                                :
                                                null
                                }
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    {
                                        userRight.add === '1' && radioType === 'N' ?
                                            <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addNew()}>Add New</Button> : null
                                    }
                                    &nbsp;
                                    {
                                        userRight.query === "1" && (radioType === 'S' || radioType === 'D') ?
                                            <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => list()}>List</Button>
                                            : null
                                    }
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Table responsive striped bordered hover>
                                    <thead>
                                        <tr>
                                            {userRight.edit === "1" ? <th></th> : null}
                                            <th>SrNo</th>
                                            <th>Date</th>
                                            <th>Bank</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {headerData.map((res) =>
                                            <tr key={res.hdr_srno}>
                                                {userRight.edit === "1" ?
                                                    <td className="textCenter">
                                                        <span className="handCursor colorThemeBlue" onClick={() => viewList(res.hdr_srno, res.hdr_location, res.hdr_type, res.new_date)}>
                                                            <FontAwesomeIcon icon={faEye} />
                                                        </span>
                                                    </td>
                                                    : null
                                                }
                                                <td>{res.hdr_srno}</td>
                                                <td>{res.hdr_date}</td>
                                                <td>{res.hdr_bank}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Row>
                        </>
                        :
                        <>
                            <Row>
                                <Col>
                                    <Button variant='outline-danger' className='buttonStyle' size='sm' onClick={() => cancelConfirm()}>Back</Button>{' '}
                                    {
                                        (userRight.edit === "1" && scrMode === 'Q') ? <>
                                            <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                btnEditRequest()}>Edit</Button>&nbsp;
                                        </> : null
                                    }
                                    {
                                        (userRight.edit === "1" && scrMode === 'E') || (userRight.add === "1" && scrMode === 'A') ? <>
                                            <Button variant='outline-primary' size='sm' className='buttonStyle' onClick={() => saveData()}>Save</Button>
                                        </> : null
                                    }
                                    {/* <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={exportFile}>Export</Button> */}
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col className='col-md-6 col-6'>
                                    <Form.Label>Location : {location}</Form.Label>
                                </Col>
                                <Col className='col-md-6 col-6'>
                                    <Form.Label>Bank : {bankName}</Form.Label>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            {
                                scrMode === 'E' || scrMode === 'A' ?
                                    <>
                                        <Row>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Quantity<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control value={cbInQuantity}
                                                        onChange={(e) => handleChangeQuantity(e)}
                                                        placeholder="Quantity" />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>From<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control value={cbInFrom}
                                                        maxLength={20}
                                                        onChange={(e) => handleChangeFrom(e)}
                                                        placeholder="From" />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>To</Form.Label>
                                                    <Form.Control value={cbInTo}
                                                        maxLength={20}
                                                        onChange={(e) => setCbInTo(e.target.value)}
                                                        placeholder="To"
                                                        disabled />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: "30px" }}>
                                            <Col className='col-md-3 col-6'>
                                                {
                                                    userRight.add === "1" ?
                                                        <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addDetail()}>Add</Button> : null
                                                }
                                            </Col>
                                        </Row>
                                    </> :
                                    null
                            }
                            <Row>&nbsp;</Row>
                            <Row>
                                <Table responsive striped bordered hover>
                                    <thead>
                                        <tr>
                                            {userRight.edit === "1" ? <th></th> : null}
                                            <th>Quantity</th>
                                            <th>From</th>
                                            <th>To</th>
                                            <th>Date</th>
                                            <th>Bank</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {innerData.map((val, index) =>
                                            <tr key={index}>
                                                {
                                                    userRight.delete === "1" ?
                                                        <td className="textCenter">
                                                            {
                                                                scrMode === 'Q' || val.del === 'N' ? null :
                                                                    <>
                                                                        <span className="handCursor colorThemeBlue" onClick={() => deleteList(val.unique_no)}>
                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                        </span>{' '}
                                                                    </>
                                                            }
                                                            {
                                                                scrMode === 'Q' ?
                                                                    <span className="handCursor colorThemeBlue" onClick={() => viewDetails(val.tcb_srno, val.tcb_line_no, val.tcb_location, val.tcb_type)}>
                                                                        <FontAwesomeIcon icon={faEye} />
                                                                    </span>
                                                                    : null
                                                            }
                                                        </td>
                                                        : null
                                                }
                                                <td>{val.tcb_quantity}</td>
                                                <td>{val.tcb_from}</td>
                                                <td>{val.tcb_to}</td>
                                                <td>{val.tcb_date}</td>
                                                <td>{val.tcb_group} {val.tcb_account} {val.tcb_bankname}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                        </>
                }
            </Container>
            <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
                <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
                    {msgTextYN}
                </DialogYesNo>
                <Dialog isOpen={isErrBox} onClose={(e) => setErrBox(false)}>
                    {errText}
                </Dialog>
                <Tran_data_unlock_request logObject={editLogObject} show={showEditRequest} onHide={() => setShowEditRequest(false)} />

            </div>
            <div>
                <Modal size='lg' show={showModal} onHide={() => setShowModal(false)} animation={false} centered>
                    <Modal.Body>
                        <Container fluid>
                            <Row>
                                <Col>
                                    <Table responsive striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>From</th>
                                                <th>Sr No.</th>
                                                <th>Out Type</th>
                                                <th>Out Line No.</th>
                                                <th>Out Location</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                subInnerData.map((res) =>
                                                    <tr key={res.tcb_line_no}>
                                                        <td>{res.tcbb_from}</td>
                                                        <td>{res.tcbb_out_srno}</td>
                                                        <td>{res.tcbb_out_type}</td>
                                                        <td>{res.tcbb_out_line_no}</td>
                                                        <td>{res.tcbb_out_location}</td>
                                                        <td></td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-success" onClick={() => setShowModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}

export default Bank_cheque_book_transfer_in
