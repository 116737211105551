import React, { useEffect, useState } from 'react'
import { Row, Col, Table, Form, Button } from 'react-bootstrap'
import * as Common from "../../Common";
import Dialog from '../../Dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Select from "react-select";

function Master_ledger_commission(props) {
    const sid = sessionStorage.getItem("sessionId");
    const [commProduct, setCommProduct] = useState("");
    const [commType, setCommType] = useState("");
    const [commValue, setCommValue] = useState("");
    const [onceRun, setOnceRun] = useState(false);

    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    const [commList, setCommList] = useState([]);
    const [isdOptions, setIsdOptions] = useState("");
    const [isd, setIsd] = useState({ value: "", label: "Select" });

    useEffect(() => {
        setCommList(props.commissionList);
    }, []);

    const addCommission = () => {
        var msg = [], i = 0;
        if (commProduct === "" || commType === "" || isd.value === "" || commValue === "") {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (commProduct === "" ? " Product." : '');
            msg[i++] = (commType === "" ? " Type." : '');
            msg[i++] = (isd.value === "" ? " ISD." : '');
            msg[i++] = (commValue === "" ? " Value." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const obj = {
                commProduct: commProduct,
                commType: commType,
                commValue: commValue,
                isd: isd.value,
                ldgsrno: props.ldgsrno,
                ldggroupsrno: props.ldggrpsrno
            }
            Common.callApi(Common.apiLedger, [sid, "addcommission", JSON.stringify(obj)], result => {
                setCommList(JSON.parse(result));
            });
        }
    }

    const deleteCommission = (srno, ldgsrno, groupsrno) => {
        Common.callApi(Common.apiLedger, [sid, "deletecomm", srno, ldgsrno, groupsrno], result => {
            let resp = JSON.parse(result);
            if (resp.msg === "MSG0000") {
                setCommProduct("");
                setCommType("");
                setCommValue("");
                setCommList(resp.commlist);
            } else {
                setMsgBox(true);
                setMsgText(Common.getMessage(resp.msg));
            }
        });
    }

    const handleProductChange = (v) => {
        setCommProduct(v);
        Common.callApi(Common.apiLedger, [sid, "getisd", v], result => {
            setIsdOptions(JSON.parse(result));
        });
    }

    return (
        <div>
            <Row>
                <Col>
                    <h3>Commission</h3>
                </Col>
            </Row>
            {
                props.visiblePg === "Q" ? null :
                    <>
                        <Row>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Product<span className="colorThemeRed">*</span></Form.Label>
                                    <Form.Select disabled={props.visiblePg === "Q"} value={commProduct} onChange={e => handleProductChange(e.target.value)}>
                                        <option value="">Select</option>
                                        <option value="CN">Currency</option>
                                        <option value="TT">TT</option>
                                        <option value="TC">TC</option>
                                        <option value="VTC">VTC</option>
                                        <option value="DD">DD</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Type<span className="colorThemeRed">*</span></Form.Label>
                                    <Form.Select disabled={props.visiblePg === "Q"} value={commType} onChange={e => setCommType(e.target.value)}>
                                        <option value="">Select</option>
                                        <option value="P">Paise</option>
                                        <option value="R">Percentage</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Isd<span className="colorThemeRed">*</span></Form.Label>
                                    <Select isDisabled={props.visiblePg === "Q"} options={isdOptions} value={isd} onChange={v => setIsd(v)} />
                                </Form.Group>
                            </Col>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Value<span className="colorThemeRed">*</span></Form.Label>
                                    <Form.Control disabled={props.visiblePg === "Q"} type='text' placeholder='Value' value={commValue} onChange={e => Common.validateDecValue(e.target.value, setCommValue)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>&nbsp;</Row>

                        <Row>
                            <Col>
                                <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addCommission()}>Add</Button>
                            </Col>
                        </Row>
                        <Row>&nbsp;</Row>
                    </>
            }
            <Row>
                <Col>
                    <Table responsive bordered striped>
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th>Type</th>
                                <th>Isd</th>
                                <th>Value</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                commList.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.vlc_product}</td>
                                        <td>{item.vlc_type}</td>
                                        <td>{item.vlc_isd}</td>
                                        <td>{item.vlc_value}</td>
                                        <td>
                                            {
                                                props.visiblePg === "Q" || props.deleteRight === "0" ? null :
                                                    <span className='colorThemeBlue' onClick={() => deleteCommission(item.vlc_srno, item.vlc_ldgsrno, item.vlc_group)}>
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </span>
                                            }
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
            </div>
        </div>
    )
}

export default Master_ledger_commission
