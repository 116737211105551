import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Table, Form, Button } from 'react-bootstrap'
import * as Common from "../../Common";
import LocationSelector from '../../LocationSelector';
import { useNavigate } from 'react-router-dom';
import Login_header from '../../Login_header';
import Menu from '../../Menu';
import $ from "jquery";

function RepCommission() {
    const sid = sessionStorage.getItem("sessionId");
    const navigate = useNavigate();

    const [oneLocation, setOneLocation] = useState("");
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [radioType, setRadioType] = useState("");
    const [userRight, setUserRight] = useState([]);
    const [mode, setMode] = useState("V");
    const [repData, setRepData] = useState([]);

    useEffect(() => {
        Common.callApi(Common.apiRight, [sid, 'all', 'REPORTS_TDS_COMMISSION'], function (result) {
            let resp = JSON.parse(result);
            if (resp.query === "1") {
                setUserRight(resp);
            } else {
                navigate('/');
            }
        });
    }, []);

    const handleDataFromChild = (newLocationCode, newSelectedItems, newRadioType) => {
        setOneLocation(newLocationCode);
        setSelectedLocations(newSelectedItems);
        setRadioType(newRadioType);
    }

    const generateReport = () => {
        $(".loader").show();
        var i = 0, msg = [];
        if ((radioType == 'O' && oneLocation == '0') || (radioType == 'S' && selectedLocations == [])) {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = ((radioType == 'O' && oneLocation == '0') ? "Please select location." : '');
            msg[i++] = ((radioType == 'S' && selectedLocations == []) ? "Please select atleast one location." : '');
        } else {
            const obj = {
                mode: mode,
                radioType: radioType,
                oneLocation: oneLocation,
                selectedLocations: selectedLocations
            }
            if (mode === "V") {
                Common.callApi(Common.apiCommission, [sid, "tdsreport", JSON.stringify(obj)], result => {
                    console.log(result);
                    $(".loader").hide();
                    setRepData(JSON.parse(result));
                });
            } else {
                $(".loader").hide();
                Common.callDownloadApiPost(Common.apiCommission, "post", [sid, 'tdsrepdownload', JSON.stringify(obj)]);
            }
        }
    }

    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h2>Commission TDS Report</h2>
                    </Col>
                </Row>
                <Row>
                    <Col className='col-md-3'>
                        <Form.Group>
                            <Form.Label>Mode</Form.Label>
                            <Form.Select value={mode} onChange={e => setMode(e.target.value)}>
                                <option value="V">View</option>
                                <option value="D">Download</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Location Filter</Form.Label><br />
                            <Col>
                                <LocationSelector onDataFromChild={handleDataFromChild} />
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                <Row>
                    <Col className='col-md-3'>
                        <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={generateReport}>List</Button>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                <Row>
                    <Table responsive bordered striped>
                        <thead>
                            <tr>
                                <th>Agent Type</th>
                                <th>Code</th>
                                <th>Rate of TDS</th>
                                <th>Pan No.</th>
                                <th>Party Name</th>
                                <th>Taxable Amount</th>
                                <th>TDS Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                repData.map(item => (
                                    <tr>
                                        <td>{item.app_agent_type == "C" ? "Company" : "Non Company"}</td>
                                        <td>194H</td>
                                        <td>5.00%</td>
                                        <td>{item.app_pan_no}</td>
                                        <td>{item.app_agent_name}</td>
                                        <td>{item.app_total_commission}</td>
                                        <td>{item.app_tds}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </Row>
            </Container>
        </>
    )
}

export default RepCommission
