import React, { useState } from 'react'
import Login_header from '../Login_header'
import Menu from '../Menu'
import { Col, Container, Row, Form, Button } from 'react-bootstrap'
import Tran_manual_book_cancellation from './Tran_manual_book_cancellation'
import { useNavigate } from 'react-router-dom'

const Tran_cancellation = () => {


    const [cancellationType, setCancellationType] = useState('MANCN');

    const navigate = useNavigate();

    const handleGoto = () => {
        switch (cancellationType) {
            case 'MANCN':
                navigate('/manual-cancellation');
                break;
            case 'CHQCN':
                navigate('/cheque-cancellation');
                break;
        }
    }

    return (
        <div>
            <Login_header />
            <Menu />

            <Container fluid>
                <Row>
                    <Col>
                    <h2>Cancellation</h2>
                    </Col>
                </Row>&nbsp;

                <Row>
                    <Col className='col-md-3 col-6'>
                        <Form.Group>
                            <Form.Label>Cancellation Type<span className="text-danger">*</span></Form.Label>
                            <Form.Select value={cancellationType} onChange={e => setCancellationType(e.target.value)}>
                                <option value="MANCN">Manual Book</option>
                                <option value="CHQCN">Cheque Book</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>&nbsp;
                <Row>
                    <Col>
                        <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => handleGoto()}>Goto</Button>&nbsp;
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Tran_cancellation
