import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Table, Container, Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';

import Login_header from '../Login_header';
import Menu from '../Menu';
import * as Common from '../Common';
import Dialog from '../Dialog';
import DialogYesNo from '../Dialog_Yes_No';

function Master_isd_list() {
    const navigate = useNavigate();
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    const sid = sessionStorage.getItem('sessionId');
    const [onceRun, setOnceRun] = useState(false);
    const [isdData, setIsdData] = useState([]);

    const [showAddNewBtn, setShowAddNewBtn] = useState(true);
    const [showAddEditForm, setAddEditFormShow] = useState(false);

    const [isd, setIsd] = useState("");
    const [isdName, setIsdName] = useState("");

    const [status, setStatus] = useState(0);
    const [ddActiveOptions, setDdActiveOptions] = useState([]);

    const [country, setCountry] = useState(0);
    const [ddCountryOptions, setDdCountryOptions] = useState([]);

    const [userRight, setUserRight] = useState([]);

    const [cnCheck, setCnCheck] = useState(false);
    const [tcCheck, setTcCheck] = useState(false);
    const [vtcCheck, setVtcCheck] = useState(false);
    const [ttCheck, setTtCheck] = useState(false);
    const [ddCheck, setDdCheck] = useState(false);

    const [formMode, setFormMode] = useState('Q'); // Q A E 

    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");

    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.callApi(Common.apiRight, [sid, 'all', 'CURRENCY'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/'); // no query right
                }
            });
            setDdActiveOptions([{ name: 'Active', value: "1" }, { name: 'Inactive', value: "0" }]);
            Common.callApi(Common.apiIsd, [sid, 'listjson'], function (result) {
                setIsdData(JSON.parse(result));
            });
            Common.callApi(Common.apiCountry, [sid, 'ddlistjson'], function (result) {
                setDdCountryOptions(JSON.parse(result));
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);

    function getList() {
        Common.callApi(Common.apiIsd, [sid, 'listjson'], function (result) {
            setIsdData(JSON.parse(result));
        });
    }

    function addNew() {
        setIsd("");
        setIsdName("");
        setStatus(1);
        setFormMode('A');
        setCountry(0);
        setCnCheck(false);
        setTtCheck(false);
        setTcCheck(false);
        setVtcCheck(false);
        setDdCheck(false);
        setAddEditFormShow(true);
        setShowAddNewBtn(false);
    }

    function fillEditForm(id) {
        setFormMode('Q');
        Common.callApi(Common.apiIsd, [sid, 'detail', id], function (result) {
            let resp = JSON.parse(result);
            setIsd(resp.isd);
            setIsdName(resp.isdName);
            setCountry(resp.countrySrno);
            setStatus(resp.active);
            setCnCheck(resp.cn === "1" ? true : false);
            setTtCheck(resp.tt === "1" ? true : false);
            setTcCheck(resp.tc === "1" ? true : false);
            setVtcCheck(resp.vtc === "1" ? true : false);
            setDdCheck(resp.dd === "1" ? true : false);
            setAddEditFormShow(true);
            setShowAddNewBtn(false);

        });
    }

    function saveData() {
        var msg = [], i = 0;
        if (isd === "" || isdName === "") {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (isd === "" ? "Currency Code." : '');
            msg[i++] = (isdName === "" ? " Currency Name ." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            var object1 = {
                isd: isd,
                isdName: isdName,
                countrySrno: country,
                act: status,
                cnCheck: cnCheck ? 1 : 0,
                ttCheck: ttCheck ? 1 : 0,
                tcCheck: tcCheck ? 1 : 0,
                vtcCheck: vtcCheck ? 1 : 0,
                ddCheck: ddCheck ? 1 : 0
            };
            Common.callApi(Common.apiIsd, [sid, 'save', JSON.stringify(object1)], function (result) {
                setAddEditFormShow(false);
                setShowAddNewBtn(true);
                getList();
            });
        }
    }

    const cancelConfirm = () => {
        if (formMode === "A" || formMode === "E") {
            setMsgTextYN('Do you really want to exit ?');
            setMsgBoxYN(true);
        } else {
            cancelData();
        }
    }

    const cancelData = () => {
        setMsgBoxYN(false);
        setAddEditFormShow(false);
        setShowAddNewBtn(true);
    }

    // function cancelData() {
    //     setAddEditFormShow(false);
    //     setShowAddNewBtn(true);
    // }

    const editData = () => {
        setFormMode('E');
    }

    const exportFile = () => {
        var obj = {
            right: 'CURRENCY'
        }
        Common.callDownloadApiPost(Common.apiReport, "post", [sid, JSON.stringify(obj)]);
    }

    return (
        <>
            <Login_header />
            <Menu></Menu>
            <Container fluid >
                <Row>
                    <Col>
                        <h1>ISD Master </h1>
                    </Col>
                </Row>
                {showAddNewBtn ?
                    <Row>
                        <Col>
                            {userRight.add === "1" ?
                                <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={addNew}>Add New</Button>
                                : null}
                            {
                                userRight.query === "1" ?
                                    <>&nbsp;
                                        <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={exportFile}>Export</Button>
                                    </>
                                    : null
                            }
                        </Col>
                    </Row>
                    : null
                }
                <Row><Col>&nbsp;</Col></Row>
                {showAddEditForm ?
                    <Row>
                        <Col className="divContentCenter">
                            <div className="maxFormWidth">
                                <Form>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Currency Code<span className="mandatory text-danger">*</span></Form.Label>
                                                <Form.Control type="text" placeholder="Currency Code" maxLength={3}
                                                    value={isd}
                                                    onChange={(e) => Common.validateAlpValue(e.target.value, setIsd)}
                                                    autoComplete="off"
                                                    disabled={formMode === 'Q'} />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Currency Name<span className="mandatory text-danger">*</span></Form.Label>
                                                <Form.Control type="text" placeholder="Currency Name" maxLength={50}
                                                    value={isdName}
                                                    onChange={(e) => Common.validateAlpValue(e.target.value, setIsdName)}
                                                    autoComplete="off"
                                                    disabled={formMode === 'Q'} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Country</Form.Label>
                                                <Form.Select
                                                    value={country}
                                                    onChange={(e) => setCountry(e.target.value)}
                                                    disabled={formMode === 'Q'}
                                                >
                                                    {ddCountryOptions.map((res) =>
                                                        <option key={res.value} value={res.value}>{res.name}</option>
                                                    )}
                                                </Form.Select>
                                            </Form.Group>

                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>Status</Form.Label>
                                                <Form.Select
                                                    value={status}
                                                    onChange={(e) => setStatus(e.target.value)}
                                                    disabled={formMode === 'Q'}
                                                >
                                                    {ddActiveOptions.map((res) =>
                                                        <option key={res.value} value={res.value}>{res.name}</option>
                                                    )}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>&nbsp;</Row>
                                    <Row>
                                        <Col className='col-md-4' style={{ display: "flex" }}>
                                            <Col>
                                                <Form.Check type="checkbox" checked={cnCheck} label="CN" value={cnCheck} onChange={e => setCnCheck(e.target.checked)}
                                                    disabled={formMode === 'Q'} />
                                            </Col>
                                            <Col>
                                                <Form.Check type="checkbox" checked={tcCheck} label="TC" value={tcCheck} onChange={e => setTcCheck(e.target.checked)}
                                                    disabled={formMode === 'Q'} />
                                            </Col>
                                            <Col>
                                                <Form.Check type="checkbox" checked={vtcCheck} label="VTC" value={vtcCheck} onChange={e => setVtcCheck(e.target.checked)}
                                                    disabled={formMode === 'Q'} />
                                            </Col>
                                            <Col>
                                                <Form.Check type="checkbox" checked={ttCheck} label="TT" value={ttCheck} onChange={e => setTtCheck(e.target.checked)}
                                                    disabled={formMode === 'Q'} />
                                            </Col>
                                            <Col>
                                                <Form.Check type="checkbox" checked={ddCheck} label="DD" value={ddCheck} onChange={e => setDdCheck(e.target.checked)}
                                                    disabled={formMode === 'Q'}
                                                />
                                            </Col>
                                        </Col>
                                    </Row>
                                    <Row><Col>&nbsp;</Col></Row>
                                    <Row>
                                        <Col>
                                            {/* <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() => saveData()}>Save</Button>&nbsp;
                                            <Button variant="outline-danger" className='buttonStyle' size="sm" onClick={() => cancelData()}>Cancel</Button> */}

                                            <Button variant="outline-danger" className='buttonStyle' size="sm" onClick={() =>
                                                cancelConfirm()}>Back</Button>&nbsp;&nbsp;

                                            {
                                                (userRight.edit === "1" && formMode === 'Q') ? <>
                                                    <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                        editData()}>Edit</Button>&nbsp;
                                                </> : null
                                            }
                                            {
                                                (userRight.edit === "1" && formMode === 'E') || (userRight.add === "1" && formMode === 'A') ? <>
                                                    <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                        saveData()}>Save</Button>&nbsp;
                                                </> : null
                                            }
                                        </Col>
                                    </Row>
                                    <Row><Col>&nbsp;</Col></Row>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                    : <>
                        <Row>
                            <Col>
                                <Table responsive striped bordered hover>
                                    <thead>
                                        <tr>
                                            {userRight.edit === "1" ? <th></th> : null}
                                            <th className="textRight">Code</th>
                                            <th>Name</th>
                                            <th>Country</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isdData.map((res) =>
                                            <tr key={res.isd_code}>
                                                {userRight.edit === "1" ?
                                                    <td className="textCenter" >
                                                        <span className="handCursor" onClick={() => fillEditForm(res.isd_code)} >
                                                            <div className="colorThemeBlue"><FontAwesomeIcon icon={faEye} /></div>
                                                        </span>
                                                    </td>
                                                    : null
                                                }
                                                <td className="textRight">{res.isd_code}</td>
                                                <td>{res.isd_name}</td>
                                                <td>{res.cnt_name}</td>
                                                <td>{res.isd_active}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </>
                }


            </Container >
            {/* <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
            </div> */}

            <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
                <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
                    {msgTextYN}
                </DialogYesNo>
            </div>
        </>
    );
}
export default Master_isd_list; 