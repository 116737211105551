import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Table, Container, Row, Col, Form } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';

import Login_header from '../Login_header';
import Menu from '../Menu';

import * as Common from '../Common';




function Master_purpose_list() {
    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const [onceRun, setOnceRun] = useState(false);
    const [userRight, setUserRight] = useState([]);
    const [purposeData, setPurposeData] = useState([]);
    const [documentList, setDocumentList] = useState([]);
    const [showAddEditForm, setAddEditFormShow] = useState(false);

    const [srNo, setSrNo] = useState(0);
    const [purpose, setPurpose] = useState("");

    const [status, setStatus] = useState(0);
    const [ddActiveOptions, setDdActiveOptions] = useState([]);

    const [document, setDocument] = useState(0);
    const [ddDocumentOptions, setDdDocumentOptions] = useState([]);

    const [docRequired, setDocRequired] = useState("0");


    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            setDdActiveOptions([{ name: 'Active', value: "1" }, { name: 'Inactive', value: "0" }]);
            Common.callApi(Common.apiRight, [sid, 'all', 'PURPOSE'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/'); // no query right
                }
            });
            Common.callApi(Common.apiPurpose, [sid, 'listjson'], function (result) {
                let resp = JSON.parse(result);
                setPurposeData(resp);
            });
            Common.callApi(Common.apiPurpose, [sid, 'ddlistjsondoc'], function (result) {
                let resp = JSON.parse(result);
                setDdDocumentOptions(resp);
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);

    function fillEditForm(id) {
        Common.callApi(Common.apiPurpose, [sid, 'detail', id], function (result) {
            let resp = JSON.parse(result);
            setSrNo(resp.purpose_id);
            setPurpose(resp.purpose_name);
            setStatus(resp.purpose_tt);
            setDocumentList(resp.doc_list);
            setAddEditFormShow(true);
        });
    }

    function changeRequired() {
        var object1 = {
            srno: srNo,
            document: document,
            docRequired: docRequired
        };
        Common.callApi(Common.apiPurpose, [sid, 'docchange', JSON.stringify(object1)], function (result) {
            let resp = JSON.parse(result);
            setSrNo(resp.purpose_id);
            setPurpose(resp.purpose_name);
            setStatus(resp.purpose_tt);
            setDocumentList(resp.doc_list);
        });
    }

    function cancelData() {
        setAddEditFormShow(false);
    }

    const exportFile = () => {
        var obj = {
            right: 'PURPOSE'
        }
        Common.callDownloadApiPost(Common.apiReport, "post", [sid, JSON.stringify(obj)]);
    }

    return (
        <>
            <Login_header />
            <Menu></Menu>
            <Container fluid >
                <Row>
                    <Col>
                        <h1>Purpose Master</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>

                        {
                            userRight.query === "1" ?
                                <>&nbsp;
                                    <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={exportFile}>Export</Button>
                                </>
                                : null
                        }
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                {
                    showAddEditForm ?
                        <>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Serial Number</Form.Label>
                                        <Form.Control type="text" placeholder="Serial Number" value={srNo} disabled />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Purpose<span className="mandatory">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="purpose" maxLength={50}
                                            value={purpose} onChange={(e) => setPurpose(e.target.value)} autoComplete="off"
                                            disabled />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Status</Form.Label>
                                        <Form.Select
                                            value={status}
                                            onChange={(e) => setStatus(e.target.value)}
                                            disabled
                                        >
                                            {ddActiveOptions.map((res) =>
                                                <option key={res.value} value={res.value}>{res.name}</option>
                                            )}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Document<span className="mandatory">*</span></Form.Label>
                                        <Form.Select
                                            value={document}
                                            onChange={(e) => setDocument(e.target.value)}
                                        >
                                            {ddDocumentOptions.map((res) =>
                                                <option key={res.value} value={res.value}>{res.name}</option>
                                            )}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Required<span className="mandatory">*</span></Form.Label>
                                        <Form.Select
                                            value={docRequired}
                                            onChange={(e) => setDocRequired(e.target.value)}  >
                                            <option key="1" value="1">Yes</option>
                                            <option key="0" value="0">No</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <br></br>

                                    <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() => changeRequired()}>Change</Button>
                                    &nbsp;
                                    <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() => cancelData()}>Cancel</Button>

                                </Col>

                            </Row>
                            <Row>
                                <Col>&nbsp;</Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Table responsive striped bordered hover>
                                        <thead>
                                            <th >Sr No.</th>
                                            <th >Document</th>
                                            <th >Required</th>
                                        </thead>
                                        <tbody >
                                            {documentList.map((res) =>
                                                <tr key={res.docm_id}>
                                                    <td className="textRight">{res.docm_id}</td>
                                                    <td>{res.docm_desc}</td>
                                                    <td>{res.doc_required}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </> :
                        <>
                            <Row>
                                <Col>
                                    <Table responsive striped bordered hover>
                                        <thead>
                                            <tr>
                                                {userRight.edit === "1" ? <th></th> : null}
                                                <th className="textRight">Sr No</th>
                                                <th >Purpose</th>
                                                <th >Status</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {purposeData.map((res) =>
                                                <tr key={res.purpose_id}>
                                                    {userRight.edit === "1" ?
                                                        <td className="textCenter" >
                                                            <span className="handCursor" onClick={() => fillEditForm(res.purpose_id)} >
                                                                <div className="colorThemeBlue"><FontAwesomeIcon icon={faEdit} /></div>
                                                            </span>
                                                        </td>
                                                        : null
                                                    }
                                                    <td className="textRight">{res.purpose_id}</td>
                                                    <td>{res.purpose_name}</td>
                                                    <td>{res.purpose_activedesc}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </>
                }

            </Container>
        </>
    )
}
export default Master_purpose_list;