import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, InputGroup, Row, Table } from 'react-bootstrap'
import Login_header from '../Login_header';
import Menu from '../Menu';
import AsyncSelect from 'react-select/async';
import * as Picklist from '../Picklist';
import { useNavigate } from 'react-router-dom';
import * as Common from "../Common";
import Dialog from '../Dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import $ from "jquery";
import Tran_data_unlock_request from '../Tran_data_unlock_request';



const Tran_currency_stock = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const allowedLocation = sessionStorage.getItem('branchallowed');
    const branchDisable = sessionStorage.getItem('branchDisable');
    const [location, setLocation] = useState({ value: 0, label: "Select" });
    const [selectedValue, setSelectedValue] = useState(null);
    const [userRight, setUserRight] = useState([]);
    const [onceRun, setOnceRun] = useState(false);
    const [showListForm, setShowListForm] = useState(true);
    const [showAddForm, setShowAddForm] = useState(false);
    const [denomination, setDenomination] = useState("");
    const [quantity, setQuantity] = useState("");
    const [rate, setRate] = useState("");
    const [amount, setAmount] = useState("");
    const [locationCode, setLocationCode] = useState("");
    const [stockList, setStockList] = useState([]);
    const [currency, setCurrency] = useState("");
    const [currencyCode, setCurrencyCode] = useState("");
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const [totalAmount, setTotalAmount] = useState("0");
    const [currencyFile, setCurrencyFile] = useState("");
    const [isImportClicked, setImportClicked] = useState(false);


    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    const [viewDate, setViewDate] = useState('');
    const currentDate = new Date();

    const [showEditRequest, setShowEditRequest] = useState(false);
    const [editLogObject, setEditLogObject] = useState({ trantype: "", trannumber: "", location: "", entity_name: "", trandate: "", tdu_status: "" });


    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.getActiveBranch().then(response => {
                setSelectedValue(response);
                response != null && handleChange(response);
            });
            Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_CURRENCY_STOCK'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/'); // no query right
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);


    var msg = [], i = 0;
    const list = () => {
        if (selectedValue == null) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (selectedValue === null ? " Please select location." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        }
        else {
            setShowListForm(false)
            const loc = selectedValue.id.split('^');
            console.log(selectedValue);
            setLocationCode(loc[1]);
            setLocation(loc[2]);
            Common.callApi(Common.apiTranCurrencyStocks, [sid, 'listCurrencyStocks', loc[1]], (result) => {
                let resp = JSON.parse(result);
                console.log(resp);
                // setAmount(resp.prd_amount);
                setStockList(resp.resarray);
                setTotalAmount(resp.sumamount)

            });
        }
    }

    const addCurrencyStock = () => {
        if (denomination == "" || quantity == "" || rate == "") {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (selectedCurrency === null ? " Please select currency." : '');
            msg[i++] = (denomination === "" ? " Please fill denomination." : '');
            msg[i++] = (quantity === "" ? " Please fill quantity." : '');
            msg[i++] = (rate === "" ? " Please fill rate." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            $('.loader').show();
            let curr = selectedCurrency.id.split('^');
            console.log(curr);
            setCurrencyCode(curr[1]);
            setCurrency(curr[2]);
            const obj = {
                deno: denomination,
                qty: quantity,
                rate: rate,
                loc: locationCode,
                amt: amount,
                currency: curr[1],
                productType: 'OPNCN',
                product: 'CN'
            }
            Common.callApi(Common.apiTranCurrencyStocks, [sid, 'addCurrencyStocks', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                console.log(resp);
                setStockList(resp.resarray);
                setTotalAmount(resp.sumamount)
                $('.loader').hide();
                setDenomination("");
                setRate("");
                setQuantity("");
                setAmount("");
                setSelectedCurrency("");
            });
            const object = {
                srno: 1,
                type: 'OPNCN',
                status: 'E',
                currentDate: Common.dateYMD(currentDate),
                pageright: 'TRAN_CURRENCY_STOCK'
            }
            Common.callApi(Common.apiUnlockEdit, [sid, 'expireUnlock', JSON.stringify(object)], (result) => {
                console.log(result);
            })
            // list();
        }
    }


    const uploadFile = () => {
        $('.loader').show();
        if (document.getElementById('importExcel').files[0] === undefined) {
            $('.loader').hide();
            setMsgText("Please select a file to upload.");
            setMsgBox(true);
        } else {
            var tempArray = document.getElementById('importExcel').files[0].name.split('.');
            if (tempArray.length > 2) {
                $('.loader').hide();
                setMsgText("(.) not allowed in file name.");
                setMsgBox(true);
            } else if (document.getElementById('importExcel').files[0].size > 10 * 1024 * 1024) {  // 10 MB
                $('.loader').hide();
                setMsgText("Please select a file with in 10 MB size.");
                setMsgBox(true);
            } else if (tempArray[1] != 'xlsx') {
                $('.loader').hide();
                setMsgText("File extension should be in xlsx format");
                setMsgBox(true);
            }
            else {
                const obj = {
                    loc: locationCode,
                    srno: 8888,
                    uploadType: "uploadCurrency",
                    pageright: "TRAN_OPN_CN_IMPORT", 
                    sid: sid,
                    currencyFile: currencyFile,
                    loc: locationCode,
                    allowedLocation:allowedLocation
                }
                Common.uploadApi(JSON.stringify(obj), 'importExcel', (result) => {
                    let resp = JSON.parse(result);
                    console.log(resp);
                    $('.loader').hide();
                    setStockList(resp.docdetail.resarray);
                    setTotalAmount(resp.docdetail.sumamount);
                    setMsgBox(true);
                    setMsgText(resp.msg)
                });
            }
        }
    }

    const calculateAmount = (deno, qty, rate) => {
        let number = deno * qty * rate;
        var roundedNumber = parseFloat(number.toFixed(2));

        setAmount(roundedNumber);
    }

    const handleDeno = (e) => {

        Common.validateDecValue(e.target.value, setDenomination)

        // setDenomination(e.target.value);

        calculateAmount(e.target.value, quantity, rate);
    }

    const handleQuantity = (e) => {
        Common.validateNumValue(e.target.value, setQuantity)

        // setQuantity(e.target.value);
        calculateAmount(denomination, e.target.value, rate)
    }

    const handleRate = (e) => {
        Common.validateDecValue(e.target.value, setRate)

        // setRate(e.target.value);
        calculateAmount(denomination, quantity, e.target.value)

    }

    const handleChange = value => {
        setSelectedValue(value);
        var id = value.id;
        var location = id.split("^");
        sessionStorage.activeBranch = location[1];
    }

    const handleCurrencyChange = value => {
        setSelectedCurrency(value);
    }


    const handleBack = () => {
        if (showAddForm) {
            setShowAddForm(false);
        } else if (isImportClicked) {
            setImportClicked(false);
        }
        else {
            setShowListForm(true);
        }
    }

    const handleDelete = (id) => {

        Common.callApi(Common.apiTranCurrencyStocks, [sid, 'deleteCurrencyStock', id, locationCode], (result) => {
            let resp = JSON.parse(result);
            console.log(resp);
            setStockList(resp.resarray);
            setTotalAmount(resp.sumamount)

        });
    }

    const handleImport = () => {
        setShowAddForm(false);
        setImportClicked(true);
    }

    const handleAddBtn = () => {
        setShowAddForm(true);
        setImportClicked(false);

    }

    function downloadFormatList() {
        var object1 = {
            srno: 23,
            pageright: 'TRAN_OPN_CN_IMPORT',
            downloadType: "downloadFormat",

        }
        Common.callDownloadApiPost(Common.apiFormat, "post", [sid, 'downloadFormat', JSON.stringify(object1)]);
    }

    const btnEditRequest = () => {
        const currDate = new Date();
        const newDate = viewDate
        // .split('/');

        // const newViewDate = newDate[2] + '-' + newDate[1] + '-' + newDate[0]
        const newCurrDate = Common.dateYMD(currDate);

        const dateA = new Date(newDate);
        const dateB = new Date(newCurrDate);

        // Calculate the difference in milliseconds
        const differenceInMilliseconds = dateB - dateA;

        // Convert milliseconds difference to days
        const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 3600 * 24));

        console.log(differenceInDays);

        const obj = {
            srno: 1,
            type: 'OPNCN',
            pageright: 'TRAN_CURRENCY_STOCK'
        }
        // const message = 'unlock'
        Common.callApi(Common.apiUnlockEdit, [sid, 'checkUnlock', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);


            if (Common.editDateLimit == differenceInDays || resp == "unlock") {
                setShowAddForm(true);
                setImportClicked(false);
            } else {
                let res;
                Common.callApi(Common.apiUnlockEdit, [sid, 'getStatus', JSON.stringify(obj)], (result) => {
                    res = JSON.parse(result);
                    setEditLogObject({ trantype: 'OPNCN', trannumber: 1, location: locationCode, entity_name: location, tranDate: Common.dateYMD(currDate), tdu_status: res });
                    setShowEditRequest(true);
                })
            }
        })


    }

    return (
        <div>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h2>Currency Stock</h2>
                    </Col>
                </Row>
                &nbsp;
                {
                    showListForm ? <>
                        <Row>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Location<span className="text-danger">*</span></Form.Label>
                                    <AsyncSelect
                                        cacheOptions={false}
                                        defaultOptions={false}
                                        value={selectedValue}
                                        getOptionLabel={e => e.label + ' '}
                                        getOptionValue={e => e.id}
                                        loadOptions={Picklist.fetchLocationAllowedPicklist}
                                        onInputChange={Picklist.handleInputChange}
                                        onChange={handleChange}
                                        isDisabled={branchDisable === "true"}
                                    >
                                    </AsyncSelect>
                                </Form.Group>
                            </Col>
                        </Row>
                        &nbsp;
                        <Row>
                            <Col >
                                {
                                    userRight.query === "1" ?
                                        <>
                                            <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => list()} >List</Button>&nbsp;
                                            
                                            <Button variant='outline-danger' className='buttonStyle' size='sm' onClick={downloadFormatList}>Download Format</Button>&nbsp;
                                            <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={handleImport}>Import</Button>&nbsp;

                                        </>
                                        : null}
                            </Col>
                        </Row>
                        &nbsp;
                    </> : <>
                        <Row>
                            <Col className='col-md-3 col-6'>
                                <Form.Label>Location : {location}</Form.Label>
                            </Col>
                        </Row>
                        &nbsp;
                        <Row>
                            <Col>
                                <Button variant='outline-danger' className='buttonStyle' size='sm' onClick={handleBack}>Back</Button>{' '}
                                {
                                    userRight.add === "1" && !showAddForm
                                        ?
                                        <>
                                            <Button variant='outline-success' className='buttonStyle' size='sm'
                                                onClick={btnEditRequest}
                                            >Edit</Button>
                                        </>
                                        : null
                                }&nbsp;
                                {/* {
                                    (!isImportClicked && !showListForm) &&
                                    <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={handleImport}>Import</Button>
                                } */}
                            </Col>
                        </Row>
                        &nbsp;
                    </>
                }

                {isImportClicked &&
                    <>
                        <Col className='col-md-3 col-6'>
                            <Form.Group controlId="importExcel">
                                <Form.Label>Choose File<span className="text-danger">*</span></Form.Label>
                                <Form.Control type="file" value={currencyFile} onChange={e => setCurrencyFile(e.target.value)} />
                            </Form.Group>
                        </Col>&nbsp;
                        <Col >
                            <Button variant="outline-success" className='buttonStyle' size='sm' onClick={() => uploadFile()}>Submit</Button>{' '}
                            {/* <Button variant="outline-danger" className="btn_admin" onClick={() => setImportClicked(false)}>Back</Button> */}
                        </Col>&nbsp;
                    </>
                }

                {
                    showAddForm && <>
                        <Row>

                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Currency<span className="text-danger">*</span></Form.Label>
                                    <AsyncSelect
                                        cacheOptions
                                        defaultOptions
                                        value={selectedCurrency}
                                        getOptionLabel={e => e.label + ' '}
                                        getOptionValue={e => e.id}
                                        // loadOptions={Picklist.fetchLocationAllowedPicklist}
                                        loadOptions={(search) => Picklist.fetchCurrencyAllowedPicklist(search, 'CN')} // location = 1 , 2
                                        onInputChange={Picklist.handleInputChange}
                                        onChange={handleCurrencyChange}
                                    >
                                    </AsyncSelect>
                                </Form.Group>
                            </Col>

                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Denomination<span className="text-danger">*</span></Form.Label>
                                    <Form.Control
                                        value={denomination} onChange={(e) => handleDeno(e)}
                                        placeholder="Denomination"
                                        maxLength={Common.maximumLength}
                                    />
                                </Form.Group>
                            </Col>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Quantity<span className="text-danger">*</span></Form.Label>
                                    <Form.Control
                                        value={quantity} onChange={(e) => handleQuantity(e)}
                                        maxLength={Common.maximumLength}
                                        placeholder="Quantity" />
                                </Form.Group>
                            </Col>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Rate<span className="text-danger">*</span></Form.Label>
                                    <Form.Control
                                        value={rate} onChange={(e) => handleRate(e)}   //e => setRate(e.target.value)}
                                        maxLength={Common.maximumLength}
                                        placeholder="Rate" />
                                </Form.Group>
                            </Col>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Amount</Form.Label>
                                    <Form.Control value={amount} disabled
                                        maxLength={Common.maximumLength}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        &nbsp;
                        <Row>
                            <Col>
                                <Button variant='outline-success' className='buttonStyle' size='sm' onClick={addCurrencyStock} >Add</Button>{' '}
                            </Col>
                        </Row>
                        &nbsp;
                    </>

                }
                {
                    !showListForm &&
                    <Row>
                        <Col>
                            <Table responsive striped bordered hover>
                                <thead>
                                    {
                                        totalAmount != '0' &&
                                        <tr>
                                            <td colSpan={4}></td>
                                            <td style={{ textAlign: 'right' }} colSpan={2}><b>Total Amount:</b> <span>{totalAmount}</span></td>
                                        </tr>
                                    }

                                    <tr>
                                        {userRight.edit === "1" && showAddForm ? <th></th> : null}
                                        <th>ISD</th>
                                        <th style={{ textAlign: 'right' }}>Denomination</th>
                                        <th style={{ textAlign: 'right' }}>Quantity</th>
                                        <th style={{ textAlign: 'right' }}>Rate</th>
                                        <th style={{ textAlign: 'right' }}>Amount</th>
                                        {/* <th>Total amount</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        stockList.map((res) =>
                                            <tr key={res.prd_line_no}>
                                                {userRight.delete === "1" && showAddForm ?
                                                    <td className="textCenter" >
                                                        <span className="handCursor" onClick={() => handleDelete(res.line)} >
                                                            <div className="colorThemeBlue"><FontAwesomeIcon icon={faTrash} /></div>
                                                        </span>
                                                    </td>
                                                    : null
                                                }
                                                <td>{res.prd_isd}</td>
                                                <td style={{ textAlign: 'right' }}>{res.prd_deno}</td>
                                                <td style={{ textAlign: 'right' }}>{res.prd_qty}</td>
                                                <td style={{ textAlign: 'right' }}>{res.prd_rate}</td>
                                                <td style={{ textAlign: 'right' }}>{res.prd_amount}</td>
                                                {/* <td>{totalAmount}</td> */}
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                }

            </Container>

            <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
                <Tran_data_unlock_request logObject={editLogObject} show={showEditRequest} hdrNotExist={true} onHide={() => setShowEditRequest(false)} />
            </div>
        </div>
    )
}

export default Tran_currency_stock
