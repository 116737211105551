import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Table, Container, Row, Col, Form } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';

import Login_header from '../Login_header';
import Menu from '../Menu';

import * as Common from '../Common';
import Dialog from '../Dialog';
import DialogYesNo from '../Dialog_Yes_No';
import Select from 'react-select';


function Master_group_right() {
    const navigate = useNavigate();
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    const sid = sessionStorage.getItem('sessionId');
    const [onceRun, setOnceRun] = useState(false);
    const [scrMode, setScrMode] = useState("Q");  // "Q A E "

    const [groupData, setGroupData] = useState([]);
    const [rightData, setRightData] = useState([]);
    const [showAddEditForm, setAddEditFormShow] = useState(false);
    const [showAddNewBtn, setShowAddNewBtn] = useState(true);

    const [srNo, setSrNo] = useState(0);
    const [group, setGroup] = useState("");
    const [right, setRight] = useState(0);
    const [ddRightOptions, setDdRightOptions] = useState([]);
    const [rightQuery, setRightQuery] = useState(1);
    const [rightAdd, setRightAdd] = useState(1);
    const [rightEdit, setRightEdit] = useState(1);
    const [rightDelete, setRightDelete] = useState(1);

    const [userRight, setUserRight] = useState([]);




    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.callApi(Common.apiRight, [sid, 'all', 'RIGHT'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/'); // no query right
                }
            });
            Common.callApi(Common.apiRight, [sid, 'ddlistjson'], function (result) {
            // jitendra
                // const list=JSON.parse(result).map(item=>item.name)
                console.log(JSON.parse(result))
                setDdRightOptions(JSON.parse(result));
            });
            Common.callApi(Common.apiRightGroup, [sid, 'listjson'], function (result) {
                setGroupData(JSON.parse(result));
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);

    function getList() {
        Common.callApi(Common.apiRightGroup, [sid, 'listjson'], function (result) {
            setGroupData(JSON.parse(result));
        });
    }



    function cancelData() {
        setAddEditFormShow(false);
        setShowAddNewBtn(true);
        setScrMode("Q");
    }

    function saveData() {
        if (group === "") {
            setMsgText("Plese fill Group!.");
            setMsgBox(true);
        } else {
            var object1 = {
                srno: srNo,
                group: group
            };
            Common.callApi(Common.apiRightGroup, [sid, 'save', srNo, JSON.stringify(object1)], function (result) {
                let resp = JSON.parse(result);
                if (resp.err === "") {
                    setScrMode("Q");
                    setAddEditFormShow(false);
                    setShowAddNewBtn(true);
                    getList();
                } else {
                    setMsgText(resp.err);
                    setMsgBox(true);
                }
            });
        }

    }

    function addNew() {
        setSrNo(0);
        setGroup("");
        setRightData([]);
        setAddEditFormShow(true);
        setShowAddNewBtn(false);
        setScrMode("A");
    }

    function fillEditForm(id) {
        Common.callApi(Common.apiRightGroup, [sid, 'detail', id], function (result) {
            let resp = JSON.parse(result);
            setSrNo(resp.srno);
            setGroup(resp.group);
            setRightData(resp.detail);
            setAddEditFormShow(true);
            setShowAddNewBtn(false);
            setScrMode("E");
        });
    }

    function changeRight() {
        if (right === 0) {
            setMsgText(Common.getMessage("MSG0006"));
            setMsgBox(true);
        } else {
            var object1 = {
                srno: srNo,
                right: right.value,
                rightQuery: rightQuery,
                rightAdd: rightAdd,
                rightEdit: rightEdit,
                rightDelete: rightDelete
            };
            Common.callApi(Common.apiRightGroup, [sid, 'saveright', JSON.stringify(object1)], function (result) {
                fillEditForm(srNo);
            });
        }
    }




    return (
        <>
            <Login_header />
            <Menu></Menu>
            <Container fluid >
                <Row>
                    <Col>
                        <h1>Right Group Master</h1>
                    </Col>
                </Row>
                {showAddNewBtn ?
                    <Row>
                        <Col>
                            {userRight.add === "1" ?
                                <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={addNew}>Add New</Button>
                                : null}
                        </Col>
                    </Row>
                    : null
                }
                <Row><Col>&nbsp;</Col></Row>
                {showAddEditForm ?
                    <>
                        <Row>
                            <Col className="divContentCenter">
                                <div className="maxFormWidth">
                                    <Form>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Serial Number</Form.Label>
                                                    <Form.Control type="text" placeholder="Serial Number" value={srNo} disabled />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Group<span className="mandatory text-danger">*</span></Form.Label>
                                                    <Form.Control type="text" placeholder="Group"
                                                        value={group} onChange={(e) => setGroup(e.target.value)} autoComplete="off"
                                                        disabled={scrMode === "E" || scrMode === "Q"} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>&nbsp;</Col>
                                        </Row>
                                        {(scrMode === "A") ?
                                            <Row>
                                                <Col>
                                                    <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() => saveData()}>Save</Button>&nbsp;
                                                    <Button variant="outline-danger" className='buttonStyle' size="sm" onClick={() => cancelData()}>Back</Button>
                                                </Col>
                                            </Row>
                                            : null
                                        }
                                        {(scrMode === "E") ?
                                            <Row>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>Right<span className="mandatory">*</span></Form.Label>
                                                        {/* <Form.Select
                                                            value={right}
                                                            onChange={(e) => setRight(e.target.value)}   >
                                                            {ddRightOptions.map((res) =>
                                                                <option key={res.value} value={res.value}>{res.name}</option>
                                                            )}
                                                            jitendra
                                                        </Form.Select>                                                         */}     
                                                        <Select options={ddRightOptions} value={right} onChange={v => setRight(v)} />                            
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>Query<span className="mandatory">*</span></Form.Label>
                                                        <Form.Select
                                                            value={rightQuery}
                                                            onChange={(e) => setRightQuery(e.target.value)}  >
                                                            <option key="1" value="1">Yes</option>
                                                            <option key="0" value="0">No</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>Add<span className="mandatory">*</span></Form.Label>
                                                        <Form.Select
                                                            value={rightAdd}
                                                            onChange={(e) => setRightAdd(e.target.value)}   >
                                                            <option key="1" value="1">Yes</option>
                                                            <option key="0" value="0">No</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>Edit<span className="mandatory">*</span></Form.Label>
                                                        <Form.Select
                                                            value={rightEdit}
                                                            onChange={(e) => setRightEdit(e.target.value)}   >
                                                            <option key="1" value="1">Yes</option>
                                                            <option key="0" value="0">No</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>Delete<span className="mandatory">*</span></Form.Label>
                                                        <Form.Select
                                                            value={rightDelete}
                                                            onChange={(e) => setRightDelete(e.target.value)}   >
                                                            <option key="1" value="1">Yes</option>
                                                            <option key="0" value="0">No</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col style={{ marginTop: "33px" }}>
                                                    <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() => changeRight()}>Change</Button>
                                                    &nbsp;
                                                    <Button variant="outline-danger" className='buttonStyle' size="sm" onClick={() => cancelData()}>Cancel</Button>


                                                </Col>
                                            </Row>
                                            : null
                                        }

                                        <Row>
                                            <Col>&nbsp;</Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table responsive striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th className="textRight">Sr No</th>
                                            <th>Key</th>
                                            <th>Right</th>
                                            {/* <th>Parent</th>
                                            <th>Level</th> */}
                                            <th>Query</th>
                                            <th>Add</th>
                                            <th>Edit</th>
                                            <th>Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody id="tblBody">
                                        {rightData.map((res) =>
                                            <tr key={res.right_srno}>
                                                <td className="textRight">{res.right_srno}</td>
                                                <td>{res.right_key}</td>
                                                <td>{res.right_desc}</td>
                                                {/* <td>{res.right_parent}</td>
                                                <td>{res.right_level}</td> */}
                                                <td>{res.right_query}</td>
                                                <td>{res.right_add}</td>
                                                <td>{res.right_edit}</td>
                                                <td>{res.right_delete}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </>
                    : null}

                {scrMode === "Q" ?
                    <Row>
                        <Col >
                            <Table responsive striped bordered hover>
                                <thead>
                                    <tr>
                                        {userRight.edit === "1" ? <th></th> : null}
                                        <th className="textRight">Sr No</th>
                                        <th >Group</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {groupData.map((res) =>
                                        <tr key={res.group_srno}>
                                            {userRight.edit === "1" ?
                                                <td className="textCenter" >
                                                    <span className="handCursor" onClick={() => fillEditForm(res.group_srno)} >
                                                        <div className="colorThemeBlue"><FontAwesomeIcon icon={faEye} /></div>
                                                    </span>
                                                </td>
                                                : null
                                            }
                                            <td className="textRight">{res.group_srno}</td>
                                            <td>{res.group_name}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    : null
                }

            </Container>
            <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
            </div>


        </>
    );
}
export default Master_group_right;