import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from "jquery";
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import ActivityLog from '../ActivityLog';
import * as Common from "../Common";
import Dialog from '../Dialog';
import DialogYesNo from '../Dialog_Yes_No';
import FaView from '../FaView';
import Login_header from '../Login_header';
import Menu from '../Menu';
import * as Picklist from '../Picklist';
import Tran_data_unlock_request from '../Tran_data_unlock_request';

const Cheque_Deposit = () => {
    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const user = sessionStorage.getItem('userSrno');
    const finStartDate = sessionStorage.getItem("startDate");
    const finEndDate = sessionStorage.getItem("endDate");
    const branchDisable = sessionStorage.getItem('branchDisable');
    const [userRight, setUserRight] = useState([]);
    const [srNo, setSrNo] = useState("0");
    const [mainSrNo, setMainSrNo] = useState('0');
    const [counter, setCounter] = useState(0);
    const [scrMode, setScrMode] = useState("");
    const [selectedValue, setSelectedValue] = useState(null);
    const [filterDate, setFilterDate] = useState(new Date());
    const [srnoFrom, setSrnoFrom] = useState("");
    const [srnoTo, setSrnoTo] = useState("");
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    const currentDate = new Date();
    const [showHeaderList, setShowHeaderList] = useState(false);
    const [headerList, setHeaderList] = useState([]);
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");
    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");
    const [radioType, setRadioType] = useState("N");
    const [location, setLocation] = useState("");
    const [locationCode, setLocationCode] = useState("");
    const [totalDebit, setTotalDebit] = useState("0");
    const [totalCredit, setTotalCredit] = useState("0");
    const [totalDifference, setTotalDifference] = useState("0");
    const [ledgerValue, setLedgerValue] = useState(null);
    const [showList, setShowList] = useState(false);
    const [bookList, setBookList] = useState([]);
    const [viewBank, setViewBank] = useState("");
    const [viewDate, setViewDate] = useState("");
    const [addCount, setAddCount] = useState("0");
    const [amount, setAmount] = useState("");
    const [viewSrno, setViewSrno] = useState("");
    const [mvgType, setMvgType] = useState("");
    const [bankName, setBankName] = useState("");
    const [chequeStatus, setChequeStatus] = useState(null);
    const [chequeList, setChequeList] = useState(null);
    const [onceRun, setOnceRun] = useState(false);
    const [chequeNo, setChequeNo] = useState("");
    const [chequeDate, setChequeDate] = useState("");
    const [chequeBank, setChequeBank] = useState("");
    const [random, setRandom] = useState("");
    const [lineNum, setLineNum] = useState("");
    const [maintype, setMainType] = useState("");
    const [randomString, setRandomString] = useState("");
    const [selectedList, setSelectedList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [groupCode, setGroupCode] = useState("");
    const [accountCode, setAccountCode] = useState("");
    const [chequeGroup, setChequeGroup] = useState("");
    const [chequeAccount, setChequeAccount] = useState("");
    const [chequeLocation, setChequeLocation] = useState("");
    const [showModalLog, setShowModalLog] = useState(false);
    const [acRemark, setAcRemark] = useState("");
    const [code, setCode] = useState(0);
    const [logObject, setLogObject] = useState({ trantype: "", trannumber: "", trancategory: "" });
    const [showActivityLog, setShowActivityLog] = useState(false);
    const [faViewObject, setFaViewObject] = useState({ location: "", trannumber: "", type: "" });
    const [showFaView, setShowFaView] = useState(false);
    const [lineNo, setLineNo] = useState(0);
    const [counter1, setCounter1] = useState(0);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const [delClicked, setDelClicked] = useState(false);
    const [delKey, setDelKey] = useState("");
    const [editDate, setEditDate] = useState('');
    const [showEditRequest, setShowEditRequest] = useState(false);
    const [editLogObject, setEditLogObject] = useState({ trantype: "", trannumber: "", trancategory: "", tranDate: "" });


    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.getActiveBranch().then(response => {
                setSelectedValue(response);
                response != null && handleChange(response);
            });
            Common.callApi(Common.apiRight, [sid, 'all', 'CHEQUE_DEPOSIT'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);

    const handleAddNew = () => {
        var msg = [], i = 0;
        var d = Common.dateYMD(filterDate);
        if (selectedValue === null || ledgerValue === null || !filterDate) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (selectedValue === null ? "Location." : '');
            msg[i++] = (ledgerValue === null ? " Bank." : '');
            msg[i++] = (!filterDate ? "Date." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (d > finEndDate || d < finStartDate) {
            setMsgText(Common.getMessage("MSG0010"));
            setMsgBox(true);
        } else {
            setLineNo(0);
            setScrMode('A');
            setSrNo(0);
            setRandomString(user + "_CHKDP_" + Common.getRandomString(15));
            setShowHeaderList(false);
            setShowList(true);
            setBookList([]);
            setTotalCredit('0');
            setTotalDebit('0');
            setTotalDifference('0');
            setViewBank(bankName);
            setChequeStatus('D');
            setViewDate(Common.dateDMY(filterDate));
        }
        setAddCount(0);
        setCode(0);
    }

    const handleAdd = () => {
        $(".loader").show();
        // console.log(`${chequeDate} chequeDate`);
        // console.log(`${Common.dateDMY(filterDate)} filterDate`);
        if (chequeList == null) {
            $(".loader").hide();
            setMsgText("Please Select Cheque.");
            setMsgBox(true);
        } else {
            setAddCount(addCount + 1);
            $(".loader").hide();
            setRandomString(randomString);
            const object = {
                type: maintype,
                randomString: randomString,
                line: lineNum,
                srno: mainSrNo,
                location: chequeLocation
            }
            Common.callApi(Common.apiChequeDeposit, [sid, 'addRandom', JSON.stringify(object)], (result) => {
                let resp = JSON.parse(result);
            });
            const obj = {
                uniquekey: Common.getRandomString(15),
                tfd_line_no: lineNo * 1 + 1,
                tfd_cheque_deposit_date: Common.dateYMD(filterDate),
                tfd_amount: amount,
                srno: srNo,
                tfd_cheque_status: 'D',
                tfd_cheque_no: chequeNo,
                formatted_date: chequeDate,
                tfd_cheque_bank_name: chequeBank,
                tcd_ref_line_no: lineNum,
                tfd_type: maintype,
                tfd_srno: mainSrNo,
                tfd_group: chequeGroup,
                tfd_account: chequeAccount,
                tcd_ref_location: chequeLocation,
                delny: 'Y',
            }
            console.log(obj.deposit_line_no);
            setBookList(Common.arrayAddItem(bookList, obj));
            setCounter(counter + 1);
            setShowList(true);
            setChequeList(null);
            setLineNo(1 * lineNo + 1);
        }
    }

    const handleSave = () => {
        if (addCount == '0') {
            setMsgText("You can't save without adding data");
            setMsgBox(true);
        } else if (scrMode === 'E' && acRemark === '') {
            setShowModalLog(true);
            $(".loader").hide();
        } else {
            const obj = {
                remark: acRemark,
                activitytype: code == 1 ? 'MODIFY' : 'CREATE',
                subtranno: code,
                trannumber: srNo,
                trantype: 'CHKDP',
                trancategory: 'T',
                branch: locationCode,

                location: locationCode,
                date: Common.dateYMD(filterDate),
                type: 'CHKDP',
                bookList: bookList,
                radioType: radioType,
                scrMode: scrMode,
                srNo: srNo,
                totalCredit: totalCredit,
                totalDebit: totalDebit,
                random: random,
                group: groupCode,
                account: accountCode,
                delClicked: delClicked,
                tfd_cheque_status: 'I',
                tfd_cheque_deposit_date: null,
                uniquekey: delKey,
            }
            Common.callApi(Common.apiChequeDeposit, [sid, 'saveChequeDeposit', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                console.log(resp);
                if (radioType == 'N') {
                    setHeaderList(resp);
                    setShowList(false);
                    setScrMode('');
                    setShowHeaderList(true);
                } else {
                    setBookList(resp.resarray);
                    setLineNo(resp.maxlineno);
                    setScrMode('Q');
                }
                setAddCount(0);
                setAcRemark('');
            });
            if (scrMode == 'E') {
                const obj = {
                    srno: srNo,
                    type: 'CHKDP',
                    status: 'E',
                    currentDate: Common.dateYMD(currentDate),
                    pageright: 'CHEQUE_DEPOSIT'
                }
                Common.callApi(Common.apiUnlockEdit, [sid, 'expireUnlock', JSON.stringify(obj)], (result) => {
                    console.log(result);
                })
            }
        }
    }

    const handleShowList = () => {
        var msg = [], i = 0;
        if (selectedValue === null || (radioType === 'S' && srnoFrom === '') || (radioType === 'S' && srnoTo === '') || (radioType === 'D' && !fromDate ||
            radioType === 'D' && !toDate)) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (selectedValue === null ? " Location." : '');
            msg[i++] = (radioType === 'S' && !srnoFrom ? " From Srno." : '');
            msg[i++] = (radioType === 'S' && !srnoTo ? " To Srno." : '');
            msg[i++] = (radioType === 'D' && !fromDate ? " From Date." : '');
            msg[i++] = (radioType === 'D' && !toDate ? " To Date." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const obj = {
                date: Common.dateYMD(filterDate),
                type: 'CHKDP',
                location: locationCode,
                radioType: radioType,
                fromSrno: srnoFrom,
                toSrno: srnoTo,
                fromDate: Common.dateYMD(fromDate),
                toDate: Common.dateYMD(toDate)
            }
            Common.callApi(Common.apiChequeDeposit, [sid, 'showChequeDepositHeader', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                setHeaderList(resp);
                setShowHeaderList(true);
            });
        }
    }

    const handleDelete = (val) => {
        setBookList(Common.arrayRemoveItem(bookList, "uniquekey", val));
        setCounter(counter - 1);
        setDelClicked(true);
        setDelKey(val);
    }

    const handleBack = () => {
        setScrMode('');
        setRadioType('N');
        setLedgerValue(null);
        setSelectedValue(null);
    }

    const handleCancel = () => {
        setMsgBoxYN(true);
        setMsgTextYN("Do you really want to exit");
    }

    const cancelData = () => {
        setScrMode('');
        setRadioType('N');
        setMsgBoxYN(false);
        setLedgerValue(null);
        setSelectedValue(null);
    }

    const handleView = (vsrno, vdate, vbank, date) => {
        $(".loader").show();
        setEditDate(date)
        if (radioType == 'N') {
            setRadioType('A');
        }
        setSrNo(vsrno);
        const obj = {
            srNo: vsrno,
            location: locationCode,
            date: vdate,
            scrMode: scrMode,
            lineNum: lineNum,
            type: 'CHKDP'
        }
        Common.callApi(Common.apiChequeDeposit, [sid, 'showChequeDeposit', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);
            setBookList(resp.resarray);
            setLineNo(resp.maxlineno);
            $(".loader").hide();
            setScrMode('Q');
            setShowHeaderList(false);
            setShowList(true);
            setViewSrno(vsrno);
            setViewDate(vdate);
            setViewBank(vbank);


            // setNarration(vnarration);
            // calculateTotal(resp);
        });
    }

    const handleChange = value => {
        setSelectedValue(value);
        const loc = value.id.split('^');
        setLocationCode(loc[1]);
        setLocation(loc[2]);
        sessionStorage.activeBranch = loc[1];
    }

    const handleLedgerValue = (value) => {
        setLedgerValue(value);
        const account = value.id.split('^');
        console.log(account);
        setGroupCode(account[1]);
        setAccountCode(account[2]);
        const accountType = account[4];
        const bank = account[3];
        setBankName(bank);
        setMvgType(accountType);
    }

    const handleChequeList = (value) => {
        setChequeList(value);
        const account = value.id.split('^');
        console.log(account);
        setChequeNo(account[1]);
        setChequeDate(account[4]);
        setChequeBank(account[2]);
        setRandom(account[5]);
        setMainSrNo(account[6]);
        setLineNum(account[7]);
        setMainType(account[8]);
        setChequeGroup(account[9]);
        setChequeAccount(account[10]);
        setChequeLocation(account[11]);
        const amt = account[3];
        setAmount(amt);
    }

    const handleSelective = () => {
        var obj = {
        }
        Common.callApi(Common.apiChequeDeposit, [sid, 'showChequeSelective', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);
            setSelectedList(resp);
            handleShow();
        });
    }

    function selectAllToggle(v) {
        if (v) {
            $('.selectorChkBox').attr('checked', true);
        } else {
            $('.selectorChkBox').attr('checked', false);
            setSelectedList([]);
        }
        setCounter1(counter1 + 1);
    }

    const handleCheckboxChange = (ch_lineNo, ch_srno, ch_location, ch_type, v) => {
        if (v) {
            $('#id_' + ch_lineNo + '_' + ch_srno).attr('checked', true);
        } else {
            $('#id_' + ch_lineNo + '_' + ch_srno).removeAttr('checked');
        }
        setCounter1(counter1 + 1);
    }

    const handleSaveChanges = () => {
        setAddCount(addCount + 1);
        const obj = {
            srno: srNo,
            selectedList: selectedRows,
            location: locationCode,
            type: 'CHKDP',
            tfd_cheque_deposit_date: Common.dateYMD(filterDate),//
            tfd_cheque_no: chequeNo,//
            tfd_amount: amount,//
            tfd_cheque_bank_name: chequeBank,//
        }
        selectedList.map(item => {
            setBookList(Common.arrayAddItem(bookList, {
                "tfd_cheque_bank_name": item.tfd_cheque_bank_name,
                "tfd_cheque_no": item.tfd_cheque_no,
                "formatted_date": item.formatted_date,
                "tfd_amount": item.tfd_amount,
                "tfd_cheque_status": chequeStatus,
                "tfd_line_no": item.tfd_line_no,
                "tfd_type": item.tfd_type,
                "tfd_srno": item.tfd_srno,
                "srno": srNo,
                "tcd_ref_location": item.tcd_ref_location,
                "tfd_cheque_deposit_date": Common.dateYMD(filterDate),
                "tfd_group": item.tfd_group,
                "tfd_account": item.tfd_account
            })
            );
        })
        handleClose();
    }

    const btnSaveModal = () => {
        if (scrMode === 'E' && acRemark === '') {
            setShowModalLog(false);
            setMsgText('Please fill Activity Remark!');
            setMsgBox(true);
        } else {
            handleSave();
            setShowModalLog(false);
        }
    }

    const btnActivityLog = () => {
        setShowActivityLog(true);
        setLogObject({ trantype: 'CHKDP', trannumber: srNo, trancategory: "T" });
    }

    const handleFaView = () => {
        setShowFaView(true);
        setFaViewObject({ location: locationCode, trannumber: viewSrno, type: 'CHKDP' });
    }

    const btnEditRequest = () => {
        const currDate = new Date();
        const newDate = viewDate.split('/');

        const newViewDate = newDate[2] + '-' + newDate[1] + '-' + newDate[0]
        const newCurrDate = Common.dateYMD(currDate);

        const dateA = new Date(newViewDate);
        const dateB = new Date(newCurrDate);

        // Calculate the difference in milliseconds
        const differenceInMilliseconds = dateB - dateA;

        // Convert milliseconds difference to days
        const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 3600 * 24));
        // console.log(differenceInDays);
        const obj = {
            srno: viewSrno,
            type: 'CHKDP',
            pageright: 'CHEQUE_DEPOSIT'
        }
        // const message = 'unlock'
        Common.callApi(Common.apiUnlockEdit, [sid, 'checkUnlock', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);
            if (Common.editDateLimit == differenceInDays || resp == "unlock") {
                setCode(1);
                setScrMode('E');
                setAddCount(1);
                setRandomString(user + "_CHKDP_" + Common.getRandomString(15));
            } else {
                setShowEditRequest(true);
                setEditLogObject({ trantype: 'CHKDP', trannumber: viewSrno, location: locationCode, tranDate: Common.dateYMD(currDate) });
            }
        })
    }


    return (
        <div>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h2>Cheque Deposit <span style={{ color: 'white' }}>{counter}</span></h2>
                    </Col>
                </Row>&nbsp;
                {
                    scrMode == "" ?
                        <>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Location<span className="text-danger">*</span></Form.Label>
                                        <AsyncSelect
                                            cacheOptions={false}
                                            defaultOptions={false}
                                            value={selectedValue}
                                            getOptionLabel={e => e.label + ' '}
                                            getOptionValue={e => e.id}
                                            loadOptions={Picklist.fetchLocationAllowedPicklist}
                                            onInputChange={Picklist.handleInputChange}
                                            onChange={(e) => handleChange(e)}
                                            isDisabled={branchDisable === "true"}
                                        >
                                        </AsyncSelect>
                                    </Form.Group>
                                </Col>
                                {
                                    radioType == 'N' ?
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Bank<span className="text-danger">*</span></Form.Label>
                                                <AsyncSelect
                                                    cacheOptions
                                                    defaultOptions
                                                    value={ledgerValue}
                                                    getOptionLabel={e => e.label + ' '}
                                                    getOptionValue={e => e.id}
                                                    loadOptions={(search) => Picklist.fetchBankPicklist(search, 1)} // location = 1 , 2
                                                    onInputChange={Picklist.handleInputChange}
                                                    onChange={handleLedgerValue}
                                                >
                                                </AsyncSelect>
                                            </Form.Group>
                                        </Col> : null
                                }
                                <Col className='col-md-4 col-6'>
                                    <Form.Group>
                                        <Row style={{ marginTop: "30px" }}>
                                            <Col>
                                                <Form.Check checked={radioType === "N"} onChange={() => setRadioType("N")} type='radio' label="New" selected />
                                            </Col>
                                            <Col>
                                                <Form.Check checked={radioType === "S"} onChange={() => setRadioType("S")} type='radio' label="SrNo" />
                                            </Col>
                                            <Col>
                                                <Form.Check checked={radioType === "D"} onChange={() => setRadioType("D")} type='radio' label="Date" />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>&nbsp;
                            <Row>
                                {
                                    radioType === "N" ?
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Deposit Date<span className="colorThemeRed">*</span></Form.Label><br />
                                                <DatePicker className="form-control"
                                                    selected={filterDate}
                                                    onChange={(date) => setFilterDate(date)}
                                                    dateFormat="dd/MM/yyyy"
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    useShortMonthInDropdown
                                                    dropdownMode="select"
                                                    placeholder="dd/mm/yyyy"
                                                    peekNextMonth
                                                    customInput={
                                                        <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        : radioType === "S" ?
                                            <>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                                                        <Form.Control value={srnoFrom}
                                                            onChange={(e) => Common.validateNumValue(e.target.value, setSrnoFrom)}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>To<span className="colorThemeRed">*</span></Form.Label>
                                                        <Form.Control value={srnoTo}
                                                            onChange={(e) => Common.validateNumValue(e.target.value, setSrnoTo)}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </>
                                            : radioType === "D" ?
                                                <>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>From Date<span className="colorThemeRed">*</span></Form.Label><br />
                                                            <DatePicker className="form-control"
                                                                selected={fromDate}
                                                                onChange={(date) => setFromDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>To Date<span className="colorThemeRed">*</span></Form.Label><br />
                                                            <DatePicker className="form-control"
                                                                selected={toDate}
                                                                onChange={(date) => setToDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </>
                                                : null
                                }
                            </Row>&nbsp;
                            <Row>
                                <Col>
                                    {
                                        radioType == 'N' ?
                                            <Button variant='outline-success' className='buttonStyle' size='sm'
                                                onClick={handleAddNew}
                                            >Add New</Button>
                                            : <Button variant='outline-primary' className='buttonStyle' size='sm'
                                                onClick={handleShowList}
                                            >List</Button>
                                    }
                                </Col>
                            </Row>&nbsp;
                            {
                                showHeaderList &&
                                <Row>
                                    <Col>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    {userRight.add === "1" ? <th></th> : null}
                                                    <th>Srno.</th>
                                                    <th>Type</th>
                                                    <th>Date</th>
                                                    <th>Group</th>
                                                    <th>Ledger</th>
                                                    <th>Bank Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {headerList.map((res) =>
                                                    <tr key={res.tfd_line_no}>
                                                        {userRight.delete === "1" ?
                                                            <td className="textCenter" >
                                                                {
                                                                    <span className="handCursor"
                                                                        onClick={() => handleView(res.hdr_srno, res.formatted_date, res.mvli_ledger_name, res.hdr_date)}
                                                                    >
                                                                        <div className="colorThemeBlue"><FontAwesomeIcon icon={faEye} /></div>
                                                                    </span>
                                                                }
                                                            </td>
                                                            : null
                                                        }
                                                        <td>{res.hdr_srno}</td>
                                                        <td>{res.hdr_type}</td>
                                                        <td>{res.formatted_date}</td>
                                                        <td>{res.hdr_group}</td>
                                                        <td>{res.hdr_ledger}</td>
                                                        <td>{res.mvli_ledger_name}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            }
                        </> :
                        <>
                            <Row>
                                <Col >
                                    {
                                        scrMode == 'A' || scrMode == 'E' ?
                                            <Button variant='outline-danger' className='buttonStyle' size='sm' onClick={handleCancel} >Cancel</Button>
                                            :
                                            <Button variant='outline-danger' className='buttonStyle' size='sm' onClick={handleBack} >Back</Button>

                                    }&nbsp;
                                    {
                                        (userRight.edit === "1" && scrMode === 'Q') ?
                                            <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() => btnEditRequest()}>Edit</Button> : null
                                    }
                                    &nbsp;
                                    {
                                        (userRight.edit === "1" && scrMode === 'E') || (userRight.add === "1" && scrMode === 'A') ?
                                            <Button variant='outline-success' className='buttonStyle' size='sm' onClick={handleSave} >Save</Button> : null
                                    }
                                    &nbsp;
                                    {
                                        (userRight.query === "1" && (scrMode === 'E' || scrMode === 'Q')) ? <>
                                            <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                btnActivityLog()}>Activity Log</Button>
                                        </> : null
                                    }
                                    &nbsp;
                                    {
                                        (userRight.query === "1" && scrMode === 'Q') ? <>
                                            <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                handleFaView()}>View</Button>
                                        </> : null
                                    }

                                </Col>
                            </Row>&nbsp;
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>SrNo : {srNo}</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Bank : {viewBank}</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Location : {location}</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Date : {viewDate}</Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row>&nbsp;
                            {scrMode == 'E' || scrMode == 'A' ?
                                <>
                                    <Row>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Cheque<span className="text-danger">*</span></Form.Label>
                                                <AsyncSelect
                                                    cacheOptions
                                                    defaultOptions
                                                    value={chequeList}
                                                    getOptionLabel={e => e.label + ' '}
                                                    getOptionValue={e => e.id}
                                                    loadOptions={(search) => Picklist.fetchChequePicklist(search, 1, randomString, Common.dateYMD(filterDate))} // location = 1 , 2   
                                                    onInputChange={Picklist.handleInputChange}
                                                    onChange={handleChequeList}
                                                >
                                                </AsyncSelect>
                                            </Form.Group>
                                        </Col>
                                    </Row>&nbsp;
                                    <Row>
                                        <Col>
                                            <Button variant='outline-success' className='buttonStyle' size='sm' onClick={handleAdd} >Add</Button>{' '}
                                            <Button variant='outline-success' className='buttonStyle' size='sm' onClick={handleSelective} >Selective</Button>{' '}
                                        </Col>
                                    </Row>&nbsp;

                                </> : null
                            }
                            {
                                showList &&
                                <Row>
                                    <Col>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    {userRight.delete === "1" ? <th></th> : null}
                                                    <th>Cheque No.</th>
                                                    <th>Bank</th>
                                                    <th>Cheque Date</th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {bookList.map((res) =>
                                                    <tr key={res.tfd_line_no}>
                                                        {userRight.delete === "1" ?
                                                            <td className="textCenter" >
                                                                {
                                                                    (scrMode == 'E' || scrMode == 'A') && res.delny == "Y" ?
                                                                        <span className="handCursor colorThemeBlue" onClick={() => handleDelete(res.uniquekey)}>
                                                                            <FontAwesomeIcon icon={faTrash} />
                                                                        </span>
                                                                        : null
                                                                }
                                                            </td>
                                                            : null
                                                        }
                                                        <td>{res.tfd_cheque_no}</td>
                                                        <td>{res.tfd_cheque_bank_name}</td>
                                                        <td>{res.formatted_date}</td>
                                                        <td>{res.tfd_amount}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            }
                        </>
                }
            </Container>
            <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                {msgText}
            </Dialog>
            <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
                {msgTextYN}
            </DialogYesNo>
            <Tran_data_unlock_request logObject={editLogObject} show={showEditRequest} onHide={() => setShowEditRequest(false)} />

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading {counter1}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    &nbsp;
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <td><input type="checkbox" onChange={(e) => selectAllToggle(e.target.checked)} /></td>
                                <th>Cheque No</th>
                                <th>Bank Name</th>
                                <th>Cheque Date</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedList.map((res) => (
                                <tr key={res.tfd_line_no}>
                                    <td><input type="checkbox" value={res.tfd_srno} id={'id_' + res.tfd_line_no + '_' + res.tfd_srno} className="selectorChkBox"
                                        onChange={(e) => handleCheckboxChange(res.tfd_line_no, res.tfd_srno, res.tcd_ref_location, res.tfd_type, e.target.checked)} /></td>
                                    <td>{res.tfd_cheque_no}</td>
                                    <td>{res.tfd_cheque_bank_name}</td>
                                    <td>{res.formatted_date}</td>
                                    <td>{res.tfd_amount}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSaveChanges}>
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>

            <ActivityLog logObject={logObject} show={showActivityLog} onHide={() => setShowActivityLog(false)} />

            <FaView faViewObject={faViewObject} show={showFaView} onHide={() => setShowFaView(false)} />

            <div>
                <Modal size='lg' show={showModalLog} onHide={() => setShowModalLog(false)} animation={false} centered>
                    <Modal.Body>
                        {
                            scrMode === 'E' ?
                                <>
                                    <Form.Group>
                                        <Form.Label>Reason<span className="text-danger">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="Reason" maxLength={499}
                                            value={acRemark}
                                            onChange={(e) => Common.validateAlpValue(e.target.value, setAcRemark)}
                                            autoComplete="off"
                                            required />
                                    </Form.Group>
                                </> :
                                null
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-success" onClick={() => btnSaveModal()}>
                            Save
                        </Button>{' '}
                        <Button variant="outline-success" onClick={() => setShowModal(false)}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
        </div>
    )
}

export default Cheque_Deposit
