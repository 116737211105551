import React, { useState } from 'react'
import Login_header from '../Login_header'
import Menu from '../Menu'
import { Button, Col, Container, Dropdown, Form, Row, Table } from 'react-bootstrap'
import Select from "react-select";
import * as Common from "../Common";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import AsyncSelect from 'react-select/async';
import * as Picklist from '../Picklist';
import Dialog from '../Dialog';
import DialogYesNo from '../Dialog_Yes_No';
import $ from "jquery";
import Tran_data_unlock_request from '../Tran_data_unlock_request';



const Tran_opn_trial = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const branchDisable = sessionStorage.getItem('branchDisable');
    const [onceRun, setOnceRun] = useState(false);
    const [trialData, setTrialData] = useState([]);
    const [location, setLocation] = useState({ value: 0, label: "Select" });
    const [flag, setFlag] = useState("1");
    const [amount, setAmount] = useState("");
    const [userRight, setUserRight] = useState([]);
    const [showListForm, setShowListForm] = useState(true);
    const [showAddForm, setShowAddForm] = useState(false);
    const [totalDebit, setTotalDebit] = useState("0");
    const [totalCredit, setTotalCredit] = useState("0");
    const [totalDifference, setTotalDifference] = useState("0");
    const [selectedValue, setSelectedValue] = useState(null);
    const [ledgerValue, setLedgerValue] = useState(null);
    const [locationCode, setLocationCode] = useState("");

    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    const [viewDate, setViewDate] = useState('');
    const currentDate = new Date();

    const [showEditRequest, setShowEditRequest] = useState(false);
    const [editLogObject, setEditLogObject] = useState({ trantype: "", trannumber: "", location: "", entity_name: "", trandate: "", tdu_status: "" });

    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.getActiveBranch().then(response => {
                setSelectedValue(response);
                response != null && handleChange(response);
            });
            Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_OPN_TRIAL'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/'); // no query right
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);

    const deleteItem = (id) => {
        Common.callApi(Common.apiTranOpnTrial, [sid, 'deleteOpenTrial', id, locationCode], (result) => {
            let resp = JSON.parse(result);
            setTrialData(resp);
            calculateTotal(resp);
        });
    }

    var msg = [], i = 0;

    const listOpenTrial = () => {
        if (selectedValue == null) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (selectedValue === null ? " Please select location." : '');
            // msg[i++] = (vgParentGrpCode.value == "0" ? " Please select parent." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        }
        else {
            setShowListForm(false)
            const loc = selectedValue.id.split('^');
            setLocationCode(loc[1]);
            setLocation(loc[2])

            Common.callApi(Common.apiTranOpnTrial, [sid, 'listOpenTrial', loc[1]], (result) => {
                let resp = JSON.parse(result);
                console.log(resp);
                setTrialData(resp);
                calculateTotal(resp);
                setViewDate(resp.tfd_date);
            });
        }
    }

    const addOpnTrial = () => {

        if (ledgerValue == null || amount == "") {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (ledgerValue === null ? " Please select account." : '');
            msg[i++] = (amount == "" ? " Please fill amount." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            $('.loader').show();
            console.log(ledgerValue);
            const account = ledgerValue.id.split('^');
            const groupCode = account[1];
            const accountCode = account[2];
            console.log(account);
            console.log(groupCode);
            console.log(accountCode);
            const obj = {
                flag: flag,
                acc: accountCode,
                grp: groupCode,
                amt: amount,
                location: locationCode
            }
            Common.callApi(Common.apiTranOpnTrial, [sid, 'addNew', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                // console.log(resp);
                setTrialData(resp);
                calculateTotal(resp);
                $('.loader').hide();
                // setAccount(null);
                setLedgerValue(null);
                setAmount("");

            });


            const object = {
                srno: 1,
                type: 'OPNTRL',
                status: 'E',
                currentDate: Common.dateYMD(currentDate),
                pageright: 'TRAN_OPN_TRIAL'
            }
            Common.callApi(Common.apiUnlockEdit, [sid, 'expireUnlock', JSON.stringify(object)], (result) => {
                console.log(result);
            })
        }
    }

    const handleChange = value => {
        setSelectedValue(value);
        let loc = value.id.split('^');
        setLocationCode(loc[1]);
        sessionStorage.activeBranch = loc[1];

    }

    const handleLedgerValue = (value) => {
        setLedgerValue(value);
    }

    const handleBack = () => {
        setShowAddForm(false);
        setShowListForm(true);
        // if (showAddForm) {
        //     setShowAddForm(false);
        // } else {
        //     setShowListForm(true);
        // }
    }

    const calculateTotal = (triallist) => {

        let totalDebt = 0;
        let totalCred = 0;
        let i;

        for (i = 0; i < triallist.length; i++) {
            if (triallist[i].tfd_flag == 'D') {
                totalDebt = totalDebt + triallist[i].tfd_amount * 1;
            } else {
                totalCred += triallist[i].tfd_amount * 1;
            }
        }
        setTotalDebit(totalDebt);
        setTotalCredit(totalCred);
        let totalDiff = totalDebt + totalCred;
        setTotalDifference(totalDiff.toFixed(2));
    }

    const handleDelete = (id) => {
        Common.callApi(Common.apiTranOpnTrial, [sid, 'deleteOpnVtc', id, locationCode], (result) => {
            let resp = JSON.parse(result);
            console.log(resp);
            setTrialData(resp);

        });
    }


    const downloadOpnTrial = (x) => {
        let loc = "";
        console.log(x);

        if (x == 'selected') {
            if (locationCode == "") {
                msg[i++] = (locationCode === "" ? " Please select location." : '');
                setMsgText(Common.buildMessageFromArray(msg));
                setMsgBox(true);
                return;
            } else {
                loc = locationCode;
                console.log(locationCode);
            }

        }
        var obj = {
            srno: 23,
            location: loc
        }
        Common.callDownloadApiPost(Common.apiTranOpnTrial, "post", [sid, 'downloadOpnTrial', JSON.stringify(obj)]);
    }



    const btnEditRequest = () => {
        const currDate = new Date();
        const newDate = viewDate
        // .split('/');

        // const newViewDate = newDate[2] + '-' + newDate[1] + '-' + newDate[0]
        const newCurrDate = Common.dateYMD(currDate);

        const dateA = new Date(newDate);
        const dateB = new Date(newCurrDate);

        // Calculate the difference in milliseconds
        const differenceInMilliseconds = dateB - dateA;

        // Convert milliseconds difference to days
        const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 3600 * 24));

        console.log(differenceInDays);

        const obj = {
            srno: 1,
            type: 'OPNTRL',
            pageright: 'TRAN_OPN_TRIAL'
        }
        // const message = 'unlock'
        Common.callApi(Common.apiUnlockEdit, [sid, 'checkUnlock', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);


            if (Common.editDateLimit == differenceInDays || resp == "unlock") {
                setShowAddForm(true)
            } else {
                let res;
                Common.callApi(Common.apiUnlockEdit, [sid, 'getStatus', JSON.stringify(obj)], (result) => {
                    res = JSON.parse(result);
                    setEditLogObject({ trantype: 'OPNTRL', trannumber: 1, location: locationCode, entity_name: location, tranDate: Common.dateYMD(currDate), tdu_status: res });
                    setShowEditRequest(true);
                })
            }
        })


    }


    return (
        <div>
            <Login_header />
            <Menu />

            <Container fluid>
                <Row>
                    <Col>
                        <h2>Opening Trial</h2>
                    </Col>
                </Row>
                &nbsp;
                {
                    showListForm ?
                        <>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Location<span className="text-danger">*</span></Form.Label>
                                        <AsyncSelect
                                            cacheOptions={false}
                                            defaultOptions={false}
                                            value={selectedValue}
                                            getOptionLabel={e => e.label + ' '}
                                            getOptionValue={e => e.id}
                                            loadOptions={Picklist.fetchLocationAllowedPicklist}
                                            onInputChange={Picklist.handleInputChange}
                                            onChange={handleChange}
                                            isDisabled={branchDisable === "true"}
                                        >
                                        </AsyncSelect>
                                    </Form.Group>
                                </Col>
                            </Row>
                            &nbsp;
                            <Row>
                                {/* <Col className='col-md-3 col-10'>
                                    {
                                        userRight.query === "1" ?
                                            <>
                                                <Button variant='outline-success' className='buttonStyle' size='sm' onClick={listOpenTrial}>List</Button>&nbsp;
                                                <span className="ml-2">       <Dropdown>
                                                    <Dropdown.Toggle variant="primary" id="dropdown-basic">Download</Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={downloadOpnTrial}>All Location</Dropdown.Item>
                                                        <Dropdown.Item href="#/action-2">Only selected location</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown></span>
                                            </>
                                            : null}
                                </Col> */}
                                <Col className='col-md-3 col-10'>
                                    {userRight.query === "1" && (
                                        <div className="d-flex align-items-center">
                                            <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={listOpenTrial}>
                                                List
                                            </Button>&nbsp;
                                            <span className="ml-2">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant='outline-primary' id="dropdown-basic">
                                                        Download
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={() => downloadOpnTrial("all")}>All Location</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => downloadOpnTrial("selected")}>Only selected location</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </span>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                            &nbsp;
                        </> :
                        <> <Row>
                            <Col className='col-md-3 col-6'>
                                <Form.Label>Location : {location}</Form.Label>
                            </Col>
                        </Row>
                            <Row>
                                <Col>
                                    <Button variant='outline-danger' className='buttonStyle' size='sm' onClick={handleBack}>Back</Button>{' '}
                                    {
                                        userRight.add === "1" && !showAddForm ?
                                            <>
                                                <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => btnEditRequest()}>Edit</Button>
                                            </>
                                            : null
                                    }
                                </Col>
                            </Row>
                            &nbsp;
                        </>
                }
                {
                    showAddForm && <>
                        <Row>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Flag<span className="text-danger">*</span></Form.Label>
                                    <Form.Select value={flag} onChange={e => setFlag(e.target.value)}
                                        // disabled={formMode === 'Q'}
                                        required>
                                        <option value="1">Credit</option>
                                        <option value="0">Debit</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Account<span className="text-danger">*</span></Form.Label>
                                    <AsyncSelect
                                        cacheOptions={false}
                                        defaultOptions={false}
                                        value={ledgerValue}
                                        getOptionLabel={e => e.label + ' '}
                                        getOptionValue={e => e.id}
                                        loadOptions={(search) => Picklist.fetchLedgerAllowedPicklist(search, 1)} // location = 1 , 2
                                        onInputChange={Picklist.handleInputChange}
                                        onChange={handleLedgerValue}
                                    >
                                    </AsyncSelect>
                                </Form.Group>
                            </Col>

                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Amount<span className="text-danger">*</span></Form.Label>
                                    <Form.Control value={amount} onChange={e => Common.validateDecValue(e.target.value, setAmount)
                                    } placeholder="Amount"
                                        maxLength={Common.maximumLength} />
                                </Form.Group>
                            </Col>
                        </Row>
                        &nbsp;
                        <Row>
                            <Col>
                                <Button variant='outline-success' className='buttonStyle' size='sm' onClick={addOpnTrial}>Add</Button>{' '}
                            </Col>
                        </Row>
                        &nbsp;
                    </>

                }

                {
                    !showListForm && <>
                        <Row>
                            <Col>
                                <Table responsive striped bordered hover>
                                    <thead>
                                        {
                                            totalDifference != '0.00' &&
                                            <>
                                                <tr>
                                                    <td colSpan={4}></td>
                                                    <td style={{ textAlign: 'right' }} colSpan={2}><b>Total Difference:</b> <span>{totalDifference}</span></td>
                                                </tr>
                                            </>
                                        }

                                        <tr>
                                            {/* <td></td>
                                            <td></td> */}
                                            <td colSpan={4}></td>
                                            <td style={{ textAlign: 'right' }} colSpan={1}><b>Total Debit:</b> <span>{totalDebit}</span></td>
                                            <td style={{ textAlign: 'right' }} colSpan={1}><b>Total Credit:</b> <span>{-1 * totalCredit}</span></td>
                                        </tr>
                                        <tr>
                                            {userRight.edit === "1" ? <th></th> : null}
                                            <th>Group</th>
                                            <th>Account</th>
                                            <th>Account Name</th>
                                            {/* <th>Amount</th> */}
                                            <th style={{ textAlign: 'right' }}>Debit</th>
                                            <th style={{ textAlign: 'right' }}>Credit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {trialData.map((res) =>
                                            <tr key={res.tfd_line_no}>
                                                {userRight.delete === "1" ?
                                                    <td className="textCenter" >
                                                        {
                                                            showAddForm &&
                                                            <span className="handCursor" onClick={() => deleteItem(res.line)} >
                                                                <div className="colorThemeBlue"><FontAwesomeIcon icon={faTrash} /></div>
                                                            </span>
                                                        }

                                                    </td>
                                                    : null
                                                }
                                                <td>{res.tfd_group}</td>
                                                <td>{res.tfd_account}</td>
                                                <td>{res.mvli_ledger_name}</td>
                                                <td style={{ textAlign: 'right' }}>{res.tfd_flag == 'D' && res.tfd_amount}</td>
                                                <td style={{ textAlign: 'right' }}>{res.tfd_flag == 'C' && (-1 * res.tfd_amount)}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </>}

            </Container>

            <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
                <Tran_data_unlock_request logObject={editLogObject} show={showEditRequest} hdrNotExist={true} onHide={() => setShowEditRequest(false)} />
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>

            </div>

        </div>
    )
}

export default Tran_opn_trial
