import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import * as Common from "../Common";
import Dialog from '../Dialog';
import Login_header from '../Login_header';
import Menu from '../Menu';
import * as Picklist from '../Picklist';
import Tran_data_unlock_request from '../Tran_data_unlock_request';


const Tran_opn_cmrcmp = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const branchDisable = sessionStorage.getItem('branchDisable');
    const [onceRun, setOnceRun] = useState(false);
    const [userRight, setUserRight] = useState([]);
    const [cmrCmpData, setCmrCmpData] = useState([]);
    const [location, setLocation] = useState({ value: 0, label: "Select" });
    const [flag, setFlag] = useState("D");
    const [cmrDate, setCmrDate] = useState(new Date());

    const [voucherType, setVoucherType] = useState({ value: 0, label: "Select" });
    const [voucherTypeData, setVoucherTypeData] = useState([]);

    const [amount, setAmount] = useState("");
    const [narration, setNarration] = useState("");
    const [invoiceNo, setInvoiceNo] = useState("");

    const [selectedValue, setSelectedValue] = useState(null);
    const [ledgerValue, setLedgerValue] = useState(null);
    const [locationCode, setLocationCode] = useState("");

    const [showListForm, setShowListForm] = useState(true);
    const [showAddForm, setShowAddForm] = useState(false);

    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    // const [debtorCreditorFlag, setDebtorCreditorFlag] = useState('');

    const startDate = sessionStorage.getItem('startDate');
    const endDate = sessionStorage.getItem('endDate');

    const [viewDate, setViewDate] = useState('');
    const currentDate = new Date();

    const [showEditRequest, setShowEditRequest] = useState(false);
    const [editLogObject, setEditLogObject] = useState({ trantype: "", trannumber: "", location: "", entity_name: "", trandate: "", tdu_status: "" });


    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.getActiveBranch().then(response => {
                setSelectedValue(response);
                response != null && handleChange(response);
            });
            Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_OPN_CMRCMP'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/'); // no query right
                }
            });

            Common.callApi(Common.apiVoucherType, [sid, "voucherType"], result => {
                let resp = JSON.parse(result);
                console.log(resp);
                setVoucherTypeData(resp);
            });

            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);

    const deleteCmr = (id) => {
        Common.callApi(Common.apiTranOpnCmr, [sid, 'deleteCmr', id, locationCode], (result) => {
            let resp = JSON.parse(result);
            setCmrCmpData(resp);
        });
    }

    var msg = [], i = 0;
    const listCmrCmp = () => {
        if (selectedValue == null) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (selectedValue === null ? " Please select location." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        }
        else {
            setShowListForm(false)
            const loc = selectedValue.id.split('^');
            setLocationCode(loc[1]);
            setLocation(loc[2])

            Common.callApi(Common.apiTranOpnCmr, [sid, 'listCmrCmp', loc[1]], (result) => {
                let resp = JSON.parse(result);
                // console.log(resp);
                setCmrCmpData(resp);
            });
        }
    }

    const addOpnCmr = () => {
        var date = Common.dateYMD(cmrDate);
        if (ledgerValue == null || voucherType == 0 || amount == "" || invoiceNo == "") {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (amount == "" ? " Amount." : '');
            msg[i++] = (ledgerValue == null ? " Account." : '');
            msg[i++] = (voucherType == 0 ? " Voucher Type." : '');
            msg[i++] = (invoiceNo == "" ? " Invoice Number." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (date > endDate || date < startDate) {
            setMsgBox(true);
            setMsgText(Common.getMessage("MSG0010"));
        } else {
            console.log(ledgerValue);
            const account = ledgerValue.id.split('^');
            const groupCode = account[1];
            const accountCode = account[2];
            const obj = {
                flag: flag,
                acc: accountCode,
                grp: groupCode,
                date: Common.dateYMD(cmrDate),
                amt: amount,
                narr: narration,
                vouchType: voucherType.value,
                invNo: invoiceNo,
                location: locationCode
            }
            Common.callApi(Common.apiTranOpnCmr, [sid, 'addNewCmr', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                setCmrCmpData(resp);
                setAmount("");
                setLedgerValue(null);
                setNarration("");
                setVoucherType("");
                setInvoiceNo("");
                setCmrDate(new Date());
            });

            const object = {
                srno: 1,
                type: 'OPNCMR',
                status: 'E',
                currentDate: Common.dateYMD(currentDate),
                pageright: 'TRAN_OPN_CMRCMP'
            }
            Common.callApi(Common.apiUnlockEdit, [sid, 'expireUnlock', JSON.stringify(object)], (result) => {
                console.log(result);
            })
        }
    }

    const handleChange = value => {
        setSelectedValue(value);
        var id = value.id;
        var location = id.split("^");
        sessionStorage.activeBranch = location[1];
    }

    const handleLedgerValue = (value) => {
        setLedgerValue(value);
    }

    const handleBack = () => {
        if (showAddForm) {
            setShowAddForm(false);
        } else {
            setShowListForm(true);
        }
    }

    const exportFile = () => {
        var obj = {
            location: locationCode,
            right: 'TRAN_OPN_CMRCMP'
        }
        Common.callDownloadApiPost(Common.apiReport, "post", [sid, JSON.stringify(obj)]);
    }


    const btnEditRequest = () => {
        const currDate = new Date();
        const newDate = viewDate
        // .split('/');

        // const newViewDate = newDate[2] + '-' + newDate[1] + '-' + newDate[0]
        const newCurrDate = Common.dateYMD(currDate);

        const dateA = new Date(newDate);
        const dateB = new Date(newCurrDate);

        // Calculate the difference in milliseconds
        const differenceInMilliseconds = dateB - dateA;

        // Convert milliseconds difference to days
        const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 3600 * 24));

        console.log(differenceInDays);

        const obj = {
            srno: 1,
            type: 'OPNCMR',
            pageright: 'TRAN_OPN_CMRCMP'
        }
        // const message = 'unlock'
        Common.callApi(Common.apiUnlockEdit, [sid, 'checkUnlock', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);


            if (Common.editDateLimit == differenceInDays || resp == "unlock") {
                setShowAddForm(true)
            } else {
                let res;
                Common.callApi(Common.apiUnlockEdit, [sid, 'getStatus', JSON.stringify(obj)], (result) => {
                    res = JSON.parse(result);
                    setEditLogObject({ trantype: 'OPNCMR', trannumber: 1, location: locationCode, entity_name: location, tranDate: Common.dateYMD(currDate), tdu_status: res });
                    setShowEditRequest(true);
                })
            }
        })


    }

    return (
        <>
            <Login_header />
            <Menu />

            <Container fluid>
                <Row>
                    <Col>
                        <h2>Opening CMR/CMP</h2>
                    </Col>
                </Row>
                &nbsp;
                {
                    showListForm ?
                        <>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Location<span className="text-danger">*</span></Form.Label>
                                        <AsyncSelect
                                            cacheOptions={false}
                                            defaultOptions={false}
                                            value={selectedValue}
                                            getOptionLabel={e => e.label + ' '}
                                            getOptionValue={e => e.id}
                                            loadOptions={Picklist.fetchLocationAllowedPicklist}
                                            onInputChange={Picklist.handleInputChange}
                                            onChange={handleChange}
                                            isDisabled={branchDisable === "true"}
                                        >
                                        </AsyncSelect>
                                    </Form.Group>
                                </Col>
                            </Row>
                            &nbsp;
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    {
                                        userRight.query === "1" ?
                                            <Button variant='outline-success' className='buttonStyle' size='sm' onClick={listCmrCmp}>List</Button>
                                            : null}
                                </Col>
                            </Row>
                            &nbsp;
                        </> :
                        <> <Row>
                            <Col className='col-md-3 col-6'>
                                <Form.Label>Location : {location}</Form.Label>
                            </Col>
                        </Row>
                            <Row>
                                <Col>
                                    <Button variant='outline-danger' className='buttonStyle' size='sm' onClick={handleBack}>Back</Button>{' '}
                                    {
                                        userRight.add === "1" && !showAddForm ?
                                            <>
                                                <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => btnEditRequest()}>Edit</Button>&nbsp;
                                                <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={exportFile}>Export</Button>
                                            </>
                                            : null
                                    }
                                </Col>
                            </Row>
                            &nbsp;
                        </>
                }
                {
                    showAddForm && <>
                        <Row>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Flag<span className="text-danger">*</span></Form.Label>
                                    <Form.Select value={flag} onChange={e => setFlag(e.target.value)}
                                        // disabled={formMode === 'Q'}
                                        required>
                                        <option value="D">Receivable</option>
                                        <option value="C">Payable</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Account<span className="text-danger">*</span></Form.Label>
                                    <AsyncSelect
                                        cacheOptions={false}
                                        defaultOptions={false}
                                        value={ledgerValue}
                                        getOptionLabel={e => e.label + ' '}
                                        getOptionValue={e => e.id}
                                        loadOptions={(search) => Picklist.fetchDebtorCreditorAllowedPicklist(search, 1, flag)} // location = 1 , 2
                                        onInputChange={Picklist.handleInputChange}
                                        onChange={handleLedgerValue}
                                    >
                                    </AsyncSelect>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Date<span className="text-danger">*</span></Form.Label>
                                    <DatePicker className="form-control"
                                        selected={cmrDate}
                                        onChange={(date) => setCmrDate(date)}
                                        dateFormat="dd/MM/yyyy"
                                        showYearDropdown
                                        showMonthDropdown
                                        useShortMonthInDropdown
                                        dropdownMode="select"
                                        peekNextMonth
                                        customInput={
                                            <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Amount<span className="text-danger">*</span></Form.Label>
                                    <Form.Control value={amount}
                                        onChange={(e) => Common.validateDecValue(e.target.value, setAmount)} placeholder="Amount" />
                                </Form.Group>
                            </Col>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Narration</Form.Label>
                                    <Form.Control value={narration} onChange={e => setNarration(e.target.value)} maxLength={500} placeholder="Narration" />
                                </Form.Group>
                            </Col>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Voucher Type<span className="text-danger">*</span></Form.Label>
                                    <Select options={voucherTypeData} value={voucherType} onChange={v => setVoucherType(v)} />
                                </Form.Group>
                            </Col>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Invoice Number<span className="text-danger">*</span></Form.Label>
                                    <Form.Control value={invoiceNo} onChange={e => Common.validateNumValue(e.target.value, setInvoiceNo)} maxLength={7} placeholder="Invoice Number" />
                                </Form.Group>
                            </Col>
                        </Row>
                        &nbsp;
                        <Row>
                            <Col>
                                <Button variant='outline-success' className='buttonStyle' size='sm' onClick={addOpnCmr}>Add</Button>{' '}
                            </Col>
                        </Row>
                        &nbsp;
                    </>
                }
                {
                    !showListForm && <>
                        <Row>
                            <Col>
                                <Table responsive striped bordered hover>
                                    <thead>
                                        <tr>
                                            {userRight.edit === "1" ? <th></th> : null}
                                            <th>Group</th>
                                            <th>Account</th>
                                            <th>Account Name</th>
                                            <th>Amount</th>
                                            <th>Date</th>
                                            <th>Flag</th>
                                            <th>Voucher Type</th>
                                            <th>Invoice Number</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cmrCmpData.map((res) =>
                                            <tr key={res.tfd_line_no}>
                                                {userRight.delete === "1" ?
                                                    <td className="textCenter">
                                                        {
                                                            showAddForm &&
                                                            <span className="handCursor" onClick={() => deleteCmr(res.tfd_line_no)} >
                                                                <div className="colorThemeBlue"><FontAwesomeIcon icon={faTrash} /></div>
                                                            </span>
                                                        }

                                                    </td>
                                                    : null
                                                }
                                                <td>{res.tfd_group}</td>
                                                <td>{res.tfd_account}</td>
                                                <td>{res.mvli_ledger_name}</td>
                                                <td>{res.abs_amount}</td>
                                                <td>{res.formatted_date}</td>
                                                <td>{res.tfd_flag === 'D' ? "RECEIVABLE" : "PAYABLE"}</td>
                                                <td>{res.tfd_reftype}</td>
                                                <td>{res.tfd_refnumber}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </>
                }
            </Container>
            <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
            </div>
            <Tran_data_unlock_request logObject={editLogObject} show={showEditRequest} hdrNotExist={true} onHide={() => setShowEditRequest(false)} />

        </>
    )
}

export default Tran_opn_cmrcmp
