import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form, Button, Table, Modal } from 'react-bootstrap'
import Login_header from '../Login_header';
import Menu from '../Menu';
import AsyncSelect from 'react-select/async';
import * as Picklist from '../Picklist';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import Dialog from '../Dialog';
import DialogYesNo from '../Dialog_Yes_No';
import * as Common from '../Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Tran_data_unlock_request from '../Tran_data_unlock_request';

const Tran_trf_manual_book = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const startDate = sessionStorage.getItem('startDate');
    const endDate = sessionStorage.getItem('endDate');
    const branchDisable = sessionStorage.getItem('branchDisable');

    const [userRight, setUserRight] = useState([]);
    const [onceRun, setOnceRun] = useState(false);
    const [scrMode, setScrMode] = useState("");

    const [radioType, setRadioType] = useState("N");
    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");
    const [isErrBox, setErrBox] = useState(false);
    const [errText, setErrText] = useState("");
    const [showModal, setShowModal] = useState(false);

    const [mbkLocation, setMbkLocation] = useState(null);
    const [location, setLocation] = useState("");
    const [locationCode, setLocationCode] = useState(0);

    const [mbkDate, setMbkDate] = useState(new Date());
    const [mbkType, setMbkType] = useState("PP");
    const [mbkFromDate, setMbkFromDate] = useState(new Date());
    const [mbkToDate, setMbkToDate] = useState(new Date());
    const [mbkFromSrno, setMbkFromSrno] = useState('');
    const [mbkToSrno, setMbkToSrno] = useState('');
    const [mbkManualType, setMbkManualType] = useState("PP");
    const [mbkManualNo, setMbkManualNo] = useState(null);
    const [fromManualNo, setFromManualNo] = useState(0);
    const [prefixManualNo, setPrefixManualNo] = useState("");

    const [innerData, setInnerData] = useState([]);
    const [headerData, setHeaderData] = useState([]);
    const [subInnerData, setSubInnerData] = useState([]);

    const [mbkQuantity, setMbkQuantity] = useState("");
    const [mbkFrom, setMbkFrom] = useState("");
    const [mbkTo, setMbkTo] = useState("");
    const [mbkPrefix, setMbkPrefix] = useState("");
    const [srNo, setSrNo] = useState(0);
    const [lineNo, setLineNo] = useState(0);
    const [counter, setCounter] = useState(0);

    const [viewSrno, setViewSrno] = useState(0);
    const [viewDate, setViewDate] = useState('');
    const currentDate = new Date();

    const [showEditRequest, setShowEditRequest] = useState(false);
    const [editLogObject, setEditLogObject] = useState({ trantype: "", trannumber: "", trancategory: "", tranDate: "" });

    var msg = [], i = 0;


    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.getActiveBranch().then(response => {
                setMbkLocation(response);
                response != null && handleChangeLocation(response);
            });
            Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_TRF_MANUAL_BOOK'], (result) => {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);


    const handleChangeLocation = (value) => {
        setMbkLocation(value);
        const loc = value.id.split('^');
        setLocationCode(loc[1]);
        setLocation(loc[2]);
        sessionStorage.activeBranch = loc[1];
    }

    const handleManualNoValue = (value) => {
        setMbkManualNo(value);
        console.log(value);
        const manualN = value.id.split('^');
        setPrefixManualNo(manualN[4]);
        setFromManualNo(manualN[5]);
    }

    const handleQuantity = (e) => {
        Common.validateNumValue(e.target.value, setMbkQuantity);
        if (mbkFrom === "" || e.target.value === "") {
            setMbkTo("");
        } else {
            setMbkTo(1 * mbkFrom - 1 + (1 * (e.target.value)));
        }
    }


    const handleFrom = (e) => {
        Common.validateNumValue(e.target.value, setMbkFrom)
        if (e.target.value === "" || mbkQuantity === "") {
            setMbkTo("");
        } else {
            setMbkTo(1 * e.target.value - 1 + (1 * (mbkQuantity)));
        }
    }


    const list = () => {
        if (mbkLocation === null || (radioType === 'S' && !mbkFromSrno) || (radioType === 'S' && !mbkToSrno) || (radioType === 'D' && !mbkFromDate) ||
            (radioType === 'D' && !mbkToDate)) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (!mbkLocation ? " Location." : '');
            msg[i++] = (radioType === 'S' && !mbkFromSrno ? " From." : '');
            msg[i++] = (radioType === 'S' && !mbkToSrno ? " To." : '');
            msg[i++] = (radioType === 'D' && !mbkFromDate ? " From Date." : '');
            msg[i++] = (radioType === 'D' && !mbkToDate ? " To Date." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (radioType === 'MN' && mbkManualNo === null) {
            setMsgText("Please select Manul No.");
            setMsgBox(true);
        } else {
            console.log(mbkLocation);
            const obj = {
                filterRadioType: radioType,
                filterLocation: locationCode,
                filterFromDate: Common.dateYMD(mbkFromDate),
                filterToDate: Common.dateYMD(mbkToDate),
                filterFromSrno: mbkFromSrno,
                filterToSrno: mbkToSrno,
                filterManualType: mbkManualType,
                filterManualNo: mbkManualNo,
                filterFromManualNo: fromManualNo,
                filterPrefixManualNo: prefixManualNo,
            }
            Common.callApi(Common.apiTRFManualBook, [sid, 'list', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                setHeaderData(resp);
            });
        }
    }


    const addNew = () => {
        var date = Common.dateYMD(mbkDate);
        if (!mbkLocation || !mbkDate) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (!mbkLocation ? " Location." : '');
            msg[i++] = (!mbkDate ? " Date." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (date > endDate || date < startDate) {
            setMsgBox(true);
            setMsgText(Common.getMessage("MSG0010"));
        } else {
            setScrMode("A");
            setMbkQuantity("");
            setMbkFrom("");
            setMbkPrefix("");
            setMbkTo("");
            setInnerData([]);
        }
    }


    const addDetail = () => {
        var msg = [], i = 0;
        if (mbkQuantity === "" || mbkFrom === "") {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (mbkQuantity === "" ? " Quantity." : '');
            msg[i++] = (mbkFrom === "" ? " From." : '');
            // msg[i++] = (mbkTo === null ? " To." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (mbkQuantity >= 101) {
            setMsgText("Quantity Should be less than 101!");
            setMsgBox(true);
        } else {
            const obj = {
                unique_no: Common.getRandomString(5),
                tmb_srno: srNo,
                tmb_location: locationCode,
                tmb_quantity: mbkQuantity,
                tmb_from: mbkFrom,
                tmb_to: mbkTo,
                tmb_prefix: mbkPrefix,
                tmb_date: Common.dateDMY(mbkDate),
                tmb_type: 'MNLBK',
                tmb_manual_type: mbkType,
                tmb_line_no: 1 * lineNo + 1,
                del: "Y",
            };
            setInnerData(Common.arrayAddItem(innerData, obj));
            setMbkQuantity('');
            setMbkFrom('');
            setMbkTo('');
            setMbkPrefix('');
            setLineNo(lineNo + 1);
        }
    }


    const saveData = () => {
        if (innerData.length < 1) {
            setMsgText("Empty List!");
            setMsgBox(true);
        } else {
            const obj = {
                location: locationCode,
                srno: srNo,
                date: Common.dateYMD(mbkDate),
                data: innerData,

                filterRadioType: radioType,
                filterLocation: locationCode,
                filterFromDate: Common.dateYMD(mbkFromDate),
                filterToDate: Common.dateYMD(mbkToDate),
                filterFromSrno: mbkFromSrno,
                filterToSrno: mbkToSrno,
                filterManualType: mbkManualType,
                filterManualNo: mbkManualNo,
                filterFromManualNo: fromManualNo,
                filterPrefixManualNo: prefixManualNo,
            }
            Common.callApi(Common.apiTRFManualBook, [sid, 'save', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                setHeaderData(resp);
                // setMbkLocation(null);
                setInnerData([]);
                setScrMode("");
            });

            if (scrMode == 'E') {
                const obj = {
                    srno: srNo,
                    type: 'MNLBK',
                    status: 'E',
                    currentDate: Common.dateYMD(currentDate),
                    pageright: 'TRAN_TRF_MANUAL_BOOK'
                }
                Common.callApi(Common.apiUnlockEdit, [sid, 'expireUnlock', JSON.stringify(obj)], (result) => {
                    console.log(result);
                })

            }
        }
    }


    const viewList = (hdrSrno, hdrLocation, hdrType, vdate) => {
        setSrNo(hdrSrno);
        setScrMode("Q");
        setViewDate(vdate);
        setViewSrno(hdrSrno);
        const obj = {
            srno: hdrSrno,
            location: hdrLocation,
            type: hdrType,
        }
        Common.callApi(Common.apiTRFManualBook, [sid, 'view', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);
            if (resp.msg === 'MSG0000') {
                setInnerData(resp.manualBookList);
                setLineNo(resp.maxlineno);
                setLocation(resp.locationname);
            }
        });
    }


    const deleteItem = (value) => {
        setInnerData(Common.arrayRemoveItem(innerData, "unique_no", value));
        setCounter(counter + 1);
    }


    const viewDetails = (srno, lineno, location, type) => {
        const obj = {
            srno: srno,
            lineno: lineno,
            location: location,
            type: type,
        }
        Common.callApi(Common.apiTRFManualBook, [sid, 'viewDtls', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);
            setSubInnerData(resp);
        });
        setShowModal(true);
    }


    const cancelConfirm = () => {
        if (scrMode === "A" || scrMode === "E") {
            setMsgTextYN('Do you really want to exit ?');
            setMsgBoxYN(true);
        } else {
            cancelData();
        }
    }


    const cancelData = () => {
        setMsgBoxYN(false);
        setScrMode("");
    }


    const editData = () => {
        setScrMode("E");
    }

    const btnEditRequest = () => {
        const currDate = new Date();
        const newDate = viewDate.split('/');

        const newViewDate = newDate[2] + '-' + newDate[1] + '-' + newDate[0]
        const newCurrDate = Common.dateYMD(currDate);

        const dateA = new Date(newViewDate);
        const dateB = new Date(newCurrDate);

        // Calculate the difference in milliseconds
        const differenceInMilliseconds = dateB - dateA;

        // Convert milliseconds difference to days
        const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 3600 * 24));

        console.log(differenceInDays);

        const obj = {
            srno: viewSrno,
            type: 'MNLBK',
            pageright: 'TRAN_TRF_MANUAL_BOOK'
        }
        // const message = 'unlock'
        Common.callApi(Common.apiUnlockEdit, [sid, 'checkUnlock', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);

            if (Common.editDateLimit == differenceInDays || resp == "unlock") {
                setScrMode('E');

            } else {
                setShowEditRequest(true);
                setEditLogObject({ trantype: 'MNLBK', trannumber: viewSrno, location: locationCode, tranDate: Common.dateYMD(currDate) });
            }
        })


    }


    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h2>Manual Book</h2>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                {
                    scrMode === "" ?
                        <>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Location<span className="text-danger">*</span></Form.Label>
                                        <AsyncSelect
                                            cacheOptions={false}
                                            defaultOptions={false}
                                            value={mbkLocation}
                                            getOptionLabel={e => e.label + ' '}
                                            getOptionValue={e => e.id}
                                            loadOptions={Picklist.fetchLocationAllowedPicklist}
                                            onInputChange={Picklist.handleInputChange}
                                            onChange={handleChangeLocation}
                                            isDisabled={branchDisable === "true"}
                                        >
                                        </AsyncSelect>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-4 col-6'>
                                    <Form.Group>
                                        <Row style={{ marginTop: "30px" }}>
                                            <Col>
                                                <Form.Check checked={radioType === "N"} onChange={() => setRadioType("N")} type='radio' label="New" />
                                            </Col><Col>
                                                <Form.Check checked={radioType === "S"} onChange={() => setRadioType("S")} type='radio' label="SrNo" />
                                            </Col><Col>
                                                <Form.Check checked={radioType === "D"} onChange={() => setRadioType("D")} type='radio' label="Date" />
                                            </Col><Col>
                                                <Form.Check checked={radioType === "MT"} onChange={() => setRadioType("MT")} type='radio' label="Manual Type" />
                                            </Col><Col>
                                                <Form.Check checked={radioType === "MN"} onChange={() => setRadioType("MN")} type='radio' label="Manual No" />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                {
                                    radioType === 'N' ?
                                        <>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Date<span className="text-danger">*</span></Form.Label>
                                                    <DatePicker className="form-control"
                                                        selected={mbkDate}
                                                        minDate={new Date(startDate)}
                                                        maxDate={new Date(endDate)}
                                                        onChange={(date) => setMbkDate(date)}
                                                        dateFormat="dd/MM/yyyy"
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        useShortMonthInDropdown
                                                        dropdownMode="select"
                                                        peekNextMonth
                                                        customInput={
                                                            <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </>
                                        : radioType === 'S' ?
                                            <>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>From<span className="text-danger">*</span></Form.Label>
                                                        <Form.Control value={mbkFromSrno}
                                                            maxLength={20}
                                                            onChange={(e) => Common.validateNumValue(e.target.value, setMbkFromSrno)}
                                                            placeholder="From" />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>To<span className="text-danger">*</span></Form.Label>
                                                        <Form.Control value={mbkToSrno}
                                                            maxLength={20}
                                                            onChange={(e) => Common.validateNumValue(e.target.value, setMbkToSrno)}
                                                            placeholder="To" />
                                                    </Form.Group>
                                                </Col>
                                            </>
                                            : radioType === 'D' ?
                                                <>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                                                            <DatePicker className="form-control"
                                                                selected={mbkFromDate}
                                                                onChange={(date) => setMbkFromDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>To<span className="colorThemeRed">*</span></Form.Label>
                                                            <DatePicker className="form-control"
                                                                selected={mbkToDate}
                                                                onChange={(date) => setMbkToDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </>
                                                : radioType === 'MT' ?
                                                    <>
                                                        <Col className='col-md-3 col-6'>
                                                            <Form.Group>
                                                                <Form.Label>Manual Type<span className="text-danger">*</span></Form.Label>
                                                                <Form.Select value={mbkManualType} onChange={e => setMbkManualType(e.target.value)}>
                                                                    <option value="PP">PP Purchase Public</option>
                                                                    <option value="SP">SP Sale Public</option>
                                                                    <option value="PB">PB Purchase Bulk</option>
                                                                    <option value="SB">SB Sale Bulk</option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Col>
                                                    </>
                                                    : radioType === 'MN' ?
                                                        <>
                                                            <Col className='col-md-3 col-6'>
                                                                <Form.Group>
                                                                    <Form.Label>Manual No.<span className='colorThemeRed'>*</span></Form.Label>
                                                                    <AsyncSelect
                                                                        cacheOptions={false}
                                                                        defaultOptions={false}
                                                                        value={mbkManualNo}
                                                                        getOptionLabel={e => e.label + ' '}
                                                                        getOptionValue={e => e.id}
                                                                        loadOptions={(search) => Picklist.fetchManualAllPicklist(search, locationCode)}
                                                                        onInputChange={Picklist.handleInputChange}
                                                                        onChange={handleManualNoValue}
                                                                    >
                                                                    </AsyncSelect>
                                                                </Form.Group>
                                                            </Col>
                                                        </>
                                                        :
                                                        null
                                }
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    {
                                        userRight.add === "1" && radioType === 'N' ?
                                            <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addNew()}>Add New</Button> : null
                                    }
                                    &nbsp;
                                    {
                                        userRight.query === "1" && (radioType === 'S' || radioType === 'D' || radioType === 'MT' || radioType === 'MN') ?
                                            <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => list()}>List</Button>
                                            : null
                                    }
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                {userRight.edit === "1" ? <th></th> : null}
                                                <th>SrNo</th>
                                                <th>Date</th>
                                                <th>Type</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                headerData.map((res) =>
                                                    <tr key={res.hdr_srno}>
                                                        {userRight.edit === "1" ?
                                                            <td className="textCenter">
                                                                <span className="handCursor colorThemeBlue" onClick={() => viewList(res.hdr_srno, res.hdr_location, res.hdr_type, res.hdr_date)}>
                                                                    <FontAwesomeIcon icon={faEye} />
                                                                </span>
                                                            </td>
                                                            : null
                                                        }
                                                        <td>{res.hdr_srno}</td>
                                                        <td>{res.formatted_date}</td>
                                                        <td>{res.hdr_type}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </>
                        :
                        <>
                            <Row>
                                <Col>
                                    <Button variant='outline-danger' className='buttonStyle' size='sm' onClick={() => cancelConfirm()}>Back</Button>{' '}
                                    {
                                        (userRight.edit === "1" && scrMode === "Q") ? <>
                                            <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                btnEditRequest()}>Edit</Button>
                                        </> : null
                                    }
                                    {
                                        (userRight.edit === "1" && scrMode === "E") || (userRight.add === "1" && scrMode === "A") ? <>
                                            <Button variant='outline-primary' size='sm' className='buttonStyle' onClick={() => saveData()}>Save</Button>
                                        </> : null
                                    }
                                    {/* <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={exportFile}>Export</Button> */}
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Label>Location : {location}</Form.Label>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            {
                                scrMode === "E" || scrMode === "A" ?
                                    <>
                                        <Row>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Type<span className="text-danger">*</span></Form.Label>
                                                    <Form.Select value={mbkType} onChange={e => setMbkType(e.target.value)}>
                                                        <option value="PP">PP Purchase Public</option>
                                                        <option value="SP">SP Sale Public</option>
                                                        <option value="PB">PB Purchase Bulk</option>
                                                        <option value="SB">SB Sale Bulk</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Quantity<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control
                                                        value={mbkQuantity}
                                                        onChange={(e) => handleQuantity(e)}
                                                        maxLength={Common.maximumLength}
                                                        placeholder="Quantity" />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Prefix</Form.Label>
                                                    <Form.Control
                                                        value={mbkPrefix}
                                                        onChange={(e) => Common.validateAlpNumValue(e.target.value, setMbkPrefix)}
                                                        maxLength={Common.prefixLength}
                                                        placeholder="Prefix" />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>From<span className="text-danger">*</span></Form.Label><br />
                                                    <Form.Control
                                                        value={mbkFrom}
                                                        onChange={(e) => handleFrom(e)}
                                                        maxLength={Common.maxFromLength}
                                                        placeholder="From" />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>To<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control
                                                        value={mbkTo}
                                                        disabled
                                                        placeholder="To" />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: "30px" }}>
                                            <Col className='col-md-3 col-6'>
                                                {
                                                    userRight.add === "1" ?
                                                        <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addDetail()}>Add</Button> : null
                                                }
                                            </Col>
                                        </Row>
                                    </> :
                                    null
                            }
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                {userRight.edit === "1" ? <th></th> : null}
                                                <th>Prefix</th>
                                                <th>Quantity</th>
                                                <th>From</th>
                                                <th>To</th>
                                                <th>Manual Type</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                innerData.map((res) =>
                                                    <tr key={res.tmb_lino_no}>
                                                        {
                                                            userRight.delete === "1" ?
                                                                <td className="textCenter" >
                                                                    {
                                                                        scrMode === 'Q' || res.del === 'N' ? null :
                                                                            <>
                                                                                <span className="handCursor colorThemeBlue" onClick={() => deleteItem(res.unique_no)} >
                                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                                </span> {' '}
                                                                            </>
                                                                    }
                                                                    {
                                                                        scrMode === 'Q' ?
                                                                            <span className="handCursor colorThemeBlue" onClick={() => viewDetails(res.tmb_srno, res.tmb_line_no, res.tmb_location, res.tmb_type)}>
                                                                                <FontAwesomeIcon icon={faEye} />
                                                                            </span>
                                                                            : null
                                                                    }
                                                                </td>
                                                                : null
                                                        }
                                                        <td>{res.tmb_prefix}</td>
                                                        <td>{res.tmb_quantity}</td>
                                                        <td>{res.tmb_from}</td>
                                                        <td>{res.tmb_to}</td>
                                                        <td>{res.tmb_manual_type}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </>
                }
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
            </Container>
            <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
                <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
                    {msgTextYN}
                </DialogYesNo>
                <Dialog isOpen={isErrBox} onClose={(e) => setErrBox(false)}>
                    {errText}
                </Dialog>

                <Tran_data_unlock_request logObject={editLogObject} show={showEditRequest} onHide={() => setShowEditRequest(false)} />

            </div>
            <div>
                <Modal size='lg' show={showModal} onHide={() => setShowModal(false)} animation={false} centered>
                    <Modal.Body>
                        <Container fluid>
                            <Row>
                                <Col>
                                    <Table responsive striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>From</th>
                                                <th>Sr No.</th>
                                                <th>Out Type</th>
                                                <th>Out Line No.</th>
                                                <th>Out Location</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                subInnerData.map((res) =>
                                                    <tr key={res.tmb_line_no}>
                                                        <td>{res.tmbb_from}</td>
                                                        <td>{res.tmbb_out_srno}</td>
                                                        <td>{res.tmbb_out_type}</td>
                                                        <td>{res.tmbb_out_line_no}</td>
                                                        <td>{res.tmbb_out_location}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-success" onClick={() => setShowModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}

export default Tran_trf_manual_book
