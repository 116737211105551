import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Badge, Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import * as Common from "../Common";
import Dialog from '../Dialog';
import Login_header from '../Login_header';
import Menu from '../Menu';
import * as Picklist from '../Picklist';
import Tran_data_unlock_request from '../Tran_data_unlock_request';

const Opn_bank_statement = () => {

    const navigate = useNavigate();
    const startDate = sessionStorage.getItem('startDate');
    const endDate = sessionStorage.getItem('endDate');
    const branchDisable = sessionStorage.getItem('branchDisable');
    const sid = sessionStorage.getItem("sessionId");
    const [onceRun, setOnceRun] = useState(false);
    const [userRight, setUserRight] = useState([]);
    const [bnkStmtData, setBnkStmtData] = useState([]);
    const [location, setLocation] = useState({ value: 0, label: "Select" });
    const [bankName, setBankName] = useState('');

    const [voucherType, setVoucherType] = useState({ value: 0, label: "Select" });
    const [voucherTypeData, setVoucherTypeData] = useState([]);

    const [locationCode, setLocationCode] = useState("");
    const [flag, setFlag] = useState("D");
    const [invoiceNo, setInvoiceNo] = useState("");
    const [bsDate, setBsDate] = useState(new Date());
    const [ledgerValue, setLedgerValue] = useState(null);

    const [amount, setAmount] = useState("");
    const [chequeNo, setChequeNo] = useState("");

    const [bsLocation, setBSLocation] = useState(null);
    const [bsLocationValue, setBSLocationValue] = useState(0);
    const [bsBank, setBSBank] = useState(null);

    const [groupCode, setGroupCode] = useState(0);
    const [accountCode, setAccountCode] = useState(0);

    const [showList, setShowList] = useState(true);
    const [showAddNewForm, setShowAddNewForm] = useState(false);
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    const [viewDate, setViewDate] = useState('');
    const currentDate = new Date();

    const [showEditRequest, setShowEditRequest] = useState(false);
    const [editLogObject, setEditLogObject] = useState({ trantype: "", trannumber: "", location: "", entity_name: "", trandate: "", tdu_status: "" });


    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.getActiveBranch().then(response => {
                setBSLocation(response);
                response != null && handleChangeLocation(response);
            });
            Common.callApi(Common.apiRight, [sid, 'all', 'OPN_BANK_STATEMENT'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/'); // no query right
                }
            });

            Common.callApi(Common.apiVoucherType, [sid, "voucherType"], result => {
                let resp = JSON.parse(result);
                console.log(resp);
                setVoucherTypeData(resp);
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);

    const handleChangeLocation = (value) => {
        console.log(value);
        setBSLocation(value);
        var id = value.id;
        var location = id.split("^");
        setBSLocationValue(location[1]);
        setBSBank(null);
        sessionStorage.activeBranch = location[1];
    }

    const handleBankValue = (value) => {
        console.log(value);
        setBSBank(value);
        const bank = value.id.split('^');
        setGroupCode(bank[1]);
        setAccountCode(bank[2]);
    }

    const addBnkStmt = () => {
        var date = Common.dateYMD(bsDate);
        if (voucherType.value == 0 || amount == "" || chequeNo == "" || invoiceNo == "") {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (amount == "" ? " Amount." : '');
            msg[i++] = (chequeNo == "" ? " Cheque No." : '');
            msg[i++] = (invoiceNo == "" ? " Invoice No." : '');
            msg[i++] = (voucherType.value == 0 ? " Voucher Type." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (date > endDate || date < startDate) {
            setMsgBox(true);
            setMsgText(Common.getMessage("MSG0010"));
        } else {
            console.log(bsBank);
            const obj = {
                flag: flag,
                bnk: accountCode,
                grp: groupCode,
                date: Common.dateYMD(bsDate),
                amt: amount,
                vouchType: voucherType.value,
                chequeno: chequeNo,
                invoiceno: invoiceNo,
                location: locationCode
            }
            Common.callApi(Common.apiOpnBankStmt, [sid, 'addNewBnkStmt', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                console.log(resp);
                setBnkStmtData(resp);
                setAmount("");
                setFlag("D");
                setVoucherType("");
                setChequeNo("");
                setInvoiceNo("");
                setBsDate(new Date());
            });
            const object = {
                srno: 1,
                type: 'OPNRECO',
                status: 'E',
                currentDate: Common.dateYMD(currentDate),
                pageright: 'OPN_BANK_STATEMENT'
            }
            Common.callApi(Common.apiUnlockEdit, [sid, 'expireUnlock', JSON.stringify(object)], (result) => {
                console.log(result);
            })
        }
    }

    var msg = [], i = 0;
    const listBnkStmt = () => {
        if (bsLocation == null || bsBank == null) {
            msg[i++] = Common.getMessage('MSG0006');
            msg[i++] = (bsLocation == null ? "Location." : '');
            msg[i++] = (bsBank == null ? "Bank." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            setShowList(false);
            const loc = bsLocation.id.split('^');
            setLocationCode(loc[1]);
            setLocation(loc[2]);
            const bnk = bsBank.id.split('^');
            setBankName(bnk[5]);
            Common.callApi(Common.apiOpnBankStmt, [sid, 'listBnkStmt', loc[1]], (result) => {
                let resp = JSON.parse(result);
                setBnkStmtData(resp);
            });
        }
    }

    const deleteBnkStmt = (id) => {
        Common.callApi(Common.apiOpnBankStmt, [sid, 'deleteBnkStmt', id, locationCode], (result) => {
            let resp = JSON.parse(result);
            setBnkStmtData(resp);
        });
    }

    const handleBack = () => {
        if (showAddNewForm) {
            setShowAddNewForm(false);
        } else {
            setShowList(true);
        }
    }

    const handleReset = () => {
        setBSLocation(null);
    }

    const exportFile = () => {
        var obj = {
            location: locationCode,
            right: 'OPN_BANK_STATEMENT',
            gpCode: groupCode,
            acCode: accountCode,
        }
        Common.callDownloadApiPost(Common.apiReport, "post", [sid, JSON.stringify(obj)]);
    }

    const exportFileWithoutLocation = () => {
        if (bsBank == null) {
            setMsgText("Please Select Bank!");
            setMsgBox(true);
        } else {
            var obj = {
                location: 0,
                right: 'OPN_BANK_STATEMENT',
                gpCode: groupCode,
                acCode: accountCode,
            }
            Common.callDownloadApiPost(Common.apiReport, "post", [sid, JSON.stringify(obj)]);
        }
    }

    const btnEditRequest = () => {
        const currDate = new Date();
        const newDate = viewDate
        // .split('/');

        // const newViewDate = newDate[2] + '-' + newDate[1] + '-' + newDate[0]
        const newCurrDate = Common.dateYMD(currDate);

        const dateA = new Date(newDate);
        const dateB = new Date(newCurrDate);

        // Calculate the difference in milliseconds
        const differenceInMilliseconds = dateB - dateA;

        // Convert milliseconds difference to days
        const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 3600 * 24));

        console.log(differenceInDays);

        const obj = {
            srno: 1,
            type: 'OPNRECO',
            pageright: 'OPN_BANK_STATEMENT'
        }
        // const message = 'unlock'
        Common.callApi(Common.apiUnlockEdit, [sid, 'checkUnlock', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);


            if (Common.editDateLimit == differenceInDays || resp == "unlock") {
                setShowAddNewForm(true)
            } else {
                let res;
                Common.callApi(Common.apiUnlockEdit, [sid, 'getStatus', JSON.stringify(obj)], (result) => {
                    res = JSON.parse(result);
                    setEditLogObject({ trantype: 'OPNRECO', trannumber: 1, location: locationCode, entity_name: location, tranDate: Common.dateYMD(currDate), tdu_status: res });
                    setShowEditRequest(true);
                })
            }
        })


    }

    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h2>Opening Bank Statement</h2>
                    </Col>
                </Row>
                &nbsp;
                {
                    showList ?
                        <>

                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Location<span className="mandatory text-danger">*</span> &nbsp;
                                            <span><Badge bg="secondary" onClick={() => handleReset()}>Reset</Badge></span>
                                        </Form.Label>
                                        <AsyncSelect
                                            cacheOptions={false}
                                            defaultOptions={false}
                                            value={bsLocation}
                                            getOptionLabel={e => e.label + ' '}
                                            getOptionValue={e => e.id}
                                            loadOptions={Picklist.fetchLocationAllowedPicklist}
                                            onInputChange={Picklist.handleInputChange}
                                            onChange={handleChangeLocation}
                                            isDisabled={branchDisable === "true"}
                                        >
                                        </AsyncSelect>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Bank<span className="mandatory text-danger">*</span></Form.Label>
                                        <AsyncSelect
                                            cacheOptions={false}
                                            defaultOptions={false}
                                            value={bsBank}
                                            getOptionLabel={e => e.label + ' '}
                                            getOptionValue={e => e.id}
                                            loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 'BANK', bsLocationValue)}
                                            onInputChange={Picklist.handleInputChange}
                                            onChange={handleBankValue}
                                        >
                                        </AsyncSelect>
                                    </Form.Group>
                                </Col>
                            </Row>
                            &nbsp;
                            <Row>
                                <Col>
                                    {
                                        userRight.query === "1" ?
                                            <>
                                                <Button variant='outline-success' className='buttonStyle' size='sm' onClick={listBnkStmt}>List</Button>&nbsp;
                                                <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={exportFileWithoutLocation}>Export</Button>
                                            </>
                                            : null
                                    }
                                </Col>
                            </Row>
                            &nbsp;
                        </>
                        :
                        <> <Row>
                            <Col className='col-md-3 col-6'>
                                <Form.Label>Location : {location}</Form.Label>
                            </Col>
                            <Col className='col-md-3 col-6'>
                                <Form.Label>Bank : {bankName}</Form.Label>
                            </Col>
                        </Row>
                            <Row>
                                <Col>
                                    <Button variant='outline-danger' className='buttonStyle' size='sm' onClick={handleBack}>Back</Button>{' '}
                                    {
                                        userRight.add === "1" && !showAddNewForm ?
                                            <>
                                                <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => btnEditRequest()}>Edit</Button>&nbsp;
                                                <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={exportFile}>Export</Button>
                                            </>
                                            : null
                                    }
                                </Col>
                            </Row>
                            &nbsp;
                        </>
                }
                {
                    showAddNewForm && <>
                        <Row>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Flag<span className="text-danger">*</span></Form.Label>
                                    <Form.Select value={flag} onChange={e => setFlag(e.target.value)}
                                        required>
                                        <option value="D">Withdraw</option>
                                        <option value="C">Deposit</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Date<span className="text-danger">*</span></Form.Label>
                                    <DatePicker className="form-control"
                                        selected={bsDate}
                                        onChange={(date) => setBsDate(date)}
                                        dateFormat="dd/MM/yyyy"
                                        showYearDropdown
                                        showMonthDropdown
                                        useShortMonthInDropdown
                                        dropdownMode="select"
                                        peekNextMonth
                                        customInput={
                                            <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Amount<span className=" mandatory text-danger">*</span></Form.Label>
                                    <Form.Control value={amount}
                                        maxLength={10}
                                        onChange={(e) => Common.validateDecValue(e.target.value, setAmount)}
                                        placeholder="Amount" />
                                </Form.Group>
                            </Col>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Cheque No<span className=" mandatory text-danger">*</span></Form.Label>
                                    <Form.Control value={chequeNo}
                                        maxLength={100}
                                        onChange={e => Common.validateAlpNumValue(e.target.value, setChequeNo)}
                                        placeholder="Cheque No" />
                                </Form.Group>
                            </Col>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Voucher Type<span className=" mandatory text-danger">*</span></Form.Label>
                                    <Select options={voucherTypeData}
                                        value={voucherType}
                                        onChange={v => setVoucherType(v)} />
                                </Form.Group>
                            </Col>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Voucher Number<span className=" mandatory text-danger">*</span></Form.Label>
                                    <Form.Control value={invoiceNo}
                                        onChange={e => Common.validateNumValue(e.target.value, setInvoiceNo)}
                                        maxLength={7}
                                        placeholder="Invoice Number" />
                                </Form.Group>
                            </Col>
                        </Row>
                        &nbsp;
                        <Row>
                            <Col>
                                <Button variant='outline-success' className='buttonStyle' size='sm' onClick={addBnkStmt}>Add</Button>{' '}
                            </Col>
                        </Row>
                        &nbsp;
                    </>
                }
                {
                    !showList && <>
                        <Row>
                            <Col>
                                <Table responsive striped bordered hover>
                                    <thead>
                                        <tr>
                                            {userRight.edit === "1" ? <th></th> : null}
                                            {/* <th>Group</th>
                                            <th>Bank</th>
                                            <th>Bank Name</th> */}
                                            <th>Bank</th>
                                            <th>Date</th>
                                            <th>Cheque No</th>
                                            <th>Type</th>
                                            <th>Invoice No</th>
                                            <th style={{ textAlign: 'right' }}>Withdraw</th>
                                            <th style={{ textAlign: 'right' }}>Deposit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {bnkStmtData.map((res) =>
                                            <tr key={res.tfd_line_no}>
                                                {userRight.delete === "1" ?
                                                    <td className="textCenter">
                                                        {
                                                            showAddNewForm &&
                                                            <span className="handCursor" onClick={() => deleteBnkStmt(res.tfd_line_no)}>
                                                                <div className="colorThemeBlue"><FontAwesomeIcon icon={faTrash} /></div>
                                                            </span>
                                                        }
                                                    </td>
                                                    : null
                                                }
                                                {/* <td>{res.tfd_group}</td>
                                                <td>{res.tfd_account}</td>
                                                <td>{res.mvli_ledger_name}</td> */}
                                                <td>{res.tfd_bank}</td>
                                                <td>{res.formatted_date}</td>
                                                <td>{res.tfd_cheque_no}</td>
                                                <td>{res.mvt_type}</td>
                                                <td>{res.tfd_refnumber}</td>
                                                <td style={{ textAlign: 'right' }}>{res.tfd_flag == 'D' && res.tfd_amount}</td>
                                                <td style={{ textAlign: 'right' }}>{res.tfd_flag == 'C' && (-1 * res.tfd_amount)}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </>
                }
            </Container>
            <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
            </div>
            <Tran_data_unlock_request logObject={editLogObject} show={showEditRequest} hdrNotExist={true} onHide={() => setShowEditRequest(false)} />
        </>
    )
}

export default Opn_bank_statement
