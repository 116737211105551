
import React from 'react';
import { Route, Routes } from "react-router-dom";
import Change_pass from "./mc/Change_pass";
import Home from "./mc/Home";
import Loginv1 from "./mc/Loginv1";
import Master_city_list from "./mc/master/Master_city_list";
import Master_country_list from "./mc/master/Master_country_list";
import Master_dealer_category from "./mc/master/Master_dealer_category";
import Master_group_right from "./mc/master/Master_group_right";
import Master_id_type from "./mc/master/Master_id_type";
import Master_isd_list from "./mc/master/Master_isd_list";
import Master_issuer_list from "./mc/master/Master_issuer_list";
import Master_purpose_list from "./mc/master/Master_purpose_list";
import Master_region_list from "./mc/master/Master_region_list";
import Master_state_list from "./mc/master/Master_state_list";
import Master_user from "./mc/master/Master_user";
import Master_voucher_group from "./mc/master/financial/Master_voucher_group";
import Master_voucher_ledger from "./mc/master/financial/Master_voucher_ledger";

import Bank_cheque_book_transfer_in from "./mc/Bank/Bank_cheque_book_transfer_in";
import Fin_year from "./mc/Fin_year";
import Testing from './mc/Testing';
import Bank_cheque_book_cancellation from "./mc/bank/Bank_cheque_book_cancellation";
import Bank_cheque_book_transfer_out from "./mc/bank/Bank_cheque_book_transfer_out";
import Bank_reconciliation from './mc/bank/Bank_reconciliation';
import Cheque_Deposit from "./mc/bank/Cheque_Deposit";
import Issue_Cheque_Bounce from "./mc/bank/Issue_Cheque_Bounce";
import Received_cheque_bounce from "./mc/bank/Received_cheque_bounce";
import Master_IBR from "./mc/master/Master_IBR";
import Master_business_nature from './mc/master/Master_business_nature';
import Master_cost_center from "./mc/master/Master_cost_center";
import Master_country_class from "./mc/master/Master_country_class";
import Master_designation from './mc/master/Master_designation';
import Master_district_list from "./mc/master/Master_district_list";
import Master_dsr from "./mc/master/Master_dsr";
import Master_entity from "./mc/master/Master_entity";
import Master_executive from "./mc/master/Master_executive";
import Master_gst_classification from './mc/master/Master_gst_classification';
import Master_individual_profile from "./mc/master/Master_individual_profile";
import Master_ledger_link from "./mc/master/Master_ledger_link";
import Master_legal_entity from './mc/master/Master_legal_entity';
import Master_margin from "./mc/master/Master_margin";
import Master_msme from "./mc/master/Master_msme";
import Master_residential_status from "./mc/master/Master_residential_status";
import Master_risk from "./mc/master/Master_risk";
import Sample_Form from "./mc/master/Sample_Form";
import Master_agent_linking from "./mc/master/financial/Master_agent_linking";
import Master_group_doc from "./mc/master/financial/Master_group_doc";
import Master_voucher_type from "./mc/master/financial/Master_voucher_type";
import Master_division from "./mc/master/master_division";
import Bill_wise_detail from "./mc/reports/financial/Bill_wise_detail";
import Bill_wise_detail_payable from "./mc/reports/financial/Bill_wise_detail_payable";
import Cheque_used_report from "./mc/reports/financial/Cheque_used_report";
import Cheques_in_hand from "./mc/reports/financial/Cheques_in_hand";
import Ledger from "./mc/reports/financial/Ledger";
import Trial_balance from "./mc/reports/financial/Trial_balance";
import Manual_book_report from "./mc/reports/mis/Manual_book_report";
import Tran_payment_report from "./mc/reports/mis/Tran_payment_report";
import Transaction_report from "./mc/reports/mis/Transaction_report";
import Stock_in_hand_report from "./mc/reports/mis/currency/Stock_in_hand_report";
import Flm1_report from "./mc/reports/rbi/Flm1_report";
import RepCommission from "./mc/reports/tds/RepCommission";
import Accept_interbranch from "./mc/transaction/Accept_interbranch";
import FundRequest from "./mc/transaction/FundRequest";
import Fx_document from "./mc/transaction/Fx_document";
import Opn_bank_statement from "./mc/transaction/Opn_bank_statement";
import Tran_cancellation from "./mc/transaction/Tran_cancellation";
import Tran_commission_module from "./mc/transaction/Tran_commission_module";
import Tran_currency_stock from "./mc/transaction/Tran_currency_stock";
import Tran_data_unlock from "./mc/transaction/Tran_data_unlock";
import Tran_evtm_surrender from "./mc/transaction/Tran_evtm_surrender";
import Tran_glvtm_settlement from "./mc/transaction/Tran_glvtm_settlement";
import Tran_manual_book_cancellation from "./mc/transaction/Tran_manual_book_cancellation";
import Tran_opn_cmrcmp from "./mc/transaction/Tran_opn_cmrcmp";
import Tran_opn_etc from "./mc/transaction/Tran_opn_etc";
import Tran_opn_evtm from "./mc/transaction/Tran_opn_evtm";
import Tran_opn_tc from "./mc/transaction/Tran_opn_tc";
import Tran_opn_tc_settlement from "./mc/transaction/Tran_opn_tc_settlement";
import Tran_opn_vtc from "./mc/transaction/Tran_opn_vtc";
import Tran_opn_vtm_settlement from "./mc/transaction/Tran_opn_vtm_settlement";
import Tran_pb from "./mc/transaction/Tran_pb";
import Tran_pi from "./mc/transaction/Tran_pi";
import Tran_pp from "./mc/transaction/Tran_pp";
import Tran_sb from "./mc/transaction/Tran_sb";
import Tran_sp from "./mc/transaction/Tran_sp";
import Tran_trf_manual_book from "./mc/transaction/Tran_trf_manual_book";
import Tran_trf_out_currency from "./mc/transaction/Tran_trf_out_currency";
import Tran_trf_out_vtc from './mc/transaction/Tran_trf_out_vtc';
import Tran_trf_vtc from "./mc/transaction/Tran_trf_vtc";
import Tran_vtm_encashment from "./mc/transaction/Tran_vtm_encashment";
import Tran_vtm_settlement from "./mc/transaction/Tran_vtm_settlement";
import Currency_change from './mc/transaction/others/Currency_change';
import Currency_write_off from "./mc/transaction/others/Currency_write_off";
import Expense_transfer from "./mc/transaction/others/Expense_transfer";
import Fund_transfer from "./mc/transaction/others/Fund_transfer";
import Tran_module_payment from "./mc/transaction/others/Tran_module_payment";
import Tran_opn_trial from "./mc/transaction/tran_opn_trial";
import Inter_branch_entry from "./mc/transaction/voucher/Inter_branch_entry";
import Tran_fa_book from "./mc/transaction/voucher/Tran_fa_book";


let footerDiv = {
    position: 'fixed',
    bottom: '0',
    width: '100%',
    backgroundColor: '#00A1E4',
    color: '#ffffff'
}

function App() {
    return (
        <div>
            <Routes>
                <Route exact path="/" element={<Loginv1 />}></Route>
                <Route exact path="*" element={<Loginv1 />}></Route>
                <Route exact path="/login" element={<Loginv1 />}></Route>
                <Route exact path="/home" element={<Home />}></Route>
                <Route exact path="/user-list" element={<Master_user />}></Route>
                <Route exact path="/right-list" element={<Master_group_right />}></Route>
                <Route exact path="/changepassword" element={<Change_pass />}></Route>
                <Route exact path="/country-list" element={<Master_country_list />}></Route>
                <Route exact path="/city-list" element={<Master_city_list />}></Route>
                <Route exact path="/state-list" element={<Master_state_list />}></Route>
                <Route exact path="/issuer-list" element={<Master_issuer_list />}></Route>
                <Route exact path="/purpose-list" element={<Master_purpose_list />}></Route>
                <Route exact path="/isd-list" element={<Master_isd_list />}></Route>
                <Route exact path="/id-type" element={<Master_id_type />}></Route>
                <Route exact path="/dealer-list" element={<Master_dealer_category />}></Route>
                <Route exact path="/voucher-group" element={<Master_voucher_group />}></Route>
                <Route exact path="/voucher-ledger" element={<Master_voucher_ledger />}></Route>
                <Route exact path="/region-list" element={<Master_region_list />}></Route>
                <Route exact path="/country-class" element={<Master_country_class />}></Route>
                <Route exact path="/division-list" element={<Master_division />}></Route>
                <Route exact path="/risk-list" element={<Master_risk />}></Route>
                <Route exact path="/business-nature" element={<Master_business_nature />}></Route>
                <Route exact path="/legal-entity" element={<Master_legal_entity />}></Route>
                <Route exact path="/district-list" element={<Master_district_list />}></Route>
                <Route exact path="/cost-center" element={<Master_cost_center />}></Route>
                <Route exact path="/gst-classification" element={<Master_gst_classification />}></Route>
                <Route exact path="/msme" element={<Master_msme />}></Route>
                <Route exact path="/designation" element={<Master_designation />}></Route>
                <Route exact path="/executive" element={<Master_executive />}></Route>
                <Route exact path="/voucher-type" element={<Master_voucher_type />}></Route>
                {
                    /*
                      <Route path="/purchase-from-public" element={<Tran_purchase_public />}></Route>
                    */
                }

                <Route path="/purchase-from-public" element={<Tran_pp />}></Route>
                <Route exact path="/individual-profile" element={<Master_individual_profile />}></Route>
                <Route exact path="/group-doc" element={<Master_group_doc />}></Route>
                <Route exact path="/financial-year" element={<Fin_year />}></Route>
                <Route exact path="/sampleform" element={<Sample_Form />}></Route>
                <Route exact path="/tran-opn-trial" element={<Tran_opn_trial />}></Route>
                <Route exact path="/residential-status" element={<Master_residential_status />}></Route>
                <Route exact path="/tran-opn-cmrcmp" element={<Tran_opn_cmrcmp />}></Route>
                <Route exact path="/tran-currency-stock" element={<Tran_currency_stock />}></Route>
                <Route exact path="/tran-opn-vtc" element={<Tran_opn_vtc />}></Route>
                <Route exact path="/opn-bank-statement" element={<Opn_bank_statement />}></Route>
                <Route exact path="/tran-opn-tc" element={<Tran_opn_tc />}></Route>
                <Route exact path="/tran-opn-etc" element={<Tran_opn_etc />}></Route>
                <Route exact path="/vtm-encashment" element={<Tran_vtm_encashment />}></Route>
                <Route exact path="/tran-opn-evtm" element={<Tran_opn_evtm />}></Route>
                <Route exact path="/vtm-encashment" element={<Tran_vtm_encashment />}></Route>
                <Route exact path="/vtm-settlement" element={<Tran_opn_vtm_settlement />}></Route>
                {
                    /*
                      <Route exact path="/cheque-book-in" element={<Bank_cheque_book_in />}></Route>
                    */
                }

                <Route exact path="/cheque-book-in" element={<Bank_cheque_book_transfer_in />}></Route>
                <Route exact path="/tc-settlement" element={<Tran_opn_tc_settlement />}></Route>
                {
                    /*
                      <Route exact path="/fa-book-entry" element={<Tran_book_entry />}></Route>
                    */
                }

                <Route exact path="/fa-book-entry" element={<Tran_fa_book />}></Route>
                <Route exact path="/purchase-bulk" element={<Tran_pb />}></Route>
                <Route exact path="/sale-public" element={<Tran_sp />}></Route>
                <Route exact path="/transfer-vtc" element={<Tran_trf_vtc />}></Route>
                <Route exact path="/cheque-deposit" element={<Cheque_Deposit />}></Route>
                <Route exact path="/cheque-book-out" element={<Bank_cheque_book_transfer_out />}></Route>
                <Route exact path="/received-cheque-bounce" element={<Received_cheque_bounce />}></Route>
                <Route exact path="/issue-cheque-bounce" element={<Issue_Cheque_Bounce />}></Route>
                <Route exact path="/bank-reconciliation" element={<Bank_reconciliation />}></Route>
                <Route exact path="/manual-book" element={<Tran_trf_manual_book />}></Route>
                <Route exact path="/ledger" element={<Ledger />}></Route>
                <Route exact path="/testing" element={<Testing />}></Route>
                <Route exact path="/sale-bulk" element={<Tran_sb />}></Route>
                <Route exact path="/trial-balance" element={<Trial_balance />}></Route>
                <Route exact path="/out-vtc" element={<Tran_trf_out_vtc />}></Route>
                <Route exact path="/out-currency" element={<Tran_trf_out_currency />}></Route>
                <Route exact path="/accept-interbranch" element={<Accept_interbranch />}></Route>
                <Route exact path="/bill-wise-detail" element={<Bill_wise_detail />}></Route>
                <Route exact path="/ledger-link" element={<Master_ledger_link />}></Route>
                <Route exact path="/cheque-in-hand-report" element={<Cheques_in_hand />}></Route>
                <Route exact path="/cheque-used-report" element={<Cheque_used_report />}></Route>
                <Route exact path="/ib-entry" element={<Inter_branch_entry />}></Route>
                <Route exact path="/margin" element={<Master_margin />}></Route>
                <Route exact path="/ibr" element={<Master_IBR />}></Route>
                <Route exact path="/data-unlock" element={<Tran_data_unlock />}></Route>
                <Route exact path="/transaction-report" element={<Transaction_report />}></Route>
                <Route exact path="/currency-writeoff" element={<Currency_write_off />}></Route>
                <Route exact path="/purchase-invoice" element={<Tran_pi />}></Route>
                <Route exact path="/global-vtm-settlement" element={<Tran_glvtm_settlement />}></Route>
                <Route exact path="/currency-change" element={<Currency_change />}></Route>
                <Route exact path="/cancellation" element={<Tran_cancellation />}></Route>
                <Route exact path="/manual-cancellation" element={<Tran_manual_book_cancellation />}></Route>
                <Route exact path="/cheque-cancellation" element={<Bank_cheque_book_cancellation />}></Route>
                <Route exact path="/entity" element={<Master_entity />}></Route>
                <Route exact path="/settlement-vtm" element={<Tran_vtm_settlement />}></Route>
                <Route exact path="/expense-transfer" element={<Expense_transfer />}></Route>
                <Route exact path="/fund-transfer" element={<Fund_transfer />}></Route>
                <Route exact path="/stock-in-hand-report" element={<Stock_in_hand_report />}></Route>
                <Route exact path="/manual-book-report" element={<Manual_book_report />}></Route>
                <Route exact path="/fund-request" element={<FundRequest />}></Route>
                <Route exact path="/master-dsr" element={<Master_dsr />}></Route>
                <Route exact path="/flm-report" element={<Flm1_report />}></Route>
                <Route exact path="/fx-document" element={<Fx_document />}></Route>
                <Route exact path="/evtm-surrender" element={<Tran_evtm_surrender />}></Route>
                <Route exact path="/commission-module" element={<Tran_commission_module />}></Route>
                <Route exact path="/tran-module-payment" element={<Tran_module_payment />}></Route>
                <Route exact path="/agent-linking" element={<Master_agent_linking />}></Route>
                <Route exact path="/bill-wise-detail-payable" element={<Bill_wise_detail_payable />}></Route>
                <Route exact path="/commission-tds" element={<RepCommission />}></Route>
                <Route exact path="/tran-payment-report" element={<Tran_payment_report />}></Route>
            </Routes>

            <div style={footerDiv}>
                {
                    sessionStorage.getItem("userSrno") == 1 ? <>
                        <div style={{ fontSize: '12px' }}>
                            {sessionStorage.getItem("startDate")}&nbsp;{sessionStorage.getItem("endDate")}&nbsp;{sessionStorage.getItem("dbname")}&nbsp;{sessionStorage.getItem("branchallowed")}
                        </div>

                    </> : null
                }
                <div style={{ fontSize: '12px', textAlign: 'center' }}>Copyright &copy; 2023 Zenith. All rights reserved.</div>
            </div>


        </div >

    );
}

export default App;