import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Table, Button } from 'react-bootstrap'
import * as Common from "../Common";
import { useNavigate } from 'react-router-dom';
import Login_header from '../Login_header';
import Menu from '../Menu';

function Master_dealer_category() {
    const sid = sessionStorage.getItem("sessionId");
    const [onceRun, setOnceRun] = useState(false);
    const [dealerList, setDealerList] = useState([]);
    const navigate = useNavigate();
    const [userRight, setUserRight] = useState([]);

    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.callApi(Common.apiRight, [sid, 'all', 'DEALERLIST'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                    Common.callApi(Common.apiDealer, [sid, "listdealer"], result => {
                        setDealerList(JSON.parse(result));
                    })
                } else {
                    navigate('/'); // no query right
                }
            });
            setOnceRun(true);
        }
    }, [onceRun]);

    const exportFile = () => {
        var obj = {
            right: 'DEALERLIST'
        }
        Common.callDownloadApiPost(Common.apiReport, "post", [sid, JSON.stringify(obj)]);
    }

    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h3>Dealer Category</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {
                            userRight.query === "1" ?
                                <>&nbsp;
                                    <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={exportFile}>Export</Button>
                                </>
                                : null
                        }
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                <Row>
                    <Col>
                        <Table responsive striped>
                            <thead>
                                <tr>
                                    <th>Code</th>
                                    <th>Description</th>
                                    <th>Expirable</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dealerList.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.md_code}</td>
                                            <td>{item.md_desc}</td>
                                            <td>{item.md_tax_applicable == 1 ? "Yes" : "No"}</td>
                                            <td>{item.md_active == 1 ? "ACTIVE" : "INACTIVE"}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Master_dealer_category
