import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, FormSelect, Row, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import * as Common from "../Common";
import Dialog from '../Dialog';
import DialogYesNo from '../Dialog_Yes_No';
import Login_header from '../Login_header';
import Menu from '../Menu';
import * as Picklist from '../Picklist';

// import DynamicDropdown from '../DynamicSelector';


const Tran_data_unlock = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const branchDisable = sessionStorage.getItem('branchDisable');
    const [userRight, setUserRight] = useState([]);

    const [count, setCount] = useState(0);

    const [onceRun, setOnceRun] = useState(false);

    const [lockItem, setLockItem] = useState([]);

    const [lockEntry, setLockEntry] = useState([]);

    const [scrMode, setScrMode] = useState('');

    const [locationValue, setLocationValue] = useState(null);
    const [locationCode, setLocationCode] = useState('');
    const [location, setLocation] = useState('');

    const [srnoFrom, setSrnoFrom] = useState('');
    const [srnoTo, setSrnoTo] = useState('');
    const [type, setType] = useState('IB');

    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());

    const [unlockType, setUnlockType] = useState('T');

    var msg = [], i = 0;

    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");

    const [selectedItems, setSelectedItems] = useState([]);
    const [radioType, setRadioType] = useState('A');

    const [lineNum, setLineNum] = useState(0);



    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.getActiveBranch().then(response => {
                setLocationValue(response);
                response != null && handleChange(response);
            });
            Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_DATA_UNLOCK'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
            });
            setOnceRun(true);
        }


    }, [onceRun, sid, navigate]);

    const handleList = () => {
        if (locationValue === null) {
            setMsgText("Please Select Location!");
            setMsgBox(true);
        } else {
            const obj = {
                status: 'P',
                type: 'IB',
                location: locationCode,
                unlockType: unlockType
            }
            Common.callApi(Common.apiDataUnlock, [sid, "getData", JSON.stringify(obj)], result => {
                let resp = JSON.parse(result);
                setLockItem(resp);
            });
        }
    }

    const handleChange = value => {
        setLocationValue(value);
        const loc = value.id.split('^');
        setLocationCode(loc[1]);
        setLocation(loc[2]);
        sessionStorage.activeBranch = loc[1];
    }

    const handleApproved = (srno, tran_srno, tran_type, fromDate, toDate, unlockType, loc) => {
        let currentDate = new Date();
        const obj = {
            srno: srno,
            tran_srno: tran_srno,
            type: tran_type,
            status: 'A',
            currentDate: Common.dateYMD(currentDate),
            fromDate: fromDate,
            toDate: toDate,
            unlockType: unlockType,
            location: loc
        }
        Common.callApi(Common.apiDataUnlock, [sid, "approveRequest", JSON.stringify(obj)], result => {
            let resp = JSON.parse(result);
            setLockItem(resp);
        });
    }

    const handleAddNew = () => {
        if (!locationValue) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (!locationValue ? "Please select location." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            setScrMode('A');
            setLineNum(0);
        }
    }

    const handleAdd = () => {
        if ((!srnoFrom && unlockType == 'T') || (!srnoTo && unlockType == 'T') || !toDate) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = ((!srnoFrom && unlockType == 'T') ? "Please enter from srno." : '');
            msg[i++] = (!srnoTo && unlockType == 'T' ? "Please enter to srno." : '');
            msg[i++] = (!type ? 'Please enter type' : '');
            msg[i++] = (!toDate ? 'Please enter till date' : '');

            // msg[i++] = ((Common.dateYMD(filterDate) < startDate) ? 'Date should be in financial year' : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {

            const obj = {
                uniquekey: Common.getRandomString(15),
                tdud_lineno: lineNum + 1,
                tdud_from: srnoFrom,
                tdud_to: srnoTo,
                to_date: Common.dateYMD(toDate),
                fromDate: Common.dateYMD(fromDate),
                tdu_tran_type: type,
                delny: 'Y',

            }
            console.log(obj);

            setLockEntry(Common.arrayAddItem(lockEntry, obj));
            setCount(count + 1);
            setSrnoFrom('');
            setSrnoTo('');
            setFromDate(new Date());
            setToDate(new Date());
            setType('IB');
        }

    }

    const handleSave = () => {

        const obj = {
            location: locationCode,
            lockEntry: lockEntry,
            unlockType: unlockType,
            status: 'P'
        }

        Common.callApi(Common.apiDataUnlock, [sid, "saveEntry", JSON.stringify(obj)], result => {
            let resp = JSON.parse(result);
            console.log(resp);
            setLockItem(resp);
            setScrMode('');
            setLineNum(0);
            // setUnlockType('T');
        })



    }

    const handleBack = () => {
        setScrMode('');
    }

    const cancelData = () => {
        setScrMode('');
        setMsgBoxYN(false);
    }

    const handleDelete = (val, srno, line, type) => {
        console.log('here');
        setLockEntry(Common.arrayRemoveItem(lockEntry, "uniquekey", val));
        setCount(count - 1);
    }

    const handleView = (srno, type, loc, id, status) => {

        const obj = {
            srno: srno,
            type: type,
            id: id,
            status: status
        }
        setScrMode('E');
        console.log(obj);

    }


    return (
        <div>
            <Login_header />
            <Menu />

            <Container fluid>
                <Row>
                    <Col>
                        <h2>Data Unlock <span style={{ color: 'white' }}>{count}</span></h2>
                    </Col>
                </Row> &nbsp;

                {
                    scrMode == '' ?
                        <>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Branch<span className="text-danger">*</span></Form.Label>
                                        <AsyncSelect
                                            cacheOptions={false}
                                            defaultOptions={false}
                                            value={locationValue}
                                            getOptionLabel={e => e.label + ' '}
                                            getOptionValue={e => e.id}
                                            loadOptions={Picklist.fetchLocationAllowedPicklist}
                                            onInputChange={Picklist.handleInputChange}
                                            onChange={(e) => handleChange(e)}
                                            // disabled={locationValue != null}
                                            isDisabled={branchDisable === "true"}
                                        >
                                        </AsyncSelect>
                                    </Form.Group>
                                </Col>

                                <Col className='col-md-3 col-6'>
                                    <Form.Label>Unlock Type</Form.Label>
                                    <FormSelect value={unlockType} onChange={(e) => setUnlockType(e.target.value)} required >
                                        <option value="T">Transaction</option>
                                        <option value="P">Period</option>
                                    </FormSelect>
                                </Col>
                            </Row>&nbsp;

                            <Row>
                                <Col >
                                    <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={handleList}>
                                        List
                                    </Button>{' '}
                                    <Button variant='outline-success' className='buttonStyle' size='sm' onClick={handleAddNew}>
                                        Add New
                                    </Button>
                                </Col>
                            </Row>&nbsp;
                            {
                                lockItem.length > 0 &&
                                <Row>
                                    <Col>
                                        <Table striped bordered hover size="sm">
                                            <thead>
                                                <tr>
                                                    {/* {userRight.add === "1" ? <th></th> : null} */}
                                                    <th>Type</th>
                                                    <th>Srno</th>
                                                    <th>Location</th>
                                                    <th>User</th>
                                                    <th>Status</th>
                                                    <th>Request</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {lockItem.map((res) => (
                                                    <tr key={res.tdu_srno}>
                                                        {/* {userRight.delete === "1" ?
                                                        <td className="textCenter" >
                                                            {
                                                                <span className="handCursor"
                                                                    onClick={() => handleView(res.tdu_tran_srno, res.tdu_tran_type, res.entity_name, res.um_id, res.tdu_status)}
                                                                >
                                                                    <div className="colorThemeBlue"><FontAwesomeIcon icon={faEye} /></div>
                                                                </span>
                                                            }
                                                        </td>
                                                        : null
                                                    } */}
                                                        <td>{res.tdu_tran_type}</td>
                                                        <td>{res.tdu_tran_srno}</td>
                                                        <td>{res.entity_name}</td>
                                                        <td>{res.um_id}</td>
                                                        <td>
                                                            {res.tdu_status === 'P' ? 'Pending' :
                                                                res.tdu_status === 'A' ? 'Approved' :
                                                                    res.tdu_status === 'R' ? 'Rejected' :
                                                                        res.tdu_status === 'E' ? 'Expired' :
                                                                            ''}
                                                        </td>
                                                        {res.tdu_status === 'P' && (
                                                            <td>
                                                                <Button variant="primary" onClick={() => handleApproved(res.tdu_srno, res.tdu_tran_srno, res.tdu_tran_type, res.tdu_from_date, res.tdu_to_date, res.tdu_unlock_type, res.tdu_tran_loc)}>Approve</Button>{' '}
                                                                <Button variant="danger" >Reject</Button>
                                                            </td>
                                                        )}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            }


                        </> : scrMode == 'A' ?
                            <>
                                <Row>
                                    <Col>
                                        <Button variant='outline-danger' className='buttonStyle' size='sm' onClick={handleBack}>
                                            Back
                                        </Button>{' '}
                                        <Button variant='outline-success' className='buttonStyle' size='sm' onClick={handleSave}>
                                            Save
                                        </Button>
                                    </Col>
                                </Row>&nbsp;

                                <Row>

                                    {
                                        unlockType == 'T' &&
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>From Srno<span className="colorThemeRed">*</span></Form.Label>
                                                <Form.Control value={srnoFrom}
                                                    onChange={(e) => Common.validateNumValue(e.target.value, setSrnoFrom)}
                                                    placeholder='From Srno'

                                                />
                                            </Form.Group>
                                        </Col>
                                    }

                                    {unlockType == 'T' &&
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>To Srno<span className="colorThemeRed">*</span></Form.Label>
                                                <Form.Control value={srnoTo}
                                                    onChange={(e) => Common.validateNumValue(e.target.value, setSrnoTo)}
                                                    placeholder='To Srno'

                                                />
                                            </Form.Group>
                                        </Col>
                                    }
                                    <Col className='col-md-3 col-6'>
                                        <Form.Group>
                                            <Form.Label>Type</Form.Label>
                                            <FormSelect value={type} onChange={(e) => setType(e.target.value)} required>
                                                {/* <option value="A">All</option> */}
                                                {unlockType == 'P' && <option value="A">All</option>}
                                                <option value="BK">BK</option>
                                                <option value="CS">CS</option>
                                                <option value="JV">JV</option>
                                                <option value="IB">IB</option>
                                            </FormSelect>
                                        </Form.Group>
                                    </Col>

                                    {/* <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>From Date<span className="colorThemeRed">*</span></Form.Label><br />
                                        <DatePicker className="form-control"
                                            selected={fromDate}
                                            onChange={(date) => setFromDate(date)}
                                            dateFormat="dd/MM/yyyy"
                                            showYearDropdown
                                            showMonthDropdown
                                            useShortMonthInDropdown
                                            dropdownMode="select"
                                            peekNextMonth
                                            customInput={
                                                <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                            }
                                        />
                                    </Form.Group>
                                </Col> */}

                                    <Col className='col-md-3 col-6'>
                                        <Form.Group>
                                            <Form.Label>Till Date<span className="colorThemeRed">*</span></Form.Label><br />
                                            <DatePicker className="form-control"
                                                selected={toDate}
                                                onChange={(date) => setToDate(date)}
                                                dateFormat="dd/MM/yyyy"
                                                showYearDropdown
                                                showMonthDropdown
                                                useShortMonthInDropdown
                                                dropdownMode="select"
                                                peekNextMonth
                                                customInput={
                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                }
                                            />
                                        </Form.Group>
                                    </Col>

                                    {/* <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Branch<span className="text-danger">*</span></Form.Label>
                                        <DynamicDropdown type='location' onDataFromChild={handleDataFromChild} />
                                    </Form.Group>
                                </Col> */}

                                </Row>&nbsp;

                                <Row>
                                    <Col>
                                        <Button variant='outline-success' className='buttonStyle' size='sm' onClick={handleAdd}>
                                            Add
                                        </Button>
                                    </Col>
                                </Row>&nbsp;

                                {
                                    lockEntry.length > 0 &&
                                    <Row>
                                        <Col>
                                            <Table striped bordered hover >
                                                <thead>
                                                    <tr>
                                                        {userRight.delete === "1" ? <th></th> : null}
                                                        <th>Type</th>
                                                        {
                                                            unlockType == 'T' ?
                                                                <>
                                                                    <th>From Srno</th>
                                                                    <th>To Srno</th>
                                                                </> : <>
                                                                    <th>Till Date</th>
                                                                </>
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {lockEntry.map((res) =>
                                                        <tr>
                                                            {userRight.delete === "1" ?
                                                                <td className="textCenter" >
                                                                    {
                                                                        (scrMode == 'E' || scrMode == 'A') && res.delny == "Y" ?
                                                                            <span className="handCursor colorThemeBlue"
                                                                                onClick={() => handleDelete(res.uniquekey, res.tdud_srno, res.tdud_line_no, res.tdu_tran_type)}>
                                                                                <FontAwesomeIcon icon={faTrash} />
                                                                            </span>
                                                                            : null
                                                                    }
                                                                </td>
                                                                : null
                                                            }
                                                            <td>{res.tdu_tran_type}</td>
                                                            {
                                                                unlockType == 'T' ?
                                                                    <>
                                                                        <td>{res.tdud_from}</td>
                                                                        <td>{res.tdud_to}</td>
                                                                    </> : <>
                                                                        <td>{res.to_date}</td>
                                                                    </>
                                                            }


                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                }

                            </> : <>

                            </>
                }
            </Container>

            <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                {msgText}
            </Dialog>

            <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
                {msgTextYN}
            </DialogYesNo>

            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>

        </div>


    )
}

export default Tran_data_unlock
