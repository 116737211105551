import React, { useEffect, useState } from 'react'
import Login_header from '../../Login_header'
import Menu from '../../Menu'
import { Button, Col, Container, Form, Row, Tab, Table, Tabs } from 'react-bootstrap'
import AsyncSelect from 'react-select/async';
import * as Picklist from '../../Picklist';
import DatePicker from 'react-datepicker';
import * as Common from '../../Common';
import { json, useNavigate } from 'react-router-dom';
import Dialog from '../../Dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Tran_data_unlock_request from '../../Tran_data_unlock_request';
import FaView from '../../FaView';

const Fund_transfer = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const startDate = sessionStorage.getItem('startDate');
    const endDate = sessionStorage.getItem('endDate');
    const branchDisable = sessionStorage.getItem('branchDisable');

    const [userRight, setUserRight] = useState([]);
    const [onceRun, setOnceRun] = useState(false);
    const [scrMode, setScrMode] = useState("");

    const [headerData, setHeaderData] = useState([]);

    const [locationValue, setLocationValue] = useState(null);
    const [locationCode, setLocationCode] = useState(0);
    const [location, setLocation] = useState('');
    const [locationGroup, setLocationGroup] = useState('0');
    const [locationLedger, setLocationLedger] = useState('0');

    const [fundDate, setFundDate] = useState(new Date());
    const [fundFromDate, setFundFromDate] = useState(new Date());
    const [fundToDate, setFundToDate] = useState(new Date());

    const [radioType, setRadioType] = useState('N');

    const [fundFromSrno, setFundFromSrno] = useState('');
    const [fundToSrno, setFundToSrno] = useState('');

    const [key, setKey] = useState("INFORMATION");

    const [infoType, setInfoType] = useState('D');
    const [infoDate, setInfoDate] = useState(new Date());
    const [infoReferenceNum, setInfoReferenceNum] = useState('');
    const [detailReferenceNum, setDetailReferenceNum] = useState('');


    const [ledgerValue, setLedgerValue] = useState(null);
    const [grpCode, setGrpCode] = useState('0');
    const [accCode, setAccCode] = useState('0');
    const [accountType, setAccountType] = useState('');
    const [accountName, setAccountName] = useState('');

    const [branchValue, setBranchValue] = useState(null);
    const [branchCode, setBranchCode] = useState(0);
    const [branch, setBranch] = useState('');
    const [branchGroup, setBranchGroup] = useState('0');
    const [branchLedger, setBranchLedger] = useState('0');

    const [amount, setAmount] = useState(0);

    const [bankValue, setBankValue] = useState(null);
    const [bankGroup, setBankGroup] = useState('0');
    const [bankLedger, setBankLedger] = useState('0');
    const [bankName, setBankName] = useState('');

    const [dataList, setDataList] = useState([]);

    const [counter, setCounter] = useState(0);

    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    const [srNo, setSrNo] = useState(0);

    const [totalDebit, setTotalDebit] = useState(0);
    const [totalCredit, setTotalCredit] = useState(0);
    const [totalDifference, setTotalDifference] = useState(0);

    const [faViewObject, setFaViewObject] = useState({ location: "", trannumber: "", type: "" });
    const [showFaView, setShowFaView] = useState(false);



    var i = 0, msg = [];

    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.getActiveBranch().then(response => {
                setLocationValue(response);
                response != null && handleChangeLocation(response);
            });
            Common.callApi(Common.apiRight, [sid, 'all', 'FUND_TRANSFER'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);

    const handleChangeLocation = (value) => {
        setLocationValue(value);
        const loc = value.id.split('^');
        console.log(loc, 'location');
        setLocationCode(loc[1]);
        setLocation(loc[2]);
        setLocationGroup(loc[6]);
        setLocationLedger(loc[7]);
        sessionStorage.activeBranch = loc[1];
    }

    const handleChangeBranch = (value) => {
        setBranchValue(value);
        const loc = value.id.split('^');
        console.log(loc, 'branch');
        setBranchCode(loc[1]);
        setBranch(loc[2]);
        setBranchGroup(loc[6]);
        setBranchLedger(loc[7]);
    }

    const handleLedgerValue = (value) => {
        setLedgerValue(value);
        console.log(value);
        const account = value.id.split('^');
        setGrpCode(account[1]);
        setAccCode(account[2]);
        setAccountType(account[4]);
        setAccountName(account[3]);
    }

    const handleBankValue = (value) => {
        setBankValue(value);
        console.log(value);
        const bank = value.id.split('^');
        console.log(bank);

        setBankGroup(bank[1]);
        setBankLedger(bank[2]);
        setBankName(bank[3]);
    }

    const handleTabChange = (k) => {
        setKey(k);
    }

    const addNew = () => {
        var date = Common.dateYMD(fundDate);
        if (!locationValue || !fundDate) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (!locationValue ? " Please select location." : '');
            msg[i++] = (!fundDate ? " Please select date." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (date > endDate || date < startDate) {
            setMsgBox(true);
            setMsgText(Common.getMessage("MSG0010"));
        }
        else {
            setScrMode('A');
            setDataList([]);
            setLedgerValue(null);
            setAmount('');
            setSrNo(0);
            setKey("INFORMATION");
            setInfoReferenceNum('');
        }
    }

    const list = () => {

        if (!locationValue || (radioType == 'S' && !fundFromSrno) || (radioType == 'S' && !fundToSrno) || (radioType == 'D' && !fundFromDate) || (radioType == 'D' && !fundToDate)) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (!locationValue ? " Please select location." : '');
            msg[i++] = ((radioType == 'S' && !fundFromSrno) ? " Please enter from srno." : '');
            msg[i++] = ((radioType == 'S' && !fundToSrno) ? " Please enter to srno." : '');
            msg[i++] = ((radioType == 'D' && !fundFromDate) ? " Please select from date." : '');
            msg[i++] = ((radioType == 'D' && !fundToDate) ? " Please select to date." : '');

            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const obj = {
                hdr_location: locationCode,
                hdr_type: 'FI',
                fromSrno: fundFromSrno,
                toSrno: fundToSrno,
                fromDate: Common.dateYMD(fundFromDate),
                toDate: Common.dateYMD(fundToDate),
                radioType: radioType
            }
            Common.callApi(Common.apiFundTransfer, [sid, 'showHeaderEntry', JSON.stringify(obj)], result => {
                let resp = JSON.parse(result);
                setHeaderData(resp);
            })
        }
    }

    const handleView = (srno, type, loc, date, grp, ledger, ref_no, loc_name, in_out_type, brGroup, brLedger) => {
        setLocation(loc_name)
        setInfoReferenceNum(ref_no);
        setInfoType(in_out_type);
        setSrNo(srno);
        setBankGroup(brGroup);
        setBankLedger(brLedger);
        setKey('INFORMATION');
        const obj = {
            srno: srno,
            type: type,
            date: date,
            location: loc,
            group: grp,
            ledger: ledger
        }
        Common.callApi(Common.apiFundTransfer, [sid, 'viewDataList', JSON.stringify(obj)], result => {
            let resp = JSON.parse(result);
            console.log(resp);
            setLedgerValue({ id: resp.p_id, label: resp.p_label });
            setGrpCode(grp);
            setAccCode(ledger)
            setDataList(resp.resarray);
            calculateTotal(resp.resarray);
            setScrMode('Q');
            setAmount('');

            const object = {

            }

            // Common.callApi(Common.apiFundTransfer, [sid, 'viewDataList', JSON.stringify(object)], result => {

            // })
        })
    }

    const handleBack = () => {
        setScrMode('');
    }

    const btnEditRequest = () => {
        setScrMode('E');
    }

    const handleSave = () => {

        if (!ledgerValue) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (!ledgerValue ? " Please select account." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {

            const obj = {
                hdr_location: locationCode,
                hdr_date: Common.dateYMD(fundDate),
                hdr_group: grpCode,
                hdr_ledger: accCode,
                hdr_type: 'FI',
                srNo: srNo,
                hdr_ref_no: infoReferenceNum,
                dataList: dataList,
                fromSrno: fundFromSrno,
                toSrno: fundToSrno,
                fromDate: Common.dateYMD(fundFromDate),
                toDate: Common.dateYMD(fundToDate),
                radioType: radioType,
                hdr_total: totalDifference,
                hdr_location_group: locationGroup,
                hdr_location_ledger: locationLedger,
                hdr_in_out: infoType == 'D' ? 'O' : 'I'
            }

            Common.callApi(Common.apiFundTransfer, [sid, 'saveEntry', JSON.stringify(obj)], result => {
                console.log(result);
                let resp = JSON.parse(result);
                setHeaderData(resp);
                setScrMode('');
            })
        }
    }

    const handleFaView = () => {
        setShowFaView(true);
        setFaViewObject({ location: locationCode, trannumber: srNo, type: 'FI' });
    }

    const handleDetailAdd = () => {

        if (!branchValue || !amount || !bankValue || locationCode == branchCode) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (!branchValue ? " Please select  branch." : '');
            msg[i++] = (!bankValue ? " Please select  bank." : '');
            msg[i++] = (!amount ? " Please enter amount." : '');
            msg[i++] = (locationCode == branchCode ? "You can't select same branch." : '');

            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const obj = {
                uniquekey: Common.getRandomString(15),
                td_location: branchCode,
                td_amount: amount,
                td_ref_no: detailReferenceNum,
                td_group: bankGroup,
                td_ledger: bankLedger,
                td_date: Common.dateYMD(fundDate),
                td_type: 'FI',
                td_location_name: branch,
                td_flag: infoType,
                delny: 'Y',
                branchgroup: branchGroup,
                branchledger: branchLedger
            }
            setDataList(Common.arrayAddItem(dataList, obj));
            calculateTotal(dataList)
            setCounter(counter + 1);
            // setFlag('D');
            setAmount('');
            setBranchValue(null);
            setBankValue(null);
            setDetailReferenceNum('');
        }
    }

    const handleDetail = () => {
        setKey("DETAIL");
    }

    const handleInnerFaView = (srno, type, loc) => {
        setShowFaView(true);
        setFaViewObject({ location: loc, trannumber: srno, type: type });
    }

    const handleDelete = (val, loc, type, srno, lineno) => {
        console.log(val);

        setDataList(Common.arrayRemoveItem(dataList, "uniquekey", val));
        setCounter(counter - 1);
        console.log(dataList);
        calculateTotal(dataList)
    }

    const calculateTotal = (list) => {

        let totalDebt = 0;
        let totalCred = 0;
        let i;

        for (i = 0; i < list.length; i++) {
            if (list[i].td_flag == 'D') {
                totalDebt = totalDebt + list[i].td_amount * 1;
            } else {
                totalCred += list[i].td_amount * -1;
            }
        }

        setTotalDebit(totalDebt);
        setTotalCredit(totalCred);
        let totalDiff = totalDebt - totalCred;
        setTotalDifference(totalDiff.toFixed(2));
        console.log(totalDiff);
        if (totalDiff < 0) {
            console.log("jjjjjj");
            // setFlag('D');
            setAmount(-1 * totalDiff);
        }
        //  else if (totalDiff == 0) {
        //     setAmount("");
        // }
        else {
            // setFlag('C');
            setAmount(totalDiff);
        }
    }



    return (
        <div>
            <Login_header />
            <Menu />

            <Container fluid>
                <Row>
                    <Col>
                        <h2>Fund Transfer <span style={{ color: 'white' }}>{counter}</span></h2>
                    </Col>
                </Row>&nbsp;

                {
                    scrMode === '' ?
                        <>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Location<span className="text-danger">*</span></Form.Label>
                                        <AsyncSelect
                                            cacheOptions={false}
                                            defaultOptions={false}
                                            value={locationValue}
                                            getOptionLabel={e => e.label + ' '}
                                            getOptionValue={e => e.id}
                                            loadOptions={Picklist.fetchLocationAllowedPicklist}
                                            onInputChange={Picklist.handleInputChange}
                                            onChange={handleChangeLocation}
                                            isDisabled={branchDisable === "true"}
                                        >
                                        </AsyncSelect>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-4 col-6'>
                                    <Form.Group>
                                        <Row style={{ marginTop: "30px" }}>
                                            <Col>
                                                <Form.Check checked={radioType === "N"} onChange={() => setRadioType("N")} type='radio' label="New" />
                                            </Col><Col>
                                                <Form.Check checked={radioType === "S"} onChange={() => setRadioType("S")} type='radio' label="SrNo" />
                                            </Col><Col>
                                                <Form.Check checked={radioType === "D"} onChange={() => setRadioType("D")} type='radio' label="Date" />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                {
                                    radioType === 'N' ?
                                        <>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Date<span className="text-danger">*</span></Form.Label>
                                                    <DatePicker className="form-control"
                                                        selected={fundDate}
                                                        minDate={new Date(startDate)}
                                                        maxDate={new Date(endDate)}
                                                        onChange={(date) => setFundDate(date)}
                                                        dateFormat="dd/MM/yyyy"
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        useShortMonthInDropdown
                                                        dropdownMode="select"
                                                        peekNextMonth
                                                        customInput={
                                                            <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </>
                                        :
                                        radioType === 'S' ?
                                            <>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>From<span className="text-danger">*</span></Form.Label>
                                                        <Form.Control value={fundFromSrno}
                                                            maxLength={20}
                                                            onChange={(e) => Common.validateNumValue(e.target.value, setFundFromSrno)}
                                                            placeholder="From" />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>To<span className="text-danger">*</span></Form.Label>
                                                        <Form.Control value={fundToSrno}
                                                            maxLength={20}
                                                            onChange={(e) => Common.validateNumValue(e.target.value, setFundToSrno)}
                                                            placeholder="To" />
                                                    </Form.Group>
                                                </Col>
                                            </>
                                            :
                                            radioType === 'D' ?
                                                <>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                                                            <DatePicker className="form-control"
                                                                selected={fundFromDate}
                                                                onChange={(date) => setFundFromDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>To<span className="colorThemeRed">*</span></Form.Label>
                                                            <DatePicker className="form-control"
                                                                selected={fundToDate}
                                                                onChange={(date) => setFundToDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </>
                                                :
                                                null
                                }
                            </Row>&nbsp;

                            <Row>
                                <Col>
                                    {
                                        // userRight.add === "1" &&
                                        radioType === 'N' ?
                                            <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addNew()}>Add New</Button> : null
                                    }
                                    &nbsp;
                                    {
                                        // userRight.query === "1" &&
                                        (radioType === 'S' || radioType === 'D') ?
                                            < Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => list()}>List</Button>
                                            : null
                                    }
                                </Col>
                            </Row>&nbsp;

                            {headerData.length > 0 &&
                                <Row>
                                    <Col>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>&nbsp;</th>
                                                    <th>Location</th>
                                                    <th>Type</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            {
                                                headerData.map((res) =>
                                                    <tbody>
                                                        <tr key={res.hdr_srno}>
                                                            <td>
                                                                <span className='pointer colorThemeBlue' onClick={() => handleView(res.hdr_srno, res.hdr_type, res.hdr_location, res.formatted_date, res.hdr_group, res.hdr_ledger, res.hdr_ref_no, res.entity_name, res.hdr_in_out, res.branchGroup, res.branchLedger)}>
                                                                    <div className="colorThemeBlue"><FontAwesomeIcon icon={faEye} /></div>
                                                                </span>
                                                            </td>
                                                            <td>{res.entity_name}</td>
                                                            <td>{res.hdr_type}</td>
                                                            <td>{res.formatted_date}</td>
                                                        </tr>
                                                    </tbody>
                                                )
                                            }
                                        </Table>
                                    </Col>
                                </Row>
                            }
                        </> :
                        <>
                            <Row>
                                <Col>
                                    <Button variant='outline-success' className='buttonStyle' size='sm'
                                        onClick={handleBack}
                                    >Back</Button>{' '}

                                    {
                                        (userRight.edit === "1" && scrMode === 'Q') ? <>
                                            <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                btnEditRequest()}>Edit</Button>&nbsp;
                                        </> : null
                                    }

                                    {
                                        (userRight.edit === "1" && scrMode === 'E') || (userRight.add === "1" && scrMode === 'A') ?
                                            <Button variant='outline-success' className='buttonStyle' size='sm'
                                                onClick={handleSave}
                                            >Save</Button> : null
                                    }
                                    {
                                        (userRight.query === "1" && scrMode === "Q") ? <>
                                            {/* <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() => print("D")}>With Deno</Button>&nbsp;
                                                        <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={() => print("")}>Without Deno</Button>&nbsp; */}
                                            <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={() => handleFaView()}>Fa View</Button>
                                        </> : null
                                    }
                                </Col>
                            </Row>&nbsp;

                            <Tabs activeKey={key} defaultActiveKey="INFO" onSelect={k => handleTabChange(k)}>
                                <Tab eventKey="INFORMATION" title="Information">
                                    <Row>&nbsp;</Row>

                                    <Row>
                                        {/*                                         
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Date<span className="text-danger">*</span></Form.Label>
                                                <DatePicker className="form-control"
                                                    selected={infoDate}
                                                    minDate={new Date(startDate)}
                                                    maxDate={new Date(endDate)}
                                                    onChange={(date) => setInfoDate(date)}
                                                    dateFormat="dd/MM/yyyy"
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    useShortMonthInDropdown
                                                    dropdownMode="select"
                                                    peekNextMonth
                                                    customInput={
                                                        <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                    }
                                                />
                                            </Form.Group>
                                        </Col> */}

                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Type<span className="text-danger">*</span></Form.Label>
                                                <Form.Select value={infoType} onChange={(e) => setInfoType(e.target.value)} required
                                                    disabled={scrMode != 'A' && true}
                                                >
                                                    <option value="D">Out</option>
                                                    <option value="C">In</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>

                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Account<span className="text-danger">*</span></Form.Label>
                                                <AsyncSelect
                                                    cacheOptions={false}
                                                    defaultOptions={false}
                                                    value={ledgerValue}
                                                    getOptionLabel={e => e.label + ' '}
                                                    getOptionValue={e => e.id}
                                                    loadOptions={(search) => Picklist.fetchLedgerAllowedPicklist(search, 1)} // location = 1 , 2
                                                    onInputChange={Picklist.handleInputChange}
                                                    onChange={handleLedgerValue}
                                                    isDisabled={scrMode != 'A' && true}
                                                >
                                                </AsyncSelect>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className='col-md-3 col-6'>
                                            <Form.Group>
                                                <Form.Label>Reference No.</Form.Label>
                                                <Form.Control
                                                    value={infoReferenceNum}
                                                    maxLength={Common.maximumLength}
                                                    onChange={(e) => Common.validateAlpNumValue(e.target.value, setInfoReferenceNum)}
                                                    placeholder="Reference No."
                                                    disabled={scrMode != 'A' && true} />
                                            </Form.Group>
                                        </Col>
                                    </Row>&nbsp;

                                    <Row>
                                        <Col>
                                            <Button variant='outline-primary' className='buttonStyle' size='sm'
                                                onClick={handleDetail}
                                            >Detail</Button>
                                        </Col>
                                    </Row>&nbsp;
                                </Tab>
                                <Tab eventKey="DETAIL" title="Detail">
                                    {
                                        (scrMode == 'A' || scrMode == 'E') &&
                                        <>
                                            <Row>&nbsp;</Row>

                                            <Row>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Branch<span className="text-danger">*</span></Form.Label>
                                                        <AsyncSelect
                                                            cacheOptions={false}
                                                            defaultOptions={false}
                                                            value={branchValue}
                                                            getOptionLabel={e => e.label + ' '}
                                                            getOptionValue={e => e.id}
                                                            loadOptions={Picklist.fetchLocationPicklist}
                                                            onInputChange={Picklist.handleInputChange}
                                                            onChange={handleChangeBranch}
                                                        >
                                                        </AsyncSelect>
                                                    </Form.Group>
                                                </Col>

                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Bank<span className="text-danger">*</span></Form.Label>
                                                        <AsyncSelect
                                                            cacheOptions={false}
                                                            defaultOptions={false}
                                                            value={bankValue}
                                                            getOptionLabel={e => e.label + ' '}
                                                            getOptionValue={e => e.id}
                                                            loadOptions={(search) => Picklist.fetchBankPicklist(search, 1)} // location = 1 , 2
                                                            onInputChange={Picklist.handleInputChange}
                                                            onChange={handleBankValue}
                                                        >
                                                        </AsyncSelect>
                                                    </Form.Group>
                                                </Col></Row>

                                            <Row>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Reference No.</Form.Label>
                                                        <Form.Control
                                                            value={detailReferenceNum}
                                                            maxLength={Common.maximumLength}
                                                            onChange={(e) => Common.validateAlpNumValue(e.target.value, setDetailReferenceNum)}
                                                            placeholder="Reference No."
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Amount <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control
                                                            value={amount}
                                                            maxLength={Common.maximumLength}
                                                            onChange={(e) => Common.validateDecValue(e.target.value, setAmount)}
                                                            placeholder="Amount"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                            <Row>&nbsp;</Row>

                                            <Row>
                                                <Col>
                                                    <Button variant='outline-primary' className='buttonStyle' size='sm'
                                                        onClick={handleDetailAdd}
                                                    >Add</Button>
                                                </Col>
                                            </Row>
                                            <Row>&nbsp;</Row>
                                        </>
                                    }

                                    {
                                        dataList.length > 0 &&
                                        <Row>
                                            <Col>
                                                <Table striped bordered hover>
                                                    <thead>
                                                        {/* {
                                                            totalDifference != '0' &&
                                                            <>
                                                                <tr>

                                                                    {
                                                                        totalDifference != 0 &&
                                                                        <td style={{ textAlign: 'right' }} colSpan={4}><b>Total Difference:</b> <span>{totalDifference}</span></td>
                                                                    }

                                                                </tr>
                                                            </>
                                                        } */}
                                                        {/* <tr>
                                                            <td
                                                                colSpan={2}
                                                            ></td>
                                                            <td style={{ textAlign: 'right' }} colSpan={1}><b>Total Credit:</b> <span>{totalCredit}</span></td>
                                                            <td style={{ textAlign: 'right' }} colSpan={1}><b>Total Debit:</b> <span>{totalDebit}</span></td>
                                                        </tr> */}
                                                        <tr>
                                                            {userRight.delete === "1" && userRight.edit === "1" ? <th></th> : null}
                                                            <th>Branch</th>
                                                            <th>Bank Group</th>
                                                            <th>Bank Ledger</th>
                                                            <th>Reference</th>
                                                            <th>Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {dataList.map((res) =>
                                                            <tr key={res.td_line_no}>
                                                                {userRight.delete === "1" ?
                                                                    <td className="textCenter " >
                                                                        {
                                                                            scrMode === 'Q' || res.delny === 'N' ? null :
                                                                                <>
                                                                                    <span className="handCursor colorThemeBlue"
                                                                                        onClick={() => handleDelete(res.uniquekey, res.td_location, res.td_type, res.td_srno, res.td_line_no)}>
                                                                                        <FontAwesomeIcon icon={faTrash} />
                                                                                    </span>{' '}
                                                                                </>
                                                                        }
                                                                        {
                                                                            scrMode === 'Q' ?
                                                                                <span className='pointer colorThemeBlue' onClick={() => handleInnerFaView(res.td_srno, res.td_type, res.td_location)}>
                                                                                    <FontAwesomeIcon icon={faEye} />
                                                                                </span>
                                                                                : null
                                                                        }
                                                                    </td>
                                                                    : null
                                                                }
                                                                <td>{res.td_location_name}</td>
                                                                <td>{res.td_group}</td>
                                                                <td>{res.td_ledger}</td>
                                                                <td>{res.td_ref_no}</td>
                                                                <td style={{ textAlign: 'right' }}>{res.td_amount}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    }
                                </Tab>
                            </Tabs>
                        </>
                }
            </Container>

            <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                {msgText}
            </Dialog>

            <FaView faViewObject={faViewObject} show={showFaView} onHide={() => setShowFaView(false)} />


            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
        </div>
    )
}

export default Fund_transfer
