import $ from "jquery";
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import * as Common from "../../Common";
import Dialog from '../../Dialog';
import LocationSelector from '../../LocationSelector';
import Login_header from '../../Login_header';
import Menu from '../../Menu';
import * as Picklist from '../../Picklist';


const Bill_wise_detail_payable = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const [userRight, setUserRight] = useState([]);

    const [counter, setCounter] = useState();
    const [transactionType, setTransactioType] = useState('');
    const [reportType, setReportType] = useState('1');

    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());

    const [showFrom, setShowFrom] = useState(true);

    const [accountType, setAccountType] = useState('1');
    const [showAccount, setShowAccount] = useState(false);

    const [ledgerValue, setLedgerValue] = useState(null);

    const [locationCode, setLocationCode] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);
    const [radioType, setRadioType] = useState('A');

    const [grpCode, setGrpCode] = useState('');
    const [accountCode, setAccountCode] = useState('');
    const [accountName, setAccountName] = useState('');

    var msg = [], i = 0;

    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState('');

    const [onceRun, setOnceRun] = useState(false);
    const [billType, setBillType] = useState("V");
    const [scr, setScr] = useState("");
    const [billReportList, setBillReportList] = useState([]);


    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.callApi(Common.apiRight, [sid, 'all', 'BILL_WISE_DETAIL_PAYABLE'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);

    const handleTypeChange = (e) => {
        let value = e.target.value;
        if (value == 2) {
            setShowFrom(false);
        } else {
            setShowFrom(true);
        }
        setReportType(value);
    }

    const handleAccountType = (e) => {
        let value = e.target.value;
        if (value == 2) {
            setShowAccount(true);
        } else {
            setShowAccount(false);
        }
        setAccountType(e.target.value);
    }

    const handleLedgerValue = (value) => {
        setLedgerValue(value)
        console.log(value);

        var ledger = value.id.split('^');
        setGrpCode(ledger[1]);
        setAccountCode(ledger[2]);
        setAccountName(ledger[5]);
    }

    const handleDataFromChild = (newLocationCode, newSelectedItems, newRadioType) => {
        setLocationCode(newLocationCode);
        setSelectedItems(newSelectedItems);
        setRadioType(newRadioType);
    }

    const handleTransactionType = (e) => {
        const value = e.target.value
        setTransactioType(value);
    }

    const handleGenerate = () => {
        $(".loader").show();
        if ((reportType == '1' && !fromDate) || !toDate || (accountType == '2' && ledgerValue == null) || (radioType == 'O' && locationCode == '0')
            || (radioType == 'S' && selectedItems == [])) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (reportType == 1 && !fromDate ? "Please select from date." : '');
            msg[i++] = (!toDate ? "Please select to date." : '');
            msg[i++] = ((accountType == '2' && ledgerValue == null) ? "Please select account ." : '');
            msg[i++] = ((radioType == 'O' && locationCode == '0') ? "Please select location." : '');
            msg[i++] = ((radioType == 'S' && selectedItems == []) ? "Please select atleast one location." : '');
            $(".loader").hide();
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);

        } else {
            const obj = {
                reportType: reportType,
                fromDate: Common.dateYMD(fromDate),
                toDate: Common.dateYMD(toDate),

                accountType: accountType,
                grpCode: grpCode,
                accountCode: accountCode,
                accountName: accountName,

                radioType: radioType,
                location: locationCode,
                selectedItems: selectedItems,
            }
            if (billType === 'D') {
                Common.callDownloadApiPost(Common.apiBillWiseDetailPayable, "post", [sid, 'getData', JSON.stringify(obj)]);
            } else {
                Common.callApi(Common.apiBillWiseDetailPayable, [sid, 'viewData', JSON.stringify(obj)], (result) => {
                    setScr('V');
                    const resData = JSON.parse(result);
                    setBillReportList(resData)
                    // console.log(scr);
                });
            }
            $(".loader").hide();
        }
    }

    return (
        <div>
            <Login_header />
            <Menu />

            <Container fluid>
                {scr === "" ?
                    <>
                        <Row>
                            <Col>
                                <h2>Bill Wise Detail <span style={{ color: "#f8f9fd" }}>{counter}</span> </h2>
                            </Col>
                        </Row>&nbsp;
                        {/* <Row>
                    <Col className='col-md-3 col-6'>
                        <Form.Label>Transaction Type</Form.Label><br />
                        <select class="form-select" aria-label="Default select example" value={transactionType}
                            onChange={(e) => handleTransactionType(e)}>
                            <option value="1">Payable</option>
                            <option value="2">Receiveable</option>
                        </select></Col>
                </Row> */}
                        <Row>
                            <Col className='col-md-3 col-6'>
                                <Form.Label>Report Type</Form.Label><br />
                                <select class="form-select" aria-label="Default select example" value={reportType}
                                    onChange={(e) => handleTypeChange(e)}>
                                    <option value="1">Periodic</option>
                                    <option value="2">Till Date</option>
                                </select>
                            </Col>&nbsp;
                            {
                                showFrom &&
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>From Date<span className="colorThemeRed">*</span></Form.Label><br />
                                        <DatePicker className="form-control"
                                            selected={fromDate}
                                            onChange={(date) => setFromDate(date)}
                                            dateFormat="dd/MM/yyyy"
                                            showYearDropdown
                                            showMonthDropdown
                                            useShortMonthInDropdown
                                            dropdownMode="select"
                                            peekNextMonth
                                            customInput={
                                                <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                            }
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>To Date<span className="colorThemeRed">*</span></Form.Label><br />
                                    <DatePicker className="form-control"
                                        selected={toDate}
                                        onChange={(date) => setToDate(date)}
                                        dateFormat="dd/MM/yyyy"
                                        showYearDropdown
                                        showMonthDropdown
                                        useShortMonthInDropdown
                                        dropdownMode="select"
                                        peekNextMonth
                                        customInput={
                                            <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                        }
                                    />
                                </Form.Group>
                            </Col>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Mode<span className="text-danger">*</span></Form.Label>
                                    <Form.Select value={billType} onChange={e => setBillType(e.target.value)}
                                        required>
                                        <option value="V">View</option>
                                        <option value="D">Download</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>&nbsp;
                        <Row>
                            <Col className='col-md-3 col-6'>
                                <Form.Label>Account Filter</Form.Label><br />
                                <select class="form-select" aria-label="Default select example" value={accountType}
                                    onChange={(e) => handleAccountType(e)}>
                                    <option value="1">All</option>
                                    <option value="2">One</option>
                                </select>
                            </Col>

                            {
                                showAccount &&
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Account<span className="colorThemeRed">*</span></Form.Label><br />
                                        <AsyncSelect
                                            cacheOptions
                                            defaultOptions
                                            value={ledgerValue}
                                            getOptionLabel={e => e.label + ' '}
                                            getOptionValue={e => e.id}
                                            loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 0, 1)
                                                // loadOptions={(search) => Picklist.fetchDebtorCreditorAllowedPicklist(search,1,transactionType )}
                                            }
                                            onInputChange={Picklist.handleInputChange}
                                            onChange={handleLedgerValue}
                                        >
                                        </AsyncSelect>
                                    </Form.Group>
                                </Col>
                            }
                        </Row>&nbsp;
                        <Row>
                            <Form.Label>Location Filter</Form.Label><br />
                            <Col className='col-md-6 col-12'>
                                <LocationSelector onDataFromChild={handleDataFromChild} />
                            </Col>
                        </Row>&nbsp;

                        <Row>
                            <Col className='col-md-3 col-6'>
                                <Button variant='outline-success' className='buttonStyle' size='sm' onClick={handleGenerate}
                                >Generate</Button>
                            </Col>
                        </Row>
                    </> : null}
                {scr === "V" ? <>
                    <Row>
                        <Col>
                            <Button onClick={() => setScr('')} variant="primary">Back</Button>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    &nbsp;
                    <Table responsive striped bordered>
                        <thead>
                            <tr>
                                <th>Branch</th>
                                <th>Date</th>
                                <th>Voucher</th>
                                <th>Traveller</th>
                                <th>Rec Amount</th>
                                <th>Adj Amount</th>
                                <th>Balance</th>
                                {/* <th>Lapse Days</th> */}
                                <th>Group Code</th>
                                <th>Account Code</th>
                                <th>Party</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                billReportList.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.tfd_location}</td>               {/* Branch */}
                                        <td>{item.formatted_date}</td>             {/* Date */}
                                        <td>{item.tfd_srno}</td>                   {/* Voucher */}
                                        <td>
                                            {item.bd_first_name} {item.bd_middle_name} {item.bd_last_name}
                                        </td>                                      {/* Traveller */}
                                        <td>{item.abs_tfd_amount}</td>             {/* Rec Amount */}
                                        <td>{item.abs_tfd_knockoff_amount}</td>    {/* Adj Amount */}
                                        <td>{1 * item.tfd_amount - item.tfd_knockoff_amount * 1}</td>                         {/* Balance */}
                                        {/* <td>{lapseDays} days</td>                  Lapse Days */}
                                        <td>{item.tfd_group}</td>                  {/* Group Code */}
                                        <td>{item.tfd_account}</td>                {/* Account Code */}
                                        <td>{item.entity_name}</td>                {/* Party */}
                                    </tr>
                                )
                                )
                            }
                        </tbody>
                    </Table>

                </> : null}
            </Container>

            <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                {msgText}
            </Dialog>

            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
        </div>
    )
}

export default Bill_wise_detail_payable
