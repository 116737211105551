import React, { useEffect, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import * as Picklist from "./Picklist";
import * as Common from "./Common";
import { useNavigate } from 'react-router-dom';


const DynamicDropdown = ({ type, onDataFromChild }) => {


    const sid = sessionStorage.getItem("sessionId");
    const navigate = useNavigate();
    const [userRight, setUserRight] = useState([]);

    const [selectedItems, setSelectedItems] = useState([]);
    const [radioType, setRadioType] = useState('A');
    const [location, setLocation] = useState(null);
    const [locationCode, setLocationCode] = useState(0);
    const [data, setData] = useState([]);

    const [valueStr, setValueStr] = useState('');

    // useEffect(() => {
    //     fetchData(type);
    //     onDataFromChild(type, selectedItems.toString(), radioType);
    // }, [selectedItems]);
    // // }, []);

    useEffect(() => {
        Common.callApi(Common.apiRight, [sid, 'all', 'REPORTS'], function (result) {
            let resp = JSON.parse(result);
            if (resp.query === "1") {
                setUserRight(resp);
            } else {
                navigate('/'); // no query right
            }
        });   
        if (type == 'location') {
            // Picklist.fetchCurrencyAllowedPicklist('', 'CN').then(res => console.log(res));
            Common.callApi(Common.apiCheck, [sid, 'location'], (result) => {
                let resp = JSON.parse(result);
                setData(resp);
            });
        } else if (type == 'currency') {
            Common.callApi(Common.apiCheck, [sid, 'currency'], (result) => {
                let resp = JSON.parse(result);
                setData(resp);
            });
        } else if (type === 'product') {
            setData([
                { label: 'CN', id: 'CN' },
                { label: 'VTM', id: 'VTM' },
                { label: 'EVTM', id: 'EVTM' }
            ]);  
        } else if (type === 'transaction') {
            setData([
                { label: 'Purchase From Public', id: 'PP' },
                { label: 'Purchase Bulk', id: 'PB' },
                { label: 'Sale Bulk', id: 'SB' },
                { label: 'Sale To Public', id: 'SP' }
            ]);
        }
        onDataFromChild(type,selectedItems.toString(), radioType);
    }, [selectedItems]);

    // const fetchData = (type) => {

    //     if (type == 'currency') {
    //         // Picklist.fetchCurrencyAllowedPicklist('', 'CN').then(res => console.log(res));
    //         Common.callApi(Common.apiCheck, [sid, 'location'], (result) => {
    //             let resp = JSON.parse(result);
    //             console.log(resp);
    //             // setData(resp);
    //         });
    //     } else if (type == 'location') {
    //         Picklist.fetchLocationAllowedPicklist('').then(res => console.log(res));
    // } else if (type === 'product') {
    //     setData([
    //         { label: 'Purchase From Public', id: 'PP' },
    //         { label: 'Purchase Bulk', id: 'PB' },
    //         { label: 'Sale Bulk', id: 'SB' },
    //         { label: 'Sale To Public', id: 'SP' }
    //     ]);
    // } else if (type === 'transaction') {
    //     setData([
    //         { label: 'CN', id: 'CN' },
    //         { label: 'VTM', id: 'VTM' },
    //         { label: 'EVTM', id: 'EVTM' }
    //     ]);
    // }

    // };

    const handleRadioChange = (value) => {
        setRadioType(value);
        setLocation(null);
        setLocationCode(0);
        setSelectedItems([]);
    };

    const getTypeLabel = (type) => {
        const typeLabels = {
            currency: 'Currency',
            location: 'Location',
            product: 'Product',
            transaction: 'Transaction'
        };
        return typeLabels[type] || '';
    };

    const checkBoxHandler = (e) => {
        let checked = e.target.checked;
        let a = e.target.value;

        setSelectedItems((prevSelectedItems) => {
            if (checked) {
                return [...prevSelectedItems, a];
            } else {
                return prevSelectedItems.filter((item) => item !== a);
            }
        });
    }
    // console.log(valueStr);
    return (
        <>
            <Row>
                <Col style={{ display: "flex" }}>
                    <Form.Check checked={radioType === "A"} onChange={() => handleRadioChange("A")} type='radio' label="All" />&nbsp;
                    <Form.Check checked={radioType === "S"} onChange={() => handleRadioChange("S")} type='radio' label="Selective" />
                </Col>
            </Row>
            <Row>
                <Col className='col-md-6 col-6'>
                    {radioType === 'A' ? null :
                        (
                            <div style={{ border: "1px solid black", minHeight: "260px", width: "224px" }}>
                                <div style={{ backgroundColor: "lightblue", color: "white", padding: "0 0 0 10px" }}>
                                    <h3>{getTypeLabel(type)}</h3>
                                </div>
                                <div style={{ height: "179px", overflow: "auto", padding: "5px 10px" }}>
                                    {data.map((item, index) => (
                                        <div key={index} className="mb-3">
                                            <Form.Check
                                                label={type == 'location' ? item.entity : type == 'currency' ? item.isd : item.label}
                                                type="checkbox"
                                                value={type == 'location' ? item.entity_id : type == 'currency' ? item.isd_code : item.id}
                                                onChange={(e) => checkBoxHandler(e)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                </Col>
            </Row>
        </>
    );
};

export default DynamicDropdown;
