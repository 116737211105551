import React, { useEffect, useState } from 'react';
import { Table, Modal, Button } from 'react-bootstrap';
import * as Common from './Common';

const ActivityLog = (props) => {
    const sid = sessionStorage.getItem('sessionId');
    const [activityData, setActivityData] = useState([]);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (props.show) {
            Common.callApi(Common.apiActivityLog, [sid, "getlog", JSON.stringify(props.logObject)], result => {
                setActivityData(JSON.parse(result));
            });
        }
    }, [props.show]);

    return (
        <div>
            <Modal {...props} animation={false} size='xl' centered>
                <Modal.Header closeButton>
                    <Modal.Title>Activity Log</Modal.Title>
                </Modal.Header>
                <Table responsive striped bordered>
                    <thead>
                        <tr>
                            <th>User ID</th>
                            <th>Remark</th>
                            <th>Timestamp</th>
                            <th>Activity</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            activityData.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.lg_usersrno}</td>
                                    <td>{item.lg_remark}</td>
                                    <td>{item.date_time}</td>
                                    <td>{item.lg_activity_type}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
                <Modal.Footer>
                    <Button variant="primary" onClick={props.onHide}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ActivityLog;
