import React, { useEffect, useState } from 'react'

import Login_header from '../Login_header';
import Menu from '../Menu';
import { Container, Form, Row, Col, Alert } from 'react-bootstrap';

import * as Common from '../Common';
import * as Picklist from '../Picklist';


import AsyncSelect from 'react-select/async';

function Sample_Form() {


    const [selectedValue, setSelectedValue] = useState(null);
    const handleChange = value => {
        setSelectedValue(value);
    }

    const [ledgerValue, setLedgerValue] = useState(null);
    const handleLedgerValue = (value) => {
        setLedgerValue(value);
    }

    const [individualValue, setIndividualValue] = useState(null);
    const handleIndividualValue = (value) => {
        setIndividualValue(value);
    }

    const [ldgValue, setLdgValue] = useState(null);
    const handleLdgValue = (value) => {
        setLdgValue(value);
    }

    const [pVtm, setPVtm] = useState(null);
    const handleVtmValue = (value) => {
        setPVtm(value);
    }

    const [pIsd, setPIsd] = useState(null);
    const handleIsdValue = (value) => {
        setPIsd(value);
    }

    const [pCountry, setPCountry] = useState(null);
    const handleCountryValue = (value) => {
        setPCountry(value);
    }

    const [pState, setPState] = useState(null);
    const handleStateValue = (value) => {
        setPState(value);
    }

    const [pCity, setPCity] = useState(null);
    const handleCityValue = (value) => {
        setPCity(value);
    }

    const [pDistrict, setPDistrict] = useState(null);
    const handleDistrictValue = (value) => {
        setPDistrict(value);
    }

    const [pGroup, setPGroup] = useState(null);
    const handleGroupValue = (value) => {
        setPGroup(value);
    }

    const [pManualNo, setPManualNo] = useState(null);
    const handleManualNoValue = (value) => {
        setPManualNo(value);
    }

    const [pLocation, setPLocation] = useState(null);
    const handleChangeLocation = (value) => {
        setPLocation(value);
    }

    return (
        <>
            <Login_header />
            <Menu />

            <Container fluid>
                <Row>
                    <Col>
                        <h1>Sample Form</h1>
                    </Col>
                </Row>
                <Row>
                    <Col><hr></hr></Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Allowed Location</Form.Label>
                            <AsyncSelect
                                cacheOptions
                                defaultOptions
                                value={selectedValue}
                                getOptionLabel={e => e.label + ' '}
                                getOptionValue={e => e.id}
                                loadOptions={Picklist.fetchLocationAllowedPicklist}
                                onInputChange={Picklist.handleInputChange}
                                onChange={handleChange}
                            >
                            </AsyncSelect>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Alert variant="success">
                            Selected Value : {JSON.stringify(selectedValue || {}, null, 2)}
                        </Alert>
                    </Col>
                </Row>
                <Row>
                    <Col><hr></hr></Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Ledger</Form.Label>
                            <AsyncSelect
                                cacheOptions
                                defaultOptions
                                value={ledgerValue}
                                getOptionLabel={e => e.label + ' '}
                                getOptionValue={e => e.id}
                                loadOptions={(search) => Picklist.fetchLedgerAllowedPicklist(search, 1)} // location = 1 , 2
                                onInputChange={Picklist.handleInputChange}
                                onChange={handleLedgerValue}
                            >
                            </AsyncSelect>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Alert variant="success">
                            Selected Value : {JSON.stringify(ledgerValue || {}, null, 2)}
                        </Alert>
                    </Col>
                </Row>

                <Row>
                    <Col><hr></hr></Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Individual</Form.Label>
                            <AsyncSelect
                                cacheOptions
                                defaultOptions
                                value={individualValue}
                                getOptionLabel={e => e.label + ' '}
                                getOptionValue={e => e.id}
                                loadOptions={Picklist.fetchIndividualPicklist} // location = 1 , 2
                                onInputChange={Picklist.handleInputChange}
                                onChange={handleIndividualValue}
                            >
                            </AsyncSelect>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Alert variant="success">
                            Selected Value : {JSON.stringify(individualValue || {}, null, 2)}
                        </Alert>
                    </Col>
                </Row>

                <Row>
                    <Col><hr></hr></Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Corporate</Form.Label>
                            <AsyncSelect
                                cacheOptions
                                defaultOptions
                                value={ldgValue}
                                getOptionLabel={e => e.label + ' '}
                                getOptionValue={e => e.id}
                                loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 'CORPORATE', 1)} // location = 1 , 2
                                onInputChange={Picklist.handleInputChange}
                                onChange={handleLdgValue}
                            >
                            </AsyncSelect>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Alert variant="success">
                            Selected Value : {JSON.stringify(ldgValue || {}, null, 2)}
                        </Alert>
                    </Col>
                </Row>

                <Row>
                    <Col><hr></hr></Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Vtm<span className="colorThemeRed">*</span></Form.Label>
                            <AsyncSelect
                                cacheOptions={false}
                                defaultOptions={false}
                                value={pVtm}
                                getOptionLabel={e => e.label + ' '}
                                getOptionValue={e => e.id}
                                loadOptions={(search) => Picklist.fetchVtmEncashmentPicklist(search, 'APPROVED')}
                                onInputChange={Picklist.handleInputChange}
                                onChange={handleVtmValue}
                            >
                            </AsyncSelect>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Alert variant="success">
                            Selected Value : {JSON.stringify(pVtm || {}, null, 2)}
                        </Alert>
                    </Col>
                </Row>

                <Row>
                    <Col><hr></hr></Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Isd<span className="colorThemeRed">*</span></Form.Label>
                            <AsyncSelect
                                cacheOptions={false}
                                defaultOptions={false}
                                value={pIsd}
                                getOptionLabel={e => e.label + ' '}
                                getOptionValue={e => e.id}
                                loadOptions={(search) => Picklist.fetchCurrencyAllowedPicklist(search, '')}
                                onInputChange={Picklist.handleInputChange}
                                onChange={handleIsdValue}
                            >
                            </AsyncSelect>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Alert variant="success">
                            Selected Value : {JSON.stringify(pIsd || {}, null, 2)}
                        </Alert>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Country<span className="colorThemeRed">*</span></Form.Label>
                            <AsyncSelect
                                cacheOptions={false}
                                defaultOptions={false}
                                value={pCountry}
                                getOptionLabel={e => e.label + ' '}
                                getOptionValue={e => e.id}
                                loadOptions={(search) => Picklist.fetchCountryPicklist(search, '')}
                                onInputChange={Picklist.handleInputChange}
                                onChange={handleCountryValue}
                            >
                            </AsyncSelect>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Alert variant='success'>
                            Selected Value : {JSON.stringify(pCountry || {}, null, 2)}
                        </Alert>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>State<span className="colorThemeRed">*</span></Form.Label>
                            <AsyncSelect
                                cacheOptions={false}
                                defaultOptions={false}
                                value={pState}
                                getOptionLabel={e => e.label + ' '}
                                getOptionValue={e => e.id}
                                loadOptions={(search) => Picklist.fetchStatePicklist(search, '')}
                                onInputChange={Picklist.handleInputChange}
                                onChange={handleStateValue}
                            >
                            </AsyncSelect>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Alert variant='success'>
                            Selected Value : {JSON.stringify(pState || {}, null, 2)}
                        </Alert>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>District<span className="colorThemeRed">*</span></Form.Label>
                            <AsyncSelect
                                cacheOptions={false}
                                defaultOptions={false}
                                value={pDistrict}
                                getOptionLabel={e => e.label + ' '}
                                getOptionValue={e => e.id}
                                loadOptions={(search) => Picklist.fetchDistrictPicklist(search, '')}
                                onInputChange={Picklist.handleInputChange}
                                onChange={handleDistrictValue}
                            >
                            </AsyncSelect>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Alert variant='success'>
                            Selected Value : {JSON.stringify(pDistrict || {}, null, 2)}
                        </Alert>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>City<span className="colorThemeRed">*</span></Form.Label>
                            <AsyncSelect
                                cacheOptions={false}
                                defaultOptions={false}
                                value={pCity}
                                getOptionLabel={e => e.label + ' '}
                                getOptionValue={e => e.id}
                                loadOptions={(search) => Picklist.fetchCityPicklist(search, '')}
                                onInputChange={Picklist.handleInputChange}
                                onChange={handleCityValue}
                            >
                            </AsyncSelect>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Alert variant='success'>
                            Selected Value : {JSON.stringify(pCity || {}, null, 2)}
                        </Alert>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Group<span className="colorThemeRed">*</span></Form.Label>
                            <AsyncSelect
                                cacheOptions={false}
                                defaultOptions={false}
                                value={pGroup}
                                getOptionLabel={e => e.label + ' '}
                                getOptionValue={e => e.id}
                                loadOptions={(search) => Picklist.fetchGroupPicklist(search, 'OTHER')}
                                onInputChange={Picklist.handleInputChange}
                                onChange={handleGroupValue}
                            >
                            </AsyncSelect>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Alert variant='success'>
                            Selected Value : {JSON.stringify(pGroup || {}, null, 2)}
                        </Alert>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Manual No.<span className='colorThemeRed'>*</span></Form.Label>
                            <AsyncSelect
                                cacheOptions={false}
                                defaultOptions={false}
                                value={pManualNo}
                                getOptionLabel={e => e.label + ' '}
                                getOptionValue={e => e.id}
                                loadOptions={(search) => Picklist.fetchManualAllPicklist(search, 1)}
                                onInputChange={Picklist.handleInputChange}
                                onChange={handleManualNoValue}
                            >
                            </AsyncSelect>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Alert variant='success'>
                            Selected Value : {JSON.stringify(pManualNo || {}, null, 2)}
                        </Alert>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Location<span className='colorThemeRed'>*</span></Form.Label>
                            <AsyncSelect
                                cacheOptions={false}
                                defaultOptions={false}
                                value={pLocation}
                                getOptionLabel={e => e.label + ' '}
                                getOptionValue={e => e.id}
                                loadOptions={(search) => Picklist.fetchLocationPicklist(search, 1)}
                                onInputChange={Picklist.handleInputChange}
                                onChange={handleChangeLocation}
                            >
                            </AsyncSelect>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Alert variant='success'>
                            Selected Value : {JSON.stringify(pLocation || {}, null, 2)}
                        </Alert>
                    </Col>
                </Row>

                <Row><Col>&nbsp;</Col></Row>
                <Row><Col>&nbsp;</Col></Row>
                <Row><Col>&nbsp;</Col></Row>
                <Row><Col>&nbsp;</Col></Row>
            </Container>
        </>
    )
}
export default Sample_Form;
