import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Table, Form, Button, Tabs, Tab } from 'react-bootstrap';
import * as Common from "../Common";
import Login_header from '../Login_header';
import Menu from '../Menu';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import * as Picklist from "../Picklist";
import AsyncSelect from 'react-select/async';
import Select from "react-select";
import Dialog from '../Dialog';
import DialogYesNo from '../Dialog_Yes_No';
import DatePicker from 'react-datepicker';
import $ from "jquery";
import Master_individual_profile from '../master/Master_individual_profile';
import FaView from '../FaView';
import Tran_data_unlock_request from '../Tran_data_unlock_request';

function Tran_sp() {
    const [radioType, setRadioType] = useState("N");
    const finStartDate = sessionStorage.getItem("startDate");
    const finEndDate = sessionStorage.getItem("endDate");
    const branchDisable = sessionStorage.getItem('branchDisable');

    const sid = sessionStorage.getItem("sessionId");
    const navigate = useNavigate();
    const [key, setKey] = useState("INFO");
    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");
    const [scrMode, setScrMode] = useState("");
    const [userRight, setUserRight] = useState([]);
    const [residentOptions, setResidentOptions] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);

    const [hdrSrno, setHdrSrno] = useState(0);
    const [spList, setSPList] = useState([]);

    const [spLocation, setSPLocation] = useState(null);
    const [spLocationValue, setSPLocationValue] = useState("");
    const [spFromDate, setSPFromDate] = useState(new Date());
    const [spToDate, setSPToDate] = useState(new Date());
    const [spIndividual, setSPIndividual] = useState(null);
    const [spSrnoFrom, setSPSrnoFrom] = useState("");
    const [spSrnoTo, setSPSrnoTo] = useState("");
    const [spNewDate, setSPNewDate] = useState(new Date());
    const [spStateName, setSPStateName] = useState("");
    const [spStateValue, setSPStateValue] = useState("");
    const [spStateType, setSPStateType] = useState("");
    const [spSaleType, setSPSaleType] = useState("N");
    const [spProductList, setSPProductList] = useState([]);

    const [nExecutive, setNExecutive] = useState(null);
    const [nTraveller, setNTraveller] = useState(null);
    const [nPurpose, setNPurpose] = useState(null);
    const [nPurposeId, setNPurposeId] = useState("");
    const [nResident, setNResident] = useState(1);
    const [nTravellerCode, setNTravellerCode] = useState("");
    const [nState, setNState] = useState({ value: 0, label: "Select" });
    const [nCorporate, setNCorporate] = useState(null);
    const [nCountry, setNCountry] = useState(null);
    const [nDepDate, setNDepDate] = useState(null);
    const [nTicketNo, setNTicketNo] = useState("");
    const [nFlightNo, setNFlightNo] = useState("");
    const [nDuration, setNDuration] = useState("");
    const [nVisaType, setNVisaType] = useState("");
    const [nTicketClass, setNTicketClass] = useState("");
    const [nPanForm60, setNPanForm60] = useState("");
    const [nMode, setNMode] = useState("B");

    const [pIssuerGroup, setPIssuerGroup] = useState("");
    const [pIssuerAccount, setPIssuerAccount] = useState("");
    const [pIssuer, setPIssuer] = useState(null);
    const [pIsd, setPIsd] = useState(null);
    const [pIsdId, setPIsdId] = useState(0);
    const [pDenomination, setPDenomination] = useState("");
    const [pQuantity, setPQuantity] = useState("");
    const [pRate, setPRate] = useState("");
    const [pProduct, setPProduct] = useState("CN");
    const [prPrefix, setPrPrefix] = useState("");
    const [prFrom, setPrFrom] = useState(null);
    const [prFromValue, setPrFromValue] = useState("");
    const [prTo, setPrTo] = useState("");
    const [pVtmCardNo, setPVtmCardNo] = useState("");
    const [isCommission, setIsCommission] = useState("0");
    const [pAgent, setPAgent] = useState(null);
    const [commType, setCommType] = useState("");
    const [commValue, setCommValue] = useState("");
    const [maxPRate, setMaxPRate] = useState(0);
    const [minPRate, setMinPRate] = useState(0);

    const [pTotalAmount, setPTotalAmount] = useState(0);
    const [pOtherCharges, setPOtherCharges] = useState(0);
    const [pCgst, setPCgst] = useState(0);
    const [pSgst, setPSgst] = useState(0);
    const [pIgst, setPIgst] = useState(0);
    const [pUgst, setPUgst] = useState(0);
    const [pForexTotal, setPForexTotal] = useState(0);
    const [pRoundOff, setPRoundOff] = useState(0);
    const [pTaxableVal, setPTaxableVal] = useState(0);
    const [pSaleReload, setPSaleReload] = useState("S");
    const [disableOtherCharge, setDisableOtherCharge] = useState(true);
    const [nServiceType, setNServiceType] = useState("");

    const [pdAccount, setPdAccount] = useState(null);
    const [pdPaymentType, setPdPaymentType] = useState("");
    const [pdAmount, setPdAmount] = useState("");
    const [pdAccountValue, setPdAccountValue] = useState("");
    const [pdGroupValue, setPdGroupValue] = useState(0);
    const [pdChequeNum, setPdChequeNum] = useState("");
    const [paymentData, setPaymentData] = useState([]);
    const [chequeBankName, setChequeBankName] = useState("");
    const [chequeDate, setChequeDate] = useState(null);
    const [ldgSrchType, setLdgSrchType] = useState("");
    const [paymentLineNo, setPaymentLineNo] = useState(0);

    const [counter, setCounter] = useState(0);
    const [productLineNo, setProductLineNo] = useState(1);

    const [showIndividualForm, setShowIndividualForm] = useState(false);
    const [nTravellerSrno, setNTravellerSrno] = useState("");
    const [scrIndividual, setScrIndividual] = useState("");

    const [commission, setCommission] = useState("");
    const [hdrDate, setHdrDate] = useState(null);
    const [hdrLocation, setHdrLocation] = useState("");

    const [faViewObject, setFaViewObject] = useState({ location: "", trannumber: "", type: "" });
    const [showFaView, setShowFaView] = useState(false);

    const [viewDate, setViewDate] = useState('');
    const currentDate = new Date();
    const [showEditRequest, setShowEditRequest] = useState(false);
    const [editLogObject, setEditLogObject] = useState({ trantype: "", trannumber: "", trancategory: "", tranDate: "" });
    const [usdRate, setUSDRate] = useState(0);

    useEffect(() => {
        Common.getActiveBranch().then(response => {
            setSPLocation(response);
            response != null && handleLocationChange(response);
        });
        Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_SP'], function (result) {
            let resp = JSON.parse(result);
            if (resp.query === "1") {
                setUserRight(resp);
            } else {
                navigate('/'); // no query right
            }
        });
        // Common.callApi(Common.apiIsd, [sid, "ddlistjsonselect", pProduct], result => {
        //     setIsdOptions(JSON.parse(result)); 
        // });
        Common.callApi(Common.apiResidentialStatus, [sid, "listResidential"], result => {
            setResidentOptions(JSON.parse(result));
        });

        const sessionValues = sessionStorage.getItem('values');
        if (sessionValues) {
            const sessionData = JSON.parse(sessionValues);
            if (sessionData.md_type == 'SALE') {
                setScrMode('A');
                Common.callApi(Common.apiIndividualProfile, [sid, 'getIndividualData', sessionData.code], (result) => {
                    let resp = JSON.parse(result);
                    // console.log(resp);
                    setNTraveller(resp);
                });
            } else {
                setScrMode('');
            }
        }
    }, []);

    const handleLocationChange = (value) => {
        setSPLocation(value);
        var id = value.id;
        var location = id.split("^");
        setSPLocationValue(location[1]);
        setSPStateValue(location[3]);
        setSPStateType(location[4]);
        setSPStateName(location[5]);

        setHdrLocation(location[2]);
        sessionStorage.activeBranch = location[1];
    }

    const handleIndiviualChange = (value) => {
        setSPIndividual(value);
    }

    const listSPdata = () => {
        var msg = [], i = 0;
        $(".loader").show();
        if (spLocation === null || (radioType === 'S' && !spSrnoFrom) || (radioType === 'S' && !spSrnoTo) || (radioType === 'D' && !spFromDate) || (radioType === 'D' && !spToDate) || (radioType === 'I' && spIndividual === null) || (radioType === 'I' && !spFromDate) || (radioType === 'I' && !spToDate) || (radioType === 'C' && nCorporate === null) || (radioType === 'C' && !spFromDate) || (radioType === 'C' && !spToDate)) {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (spLocation === null ? " Location." : '');
            msg[i++] = (radioType === 'S' && !spSrnoFrom ? " From Srno." : '');
            msg[i++] = (radioType === 'S' && !spSrnoTo ? " To Srno." : '');
            msg[i++] = (radioType === 'D' && !spFromDate ? " From Date." : '');
            msg[i++] = (radioType === 'D' && !spToDate ? " To Date." : '');
            msg[i++] = (radioType === 'I' && spIndividual === null ? " Individual." : '');
            msg[i++] = (radioType === 'I' && !spFromDate ? " From Date." : '');
            msg[i++] = (radioType === 'I' && !spToDate ? " To Date." : '');
            msg[i++] = (radioType === 'C' && nCorporate === null ? " Corporate." : '');
            msg[i++] = (radioType === 'C' && !spFromDate ? " From Date." : '');
            msg[i++] = (radioType === 'C' && !spToDate ? " To Date." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const obj = {
                type: "SP",
                radioType: radioType,
                spLocation: spLocationValue,
                //ppCorporate: ppCorporate,
                spIndividual: spIndividual,
                spNewDate: Common.dateYMD(spNewDate),
                spSrnoTo: spSrnoTo,
                spSrnoFrom: spSrnoFrom,
                spFromDate: Common.dateYMD(spFromDate),
                spToDate: Common.dateYMD(spToDate)
            }
            Common.callApi(Common.apiTranSP, [sid, "splist", JSON.stringify(obj)], result => {
                console.log(result);
                $(".loader").hide();
                setSPList(JSON.parse(result));
            });
        }
    }

    const addNewForm = () => {
        var d = Common.dateYMD(spNewDate);
        var ddmy = Common.dateDMY(spNewDate);
        var today = Common.dateYMD(new Date());
        if (spLocation == null) {
            setMsgBox(true);
            setMsgText("Please select location");
        } else if (d > finEndDate || d < finStartDate) {
            setMsgBox(true);
            setMsgText(Common.getMessage("MSG0010"));
        } else if (d > today) {
            setMsgBox(true);
            setMsgText(Common.getMessage("MSG0011"));
        } else {
            setHdrDate(ddmy);

            setScrMode('A');
            setKey('INFO');
            setProductLineNo(1);
            setHdrSrno(0);
            setNExecutive(null);
            setNMode("B");
            setNTraveller(null);
            setNPurpose(null);
            setNResident(1);
            setNTravellerCode("");
            setNCorporate(null);
            console.log(spStateValue, '----', spStateName);
            if (spStateValue * 1 > 0 && spStateValue != null) setNState({ value: spStateValue, label: spStateName });
            else setNState({ value: 0, label: "Select" });
            setNPanForm60("");
            setNCountry(null);
            setNTicketClass("");
            setNTicketNo("");
            setNFlightNo("");
            setNDepDate(null);
            setNDuration("");
            setNVisaType("");
            setPForexTotal(0);
            setPTotalAmount(0);
            setPCgst(0);
            setPIgst(0);
            setPUgst(0);
            setPSgst(0);
            setPRoundOff(0);
            setPOtherCharges(0);
            setSPProductList([]);
            setPaymentData([]);

            Common.callApi(Common.apiState, [sid, "getState", 1], result => {
                setStateOptions(JSON.parse(result));
            });
        }
    }

    const fillViewForm = (hdrsrno, location, type, date) => {
        setHdrSrno(hdrsrno);
        setKey('INFO');
        setPrFrom(null);
        setPrFromValue("");
        setViewDate(date);
        //setRandomString("0");
        $(".loader").show();
        const obj = {
            srno: hdrsrno,
            spLocationValue: location,
            type: type
        }
        Common.callApi(Common.apiTranSP, [sid, "viewdata", JSON.stringify(obj)], result => {
            let resp = JSON.parse(result);
            if (resp.msg === "MSG0000") {
                $(".loader").hide();
                setScrMode("Q");
                setSPSaleType(resp.saletype);
                setNTraveller({ id: resp.ind_id, label: resp.ind_label });
                setNTravellerSrno(resp.individual_srno);
                setNExecutive({ id: resp.executive, label: resp.executive_name });
                setNMode(resp.mode);
                setNPurpose({ id: resp.purpose, label: resp.purpose_name });
                setNServiceType(resp.service_type);
                setNCountry({ id: resp.cntsrno, label: resp.cntname });
                setNResident(resp.residential);
                if (resp.corp_id === "^0^0^") setNCorporate(null);
                else setNCorporate({ id: resp.corp_id, label: resp.corp_label });
                setNTravellerCode(resp.emp_code);
                setNState({ value: resp.state, label: resp.statename });
                setNPanForm60(resp.panform60);
                setNTicketClass(resp.ticketclass);
                setNFlightNo(resp.flightno);
                setNTicketNo(resp.ticketno);
                setNDepDate(new Date(resp.depdate));
                setNDuration(resp.duration)
                setNVisaType(resp.visatype);

                setPForexTotal(resp.forextotal);
                setPCgst(resp.cgst);
                setPSgst(resp.sgst);
                setPIgst(resp.igst);
                setPUgst(resp.ugst);
                setPRoundOff(resp.round_off);
                setPTotalAmount(resp.total_inr);
                setPOtherCharges(resp.other_charge);
                setPTaxableVal(resp.taxablevalue);

                setSPProductList(resp.productlist);
                setPaymentData(resp.paydetails);
                setPaymentLineNo(resp.paymaxlineno);
                setProductLineNo(resp.maxlineno);

                setHdrDate(resp.date);
                setHdrLocation(resp.locname);
            }
        });
    }

    const handleExecutiveChange = (value) => {
        setNExecutive(value);
    }

    const handleTravellerChange = (value) => {
        setNTraveller(value);
        var a = value.id.split("^");
        setNTravellerSrno(a[1]);
    }

    const handlePurposeChange = (value) => {
        setNPurpose(value);
        var p = value.id.split("^");
        setNPurposeId(p[2]);
    }

    const handleCorporateChange = (value) => {
        setNCorporate(value);
        var id = value.id;
        var corp = id.split("^");
        setNState({ value: corp[3], label: corp[4] });
    }

    const handleCountryChange = (value) => {
        setNCountry(value);
    }

    const handleTabChange = (k) => {
        setKey(k);
    }

    const cancelConfirm = () => {
        if (scrMode === "A" || scrMode === "E") {
            setMsgTextYN(Common.getMessage("WRN0000"));
            setMsgBoxYN(true);
        } else {
            cancelData();
        }
    }

    // const cancelData = () => {
    //     setMsgBoxYN(false);
    //     setScrMode("");
    // }

    const cancelData = () => {
        setMsgBoxYN(false);
        const sessionData = JSON.parse(sessionStorage.getItem('values'));
        if (sessionData && sessionData.md_type === "SALE") {
            navigate('/individual-profile');
            sessionStorage.removeItem('values');
            sessionStorage.setItem('flag', '1');
        } else {
            setScrMode("");
        }
    }

    const handleProductChange = (v) => {
        setPProduct(v);
        setPIsd(null);
        setSPSaleType("S");
        setPIssuer(null);
        setPDenomination("");
        if (v === "VTM") setPQuantity(1);
        else setPQuantity("");
        setPRate("");
        setPrFrom(null);
        setPrFromValue("");
        setPrPrefix("");
        setPrTo("");
        setPVtmCardNo("");
    }

    const handleDenoChange = (v) => {
        Common.validateDecValue(v, setPDenomination);
        setCommValue(calcCommission(commission, commType, pQuantity, v, pRate));
    }

    const handleQuantityChange = (v) => {
        Common.validateNumValue(v, setPQuantity);
        if (pProduct === "")
            setPrTo((v * 1 + prFrom * 1) - 1);
        setCommValue(calcCommission(commission, commType, v, pDenomination, pRate));
    }

    const handleRateChange = (v) => {
        Common.validateDecValue(v, setPRate);
        setCommValue(calcCommission(commission, commType, pQuantity, pDenomination, v));
    }

    const handleFromChange = (value) => {
        setPrFrom(value);
        var fr = value.id.split("^");
        var v = fr[1];
        setPrFromValue(v);
        setPrTo((v * 1 + pQuantity * 1) - 1);
        if (pSaleReload === "R") setPVtmCardNo(fr[7]);
    }

    const handleChangeIsd = (value) => {
        setPIsd(value);
        let a = value.id.split("^");
        setPIsdId(a[1]);
        setPAgent(null);
        setCommType("");
        setCommValue("");
        setPrFrom(null);
        setPrFromValue("");
        const obj = {
            isd: a[1],
            location: spLocationValue,
            product: pProduct
        }
        Common.callApi(Common.apiMasterIBR, [sid, "getrate", JSON.stringify(obj)], result => {
            let resp = JSON.parse(result);
            setPRate(resp.salerate);
            setMaxPRate(resp.maxsalerate * 1);
            setMinPRate(resp.minsalerate * 1);
            setUSDRate(resp.usdrate * 1);
        });
    }

    const saveData = async () => {
        $(".loader").show();

        var totalinr = 0;
        var totalCash = 0;
        var today = Common.dateYMD(new Date());
        var depdatedym = 0;
        if (nDepDate !== null) {
            depdatedym = Common.dateYMD(nDepDate);
        }
        for (var k = 0; k < paymentData.length; k++) {
            if (paymentData[k].tp_payment_type === "CH")
                totalCash += paymentData[k].tp_amount * 1;
            if (paymentData[k].tp_payment_type === "PB") //RC
                totalinr -= paymentData[k].tp_amount * 1;
            else totalinr += paymentData[k].tp_amount * 1;
        }

        // console.log(pTotalAmount,"pTotalAmount");
        // console.log(totalinr,"totalinr");

        var msg = [], i = 0;
        if (nTraveller === null || nPurpose === null || nCountry === null || nTicketNo === "" || nFlightNo === "" || nDepDate == null || nDuration === "" || depdatedym < today) {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (nTraveller === null ? "Traveller" : '');
            msg[i++] = (nPurpose === null ? "Purpose" : '');
            msg[i++] = (nCountry === null ? "Country" : '');
            msg[i++] = (nFlightNo === "" ? "Flight Number" : '');
            msg[i++] = (nTicketNo === "" ? "Ticket Number" : '');
            msg[i++] = (nDepDate === null ? "Departure Date" : '');
            msg[i++] = (nDuration === "" ? "Duration of stay abroad" : '');
            msg[i++] = (depdatedym < today ? "Departure date can't be before today." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (pTotalAmount != totalinr) {
            $(".loader").hide();
            setMsgText("Total amount of payment must be equal to calculated INR.");
            setMsgBox(true);
        } else {
            $(".loader").hide();
            const obj = {
                srno: hdrSrno,
                spLocationValue: spLocationValue,
                spSaleType: spSaleType,
                spNewDate: Common.dateYMD(spNewDate),
                nCorporate: nCorporate,
                nCountry: nCountry,
                nState: nState.value,
                nResident: nResident,
                nTraveller: nTraveller,
                nPurpose: nPurpose,
                nExecutive: nExecutive,
                pForexTotal: pForexTotal,
                nFlightNo: nFlightNo,
                nTicketNo: nTicketNo,
                nVisaType: nVisaType,
                nDepDate: Common.dateYMD(nDepDate),
                nTicketClass: nTicketClass,
                nDuration: nDuration,
                nPanForm60: nPanForm60,
                nMode: nMode,
                pSgst: pSgst,
                pIgst: pIgst,
                pUgst: pUgst,
                pCgst: pCgst,
                pOtherCharges: pOtherCharges,
                pRoundOff: pRoundOff,
                pTaxableVal: pTaxableVal,
                pTotalAmount: pTotalAmount,
                nTravellerCode: nTravellerCode,
                //randomString: randomString,
                spProductList: spProductList,
                paymentData: paymentData,
                prd_usd_rate: usdRate,

                radioType: radioType,
                spLocation: spLocationValue,
                //ppCorporate: ppCorporate,
                spIndividual: spIndividual,
                spSrnoTo: spSrnoTo,
                spSrnoFrom: spSrnoFrom,
                spFromDate: Common.dateYMD(spFromDate),
                spToDate: Common.dateYMD(spToDate)
            }

            let isOk = true;

            await getAllChecks(obj).then(response => {
                let amount = (pTotalAmount + response.totalamount * 1) / usdRate;

                let rate = response.rate;

                let cashAmt = response.cashAmt * 1 + totalCash * 1;

                // console.log(totalCash, 'in array');
                // console.log(amount, 'total amount');
                // console.log(response.cashAmt, 'from backend');
                // console.log(pTotalAmount, ' added');
                // console.log(cashAmt, ' total cash ');
                console.log(i, 'before if');
                if ((rate === '0') || (cashAmt > 50000) || (amount > rate)) {
                    isOk = false;
                    msg[i++] = ((rate === '0') ? 'Currency exhange not allowed in this country.' : '');
                    msg[i++] = ((cashAmt > 50000) ? 'Cash amount should be less than 50,000' : '');
                    msg[i++] = ((amount > rate) ? 'Total Amount should be less than USD ' + rate : '');
                    console.log(i, 'inside if');

                    console.log(msg);
                    setMsgText(Common.buildMessageFromArray(msg));
                    setMsgBox(true);
                }
                console.log(i, 'after if');

            });


            if (isOk) {
                Common.callApi(Common.apiTranSP, [sid, "savedata", JSON.stringify(obj)], result => {
                    let resp = JSON.parse(result);
                    if (resp.msg === "MSG0000") {
                        setSPList(resp.spList);
                        $(".loader").hide();
                        setScrMode("");
                        // listSPdata();
                        setMsgBox(true);
                        setMsgText(Common.getMessage(resp.msg));
                    } else {
                        $(".loader").hide();
                        setMsgBox(true);
                        setMsgText(Common.getMessage("ERR0000"));
                    }
                })

                if (scrMode == 'E') {
                    const obj = {
                        srno: hdrSrno,
                        type: 'SP',
                        status: 'E',
                        currentDate: Common.dateYMD(currentDate),
                        pageright: 'TRAN_SP'
                    }
                    Common.callApi(Common.apiUnlockEdit, [sid, 'expireUnlock', JSON.stringify(obj)], (result) => {
                        console.log(result);
                    })

                }
            }

            // Common.callApi(Common.apiTranSP, [sid, "gettotalamount", JSON.stringify(obj)], result => {
            //     let response = JSON.parse(result);
            //     let amount = (pTotalAmount + response.totalamount * 1) / usdRate;

            //     let rate = response.rate;

            //     // let cashAmt = response.cashAmt * 1 + pTotalAmount * 1;

            //     let cashAmt = response.cashAmt * 1 + totalCash * 1;                

            //     console.log(totalCash, 'in array');

            //     console.log(response.cashAmt, 'from backend');
            //     console.log(pTotalAmount, ' added');
            //     console.log(cashAmt, ' total cash ');

            //     if (rate === '0') {
            //         setMsgText("Currency exhange not allowed in this country.");
            //         setMsgBox(true);
            //     } else if (cashAmt > 50000) {
            //         setMsgText("Cash amount should be less than 50,000");
            //         setMsgBox(true);
            //     } else {
            //         if (amount > rate) {
            //             setMsgBox(true);
            //             setMsgText('Total Amount should be less than USD ' + rate)
            //         } 
            //         else {

            //             alert('here');
            //             // Common.callApi(Common.apiTranSP, [sid, "savedata", JSON.stringify(obj)], result => {
            //             //     let resp = JSON.parse(result);
            //             //     if (resp.msg === "MSG0000") {
            //             //         $(".loader").hide();
            //             //         setScrMode("");
            //             //         listSPdata();
            //             //         setMsgBox(true);
            //             //         setMsgText(Common.getMessage(resp.msg));
            //             //     } else {
            //             //         $(".loader").hide();
            //             //         setMsgBox(true);
            //             //         setMsgText(Common.getMessage("ERR0000"));
            //             //     }
            //             // })

            //             // if (scrMode == 'E') {
            //             //     const obj = {
            //             //         srno: hdrSrno,
            //             //         type: 'SP',
            //             //         status: 'E',
            //             //         currentDate: Common.dateYMD(currentDate),
            //             //         pageright: 'TRAN_SP'
            //             //     }
            //             //     Common.callApi(Common.apiUnlockEdit, [sid, 'expireUnlock', JSON.stringify(obj)], (result) => {
            //             //         console.log(result);
            //             //     })

            //             // }
            //         }
            //     }
            // });
        }
    }


    function getAllChecks(obj) {
        return new Promise((resolve, reject) => {
            Common.callApi(Common.apiTranSP, [sid, "gettotalamount", JSON.stringify(obj)], result => {
                let response = JSON.parse(result);
                resolve(response);
            });
        });
    }





    const btnEditRequest = () => {
        const currDate = new Date();
        const newDate = viewDate.split('/');

        const newViewDate = newDate[2] + '-' + newDate[1] + '-' + newDate[0]
        const newCurrDate = Common.dateYMD(currDate);

        const dateA = new Date(newViewDate);
        const dateB = new Date(newCurrDate);

        // Calculate the difference in milliseconds
        const differenceInMilliseconds = dateB - dateA;

        // Convert milliseconds difference to days
        const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 3600 * 24));

        console.log(differenceInDays);

        const obj = {
            srno: hdrSrno,
            type: 'SP',
            pageright: 'TRAN_SP'
        }
        // const message = 'unlock'
        Common.callApi(Common.apiUnlockEdit, [sid, 'checkUnlock', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);

            if (Common.editDateLimit == differenceInDays || resp == "unlock") {
                setScrMode('E');

            } else {
                setShowEditRequest(true);
                setEditLogObject({ trantype: 'SP', trannumber: hdrSrno, location: spLocationValue, tranDate: Common.dateYMD(currDate) });
            }
        })


    }

    // const editData = () => {
    //     setScrMode("E");
    // }

    const otherChargeChange = (v) => {
        Common.validateDecValue(v, setPOtherCharges);
        const obj = {
            type: "SALE",
            pOtherCharges: v === "" ? 0 : v,
            ppStateType: spStateType,
            ppStateValue: spStateValue,
            nState: nState.value
        }
        calculateGST(spProductList, obj);
    }

    const addProduct = () => {
        $(".loader").show();
        var msg = [], i = 0;
        if ((pIsd == null || pDenomination === "" || pQuantity === "" || pRate === "")
            ||
            (pProduct === "VTM" && (prFrom == null || pVtmCardNo === "" || pIssuer == null))
            ||
            (isCommission === "1" && (commType === "" || pAgent === null || commValue === ""))
        ) {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (pIsd == null ? "Please select Isd." : '');
            msg[i++] = (pDenomination === '' ? "Please fill Denomination." : '');
            msg[i++] = (pQuantity == '' ? "Please fill Quantity." : '');
            msg[i++] = (pRate == '' ? "Please fill Rate." : '');
            if (pProduct === "VTM" && (prFrom == null || prTo == "" || pIssuer == null)) {
                msg[i++] = (pIssuer == null ? "Issuer." : '');
                msg[i++] = (prFrom == null ? "From." : '');
                msg[i++] = (pVtmCardNo == '' ? "Card Number." : '');
            }
            if (isCommission === "1" && (commType === "" || pAgent === null || commValue === "")) {
                msg[i++] = (pAgent == null ? "Agent." : '');
                msg[i++] = (commType == '' ? "Commission type." : '');
                msg[i++] = (commValue == '' ? "Commission value." : '');
            }
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (pProduct === "VTM" && (prTo * 1 - prFrom * 1 > 100)) {
            $(".loader").hide();
            setMsgText("'To' must be less than 100.");
            setMsgBox(true);
        } else if (maxPRate != 0 && minPRate != 0 && (pRate > maxPRate || pRate < minPRate)) {
            $(".loader").hide();
            msg[i++] = (pRate > maxPRate ? "Maximum rate is " + maxPRate : '');
            msg[i++] = (pRate < minPRate ? "Minimum rate is " + minPRate : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            $(".loader").hide();
            var isdarr = pIsd.id.split("^");
            var agentgrp = 0;
            var agentaccnt = 0;
            var issgrp = 0;
            var issaccnt = 0;
            if (pAgent !== null) {
                var agent = pAgent.id.split("^");
                agentgrp = agent[1];
                agentaccnt = agent[2];
            }
            if (pIssuer !== null) {
                var issuer = pIssuer.id.split("^");
                issgrp = issuer[1];
                issaccnt = issuer[2];
            }
            const obj = {
                type: "SALE",
                uniqueKey: Common.getRandomString(15),
                prd_line_no: productLineNo,
                prd_location: spLocationValue,
                prd_type: 'SP',
                prd_srno: hdrSrno,
                prd_isd: isdarr[1],
                prd_date: Common.dateYMD(spNewDate),
                prd_product: pProduct,
                prd_deno: pDenomination,
                prd_qty: pQuantity,
                prd_rate: pRate,
                prd_amount: pRate * pQuantity * pDenomination,
                prd_from: prFromValue,
                prd_prefix: prPrefix,
                prd_to: prTo,
                prd_group: issgrp,
                prd_account: issaccnt,
                prd_agent_group: agentgrp,
                prd_agent_ledger: agentaccnt,
                prd_card_no: pVtmCardNo,
                isd_name: isdarr[2],
                prd_commission_type: commType,
                prd_commission_value: commValue == "" ? 0 : commValue,
                prd_commission: 0,
                prd_sale_reload: pSaleReload,
                nCorporate: nCorporate,
                nState: nState.value,
                pOtherCharges: pOtherCharges,
                ppStateType: spStateType,
                ppStateValue: spStateValue,
                scrMode: scrMode,
                prFrom: prFrom,
                delny: "Y"
            }
            var productlist = Common.arrayAddItem(spProductList, obj);
            if (productlist.length > 0) {
                setDisableOtherCharge(false);
            }
            setSPProductList(productlist);
            setCounter(counter + 1);
            setProductLineNo(1 * productLineNo + 1);
            setPIsd(null);
            setPIssuer(null);
            setPDenomination("");
            setPQuantity("");
            setPRate("");
            setPrPrefix("");
            setPrFrom(null);
            setPrFromValue("");
            setPrTo("");
            setPVtmCardNo("");
            setCommValue("");
            //setCommission("");
            setCommType();
            setPAgent(null);
            setCommType("");
            calculateGST(productlist, obj);
        }
    }

    const calculateGST = (productlist, obj) => {
        Common.callApi(Common.apiCalcTaxable, [sid, "calcinvoice", JSON.stringify(productlist), JSON.stringify(obj)], result => {
            let resp = JSON.parse(result);
            setPForexTotal(resp.fxamt);
            setPSgst(resp.sgst);
            setPTotalAmount(resp.totalamt);
            setPTaxableVal(resp.taxablevalue);
            setPRoundOff(resp.roundoff);
            setPCgst(resp.cgst);
            setPIgst(resp.igst);
            setPUgst(resp.ugst);
            var amt = 0;
            for (var i = 0; i < paymentData.length; i++) {
                amt += paymentData[i].tp_amount * 1;
            }
            setPdAmount(1 * resp.totalamt - amt);
        });
    }

    const deleteProduct = (key) => {
        const obj = {
            type: "SALE",
            pOtherCharges: pOtherCharges === "" ? 0 : pOtherCharges,
            ppStateType: spStateType,
            ppStateValue: spStateValue,
            nState: nState.value
        }
        const productlist = Common.arrayRemoveItem(spProductList, "uniqueKey", key);
        setSPProductList(productlist);
        setCounter(counter - 1);
        calculateGST(productlist, obj);
    }

    const print = (printflag) => {
        const obj = {
            srno: hdrSrno,
            location: spLocationValue,
            type: 'SP',
            printFlag: printflag,
        }
        Common.callApi(Common.apiPrint, [sid, "print", JSON.stringify(obj)], result => {
            var newWindow = window.open();
            newWindow.document.write(result);
        });
    }

    const newIndividual = (btnype) => {
        setShowIndividualForm(true);
        if (btnype === "E" && nTraveller != null) setScrIndividual("E");
        else setScrIndividual("A");
    }

    const hideIndividualProfile = (val, individualsrno) => {
        setShowIndividualForm(val);
        if (individualsrno > 0) {
            const obj = {
                individualsrno: individualsrno,
                type: "SP"
            }
            Common.callApi(Common.apiIndividualProfile, [sid, "individualidfrompicklist", JSON.stringify(obj)], result => {
                let resp = JSON.parse(result);
                const desc = resp.desc.split("^");
                if (resp.id === "") setNTraveller(null);
                else setNTraveller({ ...resp, desc: `${desc.slice().join("  ^  ")}`});
            });
        }
    }

    const handleAccountValue = (value) => {
        setPdAccount(value);
        var id = value.id.split("^");
        setPdGroupValue(id[1]);
        setPdAccountValue(id[2]);
    }

    const handlePaymentType = (v) => {
        setPdPaymentType(v);
        setPdAccount(null);
        setPdAccountValue(0);
        setPdChequeNum("");
        if (v === "CH") {
            setLdgSrchType("CASH");
            setPdAccount({ id: "^4504^1001^7^CHHATTISGARH^CASHQ^", label: "4504 1001 CASH Q" });
        } else if (v === "BN") {
            setLdgSrchType("BANK");
        } else if (v === "CQ") {
            setLdgSrchType("CHEQUE");
            setPdAccount({ id: "^4506^1001^7^CHHATTISGARH^CHEQUES IN HAND^", label: "4506 1001 CHEQUES IN HAND" });
        } else {
            setLdgSrchType("WALKIN");
            setPdAccount({ id: "^4516^1001^7^^WALK IN CLIENT^", label: "4516 1001 Walk In Client" });
        }
    }

    const addPayment = () => {
        var msg = [], i = 0;
        if (pTotalAmount < 1) {
            msg[i++] = (pTotalAmount < 1 ? "Total amount must be greater than 0." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (pdPaymentType === "" || pdAccount === null || pdAmount < 1
            || (pdPaymentType === 'CQ' && (pdChequeNum === "" || chequeBankName === "" || chequeDate === null))
            || (pdPaymentType === "BN" && pdChequeNum === "")
        ) {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (pdAccount === null ? "Account." : '');
            msg[i++] = (pdPaymentType === "" ? "Payment Type." : '');
            msg[i++] = (pdAmount < 1 ? "Entered amount can't be 0." : '');
            if (pdPaymentType === 'CQ' && (pdChequeNum === "" || chequeBankName === "" || chequeDate === null)) {
                msg[i++] = (pdChequeNum === "" ? "Cheque Number." : '');
                msg[i++] = (chequeBankName === "" ? "Bank Name." : '');
                msg[i++] = (chequeDate === null ? "Cheque Date" : '');
            }
            if (pdPaymentType === "BN" && pdChequeNum === "") msg[i++] = (pdChequeNum === "" ? "Refernece Number." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            var grp = 0;
            var accnt = 0;
            var accntname = '';
            if (pdAccount !== null) {
                var a = pdAccount.id.split("^");
                grp = a[1];
                accnt = a[2];
                accntname = a[5];
            }
            const obj = {
                uniquekey: Common.getRandomString(10),
                tp_line_no: paymentLineNo + 1,
                tp_location: spLocationValue,
                tp_srno: hdrSrno,
                //randomString: randomString,
                tp_payment_type: pdPaymentType,
                tp_account: accnt,
                tp_group: grp,
                mvli_ledger_name: accntname,
                tp_amount: pdAmount,
                tp_cheque: pdChequeNum,
                tp_cheque_bank: chequeBankName,
                tp_cheque_date: pdPaymentType === "CQ" ? Common.dateYMD(chequeDate) : "NULL",
                tp_type: "SP",
                delny: 'Y'
            }
            setPaymentData(Common.arrayAddItem(paymentData, obj));
            setPdPaymentType("");
            setPaymentLineNo((1 * paymentLineNo) + 1);
            setPdAmount("");
            setPdAccount(null);
            setPdChequeNum("");
            setChequeBankName("");
            setChequeDate(null);
            setCounter(counter + 1);
        }
    }

    const deletePayment = (value, delny) => {
        setPaymentData(Common.arrayRemoveItem(paymentData, "uniquekey", value));
        setCounter(counter - 1);
    }

    const handleAgentChange = (value) => {
        setPAgent(value);
        Common.callApi(Common.apiLedger, [sid, "getisdcommission", value.id, pIsdId, pProduct], result => {
            console.log(result);
            let resp = JSON.parse(result);
            setCommType(resp.type);
            setCommission(resp.value);
            var commvalue;
            if (resp.type === "P" || resp.type === "R") {
                commvalue = calcCommission(resp.value, resp.type, pQuantity, pDenomination, pRate);
            } else {
                commvalue = resp.value;
            }
            setCommValue(commvalue);
        });
    }

    const calcCommission = (value, type, quantity, deno, rate) => {
        var comm = '';
        if (type === "P") {
            comm = (value * quantity * deno) / 100;
        } else if (type === "R") {
            comm = (quantity * deno * rate) * (value / 100);
        } else {
            comm = value;
        }
        return comm;
    }

    const commissionTypeChange = (v) => {
        setCommType(v);
        setCommission("");
        setCommValue("");
    }

    const handleCommissionCalc = (v) => {
        Common.validateDecValue(v, setCommission);
        setCommValue(calcCommission(v * 1, commType, pQuantity, pDenomination, pRate));
    }

    const handleFaView = () => {
        setShowFaView(true);
        setFaViewObject({ location: spLocationValue, trannumber: hdrSrno, type: 'SP' });
    }

    const handleIssuerChange = (value) => {
        setPIssuer(value);
        var iss = value.id.split("^");
        setPIssuerGroup(iss[1]);
        setPIssuerAccount(iss[2]);
        setPrFrom(null);
        setPrFromValue("");
    }

    const handleSaleTypeChange = (v) => {
        setPSaleReload(v);
        setPrFrom(null);
        setPrFromValue("");
        setPrTo("");
        setPVtmCardNo("");
        setPDenomination("");
        setPRate("");
    }


    return (
        <>
            {
                showIndividualForm ?
                    <Master_individual_profile reqObj={{ nIndivualSrno: nTravellerSrno, scrIndividual: scrIndividual }} value="TRAN_SP" hideIndividualProfile={hideIndividualProfile} />
                    :
                    <>
                        <Login_header />
                        <Menu />
                        <Container fluid>
                            <Row>
                                <Col><h1>Sale to public</h1></Col>
                                <hr />
                            </Row>
                            {
                                scrMode === "" ?
                                    <>
                                        <Row>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Location<span className="colorThemeRed">*</span></Form.Label>
                                                    <AsyncSelect
                                                        cacheOptions={false}
                                                        defaultOptions={false}
                                                        value={spLocation}
                                                        getOptionLabel={e => e.label + ' '}
                                                        getOptionValue={e => e.id}
                                                        loadOptions={Picklist.fetchLocationAllowedPicklist}
                                                        onInputChange={Picklist.handleInputChange}
                                                        onChange={handleLocationChange}
                                                        isDisabled={branchDisable === "true"}
                                                    >
                                                    </AsyncSelect>
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-4 col-6'>
                                                <Form.Group>
                                                    <Row style={{ marginTop: "30px" }}>
                                                        <Col style={{ display: "flex" }}>
                                                            <Form.Check checked={radioType === "N"} onChange={() => setRadioType("N")} type='radio' label="New" />
                                                            <Form.Check checked={radioType === "S"} onChange={() => setRadioType("S")} type='radio' label="SrNo" />
                                                            <Form.Check checked={radioType === "D"} onChange={() => setRadioType("D")} type='radio' label="Date" />
                                                            <Form.Check checked={radioType === "I"} onChange={() => setRadioType("I")} type='radio' label="Individual" />
                                                            <Form.Check checked={radioType === "C"} onChange={() => setRadioType("C")} type='radio' label="Corporate" />
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            {
                                                radioType === "N" ?
                                                    <>
                                                        <Col className='col-md-3 col-6'>
                                                            <Form.Group>
                                                                <Form.Label>Date</Form.Label>
                                                                <DatePicker className="form-control"
                                                                    selected={spNewDate}
                                                                    onChange={(date) => setSPNewDate(date)}
                                                                    dateFormat="dd/MM/yyyy"
                                                                    showYearDropdown
                                                                    showMonthDropdown
                                                                    useShortMonthInDropdown
                                                                    dropdownMode="select"
                                                                    peekNextMonth
                                                                    customInput={
                                                                        <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col className='col-md-3 col-6'>
                                                            <Form.Group>
                                                                <Form.Label>Type</Form.Label>
                                                                <Form.Select value={spSaleType} onChange={e => setSPSaleType(e.target.value)}>
                                                                    <option value="N">Normal</option>
                                                                    <option value="E">EEFBC</option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Col>
                                                    </>
                                                    : radioType === "S" ?
                                                        <>
                                                            <Col className='col-md-3 col-6'>
                                                                <Form.Group>
                                                                    <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                                                                    <Form.Control type='text' maxLength={5} value={spSrnoFrom} onChange={e => Common.validateNumValue(e.target.value, setSPSrnoFrom)} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col className='col-md-3 col-6'>
                                                                <Form.Group>
                                                                    <Form.Label>To<span className="colorThemeRed">*</span></Form.Label>
                                                                    <Form.Control type='text' maxLength={5} value={spSrnoTo} onChange={e => Common.validateNumValue(e.target.value, setSPSrnoTo)} />
                                                                </Form.Group>
                                                            </Col>
                                                        </>
                                                        : radioType === "D" || radioType === "I" || radioType === "C" ?
                                                            <>
                                                                {
                                                                    radioType === "I" ?
                                                                        <Col className="col-md-6 col-12">
                                                                            <Form.Group>
                                                                                <Form.Label>Individual Profile<span className="colorThemeRed">*</span></Form.Label>
                                                                                <AsyncSelect
                                                                                    cacheOptions={false}
                                                                                    defaultOptions={false}
                                                                                    value={spIndividual}
                                                                                    getOptionLabel={e => e.label + ' '}
                                                                                    getOptionValue={e => e.id}
                                                                                    loadOptions={Picklist.fetchIndividualPicklist}
                                                                                    onInputChange={Picklist.handleInputChange}
                                                                                    onChange={handleIndiviualChange}
                                                                                >
                                                                                </AsyncSelect>

                                                                            </Form.Group>
                                                                        </Col>
                                                                        : null
                                                                }
                                                                <Col className='col-md-3 col-6'>
                                                                    <Form.Group>
                                                                        <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                                                                        <DatePicker className="form-control"
                                                                            selected={spFromDate}
                                                                            onChange={(date) => setSPFromDate(date)}
                                                                            dateFormat="dd/MM/yyyy"
                                                                            showYearDropdown
                                                                            showMonthDropdown
                                                                            useShortMonthInDropdown
                                                                            dropdownMode="select"
                                                                            peekNextMonth
                                                                            customInput={
                                                                                <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                            }
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col className='col-md-3 col-6'>
                                                                    <Form.Group>
                                                                        <Form.Label>To<span className="colorThemeRed">*</span></Form.Label>
                                                                        <DatePicker className="form-control"
                                                                            selected={spToDate}
                                                                            onChange={(date) => setSPToDate(date)}
                                                                            dateFormat="dd/MM/yyyy"
                                                                            showYearDropdown
                                                                            showMonthDropdown
                                                                            useShortMonthInDropdown
                                                                            dropdownMode="select"
                                                                            peekNextMonth
                                                                            customInput={
                                                                                <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                            }
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                            </>
                                                            : null
                                            }
                                        </Row>
                                        <Row>&nbsp;</Row>
                                        <Row>
                                            <Col>
                                                {
                                                    userRight.query === "1" && radioType !== "N" ?
                                                        <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => listSPdata()}>List</Button> : null
                                                }
                                                &nbsp;
                                                {
                                                    userRight.add === "1" && radioType === "N" ?
                                                        <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addNewForm()}>Add New</Button> : null
                                                }
                                            </Col>
                                        </Row>
                                        <Row>&nbsp;</Row>
                                        <Row>
                                            <Table responsive striped>
                                                <thead>
                                                    <tr>
                                                        <th>&nbsp;</th>
                                                        <th>Type</th>
                                                        <th>Location</th>
                                                        <th>Individual</th>
                                                        <th>Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        spList.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    <span onClick={() => fillViewForm(item.hdr_srno, item.hdr_location, item.hdr_type, item.new_date)} className='pointer colorThemeBlue'>
                                                                        <FontAwesomeIcon icon={faEye} />
                                                                    </span>
                                                                </td>
                                                                <td>{item.hdr_type}</td>
                                                                <td>{item.entity_name}</td>
                                                                <td>{item.bd_first_name + ' ' + item.bd_middle_name + ' ' + item.bd_last_name}</td>
                                                                <td>{item.hdr_date}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </Table>
                                        </Row>
                                    </>
                                    :
                                    <>
                                        <Row>
                                            <Col>
                                                <Button variant='outline-danger' size='sm' className='buttonStyle' onClick={() => cancelConfirm()}>Back</Button>
                                                &nbsp;
                                                {
                                                    (userRight.edit === "1" && scrMode === 'Q') ? <>
                                                        <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                            btnEditRequest()}>Edit</Button>&nbsp;
                                                    </> : null
                                                }
                                                {
                                                    (userRight.edit === "1" && scrMode === 'E') || (userRight.add === "1" && scrMode === 'A') ? <>
                                                        <Button variant='outline-primary' size='sm' className='buttonStyle' onClick={() => saveData()}>Save</Button>
                                                    </> : null
                                                }
                                                {
                                                    (userRight.query === "1" && scrMode === "Q") ? <>
                                                        <Button variant='outline-success' size='sm' className='buttonStyle' onClick={() => print("D")}>With Deno</Button>&nbsp;
                                                        <Button variant='outline-primary' size='sm' className='buttonStyle' onClick={() => print("")}>Without Deno</Button>&nbsp;
                                                        <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={() => handleFaView()}>Fa View</Button>
                                                    </> : null
                                                }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-md-2 col-4">
                                                <span>Location : <b>{hdrLocation} </b></span>
                                            </Col>
                                            <Col className="col-md-2 col-4">
                                                <span>Date : <b>{hdrDate}</b></span>
                                            </Col>
                                            <Col className="col-md-2 col-4">
                                                <span>Mode : <b>{spSaleType === "N" ? "Normal" : "EEFBC"}</b></span>
                                            </Col>
                                            <Col className='ppCharges'>
                                                <span><b>Forex Total (INR): {pForexTotal}</b></span>
                                                &nbsp;&nbsp;&nbsp;
                                                <span><b>Total Amount (INR): {pTotalAmount}</b></span>
                                            </Col>
                                        </Row>
                                        <Tabs activeKey={key} defaultActiveKey="INFO" onSelect={k => handleTabChange(k)}>
                                            <Tab eventKey="INFO" title="Info">
                                                <Row>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>Executive</Form.Label>
                                                            <AsyncSelect
                                                                cacheOptions={false}
                                                                defaultOptions={false}
                                                                value={nExecutive}
                                                                getOptionLabel={e => e.label + ' '}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={Picklist.fetchExecutivePicklist}
                                                                onInputChange={Picklist.handleInputChange}
                                                                onChange={handleExecutiveChange}
                                                                isDisabled={scrMode === "Q"}
                                                            >
                                                            </AsyncSelect>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>Mode</Form.Label>
                                                            <Form.Select disabled={scrMode === "Q"} value={nMode} onChange={e => setNMode(e.target.value)}>
                                                                <option value="B">In Branch</option>
                                                                <option value="D">Delivery</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>Sale Type</Form.Label>
                                                            <Form.Select value={spSaleType} onChange={e => setSPSaleType(e.target.value)} disabled>
                                                                <option value="N">Normal</option>
                                                                <option value="E">EEFBC</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>&nbsp;</Row>
                                                <Row>
                                                    <Col><h3>Traveller/Applicant Info</h3></Col>
                                                </Row>
                                                <Row>
                                                    <Col className="col-md-6 col-12">
                                                        <Form.Group>
                                                            <Form.Label>Traveller/Applicant Name<span className="colorThemeRed">*</span>
                                                                {
                                                                    scrMode === "Q" ? null :
                                                                        <>
                                                                            <span className='tranNewFormText' onClick={() => newIndividual("A")}>Not in list? Create New</span>
                                                                            &nbsp;&nbsp;
                                                                            <span className='tranNewFormText' onClick={() => newIndividual("E")}>Edit</span>
                                                                        </>
                                                                }
                                                            </Form.Label>
                                                            <AsyncSelect
                                                                cacheOptions={false}
                                                                defaultOptions={false}
                                                                value={nTraveller}
                                                                getOptionLabel={e => e.label + ' '}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={(search) => Picklist.fetchIndividualPicklist(search, "SALE")}
                                                                onInputChange={Picklist.handleInputChange}
                                                                onChange={handleTravellerChange}
                                                                isDisabled={scrMode === "Q"}
                                                            >
                                                            </AsyncSelect>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Purpose<span className="colorThemeRed">*</span></Form.Label>
                                                            <AsyncSelect
                                                                cacheOptions={false}
                                                                defaultOptions={false}
                                                                value={nPurpose}
                                                                getOptionLabel={e => e.label + ' '}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={Picklist.fetchPurposePicklist}
                                                                onInputChange={Picklist.handleInputChange}
                                                                onChange={handlePurposeChange}
                                                                isDisabled={scrMode === "Q"}
                                                            >
                                                            </AsyncSelect>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>Residential Status<span className="colorThemeRed">*</span></Form.Label>
                                                            <Form.Select disabled={scrMode === "Q"} value={nResident} onChange={e => setNResident(e.target.value)}>
                                                                {
                                                                    residentOptions.map((item, index) => (
                                                                        <option value={item.mrs_srno} key={index}>{item.mrs_resident}</option>
                                                                    ))
                                                                }
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Traveller/Employee Code</Form.Label>
                                                            <Form.Control disabled={scrMode === "Q"} value={nTravellerCode} onChange={e => setNTravellerCode(e.target.value)} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>Corporate</Form.Label>
                                                            <AsyncSelect
                                                                cacheOptions={false}
                                                                defaultOptions={false}
                                                                value={nCorporate}
                                                                getOptionLabel={e => e.label + ' '}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 'CORPORATE', spLocationValue)} // location = 1 , 2
                                                                onInputChange={Picklist.handleInputChange}
                                                                onChange={handleCorporateChange}
                                                                isDisabled={scrMode === "Q"}
                                                            >
                                                            </AsyncSelect>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>Place of Supply</Form.Label>
                                                            <Select isDisabled={scrMode === "Q"} options={stateOptions} value={nState} onChange={v => setNState(v)} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>PAN/Form 60</Form.Label>
                                                            <Form.Select disabled={scrMode === "Q"} value={nPanForm60} onChange={e => setNPanForm60(e.target.value)}>
                                                                <option value="">Select</option>
                                                                <option value="P">PAN</option>
                                                                <option value="F">Form 60</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>&nbsp;</Row>
                                                <Row>
                                                    <Col><h3>Visit Info</h3></Col>
                                                </Row>
                                                <Row>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>Country to Visit<span className="colorThemeRed">*</span></Form.Label>
                                                            <AsyncSelect
                                                                cacheOptions={false}
                                                                defaultOptions={false}
                                                                value={nCountry}
                                                                getOptionLabel={e => e.label + ' '}
                                                                getOptionValue={e => e.id}
                                                                loadOptions={(search) => Picklist.fetchCountryPicklist(search)} // location = 1 , 2
                                                                onInputChange={Picklist.handleInputChange}
                                                                onChange={handleCountryChange}
                                                                isDisabled={scrMode === "Q"}
                                                            >
                                                            </AsyncSelect>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>Ticket Class</Form.Label>
                                                            <Form.Select disabled={scrMode === "Q"} value={nTicketClass} onChange={e => setNTicketClass(e.target.value)}>
                                                                <option value="">Select</option>
                                                                <option value="FC">First Class</option>
                                                                <option value="BC">Business Class</option>
                                                                <option value="EC">Economy Class</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>Flight No<span className="colorThemeRed">*</span></Form.Label>
                                                            <Form.Control disabled={scrMode === "Q"} placeholder='Flight No' type='text' maxLength={20} value={nFlightNo} onChange={e => Common.validateAlpNumValue(e.target.value, setNFlightNo)} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>Ticket No<span className="colorThemeRed">*</span></Form.Label>
                                                            <Form.Control disabled={scrMode === "Q"} placeholder='Ticket No' type='text' maxLength={20} value={nTicketNo} onChange={e => Common.validateAlpNumValue(e.target.value, setNTicketNo)} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>Departure Date<span className="colorThemeRed">*</span></Form.Label>
                                                            <DatePicker className="form-control"
                                                                selected={nDepDate}
                                                                onChange={(date) => setNDepDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                disabled={scrMode === "Q"}
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>Duration of stay abroad<span className="colorThemeRed">*</span></Form.Label>
                                                            <Form.Control disabled={scrMode === "Q"} placeholder='Duration' type='text' maxLength={2} value={nDuration} onChange={e => Common.validateDecValue(e.target.value, setNDuration)} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className="col-md-3 col-6">
                                                        <Form.Group>
                                                            <Form.Label>Visa Type</Form.Label>
                                                            <Form.Select disabled={scrMode === "Q"} value={nVisaType} onChange={e => setNVisaType(e.target.value)}>
                                                                <option value="">Select</option>
                                                            </Form.Select>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Tab>
                                            <Tab eventKey="DETAILS" title="Details">
                                                <Row>
                                                    <Col>
                                                        <h4>Product Info</h4>
                                                    </Col>
                                                </Row>
                                                {
                                                    scrMode === "Q" ? null
                                                        :
                                                        <>
                                                            <Row>
                                                                <Col className='col-md-3 col-6'>
                                                                    <Form.Group>
                                                                        <Form.Label>Product<span className="colorThemeRed">*</span></Form.Label>
                                                                        <Form.Select value={pProduct} onChange={e => handleProductChange(e.target.value)}>
                                                                            <option value="CN">CN</option>
                                                                            <option value="VTM">VTM</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                </Col>
                                                                {
                                                                    pProduct === "VTM" &&
                                                                    <Col className='col-md-3 col-6'>
                                                                        <Form.Group>
                                                                            <Form.Label>Type<span className="colorThemeRed">*</span></Form.Label>
                                                                            <Form.Select value={pSaleReload} onChange={e => handleSaleTypeChange(e.target.value)}>
                                                                                <option value="S">Sale</option>
                                                                                <option value="R">Reload</option>
                                                                            </Form.Select>
                                                                        </Form.Group>
                                                                    </Col>
                                                                }
                                                                < Col className="col-md-3 col-6">
                                                                    <Form.Group>
                                                                        <Form.Label>Isd<span className="colorThemeRed">*</span></Form.Label>
                                                                        <AsyncSelect
                                                                            cacheOptions={false}
                                                                            defaultOptions={false}
                                                                            value={pIsd}
                                                                            getOptionLabel={e => e.label + ' '}
                                                                            getOptionValue={e => e.id}
                                                                            loadOptions={(search) => Picklist.fetchCurrencyAllowedPicklist(search, pProduct)} // location = 1 , 2
                                                                            onInputChange={Picklist.handleInputChange}
                                                                            onChange={handleChangeIsd}
                                                                        >
                                                                        </AsyncSelect>
                                                                    </Form.Group>
                                                                </Col>
                                                                {
                                                                    pProduct === "VTM" ?
                                                                        <Col className="col-md-3 col-6">
                                                                            <Form.Group>
                                                                                <Form.Label>Issuer<span className="colorThemeRed">*</span></Form.Label>
                                                                                <AsyncSelect
                                                                                    cacheOptions={false}
                                                                                    defaultOptions={false}
                                                                                    value={pIssuer}
                                                                                    getOptionLabel={e => e.label + ' '}
                                                                                    getOptionValue={e => e.id}
                                                                                    loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 'ISSUER', spLocationValue)} // location = 1 , 2
                                                                                    onInputChange={Picklist.handleInputChange}
                                                                                    onChange={handleIssuerChange}
                                                                                >
                                                                                </AsyncSelect>
                                                                            </Form.Group>
                                                                        </Col>
                                                                        : null
                                                                }
                                                            </Row>
                                                            <Row>
                                                                <Col className='col-md-3 col-6'>
                                                                    <Form.Group>
                                                                        <Form.Label>{pProduct === "VTM" ? "Value" : "Denomination"}
                                                                            <span className="colorThemeRed">*</span>
                                                                        </Form.Label>
                                                                        <Form.Control type='text' value={pDenomination} maxLength={5}
                                                                            onChange={e => handleDenoChange(e.target.value)} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col className='col-md-3 col-6' style={{ display: pProduct === "VTM" && "none" }}>
                                                                    <Form.Group>
                                                                        <Form.Label>Quantity<span className="colorThemeRed">*</span></Form.Label>
                                                                        <Form.Control type='text' placeholder='Quantity' value={pQuantity} maxLength={5}
                                                                            onChange={e => handleQuantityChange(e.target.value)} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col className='col-md-3 col-6'>
                                                                    <Form.Group>
                                                                        <Form.Label>Rate<span className="colorThemeRed">*</span></Form.Label>
                                                                        <Form.Control type='text' placeholder='Rate' value={pRate} maxLength={5}
                                                                            onChange={e => handleRateChange(e.target.value)} />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                            {
                                                                pProduct === "VTM" ?
                                                                    <Row>
                                                                        <Col className='col-md-3 col-6'>
                                                                            <Form.Group>
                                                                                <Form.Label>Prefix</Form.Label>
                                                                                <Form.Control disabled placeholder='Prefix' type='text' maxLength={5} value={prPrefix} onChange={e => Common.validateAlpNumValue(e.target.value, setPrPrefix)} />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col className='col-md-3 col-6'>
                                                                            <Form.Group>
                                                                                <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                                                                                {
                                                                                    pSaleReload === "S" ?
                                                                                        <AsyncSelect
                                                                                            cacheOptions={false}
                                                                                            defaultOptions={false}
                                                                                            value={prFrom}
                                                                                            getOptionLabel={e => e.label + ' '}
                                                                                            getOptionValue={e => e.id}
                                                                                            loadOptions={(search) => Picklist.fetchVtcInlist(search, { isd: pIsdId, location: spLocationValue, group: pIssuerGroup, account: pIssuerAccount, product: "VTC", type: "VTCIN" })}
                                                                                            onInputChange={Picklist.handleInputChange}
                                                                                            onChange={handleFromChange}
                                                                                        >
                                                                                        </AsyncSelect>
                                                                                        :
                                                                                        <AsyncSelect
                                                                                            cacheOptions={false}
                                                                                            defaultOptions={false}
                                                                                            value={prFrom}
                                                                                            getOptionLabel={e => e.label + ' '}
                                                                                            getOptionValue={e => e.id}
                                                                                            loadOptions={(search) => Picklist.fetchVtcBilllist(search, { isd: pIsdId, location: spLocationValue, group: pIssuerGroup, account: pIssuerAccount, product: "VTC", type: "SP" })}
                                                                                            onInputChange={Picklist.handleInputChange}
                                                                                            onChange={handleFromChange}
                                                                                        >
                                                                                        </AsyncSelect>
                                                                                }
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col className='col-md-3 col-6'>
                                                                            <Form.Group>
                                                                                <Form.Label>To<span className="colorThemeRed">*</span></Form.Label>
                                                                                <Form.Control disabled placeholder='To' type='text' maxLength={6} value={prTo} onChange={e => Common.validateNumValue(e.target.value, setPrTo)} />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col className='col-md-3 col-6'>
                                                                            <Form.Group>
                                                                                <Form.Label>Card No</Form.Label>
                                                                                <Form.Control disabled={pSaleReload === "R"} placeholder='Card No' maxLength={10} type='text' value={pVtmCardNo} onChange={e => setPVtmCardNo(e.target.value)} />
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Row>
                                                                    : null
                                                            }
                                                            <Row>
                                                                <Col className='col-md-3 col-6'>
                                                                    <Form.Group>
                                                                        <Form.Label>Commission</Form.Label>
                                                                        <Form.Select value={isCommission} onChange={e => setIsCommission(e.target.value)}>
                                                                            <option value="0">No</option>
                                                                            <option value="1">Yes</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                </Col>
                                                                {
                                                                    isCommission === "1" ?
                                                                        <>
                                                                            <Col className='col-md-3 col-6'>
                                                                                <Form.Group>
                                                                                    <Form.Label>Agent<span className="colorThemeRed">*</span></Form.Label>
                                                                                    <AsyncSelect
                                                                                        cacheOptions={false}
                                                                                        defaultOptions={false}
                                                                                        value={pAgent}
                                                                                        getOptionLabel={e => e.label + ' '}
                                                                                        getOptionValue={e => e.id}
                                                                                        loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 'AGENT', spLocationValue)} // location = 1 , 2
                                                                                        onInputChange={Picklist.handleInputChange}
                                                                                        onChange={handleAgentChange}
                                                                                    >
                                                                                    </AsyncSelect>
                                                                                </Form.Group>
                                                                            </Col>
                                                                            <Col className="col-md-3 col-6">
                                                                                <Form.Group>
                                                                                    <Form.Label>Type<span className="colorThemeRed">*</span></Form.Label>
                                                                                    <Form.Select value={commType} onChange={e => commissionTypeChange(e.target.value)}>
                                                                                        <option value="">Select</option>
                                                                                        <option value="P">Paisa</option>
                                                                                        <option value="R">Percentage</option>
                                                                                        <option value="F">Flat</option>
                                                                                    </Form.Select>
                                                                                </Form.Group>
                                                                            </Col>
                                                                            <Col>
                                                                                <Form.Group>
                                                                                    <Form.Label>Commission<span className="colorThemeRed">*</span></Form.Label>
                                                                                    <Form.Control type='text' maxLength={5} value={commission} onChange={e => handleCommissionCalc(e.target.value)} />
                                                                                </Form.Group>
                                                                            </Col>
                                                                            <Col>
                                                                                <Form.Group>
                                                                                    <Form.Label>Commission Value</Form.Label>
                                                                                    <Form.Control disabled value={commValue} onChange={e => Common.validateDecValue(e.target.value, setCommValue)} />
                                                                                </Form.Group>
                                                                            </Col>
                                                                        </>
                                                                        : null
                                                                }
                                                            </Row>
                                                            <Row>&nbsp;</Row>
                                                            <Row>
                                                                <Col>
                                                                    <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addProduct()}>Add</Button>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                }
                                                <Row>&nbsp;</Row>
                                                <Row>
                                                    <Table responsive bordered striped>
                                                        <thead>
                                                            <tr>
                                                                <th>Product</th>
                                                                <th>Isd Code</th>
                                                                <th>Isd Name</th>
                                                                <th>Denomination</th>
                                                                <th>Quantity</th>
                                                                <th>Rate</th>
                                                                <th>Amount</th>
                                                                <th>Prefix</th>
                                                                <th>From</th>
                                                                <th>To</th>
                                                                <th>&nbsp;</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                spProductList.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>{item.prd_product}</td>
                                                                        <td>{item.prd_isd}</td>
                                                                        <td>{item.isd_name}</td>
                                                                        <td>{item.prd_deno}</td>
                                                                        <td>{item.prd_qty}</td>
                                                                        <td>{item.prd_rate}</td>
                                                                        <td>{item.prd_amount}</td>
                                                                        <td>{item.prd_prefix}</td>
                                                                        <td>{item.prd_from}</td>
                                                                        <td>{item.prd_to}</td>
                                                                        <td>
                                                                            {
                                                                                scrMode === "Q" || item.delny === "N" ? null :
                                                                                    <span onClick={() => deleteProduct(item.uniqueKey)} className='colorThemeBlue pointer'>
                                                                                        <FontAwesomeIcon icon={faTrash} />
                                                                                    </span>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Row>
                                            </Tab>
                                            <Tab eventKey="PAYMENT" title="Payment">
                                                <Row>
                                                    <Col className="col-md-2 col-4">
                                                        <Form.Label>Taxable Value: {pTaxableVal}</Form.Label>
                                                    </Col>
                                                    <Col className="col-md-2 col-4">
                                                        <Form.Label>SGST: {pSgst}</Form.Label>
                                                    </Col>
                                                    <Col className="col-md-2 col-4">
                                                        <Form.Label>IGST: {pIgst}</Form.Label>
                                                    </Col>
                                                    <Col className="col-md-2 col-4">
                                                        <Form.Label>CGST : {pCgst}</Form.Label>
                                                    </Col>
                                                    <Col className="col-md-2 col-4">
                                                        <Form.Label>UGST : {pUgst}</Form.Label>
                                                    </Col>
                                                    <Col className="col-md-2 col-4">
                                                        <Form.Label>Round Off: {pRoundOff}</Form.Label>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="col-md-3 col-6" style={{ display: "flex", alignItems: "center" }}>
                                                        <span>OtherCharges: &nbsp;</span>
                                                        <span>
                                                            <Form.Control size='sm' placeholder='Other Charges' type='text'
                                                                maxLength={5}
                                                                value={pOtherCharges}
                                                                onChange={e => otherChargeChange(e.target.value)}
                                                                disabled={disableOtherCharge || scrMode === "Q"}
                                                            />
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <hr />
                                                <Row>
                                                    <Col><h3>Payment Details</h3></Col>
                                                </Row>
                                                {
                                                    scrMode === "Q" ? null
                                                        :
                                                        <>
                                                            <Row>
                                                                <Col className='col-md-3 col-6'>
                                                                    <Form.Group>
                                                                        <Form.Label>Payment Type</Form.Label>
                                                                        <Form.Select value={pdPaymentType} onChange={e => handlePaymentType(e.target.value)}>
                                                                            <option value="">Select</option>
                                                                            <option value="CH">Cash</option>
                                                                            <option value="BN">Bank</option>
                                                                            <option value="CQ">Cheque</option>
                                                                            <option value="RC">Recievables</option>
                                                                            <option value="PB">Payables</option>
                                                                            <option value="AP">Advance Paid</option>
                                                                            <option value="AR">Advance Recieved</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col className='col-md-3 col-6'>
                                                                    <Form.Group>
                                                                        <Form.Label>Account<span className="colorThemeRed">*</span></Form.Label>
                                                                        <AsyncSelect
                                                                            cacheOptions={false}
                                                                            defaultOptions={false}
                                                                            value={pdAccount}
                                                                            getOptionLabel={e => e.label + ' '}
                                                                            getOptionValue={e => e.id}
                                                                            loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, ldgSrchType, spLocationValue)} // location = 1 , 2
                                                                            onInputChange={Picklist.handleInputChange}
                                                                            onChange={handleAccountValue}
                                                                        >
                                                                        </AsyncSelect>
                                                                    </Form.Group>
                                                                </Col>
                                                                {
                                                                    pdPaymentType !== "" ?
                                                                        <Col className='col-md-3 col-6'>
                                                                            <Form.Group>
                                                                                <Form.Label>Amount<span className="colorThemeRed">*</span></Form.Label>
                                                                                <Form.Control placeholder='Amount' value={pdAmount} onChange={e => Common.validateDecValue(e.target.value, setPdAmount)} />
                                                                            </Form.Group>
                                                                        </Col> : null
                                                                }
                                                            </Row>
                                                            {
                                                                pdPaymentType === "BN" ?
                                                                    <Row>
                                                                        <Col className="col-md-3 col-6">
                                                                            <Form.Group>
                                                                                <Form.Label>Reference No<span className="colorThemeRed">*</span></Form.Label>
                                                                                <Form.Control placeholder='Reference No' maxLength={60} value={pdChequeNum} onChange={e => Common.validateAlpNumValue(e.target.value, setPdChequeNum)} />
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Row>
                                                                    : pdPaymentType === "CQ" ?
                                                                        <Row>
                                                                            <Col className="col-md-3 col-6">
                                                                                <Form.Group>
                                                                                    <Form.Label>Cheque No<span className="colorThemeRed">*</span></Form.Label>
                                                                                    <Form.Control placeholder='Cheque No' type='text' maxLength={10} value={pdChequeNum} onChange={e => Common.validateAlpNumValue(e.target.value, setPdChequeNum)} />
                                                                                </Form.Group>
                                                                            </Col>
                                                                            <Col className="col-md-3 col-6">
                                                                                <Form.Group>
                                                                                    <Form.Label>Bank Name<span className="colorThemeRed">*</span></Form.Label>
                                                                                    <Form.Control placeholder='Bank Name' type='text' maxLength={50} value={chequeBankName} onChange={e => Common.validateAlpNumValue(e.target.value, setChequeBankName)} />
                                                                                </Form.Group>
                                                                            </Col>
                                                                            <Col className="col-md-3 col-6">
                                                                                <Form.Group>
                                                                                    <Form.Label>Cheque Date<span className="colorThemeRed">*</span></Form.Label>
                                                                                    <DatePicker className="form-control"
                                                                                        selected={chequeDate}
                                                                                        onChange={(date) => setChequeDate(date)}
                                                                                        dateFormat="dd/MM/yyyy"
                                                                                        showYearDropdown
                                                                                        showMonthDropdown
                                                                                        useShortMonthInDropdown
                                                                                        dropdownMode="select"
                                                                                        peekNextMonth
                                                                                        customInput={
                                                                                            <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                                        }
                                                                                    />
                                                                                </Form.Group>
                                                                            </Col>
                                                                        </Row>
                                                                        : null
                                                            }
                                                            <Row>&nbsp;</Row>
                                                            <Row>
                                                                <Col>
                                                                    <Button variant='outline-primary' size='sm' className='buttonStyle' onClick={() => addPayment()}>Add</Button>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                }
                                                <Row>&nbsp;</Row>
                                                <Row>
                                                    <Table responsive striped bordered>
                                                        <thead>
                                                            <tr>
                                                                <th>Type</th>
                                                                <th>Group</th>
                                                                <th>Account</th>
                                                                <th>Name</th>
                                                                <th>Amount</th>
                                                                <th>Cheque/Refernece No</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                paymentData.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            {
                                                                                item.tp_payment_type === "BN"
                                                                                    ? "Bank" : item.tp_payment_type === "CQ"
                                                                                        ? "Cheque" : item.tp_payment_type === "CH"
                                                                                            ? "Cash" : item.tp_payment_type === "RC"
                                                                                                ? "Reveivables" : item.tp_payment_type === "PB"
                                                                                                    ? "Payables" : item.tp_payment_type === "AP"
                                                                                                        ? "Advance Paid" : item.tp_payment_type === "AR"
                                                                                                            ? "Advance Received" : ""
                                                                            }
                                                                        </td>
                                                                        <td>{item.tp_group}</td>
                                                                        <td>{item.tp_account}</td>
                                                                        <td>{item.mvli_ledger_name}</td>
                                                                        <td>{item.tp_amount}</td>
                                                                        <td>{item.tp_cheque}</td>
                                                                        <td>
                                                                            {
                                                                                scrMode !== "Q" ?
                                                                                    <span className='colorThemeBlue pointer' onClick={() => deletePayment(item.uniquekey, item.delny)}>
                                                                                        <FontAwesomeIcon icon={faTrash} />
                                                                                    </span> : null
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Row>
                                            </Tab>
                                        </Tabs>
                                    </>
                            }
                        </Container>
                    </>
            }
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <span className="corner-span">{counter}</span>
            <FaView faViewObject={faViewObject} show={showFaView} onHide={() => setShowFaView(false)} />
            <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                {msgText}
            </Dialog>
            <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
                {msgTextYN}
            </DialogYesNo>

            <Tran_data_unlock_request logObject={editLogObject} show={showEditRequest} onHide={() => setShowEditRequest(false)} />

        </>
    )
}

export default Tran_sp
