import React, { useState, useEffect } from 'react'
import Login_header from '../Login_header';
import Menu from '../Menu';
import { Container, Row, Col, Form, Button, Table, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import DatePicker from 'react-datepicker';
import Dialog from '../Dialog';
import DialogYesNo from '../Dialog_Yes_No';
import * as Common from '../Common';
import * as Picklist from '../Picklist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Tran_data_unlock_request from '../Tran_data_unlock_request';

const Tran_trf_out_currency = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const startDate = sessionStorage.getItem('startDate');
    const endDate = sessionStorage.getItem('endDate');
    const branchDisable = sessionStorage.getItem('branchDisable');

    const [userRight, setUserRight] = useState([]);
    const [onceRun, setOnceRun] = useState(false);
    const [scrMode, setScrMode] = useState("");

    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");
    const [isErrBox, setErrBox] = useState(false);
    const [errText, setErrText] = useState("");
    const [showModal, setShowModal] = useState(false);

    const [innerData, setInnerData] = useState([]);
    const [headerData, setHeaderData] = useState([]);
    const [subInnerData, setSubInnerData] = useState([]);

    const [radioType, setRadioType] = useState('N');
    const [outCNFromLocation, setOutCNFromLocation] = useState(null);
    const [outCNToLocation, setOutCNToLocation] = useState(null);
    const [fromLocationCode, setFromLocationCode] = useState(0);
    const [fromLocation, setFromLocation] = useState("");
    const [toLocationCode, setToLocationCode] = useState(0);
    const [toLocation, setToLocation] = useState("");

    const [outCNDate, setOutCNDate] = useState(new Date());
    const [outCNFromDate, setOutCNFromDate] = useState(new Date());
    const [outCNToDate, setOutCNToDate] = useState(new Date());
    const [outCNFromSrno, setOutCNFromSrno] = useState("");
    const [outCNToSrno, setOutCNToSrno] = useState("");

    const [outCNCurrency, setOutCNCurrency] = useState(null);
    const [currencyCode, setCurrencyCode] = useState("");
    const [outCNDeno, setOutCNDeno] = useState("");
    const [outCNQuantity, setOutCNQuantity] = useState("");
    const [outCNRate, setOutCNRate] = useState("");
    const [outCNAmount, setOutCNAmount] = useState(0);
    const [outCNTotalAmount, setOutCNTotalAmount] = useState(0);
    const [srNo, setSrNo] = useState(0);
    const [lineNo, setLineNo] = useState(0);

    const [viewSrno, setViewSrno] = useState(0);
    const [viewDate, setViewDate] = useState('');
    const currentDate = new Date();

    const [showEditRequest, setShowEditRequest] = useState(false);
    const [editLogObject, setEditLogObject] = useState({ trantype: "", trannumber: "", trancategory: "", tranDate: "" });

    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.getActiveBranch().then(response => {
                setOutCNFromLocation(response);
                response != null && handleChangeFromLocation(response);
            });
            Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_TRF_OUT_CURRENCY'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);


    const handleChangeFromLocation = (value) => {
        console.log(value);
        setOutCNFromLocation(value);
        const loc = value.id.split('^');
        setFromLocationCode(loc[1]);
        setFromLocation(loc[2]);
        sessionStorage.activeBranch = loc[1];
    }


    const handleChangeToLocation = (value) => {
        console.log(value);
        setOutCNToLocation(value);
        const loc = value.id.split('^');
        setToLocationCode(loc[1]);
        setToLocation(loc[2]);
    }


    const handleCurrencyChange = (value) => {
        setOutCNCurrency(value);
        const currency = value.id.split('^');
        setCurrencyCode(currency[1]);
    }


    const handleDeno = (e) => {
        Common.validateNumValue(e.target.value, setOutCNDeno)
        if (e.target.value === "" || outCNQuantity === "" || outCNRate === "") {
            setOutCNAmount("");
        } else {
            setOutCNAmount(1 * e.target.value * (1 * outCNQuantity) * (1 * outCNRate));
        }
    }


    const handleQuantity = (e) => {
        Common.validateNumValue(e.target.value, setOutCNQuantity)
        if (e.target.value === "" || outCNDeno === "" || outCNRate === "") {
            setOutCNAmount("");
        } else {
            setOutCNAmount(1 * e.target.value * (1 * outCNDeno) * (1 * outCNRate));
        }
    }


    const handleRate = (e) => {
        Common.validateNumValue(e.target.value, setOutCNRate)
        if (e.target.value === "" || outCNDeno === "" || outCNQuantity === "") {
            setOutCNAmount("");
        } else {
            setOutCNAmount(1 * e.target.value * (1 * outCNQuantity) * (1 * outCNDeno));
        }
    }


    const list = () => {
        var msg = [], i = 0;
        if (outCNFromLocation === null) {
            setMsgText("Please Select Location!");
            setMsgBox(true);
        } else if (radioType === 'S' && (outCNFromSrno === "" || outCNToSrno === "")) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (radioType === 'S' && outCNFromSrno === "" ? " From." : '');
            msg[i++] = (radioType === 'S' && outCNToSrno === "" ? " To." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const obj = {
                filterRadioType: radioType,
                filterLocation: fromLocationCode,
                // fromLocation: fromLocationCode,
                filterFromDate: Common.dateYMD(outCNFromDate),
                filterToDate: Common.dateYMD(outCNToDate),
                filterFromSrno: outCNFromSrno,
                filterToSrno: outCNToSrno,
            }
            Common.callApi(Common.apiTranTransferOutCurrency, [sid, 'list', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                setHeaderData(resp);
            });
        }
    }


    const addNew = () => {
        var msg = [], i = 0;
        var date = Common.dateYMD(outCNDate);
        if (outCNFromLocation === null || outCNToLocation === null) {
            msg[i++] = Common.getMessage('MSG0006');
            msg[i++] = (outCNFromLocation === null ? " From Location." : '');
            msg[i++] = (outCNToLocation === null ? " To Location." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (fromLocation === toLocation) {
            setMsgText("From location and To location should not be same!.");
            setMsgBox(true);
        } else if (date > endDate || date < startDate) {
            setMsgBox(true);
            setMsgText(Common.getMessage("MSG0010"));
        } else {
            setScrMode('A');
            setInnerData([]);
            setOutCNAmount(null);
            setOutCNQuantity("");
            setOutCNDeno("");
            setOutCNRate("");
            setOutCNAmount("");
        }
    }


    const addDetail = () => {
        var msg = [], i = 0;
        if (outCNCurrency === null || outCNDeno === "" || outCNQuantity === "" || outCNRate === "") {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (outCNCurrency === null ? "Select Currency." : '');
            msg[i++] = (outCNDeno === "" ? "Denomination." : '');
            msg[i++] = (outCNQuantity === "" ? "Quantity." : '');
            msg[i++] = (outCNRate === "" ? "Rate." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (outCNQuantity >= 101) {
            setMsgText("Quantity Should be less than 101!");
            setMsgBox(true);
        } else {
            let obj = {
                unique_no: Common.getRandomString(5),
                prd_line_no: 1 * lineNo + 1,
                prd_location: fromLocationCode,
                prd_date: Common.dateYMD(outCNDate),
                prd_type: 'CNOUT',
                prd_product: 'CN',
                prd_isd: currencyCode,
                prd_deno: outCNDeno,
                prd_qty: outCNQuantity,
                prd_rate: outCNRate,
                prd_amount: outCNAmount,
                del: 'Y',
            }
            setInnerData(Common.arrayAddItem(innerData, obj));
            let sumOfPrdAmount = 0;
            for (let i = 0; i < innerData.length; i++) {
                sumOfPrdAmount += innerData[i].prd_amount * 1;
            }
            // console.log('Sum of prd_amount:', sumOfPrdAmount);
            setOutCNTotalAmount(sumOfPrdAmount);
            setLineNo(lineNo + 1);
            setOutCNCurrency(null);
            setOutCNQuantity("");
            setOutCNDeno("");
            setOutCNRate("");
            setOutCNAmount("");
        }
    }


    const deleteItem = (value) => {
        setInnerData(Common.arrayRemoveItem(innerData, "unique_no", value));

        let sumOfPrdAmount = 0;
        for (let i = 0; i < innerData.length; i++) {
            sumOfPrdAmount += innerData[i].prd_amount * 1;
        }
        setOutCNTotalAmount(sumOfPrdAmount);
    }


    const saveData = () => {
        if (innerData.length < 1) {
            setMsgText("Empty list!");
            setMsgBox(true);
        } else {
            const obj = {
                fromLocation: fromLocationCode,
                toLocation: toLocationCode,
                srno: srNo,
                date: Common.dateYMD(outCNDate),
                totalAmount: outCNTotalAmount,
                data: innerData,
                status: 'P',
                type: 'CNOUT',

                filterRadioType: radioType,
                filterLocation: fromLocationCode,
                filterFromDate: Common.dateYMD(outCNFromDate),
                filterToDate: Common.dateYMD(outCNToDate),
                filterFromSrno: outCNFromSrno,
                filterToSrno: outCNToSrno,
            }
            Common.callApi(Common.apiTranTransferOutCurrency, [sid, 'save', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                setHeaderData(resp);
                // setOutCNFromLocation(null);
                setOutCNToLocation(null);
                setInnerData([]);
                setScrMode("");
            });
            if (scrMode == 'E') {
                const obj = {
                    srno: srNo,
                    type: 'CNOUT',
                    status: 'E',
                    currentDate: Common.dateYMD(currentDate),
                    pageright: 'TRAN_TRF_OUT_CURRENCY'
                }
                Common.callApi(Common.apiUnlockEdit, [sid, 'expireUnlock', JSON.stringify(obj)], (result) => {
                    console.log(result);
                })

            }
        }
    }


    const viewList = (hdrSrno, hdrLocation, hdrType, vdate) => {
        setSrNo(hdrSrno);
        setScrMode("Q");
        setViewDate(vdate);
        setViewSrno(hdrSrno);
        const obj = {
            srno: hdrSrno,
            location: hdrLocation,
            type: hdrType,
        }
        Common.callApi(Common.apiTranTransferOutCurrency, [sid, 'view', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);
            if (resp.msg === 'MSG0000') {
                setInnerData(resp.currencyList);
                setLineNo(resp.maxlineno);
                setFromLocationCode(resp.fromLocationCode);
                setToLocationCode(resp.toLocationCode);
                setFromLocation(resp.fromLocation);
                setToLocation(resp.toLocation);
                setOutCNTotalAmount(resp.totalAmount);
            }
        });
    }


    const cancelConfirm = () => {
        if (scrMode === "A" || scrMode === "E") {
            setMsgTextYN('Do you really want to exit ?');
            setMsgBoxYN(true);
        } else {
            cancelData();
        }
    }


    const cancelData = () => {
        setMsgBoxYN(false);
        setScrMode("");
    }

    const btnEditRequest = () => {
        const currDate = new Date();
        const newDate = viewDate.split('/');

        const newViewDate = newDate[2] + '-' + newDate[1] + '-' + newDate[0]
        const newCurrDate = Common.dateYMD(currDate);

        const dateA = new Date(newViewDate);
        const dateB = new Date(newCurrDate);

        // Calculate the difference in milliseconds
        const differenceInMilliseconds = dateB - dateA;

        // Convert milliseconds difference to days
        const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 3600 * 24));

        console.log(differenceInDays);

        const obj = {
            srno: viewSrno,
            type: 'CNOUT',
            pageright: 'TRAN_TRF_OUT_CURRENCY'
        }
        // const message = 'unlock'
        Common.callApi(Common.apiUnlockEdit, [sid, 'checkUnlock', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);

            if (Common.editDateLimit == differenceInDays || resp == "unlock") {
                setScrMode('E');

            } else {
                setShowEditRequest(true);
                setEditLogObject({ trantype: 'CNOUT', trannumber: viewSrno, location: fromLocationCode, tranDate: Common.dateYMD(currDate) });
            }
        })


    }


    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h3>Transfer Out Currency</h3>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                {
                    scrMode === "" ?
                        <>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Location<span className="text-danger">*</span></Form.Label>
                                        <AsyncSelect
                                            cacheOptions={false}
                                            defaultOptions={false}
                                            value={outCNFromLocation}
                                            getOptionLabel={e => e.label + ' '}
                                            getOptionValue={e => e.id}
                                            loadOptions={Picklist.fetchLocationAllowedPicklist}
                                            onInputChange={Picklist.handleInputChange}
                                            onChange={handleChangeFromLocation}
                                            isDisabled={branchDisable === "true"}
                                        >
                                        </AsyncSelect>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-4 col-6'>
                                    <Form.Group>
                                        <Row style={{ marginTop: "30px" }}>
                                            <Col>
                                                <Form.Check checked={radioType === "N"} onChange={() => setRadioType("N")} type='radio' label="New" />
                                            </Col><Col>
                                                <Form.Check checked={radioType === "S"} onChange={() => setRadioType("S")} type='radio' label="SrNo" />
                                            </Col><Col>
                                                <Form.Check checked={radioType === "D"} onChange={() => setRadioType("D")} type='radio' label="Date" />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                {
                                    radioType === 'N' ?
                                        <>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Date<span className="text-danger">*</span></Form.Label>
                                                    <DatePicker className="form-control"
                                                        selected={outCNDate}
                                                        minDate={new Date(startDate)}
                                                        maxDate={new Date(endDate)}
                                                        onChange={(date) => setOutCNDate(date)}
                                                        dateFormat="dd/MM/yyyy"
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        useShortMonthInDropdown
                                                        dropdownMode="select"
                                                        peekNextMonth
                                                        customInput={
                                                            <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>To Location<span className="text-danger">*</span></Form.Label>
                                                    <AsyncSelect
                                                        cacheOptions={false}
                                                        defaultOptions={false}
                                                        value={outCNToLocation}
                                                        getOptionLabel={e => e.label + ' '}
                                                        getOptionValue={e => e.id}
                                                        loadOptions={(search) => Picklist.fetchLocationPicklist(search)}
                                                        onInputChange={Picklist.handleInputChange}
                                                        onChange={handleChangeToLocation}
                                                    >
                                                    </AsyncSelect>
                                                </Form.Group>
                                            </Col>
                                        </>
                                        :
                                        radioType === 'S' ?
                                            <>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>From<span className="text-danger">*</span></Form.Label>
                                                        <Form.Control value={outCNFromSrno}
                                                            maxLength={20}
                                                            onChange={(e) => Common.validateNumValue(e.target.value, setOutCNFromSrno)}
                                                            placeholder="From" />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>To<span className="text-danger">*</span></Form.Label>
                                                        <Form.Control value={outCNToSrno}
                                                            maxLength={20}
                                                            onChange={(e) => Common.validateNumValue(e.target.value, setOutCNToSrno)}
                                                            placeholder="To" />
                                                    </Form.Group>
                                                </Col>
                                            </>
                                            :
                                            radioType === 'D' ?
                                                <>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                                                            <DatePicker className="form-control"
                                                                selected={outCNFromDate}
                                                                onChange={(date) => setOutCNFromDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>To<span className="colorThemeRed">*</span></Form.Label>
                                                            <DatePicker className="form-control"
                                                                selected={outCNToDate}
                                                                onChange={(date) => setOutCNToDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </>
                                                :
                                                null
                                }
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    {
                                        userRight.add === "1" && radioType === 'N' ?
                                            <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addNew()}>Add New</Button> : null
                                    }
                                    &nbsp;
                                    {
                                        userRight.query === "1" && (radioType === 'S' || radioType === 'D') ?
                                            <Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => list()}>List</Button>
                                            : null
                                    }
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                {userRight.edit === "1" ? <th></th> : null}
                                                <th>SrNo</th>
                                                <th>From Location</th>
                                                <th>To Location</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                headerData.map((res) => (
                                                    <tr key={res.hdr_srno}>
                                                        {userRight.edit === "1" ?
                                                            <td className="textCenter">
                                                                <span className="handCursor colorThemeBlue" onClick={() => viewList(res.hdr_srno, res.hdr_location, res.hdr_type, res.hdr_date)}>
                                                                    <FontAwesomeIcon icon={faEye} />
                                                                </span>
                                                            </td>
                                                            : null
                                                        }
                                                        <td>{res.hdr_srno}</td>
                                                        <td>{res.hdr_fromlocation}</td>
                                                        <td>{res.hdr_tolocation}</td>
                                                        <td>{res.formatted_date}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </>
                        :
                        <>
                            <Row>
                                <Col>
                                    <Button variant='outline-danger' className='buttonStyle' size='sm' onClick={() => cancelConfirm()}>Back</Button>{' '}
                                    {
                                        (userRight.edit === "1" && scrMode === "Q") ? <>
                                            <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() => btnEditRequest()}>Edit</Button>
                                        </> : null
                                    }
                                    {
                                        (userRight.edit === "1" && scrMode === "E") || (userRight.add === "1" && scrMode === "A") ? <>
                                            <Button variant='outline-primary' size='sm' className='buttonStyle' onClick={() => saveData()}>Save</Button>
                                        </> : null
                                    }
                                    {/* <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={exportFile}>Export</Button> */}
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Label>From Location : {fromLocation}</Form.Label>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Label>To Location : {toLocation}</Form.Label>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Total Amount</Form.Label>
                                        <Form.Control
                                            value={outCNTotalAmount}
                                            disabled
                                            placeholder="Total" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            {
                                scrMode === 'E' || scrMode === 'A' ?
                                    <>
                                        <Row>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Currency<span className="text-danger">*</span></Form.Label>
                                                    <AsyncSelect
                                                        cacheOptions={false}
                                                        defaultOptions={false}
                                                        value={outCNCurrency}
                                                        getOptionLabel={e => e.label + ' '}
                                                        getOptionValue={e => e.id}
                                                        loadOptions={(search) => Picklist.fetchCurrencyAllowedPicklist(search, 'CN')} // location = 1 , 2
                                                        onInputChange={Picklist.handleInputChange}
                                                        onChange={handleCurrencyChange}
                                                    >
                                                    </AsyncSelect>
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Denomination<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control
                                                        value={outCNDeno}
                                                        onChange={(e) => handleDeno(e)}
                                                        // onChange={e => Common.validateNumValue(e.target.value, setOutCNDeno)}
                                                        maxLength={20}
                                                        placeholder="Denomination" />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Quantity<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control
                                                        value={outCNQuantity}
                                                        onChange={(e) => handleQuantity(e)}
                                                        // onChange={(e) => Common.validateNumValue(e.target.value, setOutCNQuantity)}
                                                        maxLength={20}
                                                        placeholder="Quantity" />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Rate<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control
                                                        value={outCNRate}
                                                        onChange={(e) => handleRate(e)}
                                                        // onChange={(e) => Common.validateNumValue(e.target.value, setOutCNRate)}
                                                        maxLength={10}
                                                        placeholder="Rate" />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Amount</Form.Label>
                                                    <Form.Control
                                                        value={outCNAmount}
                                                        disabled
                                                        placeholder="Amount" />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: "30px" }}>
                                            <Col className='col-md-3 col-6'>
                                                {
                                                    userRight.add === "1" ?
                                                        <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addDetail()}>Add</Button> : null
                                                }
                                            </Col>
                                        </Row>
                                    </> :
                                    null
                            }
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                {userRight.edit === "1" ? <th></th> : null}
                                                <th>Currency</th>
                                                <th>Denomination</th>
                                                <th>Quantity</th>
                                                <th>Rate</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                innerData.map((res) => (
                                                    <tr key={res.prd_line_no}>
                                                        {
                                                            userRight.delete === "1" ?
                                                                <td className="textCenter" >
                                                                    {
                                                                        scrMode === 'Q' || res.del === 'N' ? null :
                                                                            <>
                                                                                <span className="handCursor colorThemeBlue" onClick={() => deleteItem(res.unique_no)} >
                                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                                </span> {' '}
                                                                            </>
                                                                    }
                                                                </td>
                                                                : null
                                                        }
                                                        <td>{res.prd_isd}</td>
                                                        <td>{res.prd_deno}</td>
                                                        <td>{res.prd_qty}</td>
                                                        <td>{res.prd_rate}</td>
                                                        <td>{res.prd_amount}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </>
                }
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
            </Container>
            <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
                <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
                    {msgTextYN}
                </DialogYesNo>
                <Dialog isOpen={isErrBox} onClose={(e) => setErrBox(false)}>
                    {errText}
                </Dialog>

                <Tran_data_unlock_request logObject={editLogObject} show={showEditRequest} onHide={() => setShowEditRequest(false)} />

            </div>
        </>
    )
}

export default Tran_trf_out_currency;