import React, { useEffect, useState } from 'react'
import Login_header from '../Login_header'
import Menu from '../Menu'
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap'
import AsyncSelect from 'react-select/async';
import * as Picklist from '../Picklist';
import { useNavigate } from 'react-router-dom';
import * as Common from "../Common";
import Dialog from '../Dialog';
import $ from "jquery";
import Select from "react-select";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Tran_data_unlock_request from '../Tran_data_unlock_request';
 

const Tran_opn_tc_settlement = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const branchDisable = sessionStorage.getItem('branchDisable');
    const [selectedValue, setSelectedValue] = useState(null);
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const [deno,setDeno] = useState("");
    const [selectedIssuer, setSelectedIssuer] = useState(null);
    const [userRight, setUserRight] = useState([]);
    const [onceRun, setOnceRun] = useState(false);
    const [tcStList, setTcStList] = useState([]);
    const [location, setLocation] = useState("");
    const [locationCode, setLocationCode] = useState("");
    const [showLocationForm, setLocationForm] = useState(true);
    const [showAddForm, setAddForm] = useState(false);
    const [quantity, setQuantity] = useState("");
    const [rate, setRate] = useState("");
    const [amount, setAmount] = useState("");
    const [tcFrom, setTcFrom] = useState("");
    const [tcTo, setTcTo] = useState("");
    const [currencyCode, setCurrencyCode] = useState("");
    const [currency, setCurrency] = useState("");
    const [currencyType, setCurrencyType] = useState("1");
    const [prefix, setPrefix] = useState("");
    const [groupCode, setGroupCode] = useState("");
    const [accountCode, setAccountCode] = useState("");


    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    const [isErrBox, setErrBox] = useState(false);
    const [errText, setErrText] = useState("");

    const [viewDate, setViewDate] = useState('');
    const currentDate = new Date();

    const [showEditRequest, setShowEditRequest] = useState(false);
    const [editLogObject, setEditLogObject] = useState({ trantype: "", trannumber: "", location: "", entity_name: "", trandate: "", tdu_status: "" });


    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.getActiveBranch().then(response => {
                setSelectedValue(response);
                response != null && handleChange(response);
            });
            Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_OPN_TC_SETTLEMENT'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);


    var msg = [], i = 0;
    const list = () => {
        if (selectedValue == null) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (selectedValue === null ? " Please select location." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        }
        else {
            setLocationForm(false)
            const loc = selectedValue.id.split('^');
            console.log(selectedValue);
            setLocationCode(loc[1]);
            setLocation(loc[2]);
            Common.callApi(Common.apiTranOpnTcSt, [sid, 'listOpnTc', loc[1]], (result) => {
                let resp = JSON.parse(result);
                console.log(resp);
                setTcStList(resp);
            });
        }
    }


    const addCurrencyTcSt = () => {

        if (deno === "" || selectedCurrency === null || rate === "" || quantity == "" || tcFrom == "" || selectedIssuer == null) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (deno === "" ? " Please fill deno." : '');
            msg[i++] = (selectedCurrency === null ? " Please select currency." : '');
            msg[i++] = (rate === "" ? " Please fill rate." : '');
            msg[i++] = (quantity === "" ? " Please fill quantity." : '');
            msg[i++] = (tcFrom === "" ? " Please fill from." : '');
            msg[i++] = (selectedIssuer === null ? " Please fill issuer." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            $('.loader').show();
            let obj = {};
            let grp = selectedIssuer.id.split('^');
            setGroupCode(grp[1]);
            setAccountCode(grp[2]);

            let curr = selectedCurrency.id.split('^');
            console.log(curr);
            setCurrencyCode(curr[1]);
            setCurrency(curr[2]);
            obj = {
                deno:deno,
                qty: quantity,
                rate:rate,
                amount:amount,
                from: tcFrom,
                to: tcTo,
                loc: locationCode,
                currency: curr[1],
                
                productType: 'OPNTCST',
                product: 'TCST',
                prefix: prefix,
                groupCode: grp[1],
                accountCode: grp[2]
            }
            Common.callApi(Common.apiTranOpnTcSt, [sid, 'addOpnTc', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                console.log(resp);
                setTcStList(resp)
                $('.loader').hide();
                setRate("");
                setAmount("");
                setPrefix("");
                setDeno("");
                setQuantity("");
                setTcFrom("");
                setTcTo("");
                setSelectedCurrency("");
                setSelectedIssuer("");
            });
            const object = {
                srno: 1,
                type: 'OPNTCST',
                status: 'E',
                currentDate: Common.dateYMD(currentDate),
                pageright: 'TRAN_OPN_TC_SETTLEMENT'
            }
            Common.callApi(Common.apiUnlockEdit, [sid, 'expireUnlock', JSON.stringify(object)], (result) => {
                console.log(result);
            })
        }
    }

    const handleChange = value => {
        setSelectedValue(value);
        var id = value.id;
        var location = id.split("^");
        sessionStorage.activeBranch = location[1];
    }

    const handleCurrencyChange = value => {
        setSelectedCurrency(value);
    }

    const handleIssuerChange = value => {
        setSelectedIssuer(value);
    }

    const handleBack = () => {
        if (showAddForm) {
            setAddForm(false);
        } else {
            setLocationForm(true);
        }
    }

    const handleFrom = (e) => {
        Common.validateNumValue(e.target.value, setTcFrom)
        if (e.target.value == "" || quantity == "") {
            setTcTo("");
        } else {
            setTcTo(1 * e.target.value - 1 + (1 * (quantity)));
        }
    }

    const handleQuantity = (e) => {
        if( e.target.value>0 && e.target.value < 101){
            Common.validateNumValue(e.target.value, setQuantity);
            if (tcFrom == "" || e.target.value == "") {
                setTcTo("");
            } else {
                setTcTo(1 * tcFrom - 1 + (1 * (e.target.value)));
            }
            calculateAmount(deno, e.target.value, rate)
        }else{
            setErrText("Enter quantity greater than 0 and less than 101");
                setErrBox(true);
                setQuantity("");
        }
       

    }

    const deleteItem = (id) => {
        Common.callApi(Common.apiTranOpnTcSt, [sid, 'deleteOpnTc', id, locationCode], (result) => {
            let resp = JSON.parse(result);
            console.log(resp);
            if (resp.msg == '') {
                setTcStList(resp.optrial);
            }
            else {
                setErrText(resp.msg);
                setErrBox(true);
            }
        });
    }


    const calculateAmount = (deno, qty, rate) => {
        let number = deno * qty * rate;
        var roundedNumber = parseFloat(number.toFixed(2));

        setAmount(roundedNumber);
    }

    const handleDeno = (e) => {

        Common.validateDecValue(e.target.value, setDeno)

        // setDenomination(e.target.value);

        calculateAmount(e.target.value, quantity, rate);
    }

    const handleRate = (e) => {
        Common.validateDecValue(e.target.value, setRate)

        // setRate(e.target.value);
        calculateAmount(deno, quantity, e.target.value)

    }

    const btnEditRequest = () => {
        const currDate = new Date();
        const newDate = viewDate
        // .split('/');

        // const newViewDate = newDate[2] + '-' + newDate[1] + '-' + newDate[0]
        const newCurrDate = Common.dateYMD(currDate);

        const dateA = new Date(newDate);
        const dateB = new Date(newCurrDate);

        // Calculate the difference in milliseconds
        const differenceInMilliseconds = dateB - dateA;

        // Convert milliseconds difference to days
        const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 3600 * 24));

        console.log(differenceInDays);

        const obj = {
            srno: 1,
            type: 'OPNTCST',
            pageright: 'TRAN_OPN_TC_SETTLEMENT'
        }
        // const message = 'unlock'
        Common.callApi(Common.apiUnlockEdit, [sid, 'checkUnlock', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);


            if (Common.editDateLimit == differenceInDays || resp == "unlock") {
                setAddForm(true);
            } else {
                let res;
                Common.callApi(Common.apiUnlockEdit, [sid, 'getStatus', JSON.stringify(obj)], (result) => {
                    res = JSON.parse(result);
                    setEditLogObject({ trantype: 'OPNTCST', trannumber: 1, location: locationCode, entity_name: location, tranDate: Common.dateYMD(currDate), tdu_status: res });
                    setShowEditRequest(true);
                })
            }
        })


    }

  return (
    <div>
        <Login_header />
        <Menu />
        <Container fluid>
            <Row>
                <Col>
                    <h2>TC SETTLEMENT</h2>
                </Col>
            </Row>
            &nbsp;

            {
                showLocationForm ? <>
                    <Row>
                        <Col className='col-md-3 col-6'>
                            <Form.Group>
                                <Form.Label>Location<span className="text-danger">*</span></Form.Label>
                                <AsyncSelect
                                    cacheOptions={false}
                                    defaultOptions={false}
                                    value={selectedValue}
                                    getOptionLabel={e => e.label + ' '}
                                    getOptionValue={e => e.id}
                                    loadOptions={Picklist.fetchLocationAllowedPicklist}
                                    onInputChange={Picklist.handleInputChange}
                                    onChange={handleChange}
                                    isDisabled={branchDisable === "true"}
                                >
                                </AsyncSelect>
                            </Form.Group>
                        </Col>
                    </Row>
                    &nbsp;
                    <Row>
                        <Col className='col-md-3 col-6'>
                            {
                                userRight.query === "1" ?
                                    <Button variant='outline-success' className='buttonStyle' size='sm' onClick={list}>List</Button>
                                    : null}
                        </Col>
                    </Row>
                    &nbsp;
                </> : <>
                    <Row>
                        <Col className='col-md-3 col-6'>
                            <Form.Label>Location : {location}</Form.Label>
                        </Col>
                    </Row>
                    &nbsp;
                    <Row>
                        <Col>
                            <Button variant='outline-danger' className='buttonStyle' size='sm' onClick={handleBack}>Back</Button>{' '}
                            {
                                userRight.add === "1" && !showAddForm ?
                                    <>
                                        <Button variant='outline-success' className='buttonStyle' size='sm'
                                            onClick={() => btnEditRequest()}
                                        >Edit</Button>
                                    </>
                                    : null
                            }
                        </Col>
                    </Row>
                    &nbsp;

                </>
            }

            {
                showAddForm &&
                <>
                    <Row>
                        <Col className='col-md-3 col-6'>
                            <Form.Group>
                                <Form.Label>Currency<span className="text-danger">*</span></Form.Label>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    value={selectedCurrency}
                                    getOptionLabel={e => e.label + ' '}
                                    getOptionValue={e => e.id}
                                    loadOptions={(search) => Picklist.fetchCurrencyAllowedPicklist(search, 'TCST')} // location = 1 , 2
                                    onInputChange={Picklist.handleInputChange}
                                    onChange={handleCurrencyChange}
                                >
                                </AsyncSelect>
                            </Form.Group>
                        </Col>
                        <Col className='col-md-3 col-6'>
                            <Form.Group>
                                <Form.Label>Denomination<span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    value={deno}
                                    onChange={(e) => handleDeno(e)}
                                    maxLength={Common.maximumLength}
                                    placeholder="Deno" />
                            </Form.Group>
                        </Col>
                        <Col className='col-md-3 col-6'>
                            <Form.Group>
                                <Form.Label>Issuer<span className="text-danger">*</span></Form.Label>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    value={selectedIssuer}
                                    getOptionLabel={e => e.label + ' '}
                                    getOptionValue={e => e.id}
                                    loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 'ISSUER', locationCode)}
                                    onInputChange={Picklist.handleInputChange}
                                    onChange={handleIssuerChange}
                                >
                                </AsyncSelect>
                            </Form.Group>
                        </Col>
                        <Col className='col-md-3 col-6'>
                            <Form.Group>
                                <Form.Label>Rate<span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    value={rate}
                                    onChange={(e) => handleRate(e)}
                                    maxLength={Common.maximumLength}
                                    placeholder="Rate" />
                            </Form.Group>
                        </Col>
                        <Col className='col-md-3 col-6'>
                            <Form.Group>
                                <Form.Label>Quantity<span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    value={quantity}
                                    onChange={(e) => handleQuantity(e)}
                                    maxLength={Common.maximumLength}
                                    placeholder="Quantity" />
                            </Form.Group>
                        </Col>
                        <Col className='col-md-3 col-6'>
                            <Form.Group>
                                <Form.Label>Amount<span className="text-danger"></span></Form.Label>
                                <Form.Control
                                    value={amount}
                                    maxLength={Common.maximumLength}
                                    disabled
                                    placeholder="Amount" />
                            </Form.Group>
                        </Col>
                        <Col className='col-md-3 col-6'>
                            <Form.Group>
                                <Form.Label>Prefix</Form.Label>
                                <Form.Control
                                    value={prefix}
                                    onChange={(e) => Common.validateAlpNumValue(e.target.value, setPrefix)}
                                    maxLength={Common.prefixLength}
                                    placeholder="Prefix" />
                            </Form.Group>
                        </Col>
                        <Col className='col-md-3 col-6'>
                            <Form.Group>
                                <Form.Label>From<span className="text-danger">*</span></Form.Label><br />
                                <Form.Control
                                    value={tcFrom}
                                    onChange={(e) => handleFrom(e)}
                                    maxLength={Common.maxFromLength}
                                    placeholder="From" />
                            </Form.Group>
                        </Col>
                        <Col className='col-md-3 col-6'>
                            <Form.Group>
                                <Form.Label>To</Form.Label>
                                <Form.Control
                                    value={tcTo}
                                    disabled
                                    placeholder="To" />
                            </Form.Group>
                        </Col>
                    </Row>
                    &nbsp;
                    <Row>
                        <Col>
                            <Button variant='outline-success' className='buttonStyle' size='sm' onClick={addCurrencyTcSt} >Add</Button>{' '}
                        </Col>
                    </Row>
                    &nbsp;
                </>

            }

            {!showLocationForm &&
                <Row>
                    <Col>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr>
                                    {userRight.edit === "1" ? <th></th> : null}
                                    <th>ISD</th>
                                    <th>Prefix</th>
                                    <th>Issuer Name</th>
                                    <th style={{ textAlign: 'right' }}>Deno</th>
                                    <th style={{ textAlign: 'right' }}>Quantity</th>
                                    <th style={{ textAlign: 'right' }}>Rate</th>
                                    <th style={{ textAlign: 'right' }}>Amount</th>
                                    <th style={{ textAlign: 'right' }}>From</th>
                                    <th style={{ textAlign: 'right' }}>To</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tcStList.map((res) =>
                                    <tr key={res.prd_line_no}>
                                        {userRight.delete === "1" ?
                                            <td className="textCenter" >
                                                {
                                                    showAddForm &&
                                                    <span className="handCursor" onClick={() => deleteItem(res.line)} >
                                                        <div className="colorThemeBlue"><FontAwesomeIcon icon={faTrash} /></div>
                                                    </span>
                                                }
                                            </td>
                                            : null
                                        }
                                        <td>{res.prd_isd}</td>
                                        <td>{res.prd_prefix}</td>
                                        <td>{res.mvli_ledger_name}</td>
                                        <td style={{ textAlign: 'right' }}>{res.prd_deno}</td>
                                        <td style={{ textAlign: 'right' }}>{res.prd_qty}</td>
                                        <td style={{ textAlign: 'right' }}>{res.prd_rate}</td>
                                        <td style={{ textAlign: 'right' }}>{res.prd_amount}</td>
                                        <td style={{ textAlign: 'right' }}>{res.prd_from}</td>
                                        <td style={{ textAlign: 'right' }}>{res.prd_to}</td>
                                    </tr>
                                )
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>

            }
        </Container>

        <div>
            <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                {msgText}
            </Dialog>
            <Dialog isOpen={isErrBox} onClose={(e) => setErrBox(false)}>
                {errText}
            </Dialog>
            <Tran_data_unlock_request logObject={editLogObject} show={showEditRequest} hdrNotExist={true} onHide={() => setShowEditRequest(false)} />
        </div>
    </div>
)
}

export default Tran_opn_tc_settlement
