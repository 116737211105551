import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Table, Form, Container, Col, Row } from 'react-bootstrap';

import Login_header from '../../Login_header';
import Menu from '../../Menu';
import Select from "react-select";
import * as Common from '../../Common';
import DialogYesNo from '../../Dialog_Yes_No';
import Dialog from '../../Dialog';

function Master_voucher_type() {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');

    const [onceRun, setOnceRun] = useState(false);
    const [userRight, setUserRight] = useState([]);
    const [voucherData, setVoucherData] = useState([]);
    const [visiblePage, setVisiblePage] = useState("");
    const [vtSrno, setVtSrno] = useState("");
    const [vtType, setVtType] = useState("");
    const [vtDesc, setVtDesc] = useState("");
    const [vtManual, setVtManual] = useState("1");
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");
    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");

    const [vtRemark, setVtRemark] = useState("");



    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.callApi(Common.apiRight, [sid, 'all', 'VOUCHERTYPE'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/'); // no query right
                }
            });

            // Common.callApi(Common.apiType, [sid, 'getList'], (result) => {
            //     let resp = JSON.parse(result);
            //     setVoucherData(resp);
            // });
            showList();
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);


    const showList = () => {
        Common.callApi(Common.apiVoucherType, [sid, 'getList'], (result) => {
            let resp = JSON.parse(result);
            setVoucherData(resp);
        });
    }

    const addNew = () => {
        setVisiblePage("A");
        setVtSrno(0);
        setVtType("");
        setVtDesc("");
    }

    const saveData = () => {
        var obj = {
            srNo: vtSrno,
            type: vtType,
            desc: vtDesc,
            manual: vtManual
        }
        var msg = [], i = 0;
        if (vtType === "" || vtDesc === "") {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (vtType === "" ? " Please fill Type." : '');
            msg[i++] = (vtDesc === "" ? " Please select Description." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            Common.callApi(Common.apiVoucherType, [sid, "savevtype", JSON.stringify(obj)], result => {
                setVisiblePage("");
                showList();

            });
        }

    }

    const cancelConfirm = () => {
        if (visiblePage === "A") {
            setMsgTextYN('Do you really want to exit ?');
            setMsgBoxYN(true);
        } else {
            cancelData();
        }
    }

    const cancelData = () => {
        setMsgBoxYN(false);
        setVisiblePage("");

    }

    return (
        <>
            <Login_header />
            <Menu />

            <Container fluid>
                <Row>
                    <Col>
                        <h1> Voucher Type </h1>
                    </Col>
                </Row>

                <Row>&nbsp;</Row>
                {
                    visiblePage === "" ? <>
                        <Row>
                            <Col>
                                {userRight.add === "1" ?
                                    <Button className='buttonStyle' size='sm' variant='outline-primary' onClick={() => addNew()}>Add</Button>
                                    : null}
                            </Col>
                        </Row>
                        <Row>&nbsp;</Row>
                        <Row>
                            <Col>
                                <Table responsive striped bordered>
                                    <thead>
                                        <tr>
                                            <th>Type </th>
                                            <th>Description</th>
                                            <th>Manual</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            voucherData.map((val) => (
                                                <tr>
                                                    <td>{val.mvt_type}</td>
                                                    <td>{val.mvt_description}</td>
                                                    <td>{val.mvt_manual == 1 ? "ACTIVE" : "INACTIVE"}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </> :
                        <>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Srno</Form.Label>
                                        <Form.Control disabled={true} value={vtSrno} onChange={e => setVtSrno(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Type<span className="mandatory text-danger">*</span></Form.Label>
                                        <Form.Control type='text' maxLength={80} value={vtType} onChange={e => setVtType(e.target.value)}
                                            disabled={visiblePage === 'Q'} />
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Description<span className="mandatory text-danger">*</span></Form.Label>
                                        <Form.Control type='text' maxLength={80} value={vtDesc} onChange={e => setVtDesc(e.target.value)}
                                            disabled={visiblePage === 'Q'} />
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Manual</Form.Label>
                                        <Form.Select value={vtManual}
                                            disabled={visiblePage === 'Q'}
                                            onChange={e => setVtManual(e.target.value)}>
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>

                            <Row>
                                <Col>
                                    <Button variant="outline-danger" className='buttonStyle' size="sm" onClick={() =>
                                        cancelConfirm()}>Back</Button>&nbsp;&nbsp;
                                    {
                                        (userRight.add === "1" && visiblePage === 'A') ? <>
                                            <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() =>
                                                saveData()}>Save</Button>&nbsp;
                                        </> : null
                                    }
                                </Col>
                            </Row>
                        </>
                }

            </Container>
            <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
                <DialogYesNo isOpen={isMsgBoxYN} onClose={(e) => setMsgBoxYN(false)} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
                    {msgTextYN}
                </DialogYesNo>
            </div>
        </>
    )
}

export default Master_voucher_type;
