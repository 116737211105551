import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Table, Form, Button, Modal } from 'react-bootstrap'
import * as Common from "../../Common";
import Select from "react-select";
import Dialog from '../../Dialog';
import $ from "jquery";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

function Master_ledger_documents(props) {
    const sid = sessionStorage.getItem("sessionId");
    const [documentOptions, setDocumentOptions] = useState([]);
    const [fileName, setFileName] = useState("");
    const [document, setDocument] = useState({ value: 0, label: "Select" });

    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    const [documentList, setDocumentList] = useState([]);
    const [onceRun, setOnceRun] = useState(false);

    const [showDocument, setShowDocument] = useState(false);
    const [documentToView, setDocumentToView] = useState("");
    const [documentTypeToView, setDocumentTypeToView] = useState("");
    const [documentDescToView, setDocumentDescToView] = useState("");
    const [documentNameToView, setDocumentNameToView] = useState("");

    useEffect(() => {
        setDocumentList(props.ledgerDocument);
        Common.callApi(Common.apiLedger, [sid, "getalldocs", props.listType], result => {
            setDocumentOptions(JSON.parse(result));
        });

    }, [props.ledgerDocument]);

    console.log(props.ledgerDocument);
    const documentUpload = () => {
        // if (document.getElementById('uploadGroupDoc').files[0] === undefined) {
        //     $('.loader').hide();
        //     setMsgText("Please select a file to upload.");
        //     setMsgBox(true);
        // } else {
        //     var tempArray = document.getElementById('uploadGroupDoc').files[0].name.split('.');
        //     if (tempArray.length > 2) {
        //         $('.loader').hide();
        //         setMsgText("(.) not allowed in file name.");
        //         setMsgBox(true);
        //     } else if (document.getElementById('uploadGroupDoc').files[0].size > 10 * 1024 * 1024) {  // 10 MB
        //         $('.loader').hide();
        //         setMsgText("Please select a file with in 10 MB size.");
        //         setMsgBox(true);
        //     } else {
        const object1 = {
            uploadType: "linkdocument",
            pageright: "VOUCHERLEDGER",
            sid: sid,
            docsrno: document.value,
            ldgsrno: props.ldgsrno,
            ldggrpsrno: props.ldggrpsrno,
            groupType: props.listType
        }
        Common.uploadApi(JSON.stringify(object1), 'uploadGroupDoc', result => {
            console.log(result);
            let resp = JSON.parse(result);
            setDocumentList(resp.docdetail);
        });
        //     }
        // }
    }

    const viewDoc = (lineno, filename, ext) => {
        const obj = {
            name: "ledgerdoc",
            lineno: lineno,
            filename: filename,
            pageright: "VOUCHERLEDGER"
        }
        Common.callDocumentViewApi(Common.apiDocument, [sid, "docView", JSON.stringify(obj)], function (result) {
            let resp = JSON.parse(result);
            $('.loader').hide();
            setDocumentToView(resp.bs64);
            setDocumentTypeToView(resp.typ);
            setDocumentDescToView(resp.desc);
            setDocumentNameToView(resp.fname);
            setShowDocument(true);
        });
    }

    const downloadFile = (lineno, filename, ext) => {
        const obj = {
            name: "ledgerdoc",
            lineno: lineno,
            filename: filename,
            pageright: "VOUCHERLEDGER"
        }
        Common.callDownloadApiPost(Common.apiDocument, "post", [sid, 'docdownload', JSON.stringify(obj)]);
    }

    return (
        <>
            <Container fluid>
                {
                    props.visiblePg === "Q" ? null :
                        <Row>
                            <Col className="col-md-3 col-6">
                                <Form.Group>
                                    <Form.Label>Document<span className="mandatory text-danger">*</span></Form.Label>
                                    <Select options={documentOptions} value={document} onChange={v => setDocument(v)} />
                                </Form.Group>
                            </Col>
                            {
                                document.value != 0 ?
                                    <Col className="col-md-3 col-6">
                                        <Form.Group controlId='uploadGroupDoc'>
                                            <Form.Label>File<span className="mandatory text-danger">*</span> MAX(10MB)</Form.Label>
                                            <Form.Control type='file' value={fileName} onChange={e => setFileName(e.target.value)} />
                                        </Form.Group>
                                    </Col> : null
                            }
                            <Col style={{ marginTop: "33px" }}>
                                <Button variant='outline-success' className='buttonStyle' onClick={() => documentUpload()}>Add</Button>
                            </Col>
                        </Row>
                }
                <Row>&nbsp;</Row>
                <Row>
                    <Col>
                        <Table responsive striped bordered>
                            <thead>
                                <tr>
                                    <th>Document</th>
                                    <th>File Name</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    documentList.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.docm_desc}</td>
                                            <td>{item.mvld_filename}</td>
                                            <td>
                                                {
                                                    item.mvld_ext == "png" || item.mvld_ext == "pdf" || item.mvld_ext == "jpg" || item.mvld_ext == "jpeg" || item.mvld_ext == "jfif" || item.mvld_ext == "gif" || item.mvld_ext == "bmp" ? (
                                                        <span className="colorThemeBlue" onClick={() => viewDoc(item.mvld_lineno, item.mvld_filename, item.mvld_ext)}>
                                                            <FontAwesomeIcon icon={faEye} />
                                                        </span>
                                                    ) : null
                                                }
                                                &nbsp;
                                                < span className='colorThemeBlue' onClick={() => downloadFile(item.mvld_lineno, item.mvld_filename, item.mvld_ext)}>
                                                    <FontAwesomeIcon icon={faDownload} />
                                                </span>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container >

            <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
            </div>
            <Modal show={showDocument} onHide={() => setShowDocument(false)} size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {documentDescToView.toUpperCase() + " : " + documentNameToView}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <embed src={documentToView} type={documentTypeToView} style={{ minHeight: "100vh", minWidth: "100%" }} ></embed>
                    {
                        /*
                        type="image/png" type="application/pdf"
                        width="100%" height="100%"
                        
                        <object data={documentToView} type={documentTypeToView} class="internal">
            
                        </object>
                        */
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Master_ledger_documents
