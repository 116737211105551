import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form, Button, Table, Modal } from 'react-bootstrap'
import Login_header from '../Login_header';
import Menu from '../Menu';
import AsyncSelect from 'react-select/async';
import * as Picklist from '../Picklist';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import Dialog from '../Dialog';
import DialogYesNo from '../Dialog_Yes_No';
import * as Common from '../Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Tran_data_unlock_request from '../Tran_data_unlock_request';


const Tran_trf_vtc = () => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const startDate = sessionStorage.getItem('startDate');
    const endDate = sessionStorage.getItem('endDate');
    const branchDisable = sessionStorage.getItem('branchDisable');

    const [userRight, setUserRight] = useState([]);
    const [onceRun, setOnceRun] = useState(false);
    const [scrMode, setScrMode] = useState("");

    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");
    const [isErrBox, setErrBox] = useState(false);
    const [errText, setErrText] = useState("");
    const [showModal, setShowModal] = useState(false);

    const [vtcLocation, setVtcLocation] = useState(null);
    const [location, setLocation] = useState("");
    const [locationCode, setLocationCode] = useState(0);

    const [radioType, setRadioType] = useState("N");
    const [trfDate, setTrfDate] = useState(new Date());
    const [trfFromDate, setTrfFromDate] = useState(new Date());
    const [trfToDate, setTrfToDate] = useState(new Date());
    const [trfFromSrno, setTrfFromSrno] = useState();
    const [trfToSrno, setTrfToSrno] = useState();

    const [innerData, setInnerData] = useState([]);
    const [headerData, setHeaderData] = useState([]);
    const [subInnerData, setSubInnerData] = useState([]);

    const [issuer, setIssuer] = useState(null);
    const [trfQuantity, setTrfQuantity] = useState("");
    const [trfFrom, setTrfFrom] = useState("");
    const [trfTo, setTrfTo] = useState("");
    const [trfPrefix, setTrfPrefix] = useState("");
    const [currency, setCurrency] = useState(null);
    const [currencyType, setCurrencyType] = useState("1");
    const [groupCode, setGroupCode] = useState("");
    const [accountCode, setAccountCode] = useState("");
    const [currencyCode, setCurrencyCode] = useState("");
    const [currencyName, setCurrencyName] = useState("");
    const [srNo, setSrNo] = useState(0);
    const [lineNo, setLineNo] = useState(0);
    const [issuerName, setIssuerName] = useState("");
    const [counter, setCounter] = useState(0);

    const [viewSrno, setViewSrno] = useState("");
    const [viewDate, setViewDate] = useState("");

    const [showEditRequest, setShowEditRequest] = useState(false);
    const [editLogObject, setEditLogObject] = useState({ trantype: "", trannumber: "", trancategory: "", tranDate: "" });
    // const [srno, setSrno] = useState(0);

    const currentDate = new Date();

    var msg = [], i = 0;



    useEffect(() => {

        if (onceRun) {
            return;
        } else {
            Common.getActiveBranch().then(response => {
                setVtcLocation(response);
                console.log("sun re bhai tere response hai", response);
                response != null && handleChange(response);
            });

            Common.callApi(Common.apiRight, [sid, 'all', 'TRAN_TRF_VTC'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);


    const handleChange = (value) => {
        setVtcLocation(value);
        const loc = value.id.split('^');
        setLocationCode(loc[1]);
        setLocation(loc[2]);
        sessionStorage.activeBranch = loc[1];
    }


    const handleCurrencyChange = value => {
        setCurrency(value);
        let curr = value.id.split('^');
        setCurrencyCode(curr[1]);
        setCurrencyName(curr[2]);
    }


    const handleIssuerChange = (value) => {
        console.log(value);
        setIssuer(value);
        const iss = value.id.split('^');
        setGroupCode(iss[1]);
        setAccountCode(iss[2]);
        setIssuerName(iss[5]);
    }


    const handleQuantity = (e) => {
        Common.validateNumValue(e.target.value, setTrfQuantity);
        if (trfFrom === "" || e.target.value === "") {
            setTrfTo("");
        } else {
            setTrfTo(1 * trfFrom - 1 + (1 * (e.target.value)));
        }
    }


    const handleFrom = (e) => {
        Common.validateNumValue(e.target.value, setTrfFrom)
        if (e.target.value === "" || trfQuantity === "") {
            setTrfTo("");
        } else {
            setTrfTo(1 * e.target.value - 1 + (1 * (trfQuantity)));
        }
    }


    const list = () => {
        var msg = [], i = 0;
        if (vtcLocation === null || (radioType === 'S' && !trfFromSrno) || (radioType === 'S' && !trfToSrno) ||
            (radioType === 'D' && !trfFromDate) || (radioType === 'D' && !trfToDate)) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (vtcLocation === null ? " Location." : '');
            msg[i++] = (radioType === 'S' && !trfFromSrno ? " From Srno." : '');
            msg[i++] = (radioType === 'S' && !trfToSrno ? " To Srno." : '');
            msg[i++] = (radioType === 'D' && !trfFromDate ? " From Date." : '');
            msg[i++] = (radioType === 'D' && !trfToDate ? " To Date." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            console.log(vtcLocation);
            const obj = {
                radioType: radioType,
                location: locationCode,
                fromDate: Common.dateYMD(trfFromDate),
                toDate: Common.dateYMD(trfToDate),
                fromSrno: trfFromSrno,
                toSrno: trfToSrno,
            }
            Common.callApi(Common.apiTranTRF, [sid, 'list', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                setHeaderData(resp);
            });
        }
    }

    const addNew = () => {
        var date = Common.dateYMD(trfDate);
        if (!vtcLocation || !trfDate) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (!vtcLocation ? " Location." : '');
            msg[i++] = (!trfDate ? " Date." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (date > endDate || date < startDate) {
            setMsgBox(true);
            setMsgText(Common.getMessage("MSG0010"));
        } else {
            setScrMode("A");
            setInnerData([]);
            setSrNo(0);
        }
    }


    const addDetail = () => {
        var msg = [], i = 0;
        if (trfQuantity === "" || trfFrom === "" || issuer === null) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (trfQuantity === "" ? " Quantity." : '');
            msg[i++] = (trfFrom === "" ? " From." : '');
            msg[i++] = (issuer === null ? " Issuer." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else if (trfQuantity >= 101) {
            setMsgText("Quantity should be less than 101");
            setMsgBox(true);
        } else if (currencyType === "1" && currency === null) {
            setMsgText("Please Select Currency!");
            setMsgBox(true);
        } else {
            const obj = {
                unique_no: Common.getRandomString(5),
                prd_qty: trfQuantity,
                prd_from: trfFrom,
                prd_to: trfTo,
                prd_location: locationCode,
                prd_issuername: issuerName,
                prd_isd: (currencyType === "1") ?
                    currencyCode : '',
                prd_date: Common.dateYMD(trfDate),
                prd_type: 'VTCIN',
                prd_prefix: trfPrefix,
                prd_product: 'VTC',
                prd_group: groupCode,
                prd_account: accountCode,
                prd_line_no: 1 * lineNo + 1,
                del: "Y",
            };
            setInnerData(Common.arrayAddItem(innerData, obj));
            console.log(innerData);
            setLineNo(lineNo + 1);
            setCurrency(null);
            setIssuer(null);
            setTrfQuantity("");
            setTrfFrom("");
            setTrfPrefix("");
            setTrfTo("");
        }
    }


    const deleteItem = (value) => {
        setInnerData(Common.arrayRemoveItem(innerData, "unique_no", value));
        setCounter(counter + 1);
    }


    const saveData = () => {
        console.log(locationCode);
        if (innerData.length < 1) {
            setMsgText("Empty List!");
            setMsgBox(true);
        } else {
            const obj = {
                group: groupCode,
                account: accountCode,
                location: locationCode,
                srno: srNo,
                date: Common.dateYMD(trfDate),
                data: innerData,

                radioType: radioType,
                location: locationCode,
                fromDate: Common.dateYMD(trfFromDate),
                toDate: Common.dateYMD(trfToDate),
                fromSrno: trfFromSrno,
                toSrno: trfToSrno,
            }
            Common.callApi(Common.apiTranTRF, [sid, 'save', JSON.stringify(obj)], (result) => {
                let resp = JSON.parse(result);
                setHeaderData(resp);
                // setVtcLocation(null);
                setInnerData([]);
                setScrMode("");
            });

            if (scrMode == 'E') {
                const obj = {
                    srno: srNo,
                    type: 'VTCIN',
                    status: 'E',
                    currentDate: Common.dateYMD(currentDate),
                    pageright: 'TRAN_TRF_VTC'
                }
                Common.callApi(Common.apiUnlockEdit, [sid, 'expireUnlock', JSON.stringify(obj)], (result) => {
                    console.log(result);
                })

            }
        }
    }



    const viewList = (hdrSrno, location, type, vdate) => {
        setSrNo(hdrSrno);
        setScrMode("Q");
        setViewSrno(hdrSrno);
        setViewDate(vdate);
        const obj = {
            srno: hdrSrno,
            location: location,
            type: type,
        }
        Common.callApi(Common.apiTranTRF, [sid, 'view', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);
            if (resp.msg === 'MSG0000') {
                setInnerData(resp.trfList);
                setLineNo(resp.maxlineno);
                setLocation(resp.locationname);
                setAccountCode(resp.ledgercode);
                setGroupCode(resp.groupcode);
                setIssuerName(resp.ledgername);
                setSrNo(resp.srno);
            }
        });
    }


    const viewDetails = (srno, lineno, location, type) => {
        const obj = {
            srno: srno,
            lineno: lineno,
            location: location,
            type: type,
        }
        Common.callApi(Common.apiTranTRF, [sid, 'viewDtls', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);
            setSubInnerData(resp);
        });
        setShowModal(true);
    }


    const cancelConfirm = () => {
        if (scrMode === "A" || scrMode === "E") {
            setMsgTextYN('Do you really want to exit ?');
            setMsgBoxYN(true);
        } else {
            cancelData();
        }
    }

    const cancelData = () => {
        setMsgBoxYN(false);
        setScrMode("");
    }

    const btnEditRequest = () => {
        const currDate = new Date();
        const newDate = viewDate.split('/');

        const newViewDate = newDate[2] + '-' + newDate[1] + '-' + newDate[0]
        const newCurrDate = Common.dateYMD(currDate);




        const dateA = new Date(newViewDate);
        const dateB = new Date(newCurrDate);

        // // Calculate the difference in milliseconds
        const differenceInMilliseconds = dateB - dateA;

        console.log(differenceInMilliseconds);

        // // Convert milliseconds difference to days
        const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 3600 * 24));

        console.log(differenceInDays);

        const obj = {
            srno: viewSrno,
            type: 'VTCIN',
            pageright: 'TRAN_TRF_VTC'
        }
        // const message = 'unlock'
        Common.callApi(Common.apiUnlockEdit, [sid, 'checkUnlock', JSON.stringify(obj)], (result) => {
            let resp = JSON.parse(result);

            if (Common.editDateLimit == differenceInDays || resp == "unlock") {
                setScrMode('E');

            } else {
                setShowEditRequest(true);
                setEditLogObject({ trantype: 'VTCIN', trannumber: viewSrno, location: locationCode, tranDate: Common.dateYMD(currDate) });
            }
        })


    }


    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h2>Transfer In VTC</h2>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                {
                    scrMode === "" ?
                        <>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Location<span className="text-danger">*</span></Form.Label>
                                        <AsyncSelect
                                            cacheOptions={false}
                                            defaultOptions={false}
                                            value={vtcLocation}
                                            getOptionLabel={e => e.label + ' '}
                                            getOptionValue={e => e.id}
                                            loadOptions={Picklist.fetchLocationAllowedPicklist}
                                            onInputChange={Picklist.handleInputChange}
                                            onChange={handleChange}
                                            isDisabled={branchDisable === "true"}
                                        >
                                        </AsyncSelect>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-4 col-6'>
                                    <Form.Group>
                                        <Row style={{ marginTop: "30px" }}>
                                            <Col>
                                                <Form.Check checked={radioType === "N"} onChange={() => setRadioType("N")} type='radio' label="New" />
                                            </Col><Col>
                                                <Form.Check checked={radioType === "S"} onChange={() => setRadioType("S")} type='radio' label="SrNo" />
                                            </Col><Col>
                                                <Form.Check checked={radioType === "D"} onChange={() => setRadioType("D")} type='radio' label="Date" />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                {
                                    radioType === 'N' ?
                                        <>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Date<span className="text-danger">*</span></Form.Label>
                                                    <DatePicker className="form-control"
                                                        selected={trfDate}
                                                        minDate={new Date(startDate)}
                                                        maxDate={new Date(endDate)}
                                                        onChange={(date) => setTrfDate(date)}
                                                        dateFormat="dd/MM/yyyy"
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        useShortMonthInDropdown
                                                        dropdownMode="select"
                                                        peekNextMonth
                                                        customInput={
                                                            <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </>
                                        :
                                        radioType === 'S' ?
                                            <>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>From<span className="text-danger">*</span></Form.Label>
                                                        <Form.Control value={trfFromSrno}
                                                            maxLength={20}
                                                            onChange={(e) => Common.validateNumValue(e.target.value, setTrfFromSrno)}
                                                            placeholder="From" />
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>To<span className="text-danger">*</span></Form.Label>
                                                        <Form.Control value={trfToSrno}
                                                            maxLength={20}
                                                            onChange={(e) => Common.validateNumValue(e.target.value, setTrfToSrno)}
                                                            placeholder="To" />
                                                    </Form.Group>
                                                </Col>
                                            </>
                                            :
                                            radioType === 'D' ?
                                                <>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>From<span className="colorThemeRed">*</span></Form.Label>
                                                            <DatePicker className="form-control"
                                                                selected={trfFromDate}
                                                                onChange={(date) => setTrfFromDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group>
                                                            <Form.Label>To<span className="colorThemeRed">*</span></Form.Label>
                                                            <DatePicker className="form-control"
                                                                selected={trfToDate}
                                                                onChange={(date) => setTrfToDate(date)}
                                                                dateFormat="dd/MM/yyyy"
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                useShortMonthInDropdown
                                                                dropdownMode="select"
                                                                peekNextMonth
                                                                customInput={
                                                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </>
                                                :
                                                null
                                }
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    {
                                        userRight.add === "1" && radioType === 'N' ?
                                            <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addNew()}>Add New</Button> : null
                                    }
                                    &nbsp;
                                    {
                                        userRight.query === "1" && (radioType === 'S' || radioType === 'D') ?
                                            < Button variant='outline-success' className='buttonStyle' size='sm' onClick={() => list()}>List</Button>
                                            : null
                                    }
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                {userRight.edit === "1" ? <th></th> : null}
                                                <th>SrNo</th>
                                                <th>Date</th>
                                                <th>Type</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                headerData.map((res) =>
                                                    <tr key={res.hdr_srno}>
                                                        {userRight.edit === "1" ?
                                                            <td className="textCenter">
                                                                <span className="handCursor colorThemeBlue" onClick={() => viewList(res.hdr_srno, res.hdr_location, res.hdr_type, res.hdr_date)}>
                                                                    <FontAwesomeIcon icon={faEye} />
                                                                </span>
                                                            </td>
                                                            : null
                                                        }
                                                        <td>{res.hdr_srno}</td>
                                                        <td>{res.formatted_date}</td>
                                                        <td>{res.hdr_type}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </>
                        :
                        <>
                            <Row>
                                <Col>
                                    <Button variant='outline-danger' className='buttonStyle' size='sm' onClick={() => cancelConfirm()}>Back</Button>{' '}
                                    {
                                        (userRight.edit === "1" && scrMode === "Q") ? <>
                                            <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() => btnEditRequest()}>Edit</Button>
                                        </> : null
                                    }
                                    {
                                        (userRight.edit === "1" && scrMode === "E") || (userRight.add === "1" && scrMode === "A") ? <>
                                            <Button variant='outline-primary' size='sm' className='buttonStyle' onClick={() => saveData()}>Save</Button>
                                        </> : null
                                    }
                                    {/* <Button variant="outline-primary" className='buttonStyle' size="sm" onClick={exportFile}>Export</Button> */}
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Label>Serial Number : {srNo}</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Label>Location : {location}</Form.Label>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            {
                                scrMode === "E" || scrMode === "A" ?
                                    <>
                                        <Row>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Card Type<span className="text-danger">*</span></Form.Label>
                                                    <Form.Select value={currencyType} onChange={e => setCurrencyType(e.target.value)}
                                                        required>
                                                        <option value="1">Single Currency</option>
                                                        <option value="0">Multi Currency</option>
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>

                                            {
                                                currencyType == 1 &&
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Currency<span className="text-danger">*</span></Form.Label>
                                                        <AsyncSelect
                                                            cacheOptions={false}
                                                            defaultOptions={false}
                                                            value={currency}
                                                            getOptionLabel={e => e.label + ' '}
                                                            getOptionValue={e => e.id}
                                                            loadOptions={(search) => Picklist.fetchCurrencyAllowedPicklist(search, 'VTC')} // location = 1 , 2
                                                            onInputChange={Picklist.handleInputChange}
                                                            onChange={handleCurrencyChange}
                                                        >
                                                        </AsyncSelect>
                                                    </Form.Group>
                                                </Col>
                                            }
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Issuer<span className="text-danger">*</span></Form.Label>
                                                    <AsyncSelect
                                                        cacheOptions={false}
                                                        defaultOptions={false}
                                                        value={issuer}
                                                        getOptionLabel={e => e.label + ' '}
                                                        getOptionValue={e => e.id}
                                                        loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 'ISSUER', locationCode)}
                                                        onInputChange={Picklist.handleInputChange}
                                                        onChange={handleIssuerChange}
                                                    >
                                                    </AsyncSelect>
                                                </Form.Group>
                                            </Col>

                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Quantity<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control
                                                        value={trfQuantity}
                                                        onChange={(e) => handleQuantity(e)}
                                                        maxLength={Common.maximumLength}
                                                        placeholder="Quantity" />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>Prefix</Form.Label>
                                                    <Form.Control
                                                        value={trfPrefix}
                                                        onChange={(e) => Common.validateAlpNumValue(e.target.value, setTrfPrefix)}
                                                        maxLength={Common.prefixLength}
                                                        placeholder="Prefix" />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>From<span className="text-danger">*</span></Form.Label><br />
                                                    <Form.Control
                                                        value={trfFrom}
                                                        onChange={(e) => handleFrom(e)}
                                                        maxLength={Common.maxFromLength}
                                                        placeholder="From" />
                                                </Form.Group>
                                            </Col>
                                            <Col className='col-md-3 col-6'>
                                                <Form.Group>
                                                    <Form.Label>To<span className="text-danger">*</span></Form.Label>
                                                    <Form.Control
                                                        value={trfTo}
                                                        disabled
                                                        placeholder="To" />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: "30px" }}>
                                            <Col className='col-md-3 col-6'>
                                                {
                                                    userRight.add === "1" ?
                                                        <Button variant='outline-primary' className='buttonStyle' size='sm' onClick={() => addDetail()}>Add</Button> : null
                                                }
                                            </Col>
                                        </Row>
                                    </> :
                                    null
                            }
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                {userRight.edit === "1" ? <th></th> : null}
                                                <th>ISD</th>
                                                <th>Prefix</th>
                                                <th>Issuer Name</th>
                                                <th>Quantity</th>
                                                <th>From</th>
                                                <th>To</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                innerData.map((res) =>
                                                    <tr key={res.prd_line_no}>
                                                        {
                                                            userRight.delete === "1" ?
                                                                <td className="textCenter" >
                                                                    {
                                                                        scrMode === 'Q' || res.del === 'N' ? null :
                                                                            <>
                                                                                <span className="handCursor colorThemeBlue" onClick={() => deleteItem(res.unique_no)} >
                                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                                </span> {' '}
                                                                            </>
                                                                    }
                                                                    {
                                                                        scrMode === 'Q' ?
                                                                            <span className="handCursor colorThemeBlue" onClick={() => viewDetails(res.prd_srno, res.prd_line_no, res.prd_location, res.prd_type)}>
                                                                                <FontAwesomeIcon icon={faEye} />
                                                                            </span>
                                                                            : null
                                                                    }
                                                                </td>
                                                                : null
                                                        }
                                                        <td>{res.prd_isd}</td>
                                                        <td>{res.prd_prefix}</td>
                                                        <td>{res.prd_issuername}</td>
                                                        <td>{res.prd_qty}</td>
                                                        <td>{res.prd_from}</td>
                                                        <td>{res.prd_to}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                        </>
                }
            </Container>
            <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
                <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
                    {msgTextYN}
                </DialogYesNo>
                <Dialog isOpen={isErrBox} onClose={(e) => setErrBox(false)}>
                    {errText}
                </Dialog>

                <Tran_data_unlock_request logObject={editLogObject} show={showEditRequest} onHide={() => setShowEditRequest(false)} />

            </div>
            <div>
                <Modal size='lg' show={showModal} onHide={() => setShowModal(false)} animation={false} centered >
                    <Modal.Body style={{ height: '84vh', overflowX: 'auto' }}>
                        <Container fluid>
                            <Row>
                                <Col>
                                    <Table responsive striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>From</th>
                                                <th>Sr No.</th>
                                                <th>Out Type</th>
                                                <th>Out Line No.</th>
                                                <th>Out Location</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                subInnerData.map((res) =>
                                                    <tr key={res.prd_line_no}>
                                                        <td>{res.tpb_from}</td>
                                                        <td>{res.tpb_out_srno}</td>
                                                        <td>{res.tpb_out_type}</td>
                                                        <td>{res.tpb_out_line_no}</td>
                                                        <td>{res.tpb_out_location}</td>
                                                        <td></td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-success" onClick={() => setShowModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}

export default Tran_trf_vtc
