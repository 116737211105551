import React from 'react'
import { Image, Row, Col, Container, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

let rightDiv = {
    marginBottom: '1px'
}

let imgLogo = {
    height: '80px'
}

function Login_header() {
    const navigate = useNavigate();
    return (
        <>
            <div >
                <Container fluid>
                    <Row>
                        <Col xs={4}>
                            <Image style={imgLogo} src="img/logo.png" fluid onClick={(e) => navigate('/home')} />
                        </Col>
                        <Col xs={8}>
                            <div className="divContentRight" style={rightDiv}>
                                Zenith Leisure Holidays Limited<br></br>
                                Financial Year : {sessionStorage.getItem('finyear')}&nbsp;({(sessionStorage.getItem("startDate"))?.split('-').reverse().join('/')} &nbsp;{(sessionStorage.getItem("endDate"))?.split('-').reverse().join('/')})<br></br>
                                {sessionStorage.getItem("userId")}:{sessionStorage.getItem("userName")}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Login_header
