import React, { useEffect, useState } from 'react'
import Login_header from '../../Login_header'
import Menu from '../../Menu'
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap'
import Select from "react-select";
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Common from "../../Common";
import Dialog from '../../Dialog';
import { useNavigate } from 'react-router-dom';


const Master_group_doc = () => {

    const sid = sessionStorage.getItem("sessionId");
    const [docType, setDocType] = useState({ value: 0, label: "Select" });
    const [grpType, setGrpType] = useState('');
    const [showAllDoc, setShowAllDoc] = useState([]);
    const [docGrpList, setDocGrpList] = useState([]);
    const [onceRun, setOnceRun] = useState(false);
    const navigate = useNavigate();

    const [userRight, setUserRight] = useState([]);


    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");


    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.callApi(Common.apiRight, [sid, 'all', 'DOCUMENTLINK'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/'); // no query right
                }
            });
            const obj = {
                pageright: "DOCUMENTLINK"
            }
            Common.callApi(Common.apiDocument, [sid, "getdocs", JSON.stringify(obj)], result => {
                setDocGrpList(JSON.parse(result));
            });
            setOnceRun(true);
        }
    }, [onceRun]);

    const addDocGrp = () => {
        var msg = [], i = 0;
        if (grpType === "" || docType.value == "0") {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (grpType === "" ? " Group type." : '');
            msg[i++] = (docType.value == "0" ? " Document." : '');
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            Common.callApi(Common.apiGrpDoc, [sid, "adddoctype", grpType, docType.value], result => {
                console.log(result);
                setDocType({ value: 0, label: "Select" });
                listDocGrp();
            });
        }

    }



    const listDocGrp = () => {
        Common.callApi(Common.apiGrpDoc, [sid, "showdocdata", grpType], result => {
            let res = JSON.parse(result);
            console.log(res);
            setShowAllDoc(res);
        })
    }

    const deleteData = (id) => {
        Common.callApi(Common.apiGrpDoc, [sid, "deldocdata", id], result => {
            console.log(result);
            listDocGrp();
        })
    }

    const handleGroupChange = (e) => {
        setShowAllDoc([]);
        setGrpType(e.target.value);
    }


    return (
        <div>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h3>Document</h3>
                    </Col>
                </Row>

                <Row>
                    <Col className='col-md-3 col-6'>
                        <Form.Group>
                            <Form.Label>Group<span className="mandatory text-danger">*</span></Form.Label>
                            <Form.Select value={grpType} onChange={e => handleGroupChange(e)} >
                                <option value="">Select</option>
                                <option value="DEALER">DEALER</option>
                                <option value="CORPORATE">CORPORATE</option>
                                <option value="AGENT">AGENT</option>
                                <option value="BANK">BANK</option>
                                <option value="CASH">CASH</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>

                    <Col className='col-md-3 col-6'>
                        <Form.Group>
                            <Form.Label>Document<span className="mandatory text-danger">*</span></Form.Label>
                            <Select
                                options={docGrpList} value={docType} onChange={v => setDocType(v)}
                            />
                        </Form.Group>
                    </Col>


                </Row>

                <Row>&nbsp;</Row>

                <Row>
                    <Col>
                        {
                            userRight.query === "1" ?
                                <>&nbsp;
                                    <Button className='buttonStyle' size='sm' variant='outline-success' onClick={() => listDocGrp()}>List</Button>{' '}
                                </>
                                : null
                        }
                        {userRight.add === "1" ?
                            <Button className='buttonStyle' size='sm' variant='outline-primary' onClick={() => addDocGrp()}>Add</Button>
                            : null}
                    </Col>
                </Row>
                <Row>&nbsp;</Row>

                <Row>
                    <Col>
                        <Table striped bordered hover >
                            <thead>
                                <tr>
                                    <th>Group Type</th>
                                    <th>Document</th>
                                    <th>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    showAllDoc.map((val, id) => (
                                        <tr key={id}>
                                            <td>{val.mdl_group_type}</td>
                                            <td>{val.docm_desc}</td>
                                            <td ><span>
                                                <FontAwesomeIcon icon={faTrash} onClick={() => deleteData(val.mdl_srno)} />
                                            </span>
                                            </td>
                                        </tr>
                                    ))
                                }




                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>

            <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                {msgText}
            </Dialog>

        </div>
    )
}

export default Master_group_doc
