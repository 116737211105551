import React, { useEffect, useState } from 'react';
import Login_header from '../../Login_header';
import Menu from '../../Menu';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import * as Picklist from '../../Picklist';
import LocationSelector from '../../LocationSelector';
import * as Common from "../../Common";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import Dialog from '../../Dialog';
import $ from "jquery";



const Ledger = () => {


    const navigate = useNavigate();
    const sid = sessionStorage.getItem('sessionId');
    const [userRight, setUserRight] = useState([]);

    const [typeOption, setTypeOption] = useState('');
    const [selectOption, setSelectOption] = useState('');

    const [showSelect, setShowSelect] = useState('');

    const [showGroupDD, setShowGroupDD] = useState(false);
    const [showLedgerDD, setShowLedgerDD] = useState(false);

    const [showGroupSelectorDD, setShowGroupSelectorDD] = useState(false);
    const [showLedgerSelectorDD, setShowLedgerSelectorDD] = useState(false);


    const [groupValue, setGroupValue] = useState(null);
    const [ledgerValue, setLedgerValue] = useState(null);


    const [groupCode, setGroupCode] = useState('');
    const [groupName, setGroupName] = useState('');

    const [grpCode, setGrpCode] = useState('');
    const [accountCode, setAccountCode] = useState('');
    const [ledgerName, setLedgerName] = useState('');

    // const [selectItems, setSelectItems] = useState([]);


    const [locationCode, setLocationCode] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);
    const [radioType, setRadioType] = useState('A');


    // const [data, setData] = useState([]);
    // const [dataLedger, setDataLedger] = useState([]);

    const [count, setCount] = useState(0);

    const [counter, setCounter] = useState(0);

    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());

    const [onceRun, setOnceRun] = useState(false);

    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    const [individualGroup, setIndividualGroup] = useState([]);
    const [individualLedger, setIndividualLedger] = useState([]);

    const [multipleGroup, setMultipleGroup] = useState([]);
    const [multipleLedger, setMultipleLedger] = useState([]);
    
    const [ledgerType, setLedgerType] = useState("V");
    const [scr, setScr] = useState("");
    const [ledgerReportList, setLedgerReportList] = useState([]);
    


    var msg = [], i = 0;


    useEffect(() => {
        if (onceRun) {
            return;
        } else {
            Common.callApi(Common.apiRight, [sid, 'all', 'LEDGER'], function (result) {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
            });
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);

    const handleTypeChange = (e) => {
        const value = e.target.value;
        setTypeOption(value);
        setShowSelect(true);
        setSelectOption('');
        setShowGroupDD(false);
        setShowLedgerDD(false);
        setShowGroupSelectorDD(false);
        setShowLedgerSelectorDD(false);
        setMultipleGroup([]);
        setMultipleLedger([]);
    }

    const handleSelectChange = (e) => {
        const value = e.target.value;
        setSelectOption(value);

        if (typeOption == '1' && value == '1') {
            setShowGroupDD(true);
            setShowLedgerDD(false);
            setShowGroupSelectorDD(false);
            setShowLedgerSelectorDD(false);
        } else if (typeOption == '1' && value == '2') {
            setShowGroupDD(false);
            setShowLedgerDD(false);
            setShowGroupSelectorDD(true);
            setShowLedgerSelectorDD(false);
        } else if (typeOption == '2' && value == '1') {
            setShowLedgerDD(true);
            setShowGroupDD(false);
            setShowGroupSelectorDD(false);
            setShowLedgerSelectorDD(false);
        } else if (typeOption == '2' && value == '2') {
            setShowLedgerDD(false);
            setShowGroupDD(false);
            setShowGroupSelectorDD(false);
            setShowLedgerSelectorDD(true);
        }
        else {
            setShowGroupDD(false);
            setShowLedgerDD(false);
        }
    }


    const handleLedgerValue = (value) => {
        // setIndividualLedger([]);
        setLedgerValue(value);
        console.log(value);

        var ledger = value.id.split('^');
        setGrpCode(ledger[1]);
        setAccountCode(ledger[2]);
        setLedgerName(ledger[5]);

        if (typeOption == '2' && selectOption == '1') {
            const obj = {
                // uniquekey: Common.getRandomString(15),
                groupCode: ledger[1],
                accountCode: ledger[2],
                // groupName: ledger[3]
            }
            setIndividualLedger(obj);

            // setIndividualLedger(Common.arrayAddItem(individualLedger, obj));
            setCount(count+1);
        }
    }

    const handleGroupValue = (value) => {  
        // setIndividualGroup([]); 
        setGroupValue(value);
        console.log(value);

        var grp = value.id.split('^');
        setGroupCode(grp[1]);
        setGroupName(grp[2]);

        if (typeOption == '1' && selectOption == '1') {
            setIndividualGroup(grp[1]);

            // setIndividualGroup(Common.arrayAddItem(individualGroup, grp[1]));
            setCount(count+1);
        }
    }

    const handleAdd = () => {
        $(".loader").show();

        if ( (typeOption == '1' &&  groupValue == null) || (typeOption == '2'  && ledgerValue == null)) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = ((typeOption == '1' && groupValue == null) ? "Please select group." : '');
            msg[i++] = ((typeOption == '2' && ledgerValue == null) ? "Please select ledger." : '');
            $(".loader").hide();

            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            setCounter(counter + 1);
            console.log(" added");
            console.log(typeOption, 'typeOption');
            console.log(selectOption, 'selectOption');
            if (typeOption == '1' && selectOption == '2') {
                const obj = {
                    uniquekey: Common.getRandomString(15),
                    groupCode: groupCode,
                    groupName: groupName
                }
                console.log(obj);
                setMultipleGroup(Common.arrayAddItem(multipleGroup, obj));
                setGroupValue(null);
                setGroupCode('');
                setGroupName('');
            } else if (typeOption == '2' && selectOption == '2') {
                const obj = {
                    uniquekey: Common.getRandomString(15),
                    groupCode: grpCode,
                    accountCode: accountCode,
                    ledgerName: ledgerName
                }
                console.log(obj);
                setMultipleLedger(Common.arrayAddItem(multipleLedger, obj));
                setLedgerValue(null);
                setGrpCode('');
                setAccountCode('');
                setLedgerName('');
            }
            $(".loader").hide();
            setCount(count + 1);
        }


    }

    const handleDelete = (val) => {
        setCounter(counter - 1);
        setCount(count-1);
        if (typeOption == '1' && selectOption == '2') {
            setMultipleGroup(Common.arrayRemoveItem(multipleGroup, "uniquekey", val));
        } else if (typeOption == '2' && selectOption == '2') {
            setMultipleLedger(Common.arrayRemoveItem(multipleLedger, "uniquekey", val));
        }
    }

    const handleGenerate = () => {
        $(".loader").show();
        if (fromDate == null || toDate == null || typeOption == '' || selectOption == '' || count <= '0' || 
            (radioType == 'O' && locationCode == '0' ) ||(radioType == 'S' &&  selectedItems == [])) {
            msg[i++] = Common.getMessage("MSG0006");
            msg[i++] = (fromDate == null ? "Please select from date." : '');
            msg[i++] = (toDate == null ? "Please select to date." : '');
            msg[i++] = (typeOption == '' ? "Please select ledger type." : '');
            msg[i++] = (selectOption == '' ? "Please select type." : '');
            msg[i++] = (count <= '0' ? "You can't generate without adding group or ledger." : '');
            msg[i++] = ((radioType == 'O' && locationCode == '0' ) ? "Please select location." : '');
            msg[i++] = ((radioType == 'S' &&  selectedItems == []) ? "Please select atleast one location." : '');
            $(".loader").hide();

            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            console.log('clicked');

            const obj = {
                individualGroup: individualGroup,
                individualLedger: individualLedger,

                multipleGroup: multipleGroup,
                multipleLedger: multipleLedger,

                fromDate: Common.dateYMD(fromDate),
                toDate: Common.dateYMD(toDate),

                locationCode: locationCode,
                selectedItems: selectedItems,

                ledgerType: typeOption,
                option: selectOption,
                radioType: radioType
            }
            // console.log(obj);
            if (ledgerType === 'D') {
                Common.callDownloadApiPost(Common.apiFinancialLedger, "post", [sid, 'getData', JSON.stringify(obj)]);
            } else  {
                Common.callApi(Common.apiFinancialLedger, [sid, 'viewData', JSON.stringify(obj)], (result) => {
                    setScr('V');
                    const resData = JSON.parse(result);
                    setLedgerReportList(resData)
                    // console.log(scr);
                });
            }
            $(".loader").hide();

        }
    }

    const handleDataFromChild = (newLocationCode, newSelectedItems, newRadioType) => {
        setLocationCode(newLocationCode);
        setSelectedItems(newSelectedItems);
        setRadioType(newRadioType);
    }

    return (
        <div>
            <Login_header />
            <Menu />

            <Container fluid>
            {scr === "" ?
                    <>
                <Row>
                    <Col>
                        <h2>Ledger <span style={{color:"#f8f9fd"}}>{counter}</span> </h2>
                    </Col>
                </Row>&nbsp;

                <Row>
                    <Col className='col-md-3 col-6'>
                        <Form.Group>
                            <Form.Label>From Date<span className="colorThemeRed">*</span></Form.Label><br />
                            <DatePicker className="form-control"
                                selected={fromDate}
                                onChange={(date) => setFromDate(date)}
                                dateFormat="dd/MM/yyyy"
                                showYearDropdown
                                showMonthDropdown
                                useShortMonthInDropdown
                                dropdownMode="select"
                                peekNextMonth
                                customInput={
                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col className='col-md-3 col-6'>
                        <Form.Group>
                            <Form.Label>To Date<span className="colorThemeRed">*</span></Form.Label><br />
                            <DatePicker className="form-control"
                                selected={toDate}
                                onChange={(date) => setToDate(date)}
                                dateFormat="dd/MM/yyyy"
                                showYearDropdown
                                showMonthDropdown
                                useShortMonthInDropdown
                                dropdownMode="select"
                                peekNextMonth
                                customInput={
                                    <input type="text" size='sm' onKeyUp={(e) => Common.buildDateFormat(e.target.value, e.target)}></input>
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Mode<span className="text-danger">*</span></Form.Label>
                                    <Form.Select value={ledgerType} onChange={e => setLedgerType(e.target.value)}
                                        required>
                                        <option value="V">View</option>
                                        <option value="D">Download</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                </Row>&nbsp;

                <Row>
                    <Col className='col-md-3 col-6'>
                        <Form.Label>Type<span className="colorThemeRed">*</span></Form.Label><br />
                        <select class="form-select" aria-label="Default select example" value={typeOption}
                            onChange={(e) => handleTypeChange(e)} disabled={count > 0}>
                            <option value="" disabled>Select</option>
                            <option value="1">Group</option>
                            <option value="2">Ledger</option>
                        </select>
                    </Col>
                    {
                        showSelect &&
                        <Col className='col-md-3 col-6'>
                            <Form.Label>Select<span className="colorThemeRed">*</span></Form.Label><br />
                            <select class="form-select" aria-label="Default select example" value={selectOption}
                                onChange={(e) => handleSelectChange(e)} disabled={count > 0}>
                                <option value="" disabled>Select</option>
                                <option value="1">One</option>
                                <option value="2">Selective</option>
                            </select>
                        </Col>
                    }
                    {
                        showGroupDD &&
                        <Col className='col-md-3 col-6'>
                            <Form.Group>
                                <Form.Label>Group<span className="colorThemeRed">*</span></Form.Label><br />
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    value={groupValue}
                                    getOptionLabel={e => e.label + ' '}
                                    getOptionValue={e => e.id}
                                    loadOptions={(search) => Picklist.fetchGroupPicklist(search, 0)}
                                    onInputChange={Picklist.handleInputChange}
                                    onChange={handleGroupValue}
                                >
                                </AsyncSelect>
                            </Form.Group>
                        </Col>
                    }
                    {
                        showGroupSelectorDD && <>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Group Selector<span className="colorThemeRed">*</span></Form.Label><br />
                                    <AsyncSelect
                                        cacheOptions
                                        defaultOptions
                                        value={groupValue}
                                        getOptionLabel={e => e.label + ' '}
                                        getOptionValue={e => e.id}
                                        loadOptions={(search) => Picklist.fetchGroupPicklist(search, 0)}
                                        onInputChange={Picklist.handleInputChange}
                                        onChange={handleGroupValue}
                                    >
                                    </AsyncSelect>
                                </Form.Group>
                            </Col>
                        </>
                    }
                    {
                        showLedgerDD &&
                        <Col className='col-md-3 col-6'>
                            <Form.Group>
                                <Form.Label>Ledger<span className="colorThemeRed">*</span></Form.Label><br />
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    value={ledgerValue}
                                    getOptionLabel={e => e.label + ' '}
                                    getOptionValue={e => e.id}
                                    loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 0, 1)}
                                    onInputChange={Picklist.handleInputChange}
                                    onChange={handleLedgerValue}
                                >
                                </AsyncSelect>
                            </Form.Group>
                        </Col>

                    }
                    {
                        showLedgerSelectorDD && <>
                            <Col className='col-md-3 col-6'>
                                <Form.Group>
                                    <Form.Label>Ledger<span className="colorThemeRed">*</span></Form.Label><br />
                                    <AsyncSelect
                                        cacheOptions
                                        defaultOptions
                                        value={ledgerValue}
                                        getOptionLabel={e => e.label + ' '}
                                        getOptionValue={e => e.id}
                                        loadOptions={(search) => Picklist.fetchLedgerPicklistByType(search, 0, 1)}
                                        onInputChange={Picklist.handleInputChange}
                                        onChange={handleLedgerValue}
                                    >
                                    </AsyncSelect>
                                </Form.Group>
                            </Col>
                        </>
                    }

                    {
                        showLedgerSelectorDD || showGroupSelectorDD ?
                            <Col className='col-md-3 col-6' style={{ paddingTop: '35px' }} >
                                <Button variant='outline-success' className='buttonStyle' size='sm' onClick={handleAdd}
                                >Add New</Button>
                            </Col> : null
                    }
                </Row>&nbsp;

                {
                    multipleGroup.length > '0'  && 
                    <Col>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Group Code</th>
                                    <th>Group Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    multipleGroup.map((res, index) => (
                                        <tr key={index}>
                                            <td><span className="handCursor colorThemeBlue"
                                                onClick={() => handleDelete(res.uniquekey)}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </span></td>
                                            <td>{res.groupCode}</td>
                                            <td>{res.groupName}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Col>
                }
                {
                    multipleLedger.length > '0'  && 
                    <Col>
                        <Table striped bordered hover>
                            <thead>
                                <tr><th></th>
                                    <th>Group Code</th>
                                    <th>Account Code</th>
                                    <th>Ledger Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    multipleLedger.map((res, index) => (
                                        <tr key={index}>
                                            <td><span className="handCursor colorThemeBlue"
                                                onClick={() => handleDelete(res.uniquekey)}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </span></td>
                                            <td>{res.groupCode}</td>
                                            <td>{res.accountCode}</td>
                                            <td>{res.ledgerName}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Col>
                }
                <Row>
                    <Col className='col-md-6 col-12'>
                        <LocationSelector onDataFromChild={handleDataFromChild} />
                    </Col>
                </Row>&nbsp;
                <Row>
                    <Col className='col-md-3 col-6'>
                        <Button variant='outline-success' className='buttonStyle' size='sm' onClick={handleGenerate}
                        >Generate</Button>
                    </Col>
                </Row>
                </> : null}
                {scr === "V" ? <>
                    <Row>
                        <Col>
                            <Button onClick={() => setScr('')} variant="primary">Back</Button>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    &nbsp;
                    <Table responsive striped bordered>
                        <thead>
                            <tr>
                                <th>Branch</th>
                                <th>Purchase</th>
                                <th>Transaction</th>
                                <th>Date</th>
                                <th>ISD</th>
                                <th>Deno</th>
                                <th>Quantity</th>
                                <th>Value</th>
                                <th>Rate</th>
                                <th>Net</th>
                                <th>Walk In</th>
                                <th>Manual No.</th>
                                <th>Party</th>
                                <th>Product</th>
                                <th>Issuer</th>
                                <th>VTC Ref</th>
                                <th>Card No</th>
                                <th>Normal/EEFC Flag</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                ledgerReportList.map((item, index) => (
                                    <tr key={index}>
                            <td>{item.prd_location}</td>   {/* Branch */}
                            <td>{item.prd_type}</td>       {/* Purchase */}
                            <td>{item.prd_srno}</td>       {/* Transaction */}
                            <td>{item.formatted_date}</td> {/* Date */}
                            <td>{item.prd_isd}</td>        {/* ISD */}
                            <td>{item.prd_deno}</td>       {/* Deno */}
                            <td>{item.prd_qty}</td>        {/* Quantity */}
                            <td>{item.prd_amount}</td>     {/* Value */}
                            <td>{item.prd_rate}</td>       {/* Rate */}
                            <td>{item.prd_group}</td>      {/* Net */}
                            <td>{item.hdr_sale_type}</td>  {/* Walk In */}
                            <td>{item.hdr_manual}</td>     {/* Manual No. */}
                            <td>{item.entity_name}</td>    {/* Party */}
                            <td>{item.prd_product}</td>    {/* Product */}
                            <td>{item.mvli_ledger_name}</td> {/* Issuer */}
                            <td>{item.corporate_name}</td> {/* VTC Ref */}
                            <td>{item.prd_card_no}</td>    {/* Card No */}
                            <td>{item.hdr_type}</td>       {/* Normal/EEFC Flag */}
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </> : null}
            </Container>

            <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                {msgText}
            </Dialog>


            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>
            <Row>&nbsp;</Row>

        </div>
    )
}

export default Ledger


