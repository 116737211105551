import React, { useState, useEffect } from 'react'
import Login_header from "../../Login_header";
import Menu from "../../Menu";
import { Container, Row, Col, Form, Table, Button, Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as Common from "../../Common";
import * as Picklist from "../../Picklist";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import $ from "jquery";
import AsyncSelect from 'react-select/async';
import Dialog from '../../Dialog';
import DialogYesNo from '../../Dialog_Yes_No';

const Master_agent_linking = (props) => {

    const navigate = useNavigate();
    const sid = sessionStorage.getItem("sessionId");

    const [userRight, setUserRight] = useState([]);
    const [onceRun, setOnceRun] = useState(false);
    const [scrMode, setScrMode] = useState("");

    const [isMsgBoxYN, setMsgBoxYN] = useState(false);
    const [msgTextYN, setMsgTextYN] = useState("");
    const [isMsgBox, setMsgBox] = useState(false);
    const [msgText, setMsgText] = useState("");

    const [data, setData] = useState([]);
    const [agentLocation, setAgentLocation] = useState(null);
    const [location, setLocation] = useState("");
    const [locationCode, setLocationCode] = useState(0);
    const [radioType, setRadioType] = useState("N");

    const [srNo, setSrNo] = useState(0);
    const [agentType, setAgentType] = useState('FX');
    const [agentCode, setAgentCode] = useState('');
    const [agentName, setAgentName] = useState("");
    const [agentAccountName, setAgentAccountName] = useState('');
    const [agentGroupCode, setAgentGroupCode] = useState(0);
    const [agentAccountCode, setAgentAccountCode] = useState(0);
    const [agentAccountNum, setAgentAccountNum] = useState(0);
    const [agentPan, setAgentPan] = useState("");
    const [agentIFSC, setAgentIFSC] = useState('');
    const [agentStatus, setAgentStatus] = useState("1");
    const [agentLinkFile, setAgentLinkFile] = useState("");
    const [filterSearch, setFilterSearch] = useState("AC");
    const [filterTextBox, setFilterTextBox] = useState("");
    const [filterStatus, setFilterStatus] = useState('1');
    const [filterDefault, setFilterDefault] = useState('1');
    const [createdUser, setCreatedUser] = useState('');
    const [updatedUser, setUpdatedUser] = useState('');
    const [agentCompany, setAgentCompany] = useState('C');
    const [gstType, setGstType] = useState("");
    const [faType, setFaType] = useState("")
    const [filterPan, setFilterPan] = useState("");
    const [state, setState] = useState("");
    const [stateList, setStateList] = useState([]);
    const [agentGstIn, setAgentGstIn] = useState("");



    useEffect(() => {
        Common.getActiveBranch().then(response => {
            setAgentLocation(response);
            response != null && handleChangeLocation(response);
        });
        // sessionStorage.activeBranch = location[1];

        if (onceRun) {
            return;
        } else {
            Common.callApi(Common.apiRight, [sid, 'all', 'MASTER_AGENT_LINK'], (result) => {
                let resp = JSON.parse(result);
                if (resp.query === "1") {
                    setUserRight(resp);
                } else {
                    navigate('/');
                }
            });
            if (typeof props.cmmodule != "undefined") {
                setScrMode("A");
                setAgentCode(props.cmmodule.agentcode);
                setLocationCode(props.cmmodule.location);
                setLocation(props.cmmodule.locationname);
            }
            getSatateList();
            setOnceRun(true);
        }
    }, [onceRun, sid, navigate]);

    const handleChangeLocation = (value) => {
        setAgentLocation(value);
        const loc = value.id.split('^');
        setLocationCode(loc[1]);
        setLocation(loc[2]);
    }

    function getSatateList() {
        Common.callApi(Common.apiState, [sid, 'ddlistjson', 1], function (result) {
            setStateList(JSON.parse(result));
        });
    }

    // ========================================================= Fetch Data From DataBase====================================================================
    const listData = () => {
        $(".loader").show();
        if (radioType === 'F' && !filterTextBox && filterSearch !== 'S' && filterSearch !== 'D' && filterSearch !== 'P' &&
            filterSearch !== 'GST' && filterSearch !== 'FA') {
            $(".loader").hide();
            setMsgText("Please Fill Text Box.");
            setMsgBox(true);
        } else {
            const obj = {
                filterRadioType: radioType,
                filterTextBox: filterTextBox,
                filterSearch: filterSearch,
                filterStatus: filterStatus,
                filterDefault: filterDefault,
                location: locationCode,
                filterPan: filterPan,
            }
            Common.callApi(Common.apiAgentLinking, [sid, 'list', JSON.stringify(obj)], (result) => {
                $(".loader").hide();
                let resp = JSON.parse(result);
                setData(resp);
            });
        }
    }

    const addNew = () => {
        if (agentLocation === null) {
            setMsgText("Please Select Location!");
            setMsgBox(true);
        } else {
            setScrMode('A');
            setSrNo(0);
            setAgentCode("");
            setAgentName("");
            setAgentAccountName("");
            setAgentGroupCode("");
            setAgentAccountCode("");
            setAgentAccountNum("");
            setAgentPan("");
            setAgentIFSC("");
            setAgentStatus("1");
            setAgentCompany('C');
            setGstType("");
            setFaType("");
            setAgentGstIn("");
        }
    }

    const editData = () => {
        setScrMode('E');
    }

    const cancelConfirm = () => {
        if (scrMode === "A" || scrMode === "E") {
            setMsgTextYN('Do you really want to exit ?');
            setMsgBoxYN(true);
        } else {
            cancelData();
        }
    }

    const cancelData = () => {
        setMsgBoxYN(false);
        if (typeof props.cmmodule != "undefined") {
            props.cmmodule.setShowAgentForm(false);
        } else {
            setScrMode('');
        }
    }

    // ==================================================================== Insert Data in Database======================================================
    const saveData = () => {
        $(".loader").show();
        var msg = [], i = 0;
        if (agentName === "" || agentAccountName === "" || agentAccountNum === "" || agentIFSC === "" || state === ""
            || agentGroupCode === "" || agentAccountCode === "" || agentPan === "" || gstType === "" || faType === ""
            || (gstType === "WGST" && agentGstIn === "")
            || (agentGstIn && !agentGstIn.includes(agentPan))) {
            $(".loader").hide();
            msg[i++] = Common.getMessage("MSG0006");
            // msg[i++] = (agentCode === "" ? " Agent Code." : '');
            msg[i++] = (agentGroupCode === "" ? " Agent Group." : '');
            msg[i++] = (agentAccountCode === "" ? " Agent Account." : '');
            msg[i++] = (agentName === "" ? " Agent Name." : '');
            msg[i++] = (agentAccountName === "" ? " Agent Account Name." : '');
            msg[i++] = (agentAccountNum === "" ? " Agent Account Number." : '');
            msg[i++] = (agentPan === "" ? " Agent PAN." : '');
            msg[i++] = (agentIFSC === "" ? " Agent IFSC." : '');
            msg[i++] = (gstType === "" ? " GST Type." : '');
            msg[i++] = (faType === "" ? " FA Type." : '');
            msg[i++] = (state === "" ? " State." : '');
            msg[i++] = ((gstType === "WGST" && agentGstIn === "") ? " Agent GstIN." : '');
            msg[i++] = (agentGstIn && !agentGstIn.includes(agentPan)) ? "GST Number does not matching with " : '';
            setMsgText(Common.buildMessageFromArray(msg));
            setMsgBox(true);
        } else {
            const obj = {
                filterRadioType: radioType,
                filterTextBox: filterTextBox,
                filterSearch: filterSearch,
                filterStatus: filterStatus,
                filterDefault: filterDefault,
                location: locationCode,
                srno: srNo,
                agentType: agentType,
                // agentCode: agentCode,
                groupCode: agentGroupCode,
                accountCode: agentAccountCode,
                agentName: agentName,
                agentAccountName: agentAccountName,
                agentAccountNum: agentAccountNum,
                agentPan: agentPan,
                agentIFSC: agentIFSC,
                agentStatus: agentStatus,
                agentCompany: agentCompany,
                gstType: gstType,
                faType: faType,
                state: state,
                gstin: agentGstIn
            }
            Common.callApi(Common.apiAgentLinking, [sid, 'add', srNo, JSON.stringify(obj)], (result) => {
                $(".loader").hide();
                let resp = JSON.parse(result);
                if (resp.msg === "") {
                    setData(resp.agent);
                    if (typeof props.cmmodule != "undefined") {
                        props.cmmodule.setShowAgentForm(false);
                        props.updateAgentDetails(resp.agent);
                    } else {
                        setScrMode('');
                    }
                } else {
                    setMsgText(resp.msg);
                    setMsgBox(true);
                    // setScrMode("");
                }
            });
        }
    }

    //======================================================================= Edit Data ===============================================================================
    const fillEditForm = (srno) => {
        setScrMode('Q');
        Common.callApi(Common.apiAgentLinking, [sid, 'edit', srno], (result) => {
            let resp = JSON.parse(result);
            setSrNo(resp.srno);
            setAgentType(resp.type);
            setAgentCode(resp.agentCode);
            setAgentGroupCode(resp.agentGroup);
            setAgentAccountCode(resp.agentAccount);
            setAgentName(resp.agentName);
            setAgentAccountName(resp.agentAccountName);
            setAgentAccountNum(resp.agentAccountNo);
            setAgentPan(resp.agentPan);
            setAgentIFSC(resp.agentIFSC);
            setAgentStatus(resp.agentStatus);
            setCreatedUser(resp.createdUser);
            setUpdatedUser(resp.updatedUser);
            setAgentCompany(resp.agentCompany);
            setGstType(resp.gstType);
            setFaType(resp.faType);
            setAgentGstIn(resp.gstNumber);
            setState(resp.state);
            // setState({ value: resp.state, label: resp.stateName })
        });
    }

    //================================================================= Uploading File ==================================================================
    const uploadFile = () => {
        console.log(document.getElementById('fileExcel').files[0]);
        $('.loader').show();
        if (document.getElementById('fileExcel').files[0] === undefined) {
            $('.loader').hide()
            setMsgText("Please select a file to upload.");
            setMsgBox(true);
        } else {
            var tempArray = document.getElementById('fileExcel').files[0].name.split('.');
            if (tempArray.length > 2) {
                $('.loader').hide();
                setMsgText("(.) not allowed in file name.");
                setMsgBox(true);
            } else if (document.getElementById('fileExcel').files[0].size > 10 * 1024 * 1024) {  // 10 MB
                $('.loader').hide();
                setMsgText("Please select a file with in 10 MB size.");
                setMsgBox(true);
            } else if (tempArray[1] != 'xlsx') {
                $('.loader').hide();
                setMsgText("File extension should be in xlsx format");
                setMsgBox(true);
            } else {
                const obj = {
                    sid: sid,
                    srno: 6666,
                    srNo: srNo,
                    location: locationCode,
                    AgentLinkFile: agentLinkFile,
                    uploadType: "uploadAgentLink",
                    pageright: "MASTER_AGENT_LINK",

                    filterRadioType: radioType,
                    filterTextBox: filterTextBox,
                    filterSearch: filterSearch,
                    filterStatus: filterStatus,
                    filterDefault: filterDefault,
                }
                Common.uploadApi(JSON.stringify(obj), 'fileExcel', (result) => {
                    let resp = JSON.parse(result);
                    console.log(resp);
                    $('.loader').hide();
                    setData(resp.docdetail);
                    setMsgText(resp.msg)
                    setMsgBox(true);
                    setAgentLinkFile("");

                });
            }
        }
    }

    const exportFile = () => {
        const obj = {
            right: 'MASTER_AGENT_LINK',
        }
        Common.callDownloadApiPost(Common.apiReport, "post", [sid, JSON.stringify(obj)]);
    }

    const dowmloadFormat = () => {
        const obj = {
            srno: 24,
            pageright: 'MASTER_AGENT_LINK',
            downloadType: 'downloadAgentLinkFormat',
        }
        Common.callDownloadApiPost(Common.apiFormat, "post", [sid, 'downloadAgentLinkFormat', JSON.stringify(obj)]);
    }

    function downloadStateCode() {
        const obj = {
            pageright: 'MASTER_AGENT_LINK',
            downloadType: 'downloadStateCode',
        }
        Common.callDownloadApiPost(Common.apiFormat, "post", [sid, "", JSON.stringify(obj)]);
    }

    return (
        <>
            <Login_header />
            <Menu />
            <Container fluid>
                <Row>
                    <Col>
                        <h3>Master Agent Link</h3>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                {
                    scrMode === "" ?
                        <>

                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Location<span className="text-danger">*</span></Form.Label>
                                        <AsyncSelect
                                            cacheOptions={false}
                                            defaultOptions={false}
                                            value={agentLocation}
                                            getOptionLabel={e => e.label + ' '}
                                            getOptionValue={e => e.id}
                                            loadOptions={Picklist.fetchLocationAllowedPicklist}
                                            onInputChange={Picklist.handleInputChange}
                                            onChange={handleChangeLocation}
                                        >
                                        </AsyncSelect>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-4 col-6'>
                                    <Form.Group>
                                        <Row style={{ marginTop: "30px" }}>
                                            <Col>
                                                <Form.Check checked={radioType === "N"} onChange={() => setRadioType("N")} type='radio' label="New" />
                                            </Col><Col>
                                                <Form.Check checked={radioType === "F"} onChange={() => setRadioType("F")} type='radio' label="Filters" />
                                            </Col><Col>
                                                <Form.Check checked={radioType === "U"} onChange={() => setRadioType("U")} type='radio' label="Upload" />
                                            </Col><Col>
                                                <Form.Check checked={radioType === "E"} onChange={() => setRadioType("E")} type='radio' label="Export" />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                {
                                    radioType === 'N' ?
                                        <>
                                            <Col>
                                                <Button className='buttonStyle' variant='outline-primary' size='sm' onClick={() => addNew()}>Add New</Button>
                                                {' '}<Button className='buttonStyle' variant='outline-primary' size='sm' onClick={() => listData()}>List</Button>
                                            </Col>
                                        </>
                                        : radioType === 'F' ?
                                            <>
                                                <Col className='col-md-3 col-6'>
                                                    <Form.Group>
                                                        <Form.Label>Search</Form.Label>
                                                        <Form.Select value={filterSearch}
                                                            onChange={e => setFilterSearch(e.target.value)}
                                                            required>
                                                            <option value="AC">Agent Account</option>
                                                            <option value="AN">Agent Name</option>
                                                            <option value="ACN">Account Number</option>
                                                            <option value="P">PAN Number</option>
                                                            <option value="S">Status</option>
                                                            <option value="GST">GST Tpye</option>
                                                            <option value="FA">FA Type</option>
                                                            <option value="D">Default</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col className='col-md-3 col-6'>
                                                    {
                                                        filterSearch === 'S' ?
                                                            <>
                                                                <Form.Group>
                                                                    <Form.Label>Status</Form.Label>
                                                                    <Form.Select value={filterStatus} onChange={e => setFilterStatus(e.target.value)}
                                                                        required>
                                                                        <option value="1">Active</option>
                                                                        <option value="0">Inactive</option>
                                                                    </Form.Select>
                                                                </Form.Group>
                                                            </>
                                                            : filterSearch === 'D' ?
                                                                <>
                                                                    <Form.Group>
                                                                        <Form.Label>Default</Form.Label>
                                                                        <Form.Select value={filterDefault} onChange={e => setFilterDefault(e.target.value)}
                                                                            required>
                                                                            <option value="1">Yes</option>
                                                                            <option value="0">No</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                </>
                                                                : filterSearch === 'GST' ?
                                                                    <>
                                                                        <Form.Group>
                                                                            <Form.Label>GST Type</Form.Label>
                                                                            <Form.Select value={filterStatus} onChange={e => setFilterStatus(e.target.value)}
                                                                                required>
                                                                                <option value="">Select</option>
                                                                                <option value="WGST">With GST</option>
                                                                                <option value="WOGST">With out GST</option>
                                                                                <option value="HGST">Hold GST</option>
                                                                            </Form.Select>
                                                                        </Form.Group>
                                                                    </>
                                                                    : filterSearch === 'FA' ?
                                                                        <>
                                                                            <Form.Group>
                                                                                <Form.Label>FA Type</Form.Label>
                                                                                <Form.Select value={filterStatus} onChange={e => setFilterStatus(e.target.value)}
                                                                                    required>
                                                                                    <option value="">Select</option>
                                                                                    <option value="BOTH">Both</option>
                                                                                    <option value="COMMISSION">Commision</option>
                                                                                    <option value="NOFA">No FA</option>
                                                                                </Form.Select>
                                                                            </Form.Group>
                                                                        </> :
                                                                        filterSearch === 'P' ?
                                                                            <>
                                                                                <Form.Group>
                                                                                    <Form.Label>PAN Number<span className="text-danger">*</span></Form.Label>
                                                                                    <Form.Control type="text" placeholder="PAN Number" maxLength={10}
                                                                                        value={filterPan}
                                                                                        onChange={(e) => setFilterPan(e.target.value)}
                                                                                        onBlur={(e) => Common.validatePan(e.target.value, setFilterPan)}
                                                                                        autoComplete="off" />
                                                                                </Form.Group>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <Form.Group>
                                                                                    <Form.Label>{filterSearch === "AC" ? "Agent Account" : filterSearch === "AN" ? "Agent Name" : filterSearch === "ACN" ?
                                                                                        "Account Number" : filterSearch === "P" ? "PAN Number" : null}<span className="text-danger">*</span></Form.Label>
                                                                                    <Form.Control value={filterTextBox}
                                                                                        maxLength={100}
                                                                                        placeholder="Search Box"
                                                                                        onChange={(e) => {
                                                                                            filterSearch === "AN" ? Common.validateAlpValue(e.target.value, setFilterTextBox)
                                                                                                : Common.validateNumValue(e.target.value, setFilterTextBox)
                                                                                        }}
                                                                                    />
                                                                                </Form.Group>
                                                                            </>
                                                    }
                                                </Col>
                                                {
                                                    radioType === 'F' ?
                                                        <>
                                                            <Col style={{ marginTop: "35px" }} className='col-md-3 col-6'>
                                                                <Button className='buttonStyle' variant='outline-primary' size='sm' onClick={() => listData()}>List</Button>
                                                            </Col>
                                                        </> : null
                                                }
                                            </>
                                            : radioType === 'U' ?
                                                <>
                                                    <Col className='col-md-3 col-6'>
                                                        <Form.Group controlId="fileExcel">
                                                            <Form.Label>Choose File<span className="text-danger">*</span></Form.Label>
                                                            <Form.Control type="file" value={agentLinkFile} onChange={(e) => setAgentLinkFile(e.target.value)} />
                                                        </Form.Group>
                                                        <span className="mx-2"><Badge bg="primary" className='handCursor'
                                                            onClick={() => dowmloadFormat()}>Download Format</Badge></span>
                                                        <span className="mx-2"><Badge bg="primary" className='handCursor'
                                                            onClick={downloadStateCode}>Download State Code</Badge></span>
                                                    </Col>
                                                    <Col style={{ marginTop: "35px" }}>
                                                        <Button variant="outline-success" className='buttonStyle' size='sm' onClick={() => uploadFile()}>Submit</Button>
                                                        {/* <Button variant="outline-success" className='buttonStyle' size='sm' onClick={() => dowmloadFormat()}>Download</Button> */}
                                                    </Col>
                                                </>
                                                : radioType === 'E' ?
                                                    <>
                                                        <Col>
                                                            <Button className='buttonStyle' variant='outline-success' size='sm' onClick={() => exportFile()}>Export</Button>
                                                        </Col>
                                                    </>
                                                    : null
                                }
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <th>SrNo</th>
                                                <th>Type</th>
                                                <th>Agent Branch</th>
                                                {/* <th>Agent Code</th> */}
                                                <th>Agent Group</th>
                                                <th>Agent Account</th>
                                                <th>Agent Name</th>
                                                <th>Account Number</th>
                                                <th>IFSC Code</th>
                                                <th>Account Name</th>
                                                <th>PAN</th>
                                                <th>User Name</th>
                                                <th>Agent Company</th>
                                                <th>Default</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data.map((res) =>
                                                    <>
                                                        <tr key={res.al_srno}>
                                                            {userRight.edit === "1" ?
                                                                <td className="textCenter">
                                                                    <span className="handCursor colorThemeBlue" onClick={() => fillEditForm(res.al_srno)}>
                                                                        <FontAwesomeIcon icon={faEye} />
                                                                    </span>
                                                                </td>
                                                                : null
                                                            }
                                                            <td>{res.al_srno}</td>
                                                            <td>{res.al_agent_type}</td>
                                                            <td>{res.entity_name}</td>
                                                            {/* <td>{res.al_agent_code}</td> */}
                                                            <td>{res.al_agent_group}</td>
                                                            <td>{res.al_agent_account}</td>
                                                            <td>{res.al_agent_name}</td>
                                                            <td>{res.al_agent_account_no}</td>
                                                            <td>{res.al_agent_ifsc}</td>
                                                            <td>{res.al_agent_account_name}</td>
                                                            <td>{res.al_agent_pan}</td>
                                                            <td>{res.um_user_name}</td>
                                                            <td>{res.al_agent_company === 'C' ? "Company" : "Non-Company"}</td>
                                                            <td>{res.al_default === "1" ? "Yes" : "No"}</td>
                                                            <td>{res.al_status === "1" ? "Active" : "Inactive"}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={15}>
                                                                State : {res.state_name} ,
                                                                GST Type : {res.al_gst_type === "WGST" ? "With GST, " : res.al_gst_type === "WOGST" ? "With Out GST, " : "Hold GST, "}
                                                                {res.al_agent_gstin ? " GSTIN :" + res.al_agent_gstin : ","}
                                                                FA Type : {res.al_fa_type === "NOFA " ? "No FA " : res.al_fa_type === "COMMISSION, " ? "COMMISSION, " : "BOTH, "}
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </> :
                        <>
                            {
                                scrMode !== 'A' ?
                                    <>
                                        <Row>
                                            <Col>
                                                <Form.Label>Location : <b>{location}</b> &nbsp; &nbsp; Created By : <b>{createdUser}</b> &nbsp; &nbsp; Last Created By : <b>{updatedUser}</b></Form.Label>
                                            </Col>
                                        </Row>
                                    </> :
                                    scrMode === 'A' || scrMode === 'E' ?
                                        <>
                                            <Row>
                                                <Col>
                                                    <Form.Label>Location : <b>{location}</b></Form.Label>
                                                </Col>
                                            </Row>
                                        </> : null
                            }
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Type</Form.Label>
                                        <Form.Select value={agentType} onChange={e => setAgentType(e.target.value)}
                                            // disabled={scrMode === 'Q'}
                                            disabled={scrMode !== 'A'}
                                            required>
                                            <option value="FX">FX</option>
                                            {/* <option value="ZX">ZX</option> */}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                {/* <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Agent Code<span className="text-danger">*</span></Form.Label>
                                        <Form.Control value={agentCode}
                                            disabled={scrMode !== 'A' || typeof props.cmmodule != "undefined"}
                                            maxLength={10}
                                            onChange={(e) => Common.validateNumValue(e.target.value, setAgentCode)}
                                            placeholder="Agent Code" />
                                    </Form.Group>
                                </Col> */}
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Agent Group<span className="text-danger">*</span></Form.Label>
                                        <Form.Control value={agentGroupCode}
                                            // disabled={scrMode === 'Q'}
                                            disabled={scrMode !== 'A'}
                                            maxLength={10}
                                            onChange={(e) => Common.validateNumValue(e.target.value, setAgentGroupCode)}
                                            placeholder="Agent Group" />
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Agent Account<span className="text-danger">*</span></Form.Label>
                                        <Form.Control value={agentAccountCode}
                                            // disabled={scrMode === 'Q'}
                                            disabled={scrMode !== 'A'}
                                            maxLength={10}
                                            onChange={(e) => Common.validateNumValue(e.target.value, setAgentAccountCode)}
                                            placeholder="Agent Acccount" />
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label> Agent PAN<span className="text-danger">*</span></Form.Label>
                                        <Form.Control
                                            value={agentPan}
                                            // disabled={scrMode === 'Q'}
                                            disabled={scrMode !== 'A'}
                                            maxLength={10}
                                            onChange={(e) => setAgentPan(e.target.value)}
                                            onBlur={(e) => Common.validatePan(e.target.value, setAgentPan)}
                                            placeholder="Agent PAN" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Agent Name<span className="text-danger">*</span></Form.Label>
                                        <Form.Control value={agentName}
                                            // disabled={scrMode === 'Q'}
                                            disabled={scrMode !== 'A'}
                                            maxLength={100}
                                            onChange={(e) => Common.validateAlpValue(e.target.value, setAgentName)}
                                            placeholder="Agent Name" />
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Agent Account Name<span className="text-danger">*</span></Form.Label>
                                        <Form.Control value={agentAccountName}
                                            // disabled={scrMode === 'Q'}
                                            disabled={scrMode !== 'A'}
                                            maxLength={100}
                                            onChange={(e) => Common.validateAlpValue(e.target.value, setAgentAccountName)}
                                            placeholder="Agent Account Name" />
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Agent Account Number<span className="text-danger">*</span></Form.Label>
                                        <Form.Control value={agentAccountNum}
                                            // disabled={scrMode === 'Q'}
                                            disabled={scrMode !== 'A'}
                                            maxLength={20}
                                            onChange={(e) => Common.validateNumValue(e.target.value, setAgentAccountNum)}
                                            placeholder="Agent Account Number" />
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Agent IFSC<span className="text-danger">*</span></Form.Label>
                                        <Form.Control value={agentIFSC}
                                            // disabled={scrMode === 'Q'}
                                            disabled={scrMode !== 'A'}
                                            maxLength={11}
                                            onChange={(e) => Common.validateAlpNumValue(e.target.value, setAgentIFSC)}
                                            placeholder="Agent IFSC" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>GST Type<span className='text-danger'>*</span></Form.Label>
                                        <Form.Select value={gstType} onChange={e => setGstType(e.target.value)}
                                            // disabled={scrMode === 'Q'}
                                            disabled={scrMode !== 'A'}
                                            required>
                                            <option value="">Select</option>
                                            <option value="WGST">With GST</option>
                                            <option value="WOGST">With out GST</option>
                                            <option value="HGST">Hold GST</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Agent GSTIN {gstType === 'WGST' && <span className='text-danger'>*</span>}</Form.Label>
                                        <Form.Control value={agentGstIn}
                                            disabled={scrMode !== 'A'}
                                            maxLength={15}
                                            onChange={(e) => setAgentGstIn(e.target.value)}
                                            onBlur={(e) => setAgentGstIn(e.target.value.toUpperCase())}
                                            placeholder="Agent GSTIN" />
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>FA Type<span className='text-danger'>*</span></Form.Label>
                                        <Form.Select value={faType} onChange={e => setFaType(e.target.value)}
                                            // disabled={scrMode === 'Q'}
                                            disabled={scrMode !== 'A'}
                                            required>
                                            <option value="">Select</option>
                                            <option value="BOTH">Both</option>
                                            <option value="COMISSION">Commision</option>
                                            <option value="NOFA">No FA</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Agent Company<span className='text-danger'>*</span></Form.Label>
                                        <Form.Select value={agentCompany} onChange={e => setAgentCompany(e.target.value)}
                                            // disabled={scrMode === 'Q'}
                                            disabled={scrMode !== 'A'}
                                            required>
                                            <option value="C">Company</option>
                                            <option value="N">Non-Company</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>State<span className="mandatory text-danger">*</span></Form.Label>
                                        <Form.Select
                                            value={state}
                                            onChange={(e) => setState(e.target.value)}
                                            disabled={scrMode === 'Q'}
                                        >
                                            {stateList.map((res) =>
                                                <option key={res.value} value={res.value}>{res.name}</option>
                                            )}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>
                                    <Form.Group>
                                        <Form.Label>Status</Form.Label>
                                        <Form.Select value={agentStatus} onChange={e => setAgentStatus(e.target.value)}
                                            disabled={scrMode === 'Q'}
                                            required>
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col className='col-md-3 col-6'>&nbsp;</Col>
                                <Col className='col-md-3 col-6'>&nbsp;</Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col>
                                    {
                                        (userRight.edit === "1" && scrMode === 'E') || (userRight.add === "1" && scrMode === "A") ? <>
                                            <Button className='buttonStyle' size='sm' variant='outline-primary' onClick={() => saveData()}>Save</Button> &nbsp;
                                        </> : null
                                    }
                                    {
                                        (userRight.edit === "1" && scrMode === 'Q') ? <>
                                            <Button variant="outline-success" className='buttonStyle' size="sm" onClick={() => editData()}>Edit</Button>&nbsp;
                                        </> : null
                                    }
                                    <Button variant='outline-danger' className='buttonStyle' size='sm' onClick={() => cancelConfirm()}>Back</Button>
                                </Col>
                            </Row>
                        </>
                }
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
                <Row>&nbsp;</Row>
            </Container>
            <div>
                <Dialog isOpen={isMsgBox} onClose={(e) => setMsgBox(false)}>
                    {msgText}
                </Dialog>
                <DialogYesNo isOpen={isMsgBoxYN} onYes={(e) => cancelData()} onNo={(e) => setMsgBoxYN(false)}>
                    {msgTextYN}
                </DialogYesNo>
            </div>
        </>
    )
}

export default Master_agent_linking